import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CommonService } from '../shared/common.service';
import { ProductService } from './product.service';
import { combineLatest, Subscription } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventService, EventType } from '../utilities/event.service';
import { untranslatedMenuNames } from '../static-content/menu-routes';
import { ProductStaticContentComponent } from './static-content/static-content.component';
import { setRecentEntries } from '../utilities/cache.utils';
import { ProductDetails } from './model/product-detail.model';
import {
    transformProductDetails,
    transformProductDetailsV2,
} from './model/product-detail.transformers';
import { SEOServiceService } from '../services/seoservice.service';
import { RibbonStyleService } from '../services/ribbon-style.service';
import { Product } from '../category-deals-list/model/product.model';

@Component({
    selector: 'md-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit, OnDestroy {
    productDetails: ProductDetails;
    productDetailsLoading = true;

    private productDetailsSubscription: Subscription;

    @ViewChild('pdContainer') pdContainer: ElementRef<HTMLElement>;
    @ViewChild('staticContent') staticContent: ProductStaticContentComponent;

    reviewsComponent: ElementRef;
    cmsBlockContentEdited: string;

    constructor(
        private commonService: CommonService,
        private productService: ProductService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private eventService: EventService,
        private seoService: SEOServiceService,
        private router: Router
    ) {}

    ngOnDestroy(): void {
        if (this.productDetailsSubscription) {
            this.productDetailsSubscription.unsubscribe();
        }
    }

    // TODO: these should be on route change and not on init
    ngOnInit(): void {
        // One of pId or productUrl is enought to get the product details but we're using both in case one or the other is missing

        combineLatest([this.route.queryParams, this.route.params]).subscribe(
            ([queryParams, params]) => {
                const productPath = params['productPath'];
                const productId = params['productId'];
                const fromDate: string = queryParams['fromDate'];
                const toDate: string = queryParams['toDate'];
                this.productDetailsSubscription = this.productService
                    .getProductDetailsV2(
                        Number(productId),
                        productPath,
                        fromDate,
                        toDate
                    )
                    .subscribe(([productDetailsDto, productDetailsDtoV2]) => {
                        if (!productDetailsDto || !productDetailsDto.id) {
                            // Navigate to home page if the deal is not found. We don't have a 404 page
                            this.router.navigate(['/']);
                            return;
                        }
                        this.productDetails = transformProductDetailsV2(
                            productDetailsDtoV2,
                            transformProductDetails(productDetailsDto)
                        );
                        this.productDetailsLoading = false;
                        this.cmsBlockContentEdited = this.getCmsBlockContent();
                        this.translate.onLangChange.subscribe(() => {
                            this.cmsBlockContentEdited =
                                this.getCmsBlockContent();
                        });
                        this.commonService.pageTitle =
                            this.getPageTitleBasedOnCategory(
                                this.productDetails
                            );
                        this.productService.addToRecent(this.productDetails.id);
                        setRecentEntries([this.productDetails.id]);
                        this.seoService.updateTitle(
                            this.productDetails.staticContentV2?.information
                                ?.metaTitle
                        );
                        this.seoService.updateDescription(
                            this.productDetails.staticContentV2?.information
                                ?.metaDescription
                        );
                        this.seoService.updateKeyword(
                            this.productDetails.staticContentV2?.information
                                ?.metaKeywords
                        );
                    });
            }
        );
    }

    onReviewSummaryClick(): void {
        this.staticContent.scrollToReviews();
    }

    onDealSelected(): void {
        // const stuff = document.getElementsByClassName('pane')[0];
        // stuff.scrollTo({ top: 0, behavior: 'smooth' });
    }

    getPageTitleBasedOnCategory(productDetails: ProductDetails): string {
        const { category } = productDetails;
        return this.translate.instant(untranslatedMenuNames.get(category));
    }
    getCmsBlockContent(): string {
        const hereString = this.translate.instant('here');
        const cmsBlockContentRaw = this.translate.instant('CMS_CONTENT', {
            link: `<a href="/login" (click)="toLogin()">${hereString}</a>`,
        });

        return cmsBlockContentRaw;
    }

    toLogin(): void {
        this.eventService.emitEvent(EventType.LOGIN);
    }

    getRepeatArray(length: number): number[] {
        return new Array(length);
    }
}
