<md-popup-offcanvas
    #searchLocationOffcanvas
    [mobileModalTitle]="'Where' | translate"
    [mobileContinueButtonText]="'Continue' | translate"
    [mobileContinueButtonClick]="onModalContinueClick"
>
    <div actualControl select="[actualControl]" class="s-loc-container">
        <div
            class="s-loc-option"
            [ngClass]="{
                's-loc-option-selected':
                    locationOption.filterId === selectedLocationOption?.filterId
            }"
            *ngFor="let locationOption of locationOptions"
            (click)="onLocationChanged(locationOption)"
        >
            {{ locationOption.name }}
        </div>
    </div>
    <ng-content targetControl select="[targetControl]"></ng-content>
</md-popup-offcanvas>
