import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../shared/common.service';
import {
    AddEGiftCardToCartResponseDto,
    EGiftAddToCartDto,
    EGiftCardDetailsResponseDto,
} from './egift-card.model';
import { Observable } from 'rxjs';
import { encodeForUrl } from '../utilities/encoders';
import { getCustomerId, getQuoteId } from '../utilities/user.utils';

@Injectable({
    providedIn: 'root',
})
export class EgiftCardService {
    customerId: string;

    constructor(
        private httpClient: HttpClient,
        private commonService: CommonService
    ) {
        const customerData = localStorage.getItem('marideal-pwa_user');
        if (customerData) {
            this.customerId = JSON.parse(customerData).data.id;
        }
    }

    getEGiftDetails(): Observable<EGiftCardDetailsResponseDto> {
        return this.httpGet<EGiftCardDetailsResponseDto>(
            this.commonService.api_url +
                'giftCardProductDetails?service=giftCardProductDetails&store=' +
                this.commonService.getStoreID() +
                '&customerid=' +
                this.customerId +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID()
        );
    }

    getProductDetail(newProductId: number) {
        return this.httpGet(
            this.commonService.api_url +
                'productdetaildescription?store=' +
                this.commonService.getStoreID() +
                '&service=productdetaildescription&productid=' +
                newProductId +
                '&customerid=' +
                this.customerId +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID()
        );
    }

    uploadGiftCardImage(fd: FormData) {
        return this.httpClient.post(
            this.commonService.api_url +
                'uploadGiftCardImage?store=' +
                this.commonService.getStoreID() +
                '&service=uploadGiftCardImage' +
                '&customerid=' +
                this.customerId +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            fd,
            {
                headers: this.commonService.getTokenHeaders(),
            }
        );
    }

    addToCart(
        productData: EGiftAddToCartDto
    ): Observable<AddEGiftCardToCartResponseDto> {
        const productDataString = encodeForUrl(JSON.stringify(productData));
        const customerId = getCustomerId();
        const quoteId = getQuoteId();

        const addtoCartUrl =
            this.commonService.api_url +
            'addCartItem?store=' +
            this.commonService.getStoreID() +
            '&currency=MUR&service=addCartItem&customerid=' +
            customerId +
            '&quoteid=' +
            quoteId +
            '&products=' +
            productDataString +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();

        return this.httpGet(addtoCartUrl);
    }

    // TODO: This needs to be moved into its own service and shared everywhere
    httpGet<T>(url: string): Observable<T> {
        return this.httpClient.get<T>(url, {
            headers: this.commonService.getTokenHeaders(),
        });
    }
}
