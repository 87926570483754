<div class="c-os-title-container">
    <h2 class="c-os-title">{{ "Order Summary" | translate }}</h2>
    <div class="c-os-items-in-cart">
        {{ cartItems.length | i18nPlural : itemPluralMapping }}
    </div>
</div>
<div class="c-os-item-container">
    <md-cart-items
        [items]="cartItems"
        [afterCartUpdated]="afterCartUpdated"
        [uncheckGiftAndApplyStoreCredit]="uncheckGiftAndApplyStoreCredit"
    />
    <md-booking-summary
        [cart_total_amount]="cart_total_amount"
        [coupon_code_discount]="coupon_code_discount"
        [store_discount_amount]="store_discount_amount"
        [rw_discountAmount]="rw_discountAmount"
        [discountCodeTitle]="discountCodeTitle"
        [giftcard]="giftcard"
        [gift_discount]="gift_discount"
        [rewardPts]="rewardPts"
        [servicFeeVal]="servicFeeVal"
        [totalPrice]="totalPrice"
        [desktopOnly]="true"
    />
</div>
