import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TripService } from './trip.service';
import { CommonService } from 'src/app/shared/common.service';
import { Router } from '@angular/router';
import { appConfig } from '../../config/app-config';
import { EventService, EventType } from '../../utilities/event.service';
import { getUser } from '../../utilities/user.utils';
import { User } from '../../model/user.model';
import { ProfileSection } from '../profile-wrapper.component';
import { screenSizes } from 'src/app/utilities/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare let $;

@Component({
    selector: 'app-trips',
    templateUrl: './trips.component.html',
    styleUrls: ['./trips.component.scss'],
})
export class TripsComponent implements OnInit, AfterViewInit {
    @Input() public menuItems: any;
    public loginStatus!: number;
    public loginResponse: User;
    public userLogin: boolean = false;
    public customerId: any;
    public currentPage: number = 0;
    public pageSize: number = 5;
    public category: any;
    public selectedCategoryOption: any;
    public categoryData: any = [];
    public locations: any = [];
    public divIndex: any = 0;
    public upcomingTrips: any = [];
    public vouchersTrips: any = [];
    public pastTrips: any = [];
    public TotalPage: any = [];
    public locationData: any = [];
    public totalLength: any = 0;
    public IsLoading!: boolean;
    public selectedLocCount: any;
    public isTripData: boolean = true;
    paginationLimit: any;
    selectedProductForReviewId: number;

    throttle = 300;
    scrollDistance = 1;
    scrollUpDistance = 2;
    direction = '';
    triploation: any = [];
    CurrentPage: number = 1;
    appConfig = appConfig;
    isNewTripsCall: boolean = false;
    isPastTripsCall: boolean = false;

    @ViewChild('modalNewReviewForm') modalNewReviewForm: TemplateRef<unknown>;

    constructor(
        private tripSvc: TripService,
        private eventService: EventService,
        private router: Router,
        private common: CommonService,
        private modalService: NgbModal
    ) {}

    getPastTrips(resetPage = false) {
        if (resetPage == true) {
            this.currentPage = 0;
            this.isTripData = true;
        }

        let userData = getUser();
        if (userData) {
            let uId = userData.id || '';
            if (
                this.TotalPage > this.pastTrips.length ||
                this.currentPage == 0
            ) {
                this.currentPage += 1;
                if (this.currentPage > 1) {
                    this.loadmoredatacalled = true;
                }
                let selectedCategory = this.selectedCategoryOption;
                console.log('Selected category', selectedCategory);
                if (selectedCategory == '0' || selectedCategory == undefined) {
                    selectedCategory = 'all';
                }

                let pastTripsURL =
                    this.common.api_url +
                    'mypasttrips?service=mypasttrips&customerid=' +
                    uId +
                    '&pagesize=' +
                    this.pageSize +
                    '&currentpage=' +
                    this.currentPage +
                    '&category=' +
                    selectedCategory +
                    '&store=' +
                    this.common.getStoreID() +
                    '&customer_group_id=' +
                    this.common.getCustomerGroupID();

                this.tripSvc.getPastTrip(pastTripsURL).subscribe(
                    (response: any) => {
                        this.isPastTripsCall = true;
                        if (
                            response != undefined &&
                            response.status != undefined &&
                            response.status == -1
                        ) {
                            // this.logout();
                            this.common.startProgress();
                            this.common.generalError = true;
                            this.common.generalErrorMsg = response.message;
                            this.common.endProgress();
                        }
                        if (this.currentPage == 1) {
                            this.pastTrips = response.PastTrips;
                        } else {
                            this.pastTrips = this.pastTrips.concat(
                                response.PastTrips
                            );
                        }
                        this.pastTrips.sort((a: any, b: any) => {
                            let keyA = a.checkin
                                    ? new Date(a.checkin)
                                    : new Date(a.expiry),
                                keyB = b.checkin
                                    ? new Date(b.checkin)
                                    : new Date(b.expiry);
                            return keyB.getTime() - keyA.getTime();
                        });

                        this.nomore = this.pastTrips.length < this.TotalPage;
                        this.loadmoredatacalled = false;

                        this.stopLoader();
                    },
                    () => {
                        this.isTripData = false;
                        this.common.endProgress();
                    }
                );
            }
        }
    }
    stopLoader() {
        if (this.isNewTripsCall && this.isPastTripsCall) {
            this.isTripData = false;
        }
    }

    getTrips(resetPage = false) {
        this.upcomingTrips = [];
        this.vouchersTrips = [];
        this.triploation = [];
        if (resetPage == true) {
            this.CurrentPage = 1;
        }
        let userData = getUser();
        if (userData) {
            let uId = userData.id || '';

            /**** Removed the progress bar ****/
            // this.common.startProgress();
            let selectedCategory = this.selectedCategoryOption;
            if (selectedCategory == '0' || selectedCategory == undefined) {
                selectedCategory = 'all';
            }
            let tripsUrl =
                this.common.api_url +
                'mynewtrips?service=mynewtrips&customerid=' +
                uId +
                '&category=' +
                selectedCategory +
                '&currentpage=' +
                this.CurrentPage +
                '&pagesize=' +
                this.pageSize +
                '&store=' +
                this.common.getStoreID() +
                '&customer_group_id=' +
                this.common.getCustomerGroupID();
            /**** letiable to show the skeleton for the mytrips ****/

            this.tripSvc.getNewTrips(tripsUrl).subscribe(
                (response: any) => {
                    this.isNewTripsCall = true;

                    if (
                        response != undefined &&
                        response.status != undefined &&
                        response.status == -1
                    ) {
                        // this.logout();
                        this.common.startProgress();
                        this.common.generalError = true;
                        this.common.generalErrorMsg = response.message;
                        this.common.endProgress();
                    }
                    this.upcomingTrips = response.UpcomingTrips;
                    this.upcomingTrips.sort((a, b) => {
                        let keyA = new Date(a.checkin),
                            keyB = new Date(b.checkin);
                        return keyA.getTime() - keyB.getTime();
                    });
                    this.vouchersTrips = response.Vouchers;
                    this.vouchersTrips.sort((a, b) => {
                        let keyA = new Date(a.expiry),
                            keyB = new Date(b.expiry);
                        return keyA.getTime() - keyB.getTime();
                    });

                    this.locationData = this.locations = response.location;
                    this.totalLength =
                        this.upcomingTrips.length + this.vouchersTrips.length;
                    console.log(this.totalLength);
                    this.common.endProgress();

                    this.stopLoader();
                },
                (error) => {
                    this.isTripData = false;
                    this.common.endProgress();
                }
            );
        }
    }

    ngOnInit(): void {
        this.checkLoginResponse();
        let userData = getUser();
        let uId = userData ? userData.id : '';

        let selectedCategory = this.selectedCategoryOption;
        if (selectedCategory == '0' || selectedCategory == undefined) {
            selectedCategory = 'all';
        }

        let pastTripsCountUrl =
            this.common.api_url +
            'mypasttripscount?service=mypasttripscount&customerid=' +
            uId +
            '&category=' +
            selectedCategory +
            '&store=' +
            this.common.getStoreID() +
            '&customer_group_id=' +
            this.common.getCustomerGroupID();
        this.tripSvc
            .getPastTripCount(pastTripsCountUrl)
            .subscribe((res: any) => {
                // this.common.generalError = true;
                // this.common.generalErrorMsg = 'Total Records: '+res.total_records;
                this.TotalPage = parseInt(res.total_records); //for new way

                if (this.pastTrips.length >= this.TotalPage) {
                    this.nomore = false;
                } else {
                    this.nomore = true;
                }
            });
    }

    ngAfterViewInit() {
        let innerH = window.innerHeight;
        $('.trips-list-page').css({
            'min-height': innerH + 'px',
        });
        setTimeout(() => {
            this.loadScrollobserver();
        }, 2000);
    }

    position: any;
    showfilter = !0;
    showAlert = !0;

    loadScrollobserver() {
        let contentPan = $('body');
        this.position = contentPan.scrollTop();
        contentPan.scroll(() => {
            let scroll = contentPan.scrollTop();
            if (scroll > this.position) {
                this.showfilter = !1;
                // this.$evalAsync();
                this.loadscrollmoredata(
                    contentPan.scrollTop(),
                    contentPan.height(),
                    $('.trips-parent-div').height()
                );
            } else {
                this.showfilter = !0;
                // this.$evalAsync()
            }
            if (scroll < 1) {
                this.showfilter = !0;
                // this.$evalAsync()
            }
            this.position = scroll;
        });
    }

    loadmoredatacalled: boolean = false;
    nomore: boolean = true;

    loadscrollmoredata(scroll_top, scroll_height, document_height) {
        if (this.nomore && !this.loadmoredatacalled) {
            this.getPastTrips();
        }
        // if (scroll_top + scroll_height > document_height - 100) {
        //
        // }
    }

    checkLoginResponse() {
        let userdata = getUser();
        if (userdata) {
            this.loginResponse = userdata;
            this.customerId = this.loginResponse?.id;
            this.userLogin = true;
            this.getNewInitial();
            this.getTrips();
            this.getPastTrips();
            // this.getTripDetails('all');
        } else {
            this.redirectToLoginPage();
            return false;
        }
    }

    writeReview(categoryProduct: any) {
        this.router.navigate(['/writereview/' + categoryProduct.product_id], {
            state: {
                details: categoryProduct.product_id,
                outlet_name: categoryProduct.outlet_name,
            },
        });
    }

    getNewInitial() {
        this.tripSvc.getNewInitial().subscribe((response: any) => {
            if (response) {
                this.categoryData.push({
                    name: 'All',
                    id: 'all',
                    url: '',
                });
                for (let catDataFromApi of response.categories) {
                    this.categoryData.push(catDataFromApi);
                }
                this.selectedCategoryOption = this.categoryData[0].id;
            }
        });
    }

    downloadTripsClick(tripsProduct: any) {
        let pdfUrl = tripsProduct.pdf_url;
        let platform = this.common.getCache(this, 'platform')
            ? this.common.getCache(this, 'platform').data
                ? this.common.getCache(this, 'platform').data
                : ''
            : '';
        if (platform == '' || platform == null) {
            window.open(pdfUrl, '_blank');
        } else {
            if (platform == 'iOS') {
                (
                    window as any
                ).webkit.messageHandlers.customLinkClick.postMessage(pdfUrl);
            } else if (platform == 'android') {
                (window as any).AndroidShareHandler.customLinkClick(pdfUrl);
            }
        }
    }

    repeatThis(n: number): Array<number> {
        return Array(n);
    }

    getLocationCount(locationId: number) {
        let count = 0;
        if (this.locations) {
            let location = this.locations.filter(
                (obj: any) => obj.id == locationId
            );
            if (location) {
                count = location[0].count;
            }
        }
        return count;
    }

    showMyTrip(categoryProduct: any) {
        this.router.navigate(['/customer/trips-detail'], {
            state: { tripDetail: categoryProduct },
        });
    }

    getLocationPrecent(myLocation: any) {
        let totalCount = 0;
        let selectedLocCount = parseInt(myLocation.count);
        this.locations.forEach((location: any, key: any) => {
            totalCount = totalCount + parseInt(location.count);
        });
        let locationPrecent = (selectedLocCount / totalCount) * 100;
        locationPrecent = Math.round(locationPrecent);
        return locationPrecent;
    }

    getTripsLineChartColor(location: any) {
        const className =
            'progress custom-trips-screen-div-9 border' + location.id;
        return className;
    }

    categoryOptionSelected(option: any) {
        // this.getTripDetails(option.id);
        this.selectedCategoryOption = option.id;
        this.getTrips(true);
        this.getPastTrips(true);
    }

    getTripsDate(strDate: any) {
        let tripDate = new Date(strDate);
        let dd: number | string = tripDate.getDate();
        dd = dd < 10 ? '0' + dd : dd;
        let mm: number | string = tripDate.getMonth() + 1;
        mm = mm < 10 ? '0' + mm : mm;
        let yyyy = tripDate.getFullYear().toString().substr(-2);
        return dd + '/' + mm + '/' + yyyy;
    }

    redirectToLoginPage() {
        this.eventService.emitEvent(EventType.LOGIN, '/customer/trips');
    }

    openModal(
        content: TemplateRef<unknown>,
        categoryProduct: { product_id: number }
    ): void {
        const screenWidth = window.innerWidth;
        this.selectedProductForReviewId = categoryProduct.product_id;
        if (screenWidth > screenSizes.mobile) {
            this.modalService.open(content, { size: 'md', scrollable: true });
        } else {
            this.modalService.open(content, {
                fullscreen: true,
                scrollable: true,
            });
        }
    }

    onReviewSubmitted(): void {
        this.modalService.dismissAll();
    }

    protected readonly ProfileSection = ProfileSection;
}
