import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SettingsService } from './settings.service';
import {
    CountryISO,
    PhoneNumberFormat,
    SearchCountryField,
} from '@capgo/ngx-intl-tel-input';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { appConfig } from '../../../config/app-config';
import { TranslateService } from '@ngx-translate/core';
import { getUser } from '../../../utilities/user.utils';
import { User } from '../../../model/user.model';
import { ProfileSection } from '../../profile-wrapper.component';
import { EventService, EventType } from 'src/app/utilities/event.service';

declare let $: any;

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    loginStatus!: number;
    private subscription: Subscription;
    public loginResponse: User;
    public userLogin: boolean = false;
    public customerId: any;
    customerVerificationInfoResponse: any;
    privacySettingsResponse: any;
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    accountVerification!: UntypedFormGroup;
    emailVerifyOtpForm!: UntypedFormGroup;
    mobileVerifyOtpForm!: UntypedFormGroup;
    changePasswordForm!: UntypedFormGroup;
    deleteAccountForm!: UntypedFormGroup;
    downloadPersonalDataForm!: UntypedFormGroup;
    imgCdn = '';
    verifyVerifiedImg: string = '';
    showChangePasswordBlock: boolean = false;
    showPersonalDataBlock: boolean = false;
    showDeleteAccountBlock: boolean = false;
    verifyEmailOtpPopup: boolean = false;
    verifyMobileOtpPopup: boolean = false;
    showRequiredFieldMsg: boolean = false;
    showRequiredFieldMsgInPersonalDataBlock: boolean = false;
    showPassBlockInDeleteAcc: boolean = false;
    showRequiredFieldMsgInDeleteAcc: boolean = false;
    isSettingsData: boolean = false;
    CountryPlaceholder: string = this.translate.instant('Search Country');

    sectionBeingEdited: 'password' | 'personalData' | 'deleteAccount' | '' = '';

    constructor(
        private router: Router,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private eventService: EventService
    ) {
        this.checkLoginResponse();
    }

    ngOnInit(): void {
        this.accountVerification = new UntypedFormGroup({
            email: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
            ]),
            mobile: new UntypedFormControl('', [
                Validators.pattern('[0-9]{0-10}'),
            ]),
        });

        this.emailVerifyOtpForm = new UntypedFormGroup({
            emailVerifyOtp: new UntypedFormControl('', [
                Validators.maxLength(4),
            ]),
        });

        this.mobileVerifyOtpForm = new UntypedFormGroup({
            mobileVerifyOtp: new UntypedFormControl('', [
                Validators.maxLength(4),
            ]),
        });

        this.changePasswordForm = new UntypedFormGroup({
            currentPassword: new UntypedFormControl('', [Validators.required]),
            newPassword: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(8),
            ]),
            confirmPassword: new UntypedFormControl('', [Validators.required]),
        });

        this.downloadPersonalDataForm = new UntypedFormGroup({
            currentPassword: new UntypedFormControl('', [Validators.required]),
        });

        this.deleteAccountForm = new UntypedFormGroup({
            consentCheckbox: new UntypedFormControl('', [
                Validators.requiredTrue,
            ]),
            currentPassword: new UntypedFormControl('', [Validators.required]),
        });
    }

    checkLoginResponse() {
        let userdata = getUser();
        if (userdata) {
            this.loginResponse = userdata;
            this.customerId = this.loginResponse?.id;
            this.userLogin = true;
            this.getCustomerVerificationInfo();
            this.getPrivacySettings();
        }
    }

    dialcode: string = '';
    getCustomerVerificationInfo() {
        this.isSettingsData = true;
        this.settingsService
            .getCustomerVerificationInfo(this.customerId)
            .subscribe(
                (response: any) => {
                    this.isSettingsData = false;
                    this.customerVerificationInfoResponse = response;

                    setTimeout(() => {
                        this.dialcode = this.getcountryCodeForMob();
                        this.customerVerificationInfoResponse.customer_mobile =
                            this.customerVerificationInfoResponse.customer_mobile.replace(
                                this.dialcode,
                                ''
                            );
                    }, 500);
                },
                (error) => {
                    this.isSettingsData = false;
                    console.log(error);
                }
            );
    }

    getPrivacySettings() {
        this.settingsService.getPrivacySettings().subscribe(
            (response: any) => {
                this.privacySettingsResponse = response;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    updateProfile() {
        this.verifyMobile();
        if (
            this.customerVerificationInfoResponse.customer_mobile ==
            this.accountVerification.controls['mobile'].value?.e164Number
        ) {
            if (
                this.accountVerification.controls['email'].value != '' &&
                this.accountVerification.controls['email'].value !=
                    this.customerVerificationInfoResponse.customer_email &&
                this.accountVerification.controls['email'].status != 'INVALID'
            ) {
                let encodedMobileObj = btoa(
                    JSON.stringify({
                        billphone:
                            this.accountVerification.controls['mobile'].value
                                ?.e164Number,
                    })
                );
                let encodedEmailObj = btoa(
                    JSON.stringify({
                        email: this.accountVerification.controls['email'].value,
                    })
                );
                this.settingsService
                    .mofluidUpdateEmailMobile(
                        this.customerId,
                        encodedMobileObj,
                        encodedEmailObj
                    )
                    .subscribe((response: any) => {
                        this.getCustomerVerificationInfo();
                    });
            }
        }
    }

    findVerifyVerifiedImg(condition: boolean) {
        if (condition) {
            this.verifyVerifiedImg = this.imgCdn + 'assets/img/Verified.png';
        } else if (!condition) {
            this.verifyVerifiedImg = this.imgCdn + 'assets/img/Verify.png';
        }
        return this.verifyVerifiedImg;
    }

    verifyEmail() {
        if (this.accountVerification.controls['email'].status == 'INVALID') {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant("Email id can't be empty")
            );
            return;
        } else {
            this.settingsService
                .verifyEmail(this.customerId)
                .subscribe((response: any) => {
                    if (response.status == 'success') {
                        this.verifyEmailOtpPopup = true;
                    } else if (response.status == 'error') {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            response.message
                        );
                        return;
                    }
                });
        }
    }

    emailOtpInputChange(event: any) {
        if (event.target.value.length == 4) {
            $('#login-otp-input').attr('disabled', 'disabled');
            this.verifyEmailOtp();
        }
    }

    verifyEmailOtp() {
        let otp = this.emailVerifyOtpForm.controls['emailVerifyOtp'].value;
        this.settingsService
            .verifyEmailOtp(this.customerId, otp)
            .subscribe((response: any) => {
                if (response.status == 'error') {
                    this.eventService.emitEvent(
                        EventType.MODAL_ERROR,
                        this.translate.instant('Please enter correct OTP')
                    );
                } else if (response.status == 'success') {
                    this.verifyEmailOtpPopup = false;
                    this.getCustomerVerificationInfo();
                }
            });
    }

    resendEmailOtp() {
        this.verifyEmail();
    }

    verifyMobile() {
        if (
            this.customerVerificationInfoResponse.customer_mobile !=
            this.accountVerification.controls['mobile'].value?.e164Number
        ) {
            if (
                this.accountVerification.controls['mobile'].value.number
                    .length < 6
            ) {
                this.eventService.emitEvent(
                    EventType.MODAL_ERROR,
                    this.translate.instant("Mobile number can't be empty")
                );
                return;
            } else {
                let encodedNewMob = btoa(
                    this.accountVerification.controls['mobile'].value
                        ?.e164Number
                );
                this.settingsService
                    .verifyMobile(this.customerId, encodedNewMob)
                    .subscribe((response: any) => {
                        if (response.status == 'success') {
                            this.verifyMobileOtpPopup = true;
                        } else if (response.status == 'error') {
                            this.eventService.emitEvent(
                                EventType.MODAL_ERROR,
                                response.message
                            );
                            return;
                        }
                    });
            }
        }
    }

    mobileOtpInputChange(event: any) {
        if (event.target.value.length == 4) {
            $('#login-otp-input').attr('disabled', 'disabled');
            this.verifyMobileOtp();
        }
    }

    verifyMobileOtp() {
        let otp = this.mobileVerifyOtpForm.controls['mobileVerifyOtp'].value;
        let encodedNewMob = btoa(
            this.accountVerification.controls['mobile'].value?.e164Number
        );
        this.settingsService
            .verifyMobileOtp(this.customerId, otp, encodedNewMob)
            .subscribe((response: any) => {
                if (response.status == 'error') {
                    this.eventService.emitEvent(
                        EventType.MODAL_ERROR,
                        this.translate.instant('Please enter correct OTP')
                    );
                } else if (response.status == 'success') {
                    this.verifyMobileOtpPopup = false;
                    if (
                        this.accountVerification.controls['email'].value !=
                            '' &&
                        this.accountVerification.controls['email'].value !=
                            this.customerVerificationInfoResponse
                                .customer_email &&
                        this.accountVerification.controls['email'].status !=
                            'INVALID'
                    ) {
                        let encodedMobileObj = btoa(
                            JSON.stringify({
                                billphone:
                                    this.accountVerification.controls['mobile']
                                        .value?.e164Number,
                            })
                        );
                        let encodedEmailObj = btoa(
                            JSON.stringify({
                                email: this.accountVerification.controls[
                                    'email'
                                ].value,
                            })
                        );
                        this.settingsService
                            .mofluidUpdateEmailMobile(
                                this.customerId,
                                encodedMobileObj,
                                encodedEmailObj
                            )
                            .subscribe((response: any) => {
                                this.getCustomerVerificationInfo();
                            });
                    }
                }
            });
    }

    resendMobileOtp() {
        this.verifyMobile();
    }

    changePassword() {
        if (
            this.changePasswordForm.value.currentPassword == '' ||
            this.changePasswordForm.value.newPassword == '' ||
            this.changePasswordForm.value.confirmPassword == ''
        ) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant(
                    'Password should be at least 6 characters'
                )
            );
            this.showRequiredFieldMsg = true;
        } else if (
            this.changePasswordForm.value.newPassword.length < 6 ||
            this.changePasswordForm.value.confirmPassword.length < 6
        ) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant(
                    'Password should be at least 6 characters'
                )
            );
        } else if (
            this.changePasswordForm.value.newPassword !=
            this.changePasswordForm.value.confirmPassword
        ) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant(
                    'New password and Confirm password do not match'
                )
            );
        } else {
            let encodedOldPass = btoa(
                this.changePasswordForm.value.currentPassword
            );
            let encodedNewPass = btoa(
                this.changePasswordForm.value.confirmPassword
            );
            this.settingsService
                .changePassword(
                    this.customerId,
                    this.customerVerificationInfoResponse.customer_email,
                    encodedOldPass,
                    encodedNewPass
                )
                .subscribe((response: any) => {
                    if (response.change_status == 0) {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            response.message
                        );
                    } else if (response.change_status == 1) {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            response.message
                        );
                        this.router.navigate(['rewardpoints']);
                    }
                });
        }
    }

    appConfig = appConfig;

    downloadPersonalData() {
        if (
            this.downloadPersonalDataForm.value.currentPassword == '' ||
            this.downloadPersonalDataForm.value.currentPassword == undefined
        ) {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant(
                    'Password should be at least 6 characters'
                )
            );
            this.showRequiredFieldMsgInPersonalDataBlock = true;
        } else {
            let encodedCurrentPass = btoa(
                this.downloadPersonalDataForm.value.currentPassword
            );
            this.settingsService
                .downloadPrivacyCsv(this.customerId, encodedCurrentPass)
                .subscribe((response: any) => {
                    if (response.status == 0) {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            response.errorMessage
                        );
                    } else if (response.status == 1) {
                        window.location.href = response.pdfUrl;
                    }
                });
        }
    }

    onCheckboxChange(e: any) {
        if (e.target.checked) {
            this.showPassBlockInDeleteAcc = true;
            this.showRequiredFieldMsgInDeleteAcc = false;
        } else {
            this.showPassBlockInDeleteAcc = false;
        }
    }

    deleteAccount() {
        if (
            this.deleteAccountForm.controls['consentCheckbox'].value[0] ==
                false ||
            this.deleteAccountForm.controls['consentCheckbox'].value == false
        ) {
            this.showRequiredFieldMsgInDeleteAcc = true;
        } else if (
            this.deleteAccountForm.controls['consentCheckbox'].value == true
        ) {
            if (
                this.deleteAccountForm.value.currentPassword == '' ||
                this.deleteAccountForm.value.currentPassword == undefined
            ) {
                this.eventService.emitEvent(
                    EventType.MODAL_ERROR,
                    this.translate.instant(
                        'Password should be at least 6 characters'
                    )
                );
                this.showRequiredFieldMsgInDeleteAcc = true;
            } else {
                let encodedCurrentPass = btoa(
                    this.deleteAccountForm.value.currentPassword
                );
                this.settingsService
                    .deleteRequest(this.customerId, encodedCurrentPass)
                    .subscribe((response: any) => {
                        if (response.status == 0) {
                            this.eventService.emitEvent(
                                EventType.MODAL_ERROR,
                                response.errorMessage
                            );
                        } else if (response.status == 1) {
                            this.eventService.emitEvent(
                                EventType.MODAL_ERROR,
                                response.errorMessage
                            );
                            this.router.navigate(['customer/account']);
                        }
                    });
            }
        }
    }

    repeatThis(n: number): Array<number> {
        return Array(n);
    }

    notLoggedInUserRedirectToHomepage() {
        this.router.navigate(['/']);
    }
    getcountryCodeForMob() {
        let selectedCodes = document.querySelectorAll('.selected-dial-code');
        let codes = selectedCodes[0].innerHTML;
        if (selectedCodes.length > 1) {
            codes = selectedCodes[1].innerHTML;
        }
        return codes;
    }

    protected readonly ProfileSection = ProfileSection;
}
