<!-- <div style="overflow: hidden; margin-top: 4rem;" *ngIf="userLogin"> -->
<div class="dummy-category-listing-item" *ngIf="isRecentData">
    <div class="dummy-category-listing-single-item">
        <div class="dummy-category-listing-single-item-img animated-background"></div>
        <div class="dummy-category-listing-single-item-fulldesc">
            <div class="dummy-category-listing-single-item-name animated-background"></div>
            <div class="dummy-category-listing-single-item-desc animated-background"></div>
            <div class="dummy-category-listing-single-item-locprice">
                <div class="dummy-category-listing-single-item-loc animated-background"></div>
                <div class="dummy-category-listing-single-item-price animated-background"></div>
            </div>
        </div>
    </div>
    <div class="dummy-category-listing-single-item">
        <div class="dummy-category-listing-single-item-img animated-background"></div>
        <div class="dummy-category-listing-single-item-fulldesc">
            <div class="dummy-category-listing-single-item-name animated-background"></div>
            <div class="dummy-category-listing-single-item-desc animated-background"></div>
            <div class="dummy-category-listing-single-item-locprice">
                <div class="dummy-category-listing-single-item-loc animated-background"></div>
                <div class="dummy-category-listing-single-item-price animated-background"></div>
            </div>
        </div>
    </div>
    <div class="dummy-category-listing-single-item">
        <div class="dummy-category-listing-single-item-img animated-background"></div>
        <div class="dummy-category-listing-single-item-fulldesc">
            <div class="dummy-category-listing-single-item-name animated-background"></div>
            <div class="dummy-category-listing-single-item-desc animated-background"></div>
            <div class="dummy-category-listing-single-item-locprice">
                <div class="dummy-category-listing-single-item-loc animated-background"></div>
                <div class="dummy-category-listing-single-item-price animated-background"></div>
            </div>
        </div>
    </div>
</div>
<div style="overflow: hidden;" *ngIf="recentProductsViewedLength > 0">
    <div class="row" style="margin-top: 0.8rem; border: 0.1rem solid  #CED4DA;" *ngFor="let data of recentProductsViewed let i = index">
        <a (click)="getUrlPathName(data.id, data.product_url, data.cat_id[0], data.advance_search_optionid)" class="col-sm-12" style="text-decoration: none;">
          <swiper [config]="config">
            <div class="discount" *ngIf="(data.saved_price/data.price)*100 as percent">
              <div class="innerDiscount innerDiscount2">-{{percent | number:'2.0-0'}}
                <sup style="text-align: left;">%</sup>
              </div>
            </div>
            <div class="hightlight-ribbon" *ngIf="data.deal_type != ''">
              {{data.deal_type.label}}
            </div>
            <div class="swiper-wrapper">
              <img class="swiper-slide" *ngFor="let img of data['banner_images']" [src]="img" [alt]="img" style="width: 100%; height: 20.5rem;" />
            </div>
            <div class="swiper-pagination" ></div>
            <div style="padding: 0.5rem 0.8rem;">
              <div class="title-rating" >
                <strong style="font-size: 1.4rem; color:#092e41; font-weight: 700; text-transform: capitalize;">{{data.name}}</strong>
              </div>
              <div class="hotel-short-description">{{data.short_description}}
              </div>
              <div class="row" style="margin-top: 0.4rem; justify-content: space-between;">
                  <div class="col-6" style="margin-top: 0.4rem; width: fit-content;">
                    <img [src]="imgCdn+'assets/img/General_Icons_Location_-_AI_Blue3_24x24px.svg'" alt="loc" style="width: 2.1rem; ">
                    <span style="font-size: 1.2rem; font-weight: 600; color: #6C757D;"> {{data.city_location}}</span>
                  </div>
                  <div class="col-3 " style="margin-top: 0.4rem; width: fit-content; display: flex; align-items: baseline;">
                    <h5 class="text-decoration-line-through" style="color: #919290; font-weight: 400; font-size: 1.3rem; margin: 0;">{{data.currencysymbol}}{{data.price | number:'1.0-0'}}</h5>
                    <h3 class="recent-actual-price" style="font-size: 1.6rem; padding-left: 0.5rem; margin: 0; font-weight: 700;">{{data.currencysymbol}}{{data.actualprice | number:'1.0-0'}}</h3>
                  </div>
              </div>
          </div>
          </swiper>
        </a>
      </div>
</div>

<div class="userNotLogin" style="overflow: hidden;" *ngIf="recentProductsViewedLength == 0">
    <div style="background: #f7f7f7;">
      <h2 style="padding: 1.5rem 0; font-size: 1.8rem; text-align: center; color: #5d5d5d;">{{"No Recent Products" | translate}}</h2>
    </div>
</div>
