import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { CalendarDayInfo } from './calendar.component';

export const transformNgbDateToDayJs = (date: NgbDateStruct): dayjs.Dayjs => {
    return dayjs(`${date.year}-${date.month}-${date.day}`);
};

export const transformNgbDateToYmd = (date: NgbDateStruct): string => {
    if (!date) {
        return undefined;
    }

    const day = String(date.day).padStart(2, '0');
    const month = String(date.month).padStart(2, '0');
    
    return `${date.year}-${month}-${day}`;
};

export const createDateString = (date: NgbDateStruct): string => {
    if (!date) {
        return undefined;
    }

    const day = String(date.day).padStart(2, '0');
    const month = String(date.month).padStart(2, '0');
    return `${day}-${month}-${date.year}`;
};

export const createDateFromString = (date: string): NgbDate => {
    const dateParts = date.split('-');
    return new NgbDate(
        parseInt(dateParts[2], 10),
        parseInt(dateParts[1], 10),
        parseInt(dateParts[0], 10)
    );
};

export const addDaysToDateStruct = (
    date: NgbDateStruct,
    days: number
): NgbDate => {
    const dayJsDate = transformNgbDateToDayJs(date);
    const newDate = dayjs(dayJsDate).add(days, 'day');

    return new NgbDate(newDate.year(), newDate.month() + 1, newDate.date());
};

export const generateDatesNextMonths = (
    month: number
): Map<string, CalendarDayInfo> => {
    const additionalCalendarDayInfo = new Map<string, CalendarDayInfo>();
    // Get the current date
    const currentDate = new Date();
    // Iterate over the next six months
    for (let i = 0; i < month; i++) {
        // Get the year and month for the current iteration
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + i;

        // Adjust month and year if it goes beyond December
        if (month >= 12) {
            year += Math.floor(month / 12);
            month = month % 12;
        }

        // Get the number of days in the current month
        const numberOfDays = new Date(year, month + 1, 0).getDate();

        // Generate day information for each day in the current month
        for (let day = 1; day <= numberOfDays; day++) {
            // const date = new Date(year, month, day);
            const date: NgbDateStruct = {
                year: year,
                month: month + 1,
                day: day,
            }; // NgbDateStruct format

            if (dayjs().isAfter(transformNgbDateToDayJs(date), 'day')) {
                continue;
            }
            const dateAsString = createDateString(date); // Assuming you have a function to format date as string

            additionalCalendarDayInfo.set(dateAsString, {
                date: date,
                minLengthOfStay: 1,
            });
        }
    }

    return additionalCalendarDayInfo;
};
