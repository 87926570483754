import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CurrencyLanguageService,
    LocaleData,
} from './currency-language.service';
import { Observable, shareReplay, switchMap } from 'rxjs';
import { getApiUrlV2 } from '../utilities/url.utils';

export interface Section {
    sectionId: number;
    sectionName: string;
    status: 0 | 1;
    ordering: number;
    icon: string;
}

export interface SectionsDto {
    total: number;
    sections: Section[];
}

@Injectable({
    providedIn: 'root',
})
export class SectionsService {
    constructor(
        private httpClient: HttpClient,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    private sectionsCache: { [key: string]: Observable<SectionsDto> } = {};

    getSections(): Observable<SectionsDto> {
        return this.currencyLanguageService.getLocaleData().pipe(
            switchMap((localeData: LocaleData) => {
                const cacheKey = localeData.language;
                if (!this.sectionsCache[cacheKey]) {
                    this.sectionsCache[cacheKey] = this.httpClient
                        .get<SectionsDto>(
                            `${getApiUrlV2()}results/sections?lang=${
                                localeData.language
                            }`
                        )
                        .pipe(shareReplay(1));
                }
                return this.sectionsCache[cacheKey];
            })
        );
    }
}
