import { getPathFromProductUrl } from 'src/app/utilities/url.utils';
import { Product, ProductDto } from './product.model';
import { DealDto } from 'src/app/model/deal.model';

export interface CategoryProducts {
    data: Array<Product>;
    total: number;
}

export interface CategoryProductsDto {
    bannerData: BannerDataDto;
    category: string;
    data: Array<ProductDto>;
    is_promotion_activated: number | boolean;
    promotion_data: PromotionDataDto;
    promotion_upto: Date;
    stay_advance_search_deal: string;
    total: number;
}

export interface BannerData {
    banner1: Array<Banner>;
    banner2: Array<Banner>;
}

export interface BannerDataDto {
    banner1: Array<BannerDto>;
    banner2: Array<BannerDto>;
}

export interface Banner {
    categoryId: string;
    createdAt: Date;
    filename: string;
    filenameDesktop: string;
    fromDate: Date;
    id: string;
    isProduct: boolean;
    location: string;
    productData: Array<{}>;
    status: string;
    storeIds: string;
    title: string;
    toDate: Date;
    updatedAt: Date;
    urlString: string;
}

export interface BannerDto {
    categoryid: string;
    created_at: Date;
    filename: string;
    filename_desktop: string;
    from_date: Date;
    id: string;
    is_product: boolean;
    location: string;
    product_data: Array<{}>;
    status: string;
    store_ids: string;
    title: string;
    to_date: Date;
    updated_at: Date;
    urlstring: string;
}

export interface PromotionData {
    afterBackgroundImage: string;
    beforeBackgroundImage: string;
    counterBackgroundColor: string;
    counterDigitBackgroundColor: string;
    counterDigitColor: string;
    counterDigitTextColor: string;
    isPromotionActivated: number;
    isPromotionStarted: number;
    promotionEndDate: Date;
    promotionEndDateIos: Date;
    promotionHomepageCategoryColor: number;
    promotionStartDate: Date;
    promotionStartDateIos: Date;
}

export interface PromotionDataDto {
    after_background_image: string;
    before_background_image: string;
    counter_background_color: string;
    counter_digit_background_color: string;
    counter_digit_color: string;
    counter_digit_text_color: string;
    is_promotion_activated: number;
    is_promotion_started: number;
    promotion_end_date: Date;
    promotion_end_date_ios: Date;
    promotion_homepage_category_color: number;
    promotion_start_date: Date;
    promotion_start_date_ios: Date;
}

export const transformProductListDtoToCategoryProducts = (
    raw: ProductListDto
): CategoryProducts => {
    return {
        data: raw.deals.map((deal) => transformDeal(deal)),
        total: raw.totalHits,
    } as CategoryProducts;
};

// TODO: this needs to be in some shared location along with the DealDto and the Product
export const transformDeal = (raw: DealDto): Product => {
    return {
        id: raw.dealId,
        name: raw.dealTitle,
        percentDiscount: raw.percentDiscount,
        shortDescription: raw.subTitle,
        actualPrice: raw.baseSellingPrice,
        price: raw.baseCrossedOutPrice,
        productUrl: raw.url,
        productPath: getPathFromProductUrl(raw.url),
        rating: raw.starRating,
        starCount: raw.starRating,
        bannerImages: raw.images.map((image) => image.imgUrl),
        categoryId: raw.categoryId.toString(),
        catId: [raw.categoryId.toString()],
        average: raw.outletReviews?.reviewScoreCard?.reviewsScore,
        bookedMsg: undefined,
        cityLocation: raw.townName,
        dealType: undefined,
        eventEndDate: undefined,
        eventStartDate: undefined,
        savedPrice: raw.baseCrossedOutPrice - raw.baseSellingPrice,
        ratingLabel: undefined,
        rwRatingPercentage: undefined,
        isDisplayTimer: false,
        displayTimer: '',
        ribbon: raw.ribbon
    };
};

export interface ProductListDto {
    totalHits: number;
    deals: DealDto[];
}
