import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { appConfig } from 'src/app/config/app-config';
import { CommonService } from 'src/app/shared/common.service';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from '../../config/locale';
import { EventService, EventType } from '../../utilities/event.service';
import { getUser } from '../../utilities/user.utils';
import { User } from '../../model/user.model';

@Component({
    selector: 'app-order-review',
    templateUrl: './order-review.component.html',
    styleUrls: ['./order-review.component.scss'],
})
// TODO: this may not be used by anything anymore
export class OrderReviewComponent implements OnInit {
    localeTxt: any;
    orderdata: any;
    public appConfig = appConfig;
    ipaytext = '';
    mipstext = this.translate.instant(
        'Payment Successful!\nBooking Confirmation & Invoice has been sent to your Email Address, Thank you for Choosing MariDeal'
    );
    localeBtn: any;
    paymentStatus: any;
    orderReviewSkeleton: boolean = false;
    public loginStatus!: number;
    private subscription: Subscription;
    public loginResponse: User;
    public userLogin: boolean = false;
    public customerId: any;
    payment_data: any;

    constructor(
        private router: Router,
        private eventService: EventService,
        private common: CommonService,
        private translate: TranslateService,
        private localeObj: Locale
    ) {}

    ngOnInit(): void {
        this.localeBtn = this.localeObj.getButtonMessages();
        this.localeTxt = this.localeObj.getTextMessages();
        this.checkLoginResponse();
        this.orderdata = JSON.parse(
            this.common.getCache(this, 'orderdata').data
        );
        if (this.orderdata.address == '' || this.orderdata.address == ' ') {
            this.orderdata.address = '-';
        }
        if (this.orderdata.contactno == '' || this.orderdata.contactno == ' ') {
            this.orderdata.contactno = '-';
        }
        this.paymentStatus = 'success';
        if (this.orderdata.payment_method == 'ipay') {
            this.ipaytext = this.translate.instant(
                'Payment Successful!\nBooking Confirmation & Invoice has been sent to your Email Address, Thank you for Choosing MariDeal'
            );
            if (
                this.orderdata.orderStatus == 2 ||
                this.orderdata.orderStatus == '2'
            ) {
            } else {
                this.paymentStatus = 'success';
                this.paymentStatus = 'failure';
            }
        }

        if (this.orderdata.payment_method == 'paypal_standard') {
            this.payment_data = JSON.parse(
                localStorage.getItem('payment_data')
            );
        }
        this.common.removeCache(this, 'cartItems');
        this.common.removeCache(this, 'cartItemsCount');
        this.common.removeCache(this, 'cartItemsdataCount');
    }

    checkLoginResponse() {
        let userdata = getUser();
        if (userdata) {
            this.loginResponse = userdata;
            this.customerId = this.loginResponse?.id;
            this.userLogin = true;
        } else {
            this.eventService.emitEvent(EventType.LOGIN);
        }
    }

    continueShoping() {
        this.common.removeCache(this, 'orderdata');
        this.router.navigate(['/']);
    }
}
