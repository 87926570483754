<md-profile-wrapper [activeSection]="ProfileSection.ORDERS">
    <div
        style="position: relative"
        class="order-component-parent-div"
        *ngIf="userLogin"
    >
        <!-- Skeleton -->
        <div class="order-page-skeleton" *ngIf="isOrdersData">
            <div *ngFor="let repeat of repeatThis(6)" class="order-skeleton">
                <div class="animated-background order-skeleton-div"></div>
                <div class="box row order-skeleton-div-1">
                    <div
                        class="image-box animated-background order-skeleton-div-2"
                    ></div>
                    <div class="order-skeleton-div-3">
                        <div
                            class="animated-background order-skeleton-div-4"
                            *ngFor="let repeat of repeatThis(4)"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="all-orders-area search-results"
            *ngIf="!isOrdersData"
            infiniteScroll
            [infiniteScrollDistance]="3"
            [infiniteScrollThrottle]="50"
            (scrolled)="
                allOrdersVisible == true
                    ? onScrollGetAllOrders()
                    : pendingOrdersVisible == true
                    ? onScrollGetPendingOrders()
                    : completeOrdersVisible == true
                    ? onScrollGetCompleteOrders()
                    : canceledOrdersVisible == true
                    ? onScrollGetCanceledOrders()
                    : ''
            "
            [scrollWindow]="false"
        >
            <div class="type-of-orders">
                <div class="all">
                    <h2
                        class="all-title"
                        (click)="getAllOrders()"
                        [ngClass]="allOrdersVisible == true ? 'active' : ''"
                    >
                        {{ "All" | translate }}
                    </h2>
                </div>
                <div class="Pending">
                    <h2
                        class="Pending-title"
                        (click)="getPendingOrders()"
                        [ngClass]="pendingOrdersVisible == true ? 'active' : ''"
                    >
                        {{ "Pending" | translate }}
                    </h2>
                </div>
                <div class="Complete">
                    <h2
                        class="Complete-title"
                        (click)="getCompleteOrders()"
                        [ngClass]="
                            completeOrdersVisible == true ? 'active' : ''
                        "
                    >
                        {{ "Complete" | translate }}
                    </h2>
                </div>
                <div class="Cancelled">
                    <h2
                        class="Cancelled-title"
                        (click)="getCanceledOrders()"
                        [ngClass]="
                            canceledOrdersVisible == true ? 'active' : ''
                        "
                    >
                        {{ "Cancelled" | translate }}
                    </h2>
                </div>
            </div>
            <div *ngFor="let orderPage of ordersArray" class="orders-array">
                <div *ngIf="orderPage.total > 0">
                    <div
                        *ngFor="let orders of orderPage.data"
                        class="individual-order"
                    >
                        <div
                            [ngClass]="orders.status.toLowerCase()"
                            class="parent-box-details"
                        >
                            <div
                                (click)="orderExpendClick(orders)"
                                class="order-date"
                            >
                                <h3>
                                    {{ orders.order_date | date : "dd/MM/yy" }}
                                </h3>
                                <img
                                    class="down-arrow-img"
                                    [src]="
                                        imgCdn +
                                        'assets/img/order/orderArrow.png'
                                    "
                                    alt="down-arrow"
                                    [ngStyle]="{
                                        transform: orders.isExpend
                                            ? 'none'
                                            : 'rotate(180deg)'
                                    }"
                                />
                            </div>
                            <div
                                class="order-details"
                                [ngClass]="
                                    orders.status == 'Complete'
                                        ? 'four-child-div'
                                        : 'three-child-div'
                                "
                            >
                                <div class="order-id">
                                    <h3>{{ "Order #" | translate }}</h3>
                                    <p>{{ orders.order_id }}</p>
                                </div>
                                <div class="order-grand-total">
                                    <h3>{{ "Order Total" | translate }}</h3>
                                    <p>
                                        {{
                                            orders.grand_total
                                                | currency
                                                    : currencySymbol
                                                    : "symbol"
                                                    : "1.0-2"
                                                    : locale
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="order-status"
                                    [ngClass]="orders.status.toLowerCase()"
                                >
                                    <h3>
                                        {{
                                            orders.status == "Pending"
                                                ? ("Pending" | translate)
                                                    ? ("Pending" | translate)
                                                    : "Pending"
                                                : orders.status == "Complete"
                                                ? ("Complete" | translate)
                                                    ? ("Complete" | translate)
                                                    : "Complete"
                                                : orders.status == "Closed"
                                                ? ("Closed" | translate)
                                                    ? ("Closed" | translate)
                                                    : "Closed"
                                                : orders.status == "Processing"
                                                ? ("Processing" | translate)
                                                    ? ("Processing" | translate)
                                                    : "Processing"
                                                : orders.status ==
                                                  "Partial_refund"
                                                ? ("Partial Refund" | translate)
                                                    ? ("Partial Refund"
                                                      | translate)
                                                    : "Partial Refund"
                                                : ("Cancelled" | translate)
                                                ? ("Cancelled" | translate)
                                                : "Cancelled"
                                        }}
                                    </h3>
                                    <div
                                        *ngIf="
                                            orders.status == 'Pending' &&
                                            orders.payment_link
                                        "
                                        (click)="makePaymentClick(orders)"
                                        class="order-make-payment"
                                    >
                                        <h3>
                                            {{ "Make Payment" | translate }}
                                        </h3>
                                    </div>
                                </div>
                                <div
                                    *ngIf="orders.status == 'Complete'"
                                    (click)="
                                        vatInvoiceClick(
                                            orderPage.invoice_status,
                                            orderPage.company_profile_data,
                                            orders
                                        )
                                    "
                                    style="width: 18%"
                                    class="order-Vat-Invoice"
                                >
                                    <h3>{{ "VAT Invoice" | translate }}</h3>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="orders.isExpend" class="expanded-order">
                            <div *ngFor="let innerProducts of orders.product">
                                <div class="expanded-order-image">
                                    <img [src]="innerProducts.image" />
                                </div>
                                <div class="expanded-order-details">
                                    <div class="expanded-order-title">
                                        <h3>
                                            {{ innerProducts.name | titlecase }}
                                        </h3>
                                    </div>
                                    <div class="custom-order-screen-div-22">
                                        {{ getCategoryName(innerProducts) }}
                                    </div>
                                    <div
                                        class="expanded-order-below-title-detail"
                                    >
                                        <div
                                            class="check-in-out-area"
                                            *ngIf="
                                                innerProducts.check_ins &&
                                                innerProducts.check_outs &&
                                                innerProducts.check_ins !=
                                                    '0000-00-00 00:00:00' &&
                                                innerProducts.check_outs !=
                                                    '0000-00-00 00:00:00'
                                            "
                                        >
                                            <div
                                                *ngIf="
                                                    innerProducts.check_ins !=
                                                    ''
                                                "
                                            >
                                                <img
                                                    [src]="
                                                        imgCdn +
                                                        'assets/img/order/tripCheckIn.png'
                                                    "
                                                    alt="check-in-img"
                                                    class="small-icon"
                                                />
                                                <span>{{
                                                    getOrderDate(
                                                        innerProducts.check_ins
                                                    )
                                                }}</span>
                                            </div>
                                            <div
                                                *ngIf="
                                                    innerProducts.check_outs !=
                                                        '' &&
                                                    innerProducts.check_ins !=
                                                        innerProducts.check_outs
                                                "
                                            >
                                                <img
                                                    [src]="
                                                        imgCdn +
                                                        'assets/img/order/tripCheckOut.png'
                                                    "
                                                    alt="check-out-img"
                                                    class="small-icon"
                                                />
                                                <span>{{
                                                    getOrderDate(
                                                        innerProducts.check_outs
                                                    )
                                                }}</span>
                                            </div>
                                        </div>
                                        <div
                                            class="expiry-send-date-area"
                                            *ngIf="
                                                !innerProducts.check_ins &&
                                                !innerProducts.check_outs &&
                                                innerProducts.expiry
                                            "
                                        >
                                            <div
                                                *ngIf="
                                                    innerProducts.expiry != ''
                                                "
                                            >
                                                <img
                                                    [src]="
                                                        imgCdn +
                                                        'assets/img/order/tripExpiry.png'
                                                    "
                                                    alt="expire-date-img"
                                                    class="small-icon"
                                                />
                                                <span
                                                    >Expires
                                                    {{
                                                        getOrderDate(
                                                            innerProducts.expiry
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                            <div
                                                *ngIf="
                                                    innerProducts.day_to_send !=
                                                        '' &&
                                                    innerProducts.sku ==
                                                        'gift-voucher'
                                                "
                                            >
                                                <img
                                                    [src]="
                                                        imgCdn +
                                                        'assets/img/order/tripCheckIn.png'
                                                    "
                                                    alt="day-to-send-img"
                                                    class="small-icon"
                                                />
                                                <span>{{
                                                    getOrderDate(
                                                        innerProducts.day_to_send
                                                    )
                                                }}</span>
                                            </div>
                                        </div>
                                        <div
                                            class="download-order-pdf-area"
                                            *ngIf="
                                                innerProducts.pdf_url &&
                                                innerProducts.pdf_url != ''
                                            "
                                        >
                                            <img
                                                [src]="
                                                    imgCdn +
                                                    'assets/img/tripDownload.png'
                                                "
                                                alt="download-icon"
                                                (click)="
                                                    downloadOrderPdfClick(
                                                        innerProducts
                                                    )
                                                "
                                                class="small-icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="no-orders" *ngIf="orderPage.total == 0">
                    <h2>{{ "You have no Orders" | translate }}</h2>
                </div>
            </div>
        </div>
    </div>
</md-profile-wrapper>
