import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    NgbDate,
    NgbDatepicker,
    NgbDatepickerConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerService } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-service';

@Component({
    selector: 'ngbd-datepicker-popup',
    templateUrl: './datepicker-popup.component.html',
})
export class NgbdDatepickerPopup implements OnInit {
    model;
    @Output() fromDateEvent = new EventEmitter();
    @Output() toDateEvent = new EventEmitter();
    @Input() dateField: any;

    _fg: UntypedFormGroup;

    @ViewChild('d') datePicker: NgbDatepicker;

    clear() {
        // This is the core of the hack
        (this.datePicker['_service'] as NgbDatepickerService).select(
            new NgbDate(2000, 1, 1)
        );
        this._fg.reset();
    }

    constructor(private _fb: UntypedFormBuilder, private config: NgbDatepickerConfig) {
        const current = new Date();
        config.minDate = {
            year: current.getFullYear(),
            month: current.getMonth() + 1,
            day: current.getDate(),
        };
        //config.maxDate = { year: 2099, month: 12, day: 31 };
        config.outsideDays = 'hidden';

        if (this.dateField == 'fromDate') {
            this.model.value = '';
        }

        if (this.dateField == 'toDate') {
            this.model.value = '';
        }
    }

    ngOnInit() {
        this._fg = this._fb.group({
            date: [null, [Validators.required]],
        });
        this._fb.control('date').patchValue('');
    }

    submit() {
        console.log(this._fg.value);
    }

    dateSelected() {
        if (this.dateField == 'fromDate') {
            this.fromDateEvent.emit(this._fg.value.date);
        }

        if (this.dateField == 'toDate') {
            this.toDateEvent.emit(this._fg.value.date);
        }
        console.log('date selected', this._fg.value.date);
    }
}
