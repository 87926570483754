import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { RecentlyViewedDto } from 'src/app/home/home.model';
import { CommonService } from 'src/app/shared/common.service';
import { encodeForUrl } from 'src/app/utilities/encoders';
import { RECENTLY_VIEWED_DEALS } from 'src/app/utilities/local-storage.keys';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { getCustomerId } from 'src/app/utilities/user.utils';

@Injectable({
    providedIn: 'root',
})
export class RecentlyViewedService {
    constructor(
        private httpClient: HttpClient,
        private common: CommonService
    ) {}

    /**
     * Service Method to get recently viewed data
     */
    getRecentlyViewedData(): Observable<RecentlyViewedDto> {
        const storedRecentIds: string[] =
            JSON.parse(localStorage.getItem(RECENTLY_VIEWED_DEALS)) || [];
        storedRecentIds.reverse();
        const encodedRecentIds: string = encodeForUrl(
            JSON.stringify(storedRecentIds)
        );

        const customerId = getCustomerId();

        // TODO: all of these localstorage methods should be in their own service
        const api_url =
            getApiUrlMofluid() +
            'getNewRecentProducts?service=getNewRecentProducts&product_arr=' +
            encodedRecentIds +
            '&customerid=' +
            customerId +
            '&store=' +
            this.common.getStoreID() +
            '&customer_group_id=' +
            this.common.getCustomerGroupID();
        return this.httpClient
            .get(api_url, { headers: this.common.getTokenHeaders() })
            .pipe(
                map((res: RecentlyViewedDto) => {
                    return res;
                })
            );
    }
}
