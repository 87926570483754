<div *ngFor="let serviceCategory of productDetails.serviceOptionCategories">
    <h3 class="pd-spa-title">{{ serviceCategory.name }}</h3>
    <label
        *ngFor="let servOption of serviceCategory.options"
        class="pd-spa-service-option"
        [for]="servOption.id"
    >
        <input
            type="radio"
            name="serviceOption"
            [id]="servOption.id"
            [(ngModel)]="selectedServiceOption"
            [value]="servOption"
        />
        <div class="pd-spa-service-option-details">
            <div class="pd-spa-service-option-data">
                <span class="pd-spa-service-option-name">{{
                    servOption.name
                }}</span>
                <div>
                    <span class="pd-spa-service-option-fullPrice">
                        {{
                            servOption.fullPrice
                                | currency
                                    : currencySymbol
                                    : "symbol"
                                    : "1.0-2"
                                    : locale
                        }}
                    </span>
                    <span class="pd-spa-service-option-price">
                        {{
                            servOption.price
                                | currency
                                    : currencySymbol
                                    : "symbol"
                                    : "1.0-2"
                                    : locale
                        }}
                    </span>
                </div>
            </div>
            <div class="pd-spa-service-option-discout">
                -{{
                    ((servOption.fullPrice - servOption.price) /
                        servOption.fullPrice) *
                        100 | number : "2.0-0"
                }}%
            </div>
        </div>
    </label>
</div>
