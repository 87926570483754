import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CommonService} from 'src/app/shared/common.service';

@Injectable({
    providedIn: 'root'
})
export class StoreCreditService {

    constructor(private commonService: CommonService, private httpClient: HttpClient) {
    }

    findStoreCredit(customerId: any, currentPage: any) {
        return this.httpClient.get(this.commonService.api_url + "findStoreCredit?service=findStoreCredit&customerid=" + customerId + "&is_history_fetch=" + "1" + "&pagesize=" + "13" + "&currentpage=" + currentPage + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
    }
}
