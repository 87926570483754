import { NgModule } from "@angular/core";
import { LoginComponent } from "./login.component";
import { LoginRoutingModule } from "./login-routing.module";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxIntlTelInputModule } from "@capgo/ngx-intl-tel-input";
import { NgOtpInputModule } from "ng-otp-input";
import { TranslateModule } from "@ngx-translate/core";
import { SocialLoginModule } from "../social-login/social-login.module";
import { ControlsModule } from "../controls/controls.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
    declarations: [
        LoginComponent
    ],
    exports: [
        LoginComponent
    ],
    imports: [
        LoginRoutingModule,
        CommonModule,
        ControlsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        NgOtpInputModule,
        TranslateModule,
        SocialLoginModule,
        NgxSpinnerModule,
        NgbModule
    ]
})
export class LoginModule {
}