import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[mdDesktopOnly]',
})
export class DesktopOnlyDirective {
    constructor(private el: ElementRef) {
        this.el.nativeElement.classList.add('c-do-desktop-only');
    }
}
