<form class="form-inline" [formGroup]="_fg">
    <div class="form-group">
        <div class="input-group">
            <input class="form-control request-date-input" placeholder="YYYY-MM-DD"
                   name="dp" ngbDatepicker [(ngModel)]="model" (dateSelect)="dateSelected()"
                   [ngModelOptions]="{standalone: true}" appDate
                   [form]="_fg"
                   [controlName]="'date'" #d="ngbDatepicker">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar custom-input-btn divLikeButton ripleEffect"
                        (click)="d.toggle()" type="button"></button>
            </div>
        </div>
    </div>
</form>

