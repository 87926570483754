export interface OccupancyOptionsDto {
    data: OccupancyOptionDto[];
}

export interface OccupancyOptionDto {
    label: string;
    value: string;
}

export interface OccupancyOption {
    label: string;
    id: string;
}

export const transformOccupancyOptions = (
    occupancyOptionsDto: OccupancyOptionsDto
): OccupancyOption[] => {
    return occupancyOptionsDto.data.map((occupancyOptionDto) => ({
        label: occupancyOptionDto.label,
        id: occupancyOptionDto.value,
    }));
};
