import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class EditService {

  constructor(private httpClient: HttpClient, private common: CommonService) { }

  getCustomerVerificationInfo(customerId:any){
    return this.httpClient.get(this.common.api_url+`getCustomerVerificationInfo?store=`+ this.common.getStoreID() +`&currency=MUR&service=getCustomerVerificationInfo&customerid=${customerId}` + "&customer_group_id=" + this.common.getCustomerGroupID(), {headers: this.common.getTokenJWTheaders()});
  }
  update_billing_address_api(data:any){
    return this.httpClient.get(this.common.api_url+`mofluidUpdateProfile?store=`+ this.common.getStoreID() +`&service=mofluidUpdateProfile&customerid=${data.customerid}&billaddress=${data.billaddress}&shippaddress=${data.shippaddress}&profile=${data.profile}&shipbillchoice=billingaddress&is_edit_customer=1` + "&customer_group_id=" + this.common.getCustomerGroupID() + "&preferred_language=" + data.preferred_language, {headers: this.common.getTokenJWTheaders()})
  }
  profileData(customerId:any){
    return this.httpClient.get(this.common.api_url+`myprofile?store=`+ this.common.getStoreID() +`&currency=MUR&service=myprofile&customerid=${customerId}&is_edit_customer=1` + "&customer_group_id=" + this.common.getCustomerGroupID(), {headers: this.common.getTokenJWTheaders()});
  }
}
