import {TranslateService} from "@ngx-translate/core";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class Locale {
    constructor(private translate: TranslateService) {
    }

    getButtonMessages() {
        return {
            home: this.translate.instant("HOME") ? this.translate.instant("HOME") : "HOME",
            my_account: this.translate.instant("MY ACCOUNT") ? this.translate.instant("MY ACCOUNT") : "MY ACCOUNT",
            edit_profile: this.translate.instant("EDIT PROFILE") ? this.translate.instant("EDIT PROFILE") : "EDIT PROFILE",
            my_orders: this.translate.instant("My Orders") ? this.translate.instant("My Orders") : "My Orders",
            sign_in: this.translate.instant("SIGN IN") ? this.translate.instant("SIGN IN") : "SIGN IN",
            sign_out: this.translate.instant("Sign Out") ? this.translate.instant("Sign Out") : "Sign Out",
            login: this.translate.instant("Login") ? this.translate.instant("Login") : "Login",
            proceed: this.translate.instant("PROCEED") ? this.translate.instant("PROCEED") : "PROCEED",
            update: this.translate.instant("UPDATE") ? this.translate.instant("UPDATE") : "UPDATE",
            change_password: this.translate.instant("Change Password") ? this.translate.instant("Change Password") : "Change Password",
            forgot_password: this.translate.instant("Forgot Password") ? this.translate.instant("Forgot Password") : "Forgot Password",
            create_an_account: this.translate.instant("CREATE ACCOUNT ") ? this.translate.instant("CREATE ACCOUNT ") : "CREATE ACCOUNT ",
            details: this.translate.instant("DETAILS") ? this.translate.instant("DETAILS") : "DETAILS",
            add_to_cart: this.translate.instant("ADD TO CART") ? this.translate.instant("ADD TO CART") : "ADD TO CART",
            apply_coupon: this.translate.instant("APPLY COUPON") ? this.translate.instant("APPLY COUPON") : "APPLY COUPON",
            cancel_coupon: this.translate.instant("CANCEL COUPON") ? this.translate.instant("CANCEL COUPON") : "CANCEL COUPON",
            checkout: this.translate.instant("CHECKOUT") ? this.translate.instant("CHECKOUT") : "CHECKOUT",
            continue_shopping: this.translate.instant("Continue Shopping") ? this.translate.instant("Continue Shopping") : "Continue Shopping",
            confirm_proceed: this.translate.instant("CONFIRM PROCEED") ? this.translate.instant("CONFIRM PROCEED") : "CONFIRM PROCEED",
            submit: this.translate.instant("Submit") ? this.translate.instant("Submit") : "Submit",
            retrive_your_password: this.translate.instant("Send reset link") ? this.translate.instant("Send reset link") : "Send reset link",
            click_here: this.translate.instant("Click Here") ? this.translate.instant("Click Here") : "Click Here",
            btnContinue: this.translate.instant("Continue") ? this.translate.instant("Continue") : "Continue",
            sign_up_now: this.translate.instant("Sign Up Now") ? this.translate.instant("Sign Up Now") : "Sign Up Now",
            edit_information: this.translate.instant("Edit Information") ? this.translate.instant("Edit Information") : "Edit Information",
            change_account_password: this.translate.instant("Change Account Password") ? this.translate.instant("Change Account Password") : "Change Account Password",
            get_price: this.translate.instant("GET PRICE") ? this.translate.instant("GET PRICE") : "GET PRICE",
            close: this.translate.instant("CLOSE") ? this.translate.instant("CLOSE") : "CLOSE",
            update_cart: this.translate.instant("UPDATE CART") ? this.translate.instant("UPDATE CART") : "UPDATE CART"
        };
    }

    getAlertMessages() {
        return {
            username_validation_for_blank: this.translate.instant("Username can't be left blank") ? this.translate.instant("Username can't be left blank") : "Username can't be left blank",
            username_validation_for_wrong_attempt: this.translate.instant("Please provide a valid email id as Username") ? this.translate.instant("Please provide a valid email id as Username") : "Please provide a valid email id as Username",
            customer_already_exist: this.translate.instant("Customer already exist") ? this.translate.instant("Customer already exist") : "Customer already exist",
            password_validation_for_blank: this.translate.instant("Password can't be left blank") ? this.translate.instant("Password can't be left blank") : "Password can't be left blank",
            password_validation_for_minimum_length: this.translate.instant("Password should be at least 6 characters") ? this.translate.instant("Password should be at least 6 characters") : "Password should be at least 6 characters",
            invalid_username__password_message: this.translate.instant("The username or password you entered is incorrect") ? this.translate.instant("The username or password you entered is incorrect") : "The username or password you entered is incorrect",
            invalid_login_otp_message: this.translate.instant("Please enter correct OTP.") ? this.translate.instant("Please enter correct OTP.") : "Please enter correct OTP.",
            login_success_message: this.translate.instant("Welcome you have logged in successfully") ? this.translate.instant("Welcome you have logged in successfully") : "Welcome you have logged in successfully",
            company_profile_personName: this.translate.instant("Please enter the legal personal name") ? this.translate.instant("Please enter the legal personal name") : "Please enter the legal personal name",
            company_profile_companyName: this.translate.instant("Please enter the legal company name") ? this.translate.instant("Please enter the legal company name") : "Please enter the legal company name",
            company_profile_companyAddress: this.translate.instant("Please enter the company address") ? this.translate.instant("Please enter the company address") : "Please enter the company address",
            date_not_correct: this.translate.instant("Please enter a valid birthday") ? this.translate.instant("Please enter a valid birthday") : "Please enter a valid birthday",
            date_must_be_number: this.translate.instant("Date must be a number") ? this.translate.instant("Date must be a number") : "Date must be a number",
            server_not_responding: this.translate.instant("Server is busy please try after some time") ? this.translate.instant("Server is busy please try after some time") : "Server is busy please try after some time",
            sign_out_message: this.translate.instant("You have successfully log Out") ? this.translate.instant("You have successfully log Out") : "You have successfully log Out",
            out_of_stock_message: this.translate.instant("This product is out of stock") ? this.translate.instant("This product is out of stock") : "This product is out of stock",
            country_blank: this.translate.instant("Please enter the country") ? this.translate.instant("Please enter the country") : "Please enter the country",
            firstname_blank: this.translate.instant("Please enter the first name") ? this.translate.instant("Please enter the first name") : "Please enter the first name",
            firstname_invalid: this.translate.instant("Please enter the valid first name") ? this.translate.instant("Please enter the valid first name") : "Please enter the valid first name",
            lastname_blank: this.translate.instant("Please enter the last name") ? this.translate.instant("Please enter the last name") : "Please enter the last name",
            dob_blank: this.translate.instant("Please enter the date of birth") ? this.translate.instant("Please enter the date of birth") : "Please enter the date of birth",
            lastname_invalid: this.translate.instant("Please enter the valid last name") ? this.translate.instant("Please enter the valid last name") : "Please enter the valid last name",
            contact_no_blank: this.translate.instant("Please enter the phone number") ? this.translate.instant("Please enter the phone number") : "Please enter the phone number",
            empty_address: this.translate.instant("Please enter the address") ? this.translate.instant("Please enter the address") : "Please enter the address",
            empty_city_message: this.translate.instant("Please enter the city") ? this.translate.instant("Please enter the city") : "Please enter the city",
            empty_country_message: this.translate.instant("Please enter the country") ? this.translate.instant("Please enter the country") : "Please enter the country",
            empty_state_message: this.translate.instant("Please enter the state") ? this.translate.instant("Please enter the state") : "Please enter the state",
            empty_zipcode_message: this.translate.instant("Please enter the zipcode") ? this.translate.instant("Please enter the zipcode") : "Please enter the zipcode",
            invalid_address: this.translate.instant("Please enter the valid address") ? this.translate.instant("Please enter the valid address") : "Please enter the valid address",
            invalid_city_message: this.translate.instant("Please enter the valid city") ? this.translate.instant("Please enter the valid city") : "Please enter the valid city",
            invalid_state_message: this.translate.instant("Please enter the valid state") ? this.translate.instant("Please enter the valid state") : "Please enter the valid state",
            invalid_country_message: this.translate.instant("Please enter the valid country") ? this.translate.instant("Please enter the valid country") : "Please enter the valid country",
            invalid_zipcode_message: this.translate.instant("Please enter the valid zipcode") ? this.translate.instant("Please enter the valid zipcode") : "Please enter the valid zipcode",
            invalid_contact_message: this.translate.instant("Please enter the valid contact number") ? this.translate.instant("Please enter the valid contact number") : "Please enter the valid contact number",
            email_not_registered: this.translate.instant("This email id is not registered with us") ? this.translate.instant("This email id is not registered with us") : "This email id is not registered with us",
            empty_email: this.translate.instant("Please enter a valid email id") ? this.translate.instant("Please enter a valid email id") : "Please enter a valid email id",
            invalid_email: this.translate.instant("Please provide a valid email address") ? this.translate.instant("Please provide a valid email address") : "Please provide a valid email address",
            email_already_registered: this.translate.instant("This email id is already registered with us") ? this.translate.instant("This email id is already registered with us") : "This email id is already registered with us",
            forget_password_action_message: this.translate.instant("You will receive an email with a link to reset your password") ? this.translate.instant("You will receive an email with a link to reset your password") : "You will receive an email with a link to reset your password",
            confirm_password_different: this.translate.instant("Password doesn't match") ? this.translate.instant("Password doesn't match") : "Password doesn't match",
            confirm_password_empty: this.translate.instant("Please enter the confirm password") ? this.translate.instant("Please enter the confirm password") : "Please enter the confirm password",
            change_password_success: this.translate.instant("Your password has been changed successfully") ? this.translate.instant("Your password has been changed successfully") : "Your password has been changed successfully",
            register_successfully_message: this.translate.instant("You have registered successfully") ? this.translate.instant("You have registered successfully") : "You have registered successfully",
            profile_address_update: this.translate.instant("Default Billing and Shipping Address has been Updated") ? this.translate.instant("Default Billing and Shipping Address has been Updated") : "Default Billing and Shipping Address has been Updated",
            profile_update: this.translate.instant("Your profile has been updated") ? this.translate.instant("Your profile has been updated") : "Your profile has been updated",
            payment_mode: this.translate.instant("Please select mode of payment") ? this.translate.instant("Please select mode of payment") : "Please select mode of payment",
            configurable_options_validation: this.translate.instant("Please Select option from") ? this.translate.instant("Please Select option from") : "Please Select option from",
            shipping_mode: this.translate.instant("Please select shipping method") ? this.translate.instant("Please select shipping method") : "Please select shipping method",
            select_one_mode: this.translate.instant("Please select one mode") ? this.translate.instant("Please select one mode") : "Please select one mode",
            item_remove_message: this.translate.instant("Please remove this item") ? this.translate.instant("Please remove this item") : "Please remove this item",
            product_added_to_cart: this.translate.instant("This product is added in your cart") ? this.translate.instant("This product is added in your cart") : "This product is added in your cart",
            when_same_product_added_to_cart: this.translate.instant("This product is already added in your cart, Please visit cart to change the quantity of the product") ? this.translate.instant("This product is already added in your cart, Please visit cart to change the quantity of the product") : "This product is already added in your cart, Please visit cart to change the quantity of the product",
            when_a_product_removed_from_cart: "{{product}}" + " " + this.translate.instant("has been removed") ? this.translate.instant("has been removed") : "has been removed",
            quantity_of_product_increased_more_than_stock: (this.translate.instant("This Product is having only") ? "This Product is having only" : "This Product is having only") + " " + "{{quantity}}" + (this.translate.instant("quantities in stock") ? this.translate.instant("quantities in stock") : "quantities in stock"),
            product_out_of_stock: "Sorry Product is Out Of Stock",
            limited_quantity_is_in_stock_for_product: "Sorry for Product {{product}} we have only {{quantity}} quanity in Stock please select less quantity",
            order_success_message: this.translate.instant("The order has been placed successfully") ? this.translate.instant("The order has been placed successfully") : "The order has been placed successfully",
            failure_message_for_address_save: "Sorry, Address are not Saved.Please Retry",
            message_when_empty_search: this.translate.instant("Please provide any keyword for search") ? this.translate.instant("Please provide any keyword for search") : "Please provide any keyword for search",
            coupon_applied: (this.translate.instant("Coupon code") ? this.translate.instant("Coupon code") : "Coupon code") + "{{coupon}} " + " " + (this.translate.instant("was applied") ? this.translate.instant("was applied") : "was applied"),
            coupon_failed: (this.translate.instant("Coupon code") ? this.translate.instant("Coupon code") : "Coupon code") + +" " + "{{coupon}}" + " " + (this.translate.instant("is not valid") ? this.translate.instant("is not valid") : "is not valid"),
            coupon_canceled: "{{coupon}}" + " " + this.translate.instant("Coupon Canceled") ? this.translate.instant("Coupon Canceled") : "Coupon Canceled",
            required_validation: this.translate.instant("Please fill all the required entries") ? this.translate.instant("Please fill all the required entries") : "Please fill all the required entries",
            check_email: this.translate.instant("Please check your Email address") ? this.translate.instant("Please check your Email address") : "Please check your Email address",
            try_again: this.translate.instant("There was a temporary error please try again later") ? this.translate.instant("There was a temporary error please try again later") : "There was a temporary error please try again later",
            emai_id_validation_for_blank: this.translate.instant("Email id can not be left blank") ? this.translate.instant("Email id can not be left blank") : "Email id can not be left blank",
            invalid_email_id_or_password_message: this.translate.instant("Details you have entered is incorrect") ? this.translate.instant("Details you have entered is incorrect") : "Details you have entered is incorrect",
            full_name_message: this.translate.instant("Please enter full name including space between first and second name") ? this.translate.instant("Please enter full name including space between first and second name") : "Please enter full name including space between first and second name",
            products_out_of_stock_message: this.translate.instant("Some products in your cart has been out of stock or we do not have required quantity at this time") ? this.translate.instant("Some products in your cart has been out of stock or we do not have required quantity at this time") : "Some products in your cart has been out of stock or we do not have required quantity at this time",
            group_pro_validation: this.translate.instant("Please specify the quantity of product(s)") ? this.translate.instant("Please specify the quantity of product(s)") : "Please specify the quantity of product(s)",
            billing_address_update: this.translate.instant("Billing Address has been Updated") ? this.translate.instant("Billing Address has been Updated") : "Billing Address has been Updated",
            shipping_address_update: this.translate.instant("Shipping Address has been Updated") ? this.translate.instant("Shipping Address has been Updated") : "Shipping Address has been Updated",
            logout_success: this.translate.instant("You have successfully logged out") ? this.translate.instant("You have successfully logged out") : "You have successfully logged out",
            currency_not_validated: this.translate.instant("Please enter your store currency") ? this.translate.instant("Please enter your store currency") : "Please enter your store currency",
            password_confirm_pass_same: this.translate.instant("Password &amp; Confirm Password should be same") ? this.translate.instant("Password &amp; Confirm Password should be same") : "Password &amp; Confirm Password should be same",
            plz_enter_mobile: this.translate.instant("Please enter mobile number") ? this.translate.instant("Please enter mobile number") : "Please enter mobile number",
            plz_select_country: this.translate.instant("Please select country") ? this.translate.instant("Please select country") : "Please select country",
            pz_enter_a_valid_email_id: this.translate.instant("Please enter a valid email id") ? this.translate.instant("Please enter a valid email id") : "Please enter a valid email id",
            plz_enter_paaword: this.translate.instant("Please enter password") ? this.translate.instant("Please enter password") : "Please enter password",
            mobile_number_should_be: this.translate.instant("Mobile number should be minimum six digits") ? this.translate.instant("Mobile number should be minimum six digits") : "Mobile number should be minimum six digits",
            plz_enter_order_no: this.translate.instant("Please enter the order number") ? this.translate.instant("Please enter the order number") : "Please enter the order number",
            plz_enter_review_summary: this.translate.instant("Please enter the review summary") ? this.translate.instant("Please enter the review summary") : "Please enter the review summary",
            plz_enter_propert_summary: this.translate.instant("Please select the property to submit the review") ? this.translate.instant("Please select the property to submit the review") : "Please select the property to submit the review",
            plz_enter_yourexperince_summary: this.translate.instant("Please select the your experience to submit the review") ? this.translate.instant("Please select the your experience to submit the review") : "Please select the your experience to submit the review",
            plz_rate_service: this.translate.instant("Please rate service") ? this.translate.instant("Please rate service") : "Please rate service",
            plz_rate_food: this.translate.instant("Please rate food quality") ? this.translate.instant("Please rate food quality") : "Please rate food quality",
            plz_rate_shotel: this.translate.instant("Please rate hotel facilities") ? this.translate.instant("Please rate hotel facilities") : "Please rate hotel facilities",
            plz_rate_room: this.translate.instant("Please rate room condition") ? this.translate.instant("Please rate room condition") : "Please rate room condition",
            plz_enter_name_review: this.translate.instant("Please enter the name") ? this.translate.instant("Please enter the name") : "Please enter the name",
            mobile_number_should_not_be: this.translate.instant("Mobile number should not be more than sixteen digits") ? this.translate.instant("Mobile number should not be more than sixteen digits") : "Mobile number should not be more than sixteen digits",
            plz_eneter_password: this.translate.instant("Please enter password") ? this.translate.instant("Please enter password") : "Please enter password",
            plz_enter_gift_card: this.translate.instant("Please enter valid gift card") ? this.translate.instant("Please enter valid gift card") : "Please enter valid gift card",
            plz_enter_discount_code: this.translate.instant("Please enter valid discount code") ? this.translate.instant("Please enter valid discount code") : "Please enter valid discount code",
            plz_select_payment_method: this.translate.instant("Please select payment method") ? this.translate.instant("Please select payment method") : "Please select payment method",
            invalid_category_or_message: this.translate.instant("Please select the category and type in your message") ? this.translate.instant("Please select the category and type in your message") : "Please select the category and type in your message",
            help_message_sent: this.translate.instant("Your message was sent successfully") ? this.translate.instant("Your message was sent successfully") : "Your message was sent successfully",
            recaptcha_alert: this.translate.instant("Please verify that you are not a robot") ? this.translate.instant("Please verify that you are not a robot") : "Please verify that you are not a robot",
            select_date: this.translate.instant("Please select date") ? this.translate.instant("Please select date") : "Please select date"
        };
    }

    getTextMessages() {
        return {
            display_application_name: "",
            support_text: this.translate.instant("Need help? 24 X 7 support") ? this.translate.instant("Need help? 24 X 7 support") : "Need help? 24 X 7 support",
            policy_text: this.translate.instant("Policies") ? this.translate.instant("Policies") : "Policies",
            shop_by_departments: "",
            personal_information: this.translate.instant("Personal Information") ? this.translate.instant("Personal Information") : "Personal Information",
            billing_address: this.translate.instant("Billing Address") ? this.translate.instant("Billing Address") : "Billing Address",
            no_default_billing_address_found: this.translate.instant("No Default Billing Address Found") ? this.translate.instant("No Default Billing Address Found") : "No Default Billing Address Found",
            shipping_address: this.translate.instant("Shipping Address") ? this.translate.instant("Shipping Address") : "Shipping Address",
            no_default_shipping_address: this.translate.instant("No Default Shipping Address") ? this.translate.instant("No Default Shipping Address") : "No Default Shipping Address",
            full_name: this.translate.instant("Full Name") ? this.translate.instant("Full Name") : "Full Name",
            first_name: this.translate.instant("First Name") ? this.translate.instant("First Name") : "First Name",
            last_name: this.translate.instant("Last Name") ? this.translate.instant("Last Name") : "Last Name",
            dob: this.translate.instant("Date of Birth") ? this.translate.instant("Date of Birth") : "Date of Birth",
            change_account_password: this.translate.instant("Change Account Password") ? this.translate.instant("Change Account Password") : "Change Account Password",
            old_password: this.translate.instant("Old Password") ? this.translate.instant("Old Password") : "Old Password",
            new_password: this.translate.instant("New Password") ? this.translate.instant("New Password") : "New Password",
            confirm_password: this.translate.instant("Confirm Password") ? this.translate.instant("Confirm Password") : "Confirm Password",
            address: this.translate.instant("Address") ? this.translate.instant("Address") : "Address",
            member_since: this.translate.instant("Member Since") ? this.translate.instant("Member Since") : "Member Since",
            edit_information: this.translate.instant("Edit Information") ? this.translate.instant("Edit Information") : "Edit Information",
            email_address: this.translate.instant("Email Address") ? this.translate.instant("Email Address") : "Email Address",
            contact_number: this.translate.instant("Contact Number") ? this.translate.instant("Contact Number") : "Contact Number",
            city: this.translate.instant("City") ? this.translate.instant("City") : "City",
            state: this.translate.instant("State") ? this.translate.instant("State") : "State",
            country: this.translate.instant("Country") ? this.translate.instant("Country") : "Country",
            zipcode: this.translate.instant("Zipcode") ? this.translate.instant("Zipcode") : "Zipcode",
            you_have_no_orders: this.translate.instant("You have no Orders") ? this.translate.instant("You have no Orders") : "You have no Orders",
            total_orders: (this.translate.instant("You have total") ? this.translate.instant("You have total") : "You have total") + " " + "{{totalorder}}" + " " + (this.translate.instant("Orders") ? this.translate.instant("Orders") : "Orders"),
            order: this.translate.instant("Order") ? this.translate.instant("Order") : "Order",
            orders: this.translate.instant("Orders") ? this.translate.instant("Orders") : "Orders",
            order_id: this.translate.instant("Order Id") ? this.translate.instant("Order Id") : "Order Id",
            status: this.translate.instant("Status") ? this.translate.instant("Status") : "Status",
            products: this.translate.instant("Products") ? this.translate.instant("Products") : "Products",
            payment_method: this.translate.instant("Payment Method") ? this.translate.instant("Payment Method") : "Payment Method",
            shipping_method: this.translate.instant("Shipping Method") ? this.translate.instant("Shipping Method") : "Shipping Method",
            total_amount: this.translate.instant("Total Amount") ? this.translate.instant("Total Amount") : "Total Amount",
            sku: this.translate.instant("SKU") ? this.translate.instant("SKU") : "SKU",
            name: this.translate.instant("Name") ? this.translate.instant("Name") : "Name",
            qty: this.translate.instant("Qty") ? this.translate.instant("Qty") : "Qty",
            price: this.translate.instant("Price") ? this.translate.instant("Price") : "Price",
            total: this.translate.instant("Total") ? this.translate.instant("Total") : "Total",
            item: this.translate.instant("Item") ? this.translate.instant("Item") : "Item",
            shipping_amount: this.translate.instant("Shipping Amount") ? this.translate.instant("Shipping Amount") : "Shipping Amount",
            grand_total: this.translate.instant("Grand Total") ? this.translate.instant("Grand Total") : "Grand Total",
            sub_total: this.translate.instant("Sub Total") ? this.translate.instant("Sub Total") : "Sub Total",
            search: this.translate.instant("Search by Name") ? this.translate.instant("Search by Name") : "Search by Name",
            search_title: this.translate.instant("Search") ? this.translate.instant("Search") : "Search",
            search_result_text: (this.translate.instant("Showing search result for") ? this.translate.instant("Showing search result for") : "Showing search result for") + " " + "{{serachstring}}",
            no_search_result_found: this.translate.instant("No such product found") ? this.translate.instant("No such product found") : "No such product found",
            position_sort_type: this.translate.instant("Position") ? this.translate.instant("Position") : "Position",
            name_sort_type: this.translate.instant("Name") ? this.translate.instant("Name") : "Name",
            price_sort_type: this.translate.instant("Price") ? this.translate.instant("Price") : "Price",
            product_description: this.translate.instant("Product Description") ? this.translate.instant("Product Description") : "Product Description",
            description: this.translate.instant("Description") ? this.translate.instant("Description") : "Description",
            availability: this.translate.instant("Availability") ? this.translate.instant("Availability") : "Availability",
            product_sku: this.translate.instant("Product SKU") ? this.translate.instant("Product SKU") : "Product SKU",
            shipping_charge: this.translate.instant("Shipping Charge") ? this.translate.instant("Shipping Charge") : "Shipping Charge",
            in_stock: this.translate.instant("In Stock") ? this.translate.instant("In Stock") : "In Stock",
            out_of_stock: this.translate.instant("Out of Stock") ? this.translate.instant("Out of Stock") : "Out of Stock",
            product_options: this.translate.instant("Product Options") ? this.translate.instant("Product Options") : "Product Options",
            cart_empty_text: this.translate.instant("The Cart is empty now") ? this.translate.instant("The Cart is empty now") : "The Cart is empty now",
            discount_codes: this.translate.instant("Discount Codes") ? this.translate.instant("Discount Codes") : "Discount Codes",
            remove: this.translate.instant("Remove") ? this.translate.instant("Remove") : "Remove",
            amount_payable: this.translate.instant("Amount Payable") ? this.translate.instant("Amount Payable") : "Amount Payable",
            sender: this.translate.instant("Sender") ? this.translate.instant("Sender") : "Sender",
            receiver: this.translate.instant("Receiver") ? this.translate.instant("Receiver") : "Receiver",
            order_success: (this.translate.instant("Thank You for your order. Payment is still pending to finalize this reservation. Please check your email for our bank details") ? this.translate.instant("Thank You for your order. Payment is still pending to finalize this reservation. Please check your email for our bank details") : "Thank You for your order. Payment is still pending to finalize this reservation. Please check your email for our bank details") + ".<br/><br/> " + (this.translate.instant("Note: 100% of payment must be made and received in 1 our accounts: MCB, SBM or in Any Agency (Grand Baie, Trianon, Caudan) for a Booking Confirmation to be created") ? this.translate.instant("Note: 100% of payment must be made and received in 1 our accounts: MCB, SBM or in Any Agency (Grand Baie, Trianon, Caudan) for a Booking Confirmation to be created") : "Note: 100% of payment must be made and received in 1 our accounts: MCB, SBM or in Any Agency (Grand Baie, Trianon, Caudan) for a Booking Confirmation to be created"),
            coupon_code_text: this.translate.instant("Enter your coupon code if you have one") ? this.translate.instant("Enter your coupon code if you have one") : "Enter your coupon code if you have one",
            checkout_form_heading: this.translate.instant("Please fill the form to complete your order") ? this.translate.instant("Please fill the form to complete your order") : "Please fill the form to complete your order",
            billing__shipping_address: this.translate.instant("Billing & Shipping Address") ? this.translate.instant("Billing & Shipping Address") : "Billing & Shipping Address",
            shipping_to_different_address: this.translate.instant("Shipping to different address") ? this.translate.instant("Shipping to different address") : "Shipping to different address",
            save_to_address_book: this.translate.instant("Save to address book") ? this.translate.instant("Save to address book") : "Save to address book",
            authorizenet_redirect_message: (this.translate.instant("Please wait, your order is being processed and you will be redirected to the Authorize") ? this.translate.instant("Please wait, your order is being processed and you will be redirected to the Authorize") : "Please wait, your order is being processed and you will be redirected to the Authorize") + ".Net" + " " + (this.translate.instant("website") ? this.translate.instant("website") : "website"),
            authorizenet_auto_redirect_message: this.translate.instant("If you are not automatically redirected to authorize.net within 5 seconds.."),
            click_here: this.translate.instant("Click Here") ? this.translate.instant("Click Here") : "Click Here",
            paypal_redirect_message: this.translate.instant("Please wait, your order is being processed and you will be redirected to the paypal website") ? this.translate.instant("Please wait, your order is being processed and you will be redirected to the paypal website") : "Please wait, your order is being processed and you will be redirected to the paypal website",
            paypal_auto_redirect_message: "If you are not automatically redirected to paypal within 5 seconds",
            forget_password_message_for_email: "Enter the email address or phone number associated with your account and we'll send you a link to reset your password",
            forget_password_message_for_mobile: "Please enter your mobile number below. You will receive OTP to reset your password",
            please_enter_your_username: "Please enter your username",
            please_enter_your_password: "Please enter your password",
            password: "Password",
            payment_information: "Payment Information",
            image: "Image",
            unit_price: "Unit Price",
            shipping__handling: "Shipping & Handling",
            discount: "Discount",
            terms_and_conditions: "Terms and Conditions",
            terms_and_conditions_message: "I agree to the above terms and conditions",
            no_shipping_methods_message: "Sorry, no quotes are available for this order at this time",
            no_product_found_text: "Products will be added soon",
            discount_coupon: "Discount (coupon)",
            default: "Default",
            select: "Select",
            text_all: "All",
            all_products: "All Products",
            tax: "Tax",
            no_payment_required: "No Payment Method Required",
            retrive_your_password: "Send reset link",
            check_email: "Please check your Email address",
            sign_up: "Sign Up ",
            my_cart: "My Cart ",
            checkout: "Checkout",
            continue_shopping: "Continue Shopping",
            add_address: "Add address",
            submit: "Submit",
            order_summary: "Order Summary",
            apply_coupon: "Apply Coupon",
            cancel_coupon: "Cancel Coupon",
            proceed: "Proceed",
            payment_mode: this.translate.instant("Please select mode of payment"),
            pay: "Pay",
            thank_you_message_for_cod_mode: "Thank You for placing order with us. We will do our best to deliver it to below address",
            order_amount: this.translate.instant("Order Amount") ? this.translate.instant("Order Amount") : "Order Amount",
            my_account: this.translate.instant("My Account") ? this.translate.instant("My Account") : "My Account",
            edit: this.translate.instant("Edit") ? this.translate.instant("Edit") : "Edit",
            edit_address: this.translate.instant("Edit Address") ? this.translate.instant("Edit Address") : "Edit Address",
            save: this.translate.instant("Save") ? this.translate.instant("Save") : "Save",
            back: this.translate.instant("Back") ? this.translate.instant("Back") : "Back",
            view_details: this.translate.instant("View Order") ? this.translate.instant("View Order") : "View Order",
            load_more: this.translate.instant("Load More") ? this.translate.instant("Load More") : "Load More",
            order_details: this.translate.instant("Order Details") ? this.translate.instant("Order Details") : "Order Details",
            order_date: this.translate.instant("Order Date") ? this.translate.instant("Order Date") : "Order Date",
            items_in_your_order: this.translate.instant("Items in your Order") ? this.translate.instant("Items in your Order") : "Items in your Order",
            grand_total__items: this.translate.instant("Grand Total ( # Count Items)")  ? this.translate.instant("Grand Total ( # Count Items)") : "Grand Total ( # Count Items)",
            view_all_orders: this.translate.instant("View All Orders") ? this.translate.instant("View All Orders") : "View All Orders",
            shop_by_category: this.translate.instant("Shop by Category") ? this.translate.instant("Shop by Category") : "Shop by Category",
            featured_products: this.translate.instant("Featured Products") ? this.translate.instant("Featured Products") : "Featured Products",
            product_sort_title: this.translate.instant("Sort by") ? this.translate.instant("Sort by") : "Sort by",
            no_product_in_category: this.translate.instant("There is no product in selected category") ? this.translate.instant("There is no product in selected category") : "There is no product in selected category",
            sort_type_best_seller: this.translate.instant('Best Seller') ? this.translate.instant('Best Seller') : 'Best Seller',
            price_sort_type_high__low: this.translate.instant("Highest Price") ? this.translate.instant("Highest Price") : "Highest Price",
            price_sort_type_low__high: this.translate.instant("Lowest Price") ? this.translate.instant("Lowest Price") : "Lowest Price",
            date_sort_closest: this.translate.instant("Closest Date") ? this.translate.instant("Closest Date") : "Closest Date",
            rating_sort_type: this.translate.instant("Customer Rating") ? this.translate.instant("Customer Rating") : "Customer Rating",
            close: this.translate.instant("Close") ? this.translate.instant("Close") : "Close",
            try_again: this.translate.instant("There was a temporary error please try again later") ? this.translate.instant("There was a temporary error please try again later") : "There was a temporary error please try again later",
            internet_connection_problem: this.translate.instant("Unable to connect to internet") ? this.translate.instant("Unable to connect to internet") : "Unable to connect to internet",
            show_password: this.translate.instant("Show Password") ? this.translate.instant("Show Password") : "Show Password",
            hide_password: "Hide Password",
            invalid_quantity: "Please provide numeric value for the product quantity",
            new_user_sign_up: "New User? Sign Up",
            already_have_an_account: "Already have an account?",
            incorrect_old_password: "Incorrect Old Password",
            reorder: "ReOrder",
            payment_canceled: "Sorry your payment has been canceled",
            message_for_invalid_payment_amount: "Invalid amount for payment processing",
            including_tax: "Inc. Tax",
            excluding_tax: "Ex. Tax",
            checkout_header_text: "CHECKOUT AS A GUEST OR REGISTER OR LOGIN",
            login_checkout_text: "Create An Account",
            create_account_checkout_text: "Checkout As Guest",
            sign_in_checkout_text: "Sign In",
            register_login: "Register Or Login",
            google_login_text: "Login With Google",
            new_products: "New Products",
            push_notification: "Push Notification",
            aboutus: "About Us",
            termcondition: "Term & Condition",
            privacypolicy: "Privacy Policy",
            returnpolicy: "Return Policy",
            starting_at: "Starting At",
            no_grouped_product: "No options of this product are available",
            login: "Login",
            forgot_password: this.translate.instant("Forgot Password") ? this.translate.instant("Forgot Password") : "Forgot Password",
            change_password: this.translate.instant("Change Password") ? this.translate.instant("Change Password") : "Change Password",
            user_profile: this.translate.instant("User Profile") ? this.translate.instant("User Profile") : "User Profile",
            edit_profile: this.translate.instant("Edit Profile") ? this.translate.instant("Edit Profile") : "Edit Profile",
            order_review: this.translate.instant("Order Review") ? this.translate.instant("Order Review") : "Order Review",
            search_result: this.translate.instant("Mofluid Search") ? this.translate.instant("Mofluid Search") : "Mofluid Search",
            login_reg: this.translate.instant("Login/Register") ? this.translate.instant("Login/Register") : "Login/Register",
            hotel_avlb: this.translate.instant("Hotel Availability") ? this.translate.instant("Hotel Availability") : "Hotel Availability",
            check_in: this.translate.instant("Check-In") ? this.translate.instant("Check-In") : "Check-In",
            check_out: this.translate.instant("Check-Out") ? this.translate.instant("Check-Out") : "Check-Out",
            occupancy: this.translate.instant("Occupancy") ? this.translate.instant("Occupancy") : "Occupancy",
            see_all: this.translate.instant("See all") ? this.translate.instant("See all") : "See all",
            retry: this.translate.instant("Retry") ? this.translate.instant("Retry") : "Retry",
            review_by_customer: this.translate.instant("Reviews by customers only") ? this.translate.instant("Reviews by customers only") : "Reviews by customers only",
            reservation_details: this.translate.instant("Reservation Details") ? this.translate.instant("Reservation Details") : "Reservation Details",
            countryName: "Mauritius",
            checkoutsuccess_title: this.translate.instant("Payment Successfull") ? this.translate.instant("Payment Successfull") : "Payment Successfull",
            checkoutfailure_title: this.translate.instant("Payment Failed") ? this.translate.instant("Payment Failed") : "Payment Failed"
        }
    }
}
