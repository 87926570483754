<div class="main-div-payment" *ngIf="userLogin">
    <div class="skeleton" *ngIf="paymentSuccessSkeleton">
        <div class="animated-background"
             style="height: 94vh;border: 2px solid white;width: auto;box-sizing: border-box;"></div>
    </div>
    <div class="checkoutpayment_successpage" *ngIf="!paymentSuccessSkeleton" has-header="true" scroll="false">
        <div class="page-side-space">
            <div class="checkoutpayment_successcontent">
                <div class="payment-success-messageblock">
                    <div class="payment-success-emoticon">
                        <img src="assets/img/payment-success-emoticon.png"/>
                    </div>
                    <div class="payment-success-msg ">
                        <p>{{"Payment Successful!" | translate}}</p>
                        <p>{{"Booking Confirmation & Invoice has been sent to your Email Address, Thank you for Choosing MariDeal" | translate}}
                            .</p>
                    </div>
                </div>
                <div class="checkoutorderpreview-table">
                    <div class="table-preview-div">
                        <div>

                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{localeTxt.order_id}}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{orderdata.orderid}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="orderdata.name">
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{localeTxt.name}}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{orderdata.name}}
                                </label>
                            </div>
                        </div>
                        <!-- <div *ngIf="orderdata.address">
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{localeTxt.shipping_address}}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{orderdata.address}}
                                </label>
                            </div>
                        </div> -->
                        <!-- <div>
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{localeTxt.country}}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{localeTxt.countryName}}
                                </label>
                            </div>
                        </div> -->
                        <div>
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{localeTxt.amount_payable }}
                                </label>
                            </div>
                            <div *ngIf="common.language == 'en' || common.language == 'fr'" class="invoice-right-div">
                                <label class="invoice-label">
                                    {{0 | currency:(common.language == 'en' || common.language == 'fr' ? appConfig.app_currency :
                                    appConfig.currency_reunion):'code' | slice:0:3}} {{orderdata.amount | number:'1.0-0'}}
                                </label>
                            </div>
                            
                            <div *ngIf="common.language == 'fr-reunion'" class="invoice-right-div">
                                <label class="invoice-label">
                                    {{appConfig.currency_reunion + common.convertPriceToEuroFormat(orderdata.amount)}}
                                </label>
                            </div>

                        </div>
                        <div *ngIf="orderdata.contactno">
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{localeTxt.contact_number}}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{orderdata.contactno}}
                                </label>
                            </div>
                        </div>
                        <div>
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{localeTxt.email_address}}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{orderdata.recemail}}
                                </label>
                            </div>
                        </div>

                        <div *ngIf="paymentStatus =='failure'">
                            <div class="invoice-left-div">
                                <label class="invoice-label">
                                    {{"Payment Status" | translate}}
                                </label>
                            </div>
                            <div class="invoice-right-div">
                                <label class="invoice-label">
                                    {{"Error" | translate}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="height30"></div>
                <div class="securecheckout_textblock">
                    <div class="sercurecheckout_msg">
                        <p>{{"MariDeal Management, staff or employees at no time will ever see your credit card number, your account name, expiration dates or CVV codes" | translate}}
                            . {{"All details regarding your payment will be processed directly by the Bank, State Bank of Mauritius" | translate}}
                            . {{"All data and personal details are protected by bank at the highest encryption possible" | translate}}
                            .</p>
                    </div>
                </div>
                <button class="button button-block button-positiveBtn-rounded continue-shopping ripleEffect"
                        (click)="continueShoping()">
                    {{"Home Page" | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
