import { Component } from '@angular/core';

@Component({
    selector: 'md-bookings-loading',
    template: `
        <div style="height: 37px"></div>
        <div
            style="width: 100%; display: flex; flex-direction: row; margin-bottom: 43px; border-radius: 10px; border: 1px solid #DEE2E6; height: 126px;"
        >
            <div mdSkeleton class="left-side"></div>
            <div class="center">
                <p
                    mdSkeleton
                    style="width: 120px; height:21px; border-radius: 4px; margin-bottom: 8px"
                ></p>
                <p
                    mdSkeleton
                    style="width: 100px; height:18px; border-radius: 4px; margin-bottom: 4px"
                ></p>
                <p
                    mdSkeleton
                    style="width: 90px; height:18px; border-radius: 4px; margin-bottom: 8px"
                ></p>
                <p
                    mdSkeleton
                    style="width: 160px; height:21px; border-radius: 4px; margin-bottom: -8px"
                ></p>
            </div>
        </div>
        <div
            style="width: 100%; display: flex; flex-direction: row; margin-bottom: 43px; border-radius: 10px; border: 1px solid #DEE2E6; height: 126px;"
        >
            <div mdSkeleton class="left-side"></div>
            <div class="center">
                <p
                    mdSkeleton
                    style="width: 110px; height:21px; border-radius: 4px; margin-bottom: 8px"
                ></p>
                <p
                    mdSkeleton
                    style="width: 110px; height:18px; border-radius: 4px; margin-bottom: 4px"
                ></p>
                <p
                    mdSkeleton
                    style="width: 90px; height:18px; border-radius: 4px; margin-bottom: 8px"
                ></p>
                <p
                    mdSkeleton
                    style="width: 140px; height:21px; border-radius: 4px; margin-bottom: -8px"
                ></p>
            </div>
        </div>
        <div
            style="width: 100%; display: flex; flex-direction: row; margin-bottom: 43px; border-radius: 10px; border: 1px solid #DEE2E6; height: 126px;"
        >
            <div mdSkeleton class="left-side"></div>
            <div class="center">
                <p
                    mdSkeleton
                    style="width: 180px; height:21px; border-radius: 4px margin-bottom: 8px"
                ></p>
                <p
                    mdSkeleton
                    style="width: 110px; height:18px; border-radius: 4px; margin-bottom: 4px"
                ></p>
                <p
                    mdSkeleton
                    style="width: 120px; height:18px; border-radius: 4px; margin-bottom: 8px"
                ></p>
                <p
                    mdSkeleton
                    style="width: 140px; height:21px; border-radius: 4px; margin-bottom: -8px"
                ></p>
            </div>
        </div>
    `,
    styles: [
        `
            @import '/src/theme.scss';

            .left-side {
                width: 217px;
                height: 100%;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;

                @media (max-width: $mobile-width - 1) {
                    width: 130px;
                }
            }

            .center {
                display: flex;
                flex-direction: column;
                padding: 11px 25px;
            }
        `,
    ],
})
export class BookingsLoadingComponent {
    // Component logic goes here
}
