import { Component } from '@angular/core';

@Component({
    selector: 'md-edit-profile-loading',
    template: `
        <div
            style="width: 100%; display: flex; flex-direction: row; padding: 19px 0; border-bottom: 1px solid #DEE2E6"
        >
            <div class="left-side">
                <p
                    mdSkeleton
                    style="width: 43px; height:21px; border-radius: 4px"
                ></p>
            </div>
            <div class="center">
                <p
                    mdSkeleton
                    style="width: 120px; height:21px; border-radius: 4px"
                ></p>
            </div>
            <div style="width:40px">
                <p
                    mdSkeleton
                    style="width: 35px; height:21px; border-radius: 4px"
                ></p>
            </div>
        </div>
        <div
            style="width: 100%; display: flex; flex-direction: row; padding: 19px 0; border-bottom: 1px solid #DEE2E6"
        >
            <div class="left-side">
                <p
                    mdSkeleton
                    style="width: 43px; height:21px; border-radius: 4px"
                ></p>
            </div>
            <div class="center">
                <p
                    mdSkeleton
                    style="width: 35%; height:21px; border-radius: 4px"
                ></p>
            </div>
            <div style="width:40px">
                <p
                    mdSkeleton
                    style="width: 35px; height:21px; border-radius: 4px"
                ></p>
            </div>
        </div>
        <div
            style="width: 100%; display: flex; flex-direction: row; padding: 19px 0; border-bottom: 1px solid #DEE2E6"
        >
            <div class="left-side">
                <p
                    mdSkeleton
                    style="width: 43px; height:21px; border-radius: 4px"
                ></p>
            </div>
            <div class="center">
                <p
                    mdSkeleton
                    style="width: 210px; height:21px; border-radius: 4px"
                ></p>
            </div>
            <div style="width:40px">
                <p
                    mdSkeleton
                    style="width: 35px; height:21px; border-radius: 4px"
                ></p>
            </div>
        </div>
        <div
            style="width: 100%; display: flex; flex-direction: row; padding: 19px 0; border-bottom: 1px solid #DEE2E6"
        >
            <div class="left-side">
                <p
                    mdSkeleton
                    style="width: 43px; height:21px; border-radius: 4px"
                ></p>
            </div>
            <div class="center">
                <p
                    mdSkeleton
                    style="width: 160px; height:21px; border-radius: 4px"
                ></p>
            </div>
            <div style="width:40px">
                <p
                    mdSkeleton
                    style="width: 35px; height:21px; border-radius: 4px"
                ></p>
            </div>
        </div>
    `,
    styles: [
        `
            @import '/src/theme.scss';

            .left-side {
                width: 180px;

                @media (max-width: $mobile-width - 1) {
                    width: 0;
                    display: none;
                }
            }

            .center {
                width: calc(100% - 180px - 40px);

                @media (max-width: $mobile-width - 1) {
                    width: calc(100% - 40px);
                }
            }
        `,
    ],
})
export class EditProfileLoadingComponent {
    // Component logic goes here
}
