import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';

export interface GetAllOrdersResponse {
    company_profile_data: string;
    total: number;
    invoice_status: string;
    data: Order[];
}

export interface Order {
    order_date: Date;
    order_id: string;
    status: string;
    id: string;
    product: OrderProduct[];
}

export interface OrderProduct {
    image: string;
    name: string;
    check_ins: Date;
    check_outs: Date;
    day_to_send: Date;
    expiry: Date;
    customdescription: string;
    pdf_url: InvoicePdfUrl[];
    type_of_room: string;
    no_of_nights: number;
    meal_plan: string;
    location: string;
    coupon_code: string;
}

export interface InvoicePdfUrl {
    code: string;
    url: string;
}

@Injectable({
    providedIn: 'root',
})
export class BookingsService {
    constructor(
        private commonService: CommonService,
        private httpClient: HttpClient
    ) {}

    getAllOrders(
        customerId: any,
        status: string,
        pageSize: number,
        currentPage: number
    ) {
        return this.httpClient.get<GetAllOrdersResponse>(
            this.commonService.api_url +
                'myordersnew?service=myordersnew&customerid=' +
                customerId +
                '&status=' +
                status +
                '&pagesize=' +
                pageSize +
                '&currentpage=' +
                currentPage +
                '&store=' +
                this.commonService.getStoreID() +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            { headers: this.commonService.getTokenJWTheaders() }
        );
    }

    getPendingOrders(
        customerId: any,
        status: string,
        pageSize: number,
        currentPage: number
    ) {
        return this.httpClient.get(
            this.commonService.api_url +
                'myordersnew?service=myordersnew&customerid=' +
                customerId +
                '&status=' +
                status +
                '&pagesize=' +
                pageSize +
                '&currentpage=' +
                currentPage +
                '&store=' +
                this.commonService.getStoreID() +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            { headers: this.commonService.getTokenJWTheaders() }
        );
    }

    getCompleteOrders(
        customerId: any,
        status: string,
        pageSize: number,
        currentPage: number
    ) {
        return this.httpClient.get(
            this.commonService.api_url +
                'myordersnew?service=myordersnew&customerid=' +
                customerId +
                '&status=' +
                status +
                '&pagesize=' +
                pageSize +
                '&currentpage=' +
                currentPage +
                '&store=' +
                this.commonService.getStoreID() +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            { headers: this.commonService.getTokenJWTheaders() }
        );
    }

    getCanceledOrders(
        customerId: any,
        status: string,
        pageSize: number,
        currentPage: number
    ) {
        return this.httpClient.get(
            this.commonService.api_url +
                'myordersnew?service=myordersnew&customerid=' +
                customerId +
                '&status=' +
                status +
                '&pagesize=' +
                pageSize +
                '&currentpage=' +
                currentPage +
                '&store=' +
                this.commonService.getStoreID() +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            { headers: this.commonService.getTokenJWTheaders() }
        );
    }
}
