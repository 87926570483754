<div style="position: relative; z-index: 2">
    <md-date-badge
        [startDate]="eventStartDate"
        [endDate]="eventEndDate"
        position="top-left"
        large="true"
    />
</div>
<swiper [config]="carouselConfig" #mainSwiper class="pic-main-swiper">
    <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let image of desktopImages">
            <img
                class="pic-main-img"
                [alt]="image.caption"
                src="assets/img/deal_background.png"
                async
                [lazyLoad]="image.url"
            />
        </div>
    </div>
    <div mdDesktopOnly class="swiper-button-next"></div>
    <div mdDesktopOnly class="swiper-button-prev"></div>
</swiper>
<div mdDesktopOnly>
    <swiper [config]="thumbnailsConfig" class="pic-thumb-swiper">
        <div class="swiper-wrapper">
            <div
                class="swiper-slide pic-thumb"
                *ngFor="let image of desktopImages; let i = index"
                (click)="thumbClick(i)"
            >
                <img
                    class="pic-thumb-img"
                    src="assets/img/deal_background.png"
                    async
                    [lazyLoad]="image.url"
                    [alt]="image.caption"
                />
            </div>
        </div>
        <div class="swiper-thumb-next"></div>
        <div class="swiper-thumb-prev"></div>
    </swiper>
</div>
