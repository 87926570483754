import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOffcanvasServiceService } from 'src/app/services/modal-offcanvas-service.service';
import { screenSizes } from 'src/app/utilities/theme';

@Component({
    selector: 'md-modal-offcanvas',
    templateUrl: './modal-offcanvas.component.html',
    styleUrls: ['./modal-offcanvas.component.scss'],
})
export class ModalOffcanvasComponent {
    @Input() mobileModalTitle: string;
    @Input() thinPadding: boolean;
    @Input() isBorderRadius = false;
    @Input() closeButtonOnLeft = false;
    @Input() isCloseButtonShow = true;
    @Input() isBackbuttonShow = true;
    @Output() modalBackButtonClicked = new EventEmitter<void>();

    hasRenderedAsMobile: boolean;
    private currentOffcanvasRef: NgbOffcanvasRef | null = null;

    @ViewChild('mobileControl') mobileControl: TemplateRef<unknown>;
    @ViewChild('modal') modal: TemplateRef<unknown>;

    constructor(
        private offcanvasService: NgbOffcanvas,
        private modalOffcanvasService: ModalOffcanvasServiceService,
        private modalService: NgbModal
    ) {}

    open() {
        this.hasRenderedAsMobile = window.innerWidth < screenSizes.mobile;
        if (this.hasRenderedAsMobile) {
            // Close previous offcanvas before opening a new one
            this.modalOffcanvasService.dismissAllOffcanvases();
            this.currentOffcanvasRef = this.offcanvasService.open(this.mobileControl, {
                position: 'bottom',
                panelClass: 'c-o-modal-offcanvas',
                scroll: true,
            });
            this.modalOffcanvasService.registerNewOffcanvas(this.currentOffcanvasRef);
        } else {
            this.close();
            this.openModal();
        }
    }

    onModalCloseButtonClicked() {
        this.close();
    }

    back() {
        this.modalBackButtonClicked.emit();
    }

    onModalContinueClick() {
        this.close();
    }

    close() {
        if (this.hasRenderedAsMobile) {
            this.modalOffcanvasService.dismissAllOffcanvases();
        } else {
            this.modalService.dismissAll();
        }
    }

    openModal() {
        this.modalService.open(this.modal, {
            centered: true,
            modalDialogClass: this.isBorderRadius ? 'border-radius-15' : '',
        });
    }
}
