// this card has to have a border radius and a shadow
// it also needs to scale with the screen size
// border radius 15px for desktop and 10px for mobile

import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[mdCard]',
})
export class CardDirective implements OnInit {
    @Input() hasInternalPadding = false;
    @Input() noPointer = false;

    constructor(private el: ElementRef) {
        this.el.nativeElement.classList.add('c-ca-rounded-corners');
        this.el.nativeElement.classList.add('c-ca-shadow');
    }

    ngOnInit(): void {
        if (this.hasInternalPadding) {
            this.el.nativeElement.classList.add('c-ca-internal-padding');
        }
        if (!this.noPointer) {
            this.el.nativeElement.classList.add('c-ca-pointer');
        }
    }
}
