import { DealDto } from 'src/app/model/deal.model';
import {
    ProductDetailsAddonItemDto,
    ProductDetailsCustomOptionDto,
    ProductDetailsDto,
    ProductDetailsOccupancyOptionsDto,
    ProductDetailsStayDataDto,
} from './product-detail.dto.model';
import {
    MaribnbStayData,
    ProductAddOnGroup,
    ProductDetails,
    ProductDetailsDtoV2,
    ProductOccupancyOption,
    ProductServiceCategory,
    StaticContentV2,
} from './product-detail.model';

export const transformProductDetails = (
    productDetailsDto: ProductDetailsDto
): ProductDetails => {
    return {
        id: Number(productDetailsDto.id),
        name: productDetailsDto.name,
        type: productDetailsDto.type,
        showCalendar: productDetailsDto.show_calendar,
        cmsBlockContent: productDetailsDto.cmsBlockContent,
        eventStartDate: productDetailsDto.event_start_date,
        eventEndDate: productDetailsDto.event_end_date,
        shortDescription: removeHtmlTags(productDetailsDto.shortdes),
        rating: Number(productDetailsDto.star_count),
        locationName: productDetailsDto.city_location,
        category: Number(productDetailsDto.category[0]),
        images: productDetailsDto.banner_images.map((image, index) => ({
            url: image,
            caption: productDetailsDto.images_caption[index],
            imageTypeId: 2,
        })),
        badge: {
            label: productDetailsDto.badges_detail?.badge_label,
            group: productDetailsDto.badges_detail?.group,
            iconUrl: productDetailsDto.badges_detail?.icon,
        },
        occupancyOptions: transformOccupancyOptions(
            productDetailsDto.custom_option_dropdown_values
        ),
        serviceOptionCategories: transformServiceOptionCategories(
            productDetailsDto.custom_option
        ),
        staticContent: [
            ...productDetailsDto.custom_attribute.data.map((item) => ({
                title: item.attr_label,
                code: item.attr_code,
                htmlContent:
                    item.attr_code === 'sitemap'
                        ? atob(item.attr_value)
                        : item.attr_value,
            })),
            ...productDetailsDto.new_outlet_data.map((item) => ({
                title: item.attr_label,
                code: item.attr_code,
                htmlContent: item.attr_value,
            })),
        ],
        addons: transformAddonItems(productDetailsDto.addon_items),
        subtotalDiscounts: productDetailsDto.subtotal_discount_percent.map(
            (item) => ({
                minNights: Number(item.night),
                discountPercent: Number(item.discount),
            })
        ),
    };
};

const transformAddonItems = (
    addonItems: ProductDetailsAddonItemDto[]
): ProductAddOnGroup[] => {
    return addonItems.map((addonItem) => ({
        name: addonItem.name,
        items: addonItem.addon_data.map((addonData) => ({
            id: Number(addonData.id),
            maxQty: Number(addonData.max_qty),
            price: Number(addonData.price),
            priceType: addonData.price_type,
            sku: addonData.sku,
            title: addonData.title,
        })),
    }));
};

const transformServiceOptionCategories = (
    customOption: ProductDetailsCustomOptionDto[]
): ProductServiceCategory[] => {
    return customOption.map((option) => ({
        id: Number(option.custom_option_id),
        name: option.custom_option_name,
        options: option.custom_option_value_array?.map((value) => ({
            id: Number(value.id),
            name: value.title,
            ageGroup: value.agegroup,
            occupancyDescription: value.display_description,
            price: Number(value.actualprice),
            fullPrice: Number(value.cross_price),
            flightPrice: Number(value.flight_price),
            maxPax: Number(value.maxpax),
            minPax: Number(value.minpax),
            minLenghtOfStay: Number(value.minimumlength),
            roomType: Number(value.room_type),
            occupancyId: Number(value.occu_id),
            categoryId: Number(option.custom_option_id),
            isActive: value.status,
            optionPrice: Number(value.option_price),
            maribnbStayData: transformMaribnbData(value.stay_data),
            optionDiscounts: value.percent_free_nights_data.map((value) => ({
                minNights: Number(value.min_night),
                discountPercent: Number(value.min_night_offer[0].percent),
            })),
        })),
    }));
};

const transformMaribnbData = (
    maribnbData: ProductDetailsStayDataDto
): MaribnbStayData => {
    if (!maribnbData) {
        return undefined;
    }
    return {
        adultPricing: maribnbData.adult_pricing?.map((item) => Number(item)),
        childPricing: maribnbData.children_pricing?.map((item) => Number(item)),
        infantPricing: maribnbData.infant_pricing?.map((item) => Number(item)),
        maximumOccupancy: Number(maribnbData.maximum_occupancy),
        numberOfAdults: Number(maribnbData.number_of_adult) || 0,
        numberOfChildren: Number(maribnbData.number_of_children) || 0,
        numberOfInfants: Number(maribnbData.number_of_infant) || 0,
    };
};

const transformOccupancyOptions = (
    customOptionDropdownValues: ProductDetailsOccupancyOptionsDto
): ProductOccupancyOption[] => {
    return Object.keys(customOptionDropdownValues)
        .map((key) => Number(key))
        .map((key) => ({
            id: key,
            label: customOptionDropdownValues[key]?.label,
            isActive: customOptionDropdownValues[key]?.is_active,
        }))
        .sort((a, b) =>
            a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
        );
};

const removeHtmlTags = (inputString: string | undefined): string => {
    return inputString?.replace(/<\/?[^>]+(>|$)/g, '');
};

export const transformProductDetailsV2 = (
    productDetailsDto: ProductDetailsDtoV2,
    productDetails: ProductDetails
): ProductDetails => {
    const deal = productDetailsDto.deals[0];

    return {
        ...productDetails,
        staticContentV2: transformStaticContentV2(deal),
        id: deal.dealId,
        name: deal.dealTitle,
        shortDescription: deal.subTitle,
        rating: deal.starRating,
        images: deal.images.map((image) => ({
            url: image.imgUrl,
            caption: image.imgMeta?.imgAltText,
            imageTypeId: image.imgType?.imgTypeId,
        })),
    };
};

export const transformStaticContentV2 = (
    productDetailsDto: DealDto
): StaticContentV2 => {
    const infoDto = productDetailsDto.information;
    return {
        // TODO: this is a temporary solution. This data should come translated from the server
        information: {
            about: infoDto.about_description || infoDto.about_description_fr,
            metaDescription:
                infoDto.meta_description || infoDto.meta_description_fr,
            metaKeywords: infoDto.meta_keywords || infoDto.meta_keywords_fr,
            metaTitle: infoDto.meta_title || infoDto.meta_title_fr,
            packageDetails:
                infoDto.package_details || infoDto.package_details_fr,
            purchaseIncludes:
                infoDto.purchase_includes || infoDto.purchase_includes_fr,
        },
        location: {
            latitude: infoDto.latitude,
            longitude: infoDto.longitude,
        },
        conditions: productDetailsDto.conditions,
        facilities: productDetailsDto.outletFacilities.facilities.map(
            (facility) => ({
                id: facility.facilityId,
                name: facility.facilityName,
                iconUrl: facility.icon,
                importance: facility.importance,
            })
        ),
        roomCategories: productDetailsDto.outletFacilities.roomCategories.map(
            (roomCategory) => ({
                facilities: roomCategory.facilities.map((facility) => ({
                    id: facility.facilityId,
                    name: facility.facilityName,
                    iconUrl: facility.icon,
                    importance: facility.importance,
                })),
                room: {
                    outletId: roomCategory.room.outletId,
                    roomId: roomCategory.room.roomId,
                    roomImage: roomCategory.room.roomImage,
                    roomName: roomCategory.room.roomName,
                    roomNameFr: roomCategory.room.roomNameFr,
                },
            })
        ),
        reviews: productDetailsDto.outletReviews,
    };
};
