import { Component, OnInit } from '@angular/core';
import { GiftCardService } from './gift-card.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { getUser } from 'src/app/utilities/user.utils';
import { ProfileSection } from 'src/app/user/profile-wrapper.component';
import { RewardsAndWalletService } from '../../rewards-and-wallet.service';

@Component({
    selector: 'md-gift-card-balance',
    templateUrl: './gift-card-balance.component.html',
    styleUrls: ['../../rewards-and-wallet.component.scss'],
})
export class GiftCardBalanceComponent implements OnInit {
    isShowDummyGiftCardBalance: boolean = false;
    public userLogin: boolean = false;
    giftCardItem: any = [];

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    constructor(
        private giftsService: GiftCardService,
        private currencyLanguageService: CurrencyLanguageService,
        private eventService: EventService,
        private rewardsAndWalletService: RewardsAndWalletService
    ) {
        this.checkLoginResponse();
    }

    ngOnInit(): void {
        this.getListItem();
        this.currencyLanguageService
            .getCurrency()
            .subscribe((currencySymbol) => {
                this.currencySymbol = currencySymbol;
            });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    checkLoginResponse() {
        let userdata = getUser();
        if (userdata) {
            this.userLogin = true;
        }
    }

    getMinus(giftItem: any) {
        const sign = giftItem.status === 'negative-price' ? '-' : '';
        return sign;
    }

    getListItem() {
        this.isShowDummyGiftCardBalance = true;
        this.giftsService.myGiftCardHistory().subscribe((response: any) => {
            this.isShowDummyGiftCardBalance = false;
            if (response) {
                if (response.status != 0) {
                    //added data
                    this.giftCardItem = response.giftCardHistory;
                    if (response.balance) {
                        this.rewardsAndWalletService.updateGiftCardBalance(
                            parseInt(response.balance)
                        );
                    }
                } else {
                    this.eventService.emitEvent(
                        EventType.MODAL_INFO,
                        response.errorMessage
                    );
                }
            }
        });
    }

    protected readonly ProfileSection = ProfileSection;
}
