import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SearchService } from './search.service';
import { Product } from '../category-deals-list/model/product.model';
import { ScreenSizeService } from '../services/screen-size.service';
import {
    AppliedFilterIds,
    Filter,
    SortLabel,
} from '../filter-data/filter-data.model';
import {
    addFilterIdsToQueryStringAndSearch,
    addSortingDataToQueryString,
} from '../category-deals-list/category-sort-bar/category-sort-bar.utils';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
    createDateFromString,
    createDateString,
} from '../controls/calendar/calendar.utils';
import {
    addClassToSearchDatesComponent,
    removeClassFromSearchDatesComponent,
} from '../utilities/utils';
import { combineLatest, Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core'; // Import TranslateService
import { DeviceCommsService } from '../services/device-comms.service';
import * as Sentry from '@sentry/angular-ivy';

@Component({
    selector: 'md-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
    searchQuery = '';
    deals: Product[] = [];
    scrollablePane: Element;
    loading = true;
    currentPage = 1;
    totalRecords = 0;

    sortLabel: SortLabel;
    appliedFilterIds2: AppliedFilterIds[] = [];
    filterIdsFromQueryString2: AppliedFilterIds[] = [];
    productIds: number[] = [];
    sectionId: number;
    fromDate: NgbDate;
    toDate: NgbDate;

    private queryParamsSubscription: Subscription;
    errorMessage = '';

    constructor(
        public screenSizeService: ScreenSizeService,
        private route: ActivatedRoute,
        private router: Router,
        private searchService: SearchService,
        private translate: TranslateService, // Inject TranslateService
        private deviceCommsService: DeviceCommsService
    ) {}

    ngOnInit(): void {
        this.queryParamsSubscription = combineLatest([
            this.route.queryParams,
            this.route.params,
        ]).subscribe(([queryParams, params]) => {
            const searchQuery: string = queryParams['q'];
            const fromDate: string = queryParams['fromDate'];
            const filterParams: string = queryParams['filters'];
            const sortParam: string = queryParams['sort'];
            const { filterIds, sortLabel } = this.setSortAndFiltering(
                filterParams,
                sortParam
            );
            this.sectionId = Number.parseInt(params['sectionId']);
            if (this.isGibberish(searchQuery)) {
                this.loading = false;
                this.deals = [];
                this.errorMessage = this.translate.instant(
                    "Your search looks like it's got some typos, please take another look"
                );
                Sentry.captureMessage('Search service gibberish', 'error');
                return;
            }
            if (fromDate) {
                const { fromDate, toDate, selectedOccupancyId } =
                    this.extractDateParams(queryParams);
                this.searchService
                    .searchForProductIds(
                        fromDate,
                        toDate,
                        selectedOccupancyId,
                        filterIds,
                        this.sectionId
                    )
                    .pipe(
                        catchError((error) => {
                            this.errorMessage = this.translate.instant(
                                "Your search looks like it's got some typos, please take another look"
                            );
                            Sentry.captureMessage(
                                'Search service gibberish',
                                'error'
                            );
                            this.loading = false;
                            this.deals = [];
                            return of(null);
                        })
                    )
                    .subscribe((response) => {
                        if (response) {
                            this.productIds = response;
                            this.search('', filterIds, sortLabel);
                        }
                    });
            } else if (searchQuery) {
                this.search(searchQuery, filterIds, sortLabel);
            }
        });

        this.scrollablePane = document.getElementsByClassName('pane')[0];
        addClassToSearchDatesComponent();
    }

    ngOnDestroy(): void {
        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
        }
        removeClassFromSearchDatesComponent();
    }

    private setSortAndFiltering(
        filterParams: string,
        sortParams: string
    ): {
        filterIds: AppliedFilterIds[];
        sortLabel: SortLabel;
    } {
        if (filterParams) {
            this.filterIdsFromQueryString2 = filterParams
                .split(',')
                .map((filter) => {
                    const [filterTypeId, filterId] = filter.split('-');
                    return { filterTypeId: +filterTypeId, filterId: +filterId };
                });
        } else {
            this.filterIdsFromQueryString2 = [];
        }
        if (sortParams) {
            this.sortLabel = sortParams as SortLabel;
        }

        const filterIds = this.getFilterIdsFromParamsAndFilterComponent();
        return { filterIds, sortLabel: this.sortLabel };
    }

    private extractDateParams(queryParams: Params): {
        fromDate: NgbDate;
        toDate: NgbDate;
        selectedOccupancyId: string;
    } {
        const fromDateString: string = queryParams['fromDate'];
        const toDateString: string = queryParams['toDate'];
        this.fromDate = createDateFromString(fromDateString);
        this.toDate = createDateFromString(toDateString);
        const selectedOccupancyId: string = queryParams['occupancyId'];
        return {
            fromDate: this.fromDate,
            toDate: this.toDate,
            selectedOccupancyId,
        };
    }

    private getFilterIdsFromParamsAndFilterComponent(): AppliedFilterIds[] {
        if (this.appliedFilterIds2.length > 0) {
            return this.appliedFilterIds2;
        } else {
            return this.filterIdsFromQueryString2;
        }
    }

    private isGibberish(searchQuery: string): boolean {
        const gibberishPattern =
            /^(?![\d\s]*$)(?!.*(.)\1{2})(?!.*(.)\2\2)[^\w\d]*$/;
        const isGibberish = gibberishPattern.test(searchQuery);

        if (isGibberish) {
            Sentry.captureMessage(
                'Potential gibberish search query',
                'warning'
            );
        }

        return isGibberish;
    }

    search(
        searchQuery: string,
        filterIds: AppliedFilterIds[],
        sortLabel: SortLabel
    ): void {
        if (this.isGibberish(searchQuery)) {
            this.loading = false;
            this.deals = [];
            this.errorMessage = this.translate.instant(
                "Your search looks like it's got some typos, please take another look"
            );
            Sentry.captureMessage(
                'Potential gibberish search query',
                'warning'
            );
            return;
        }

        this.loading = true;
        this.errorMessage = '';
        this.searchQuery = searchQuery;
        this.searchService
            .searchProducts(
                this.searchQuery,
                this.productIds,
                filterIds,
                sortLabel,
                1,
                this.totalRecords,
                this.sectionId
            )
            .subscribe(
                (response) => {
                    this.deals = response.deals;
                    this.totalRecords = response.totalHits;
                    this.loading = false;
                    setTimeout(() => {
                        this.scrollablePane.scrollTop =
                            this.searchService.scrollPosition;
                    }, 0);
                },
                (error) => {
                    this.errorMessage = this.translate.instant(
                        "Your search looks like it's got some typos, please take another look"
                    );
                    Sentry.captureMessage('Search service gibberish', 'error');
                    this.loading = false; // Ensure loading state is reset on error
                    this.deals = []; // Clear deals on error to indicate no results
                }
            );
    }

    onScroll(): void {
        if (this.deals.length < this.currentPage * 20) {
            return;
        }
        this.currentPage++;
        this.loading = true;
        this.searchService
            .searchProducts(
                this.searchQuery,
                this.productIds,
                this.appliedFilterIds2,
                this.sortLabel,
                this.currentPage,
                this.totalRecords,
                this.sectionId
            )
            .subscribe((response) => {
                this.deals = response.deals;
                this.loading = false;
            });
    }

    redirectToProductDetails(product: Product) {
        this.searchService.scrollPosition = this.scrollablePane.scrollTop;
        const url = this.createUrl(product);
        
        this.deviceCommsService.openLinkInNewTabIfBrowser(url.baseUrl, url.queryParams);
    }

    private createUrl(product: Product): { baseUrl: string, queryParams: Params } {
        const queryParams: Params = {
            search: this.searchQuery,
            fromDate: this.fromDate
                ? createDateString(this.fromDate)
                : undefined,
            toDate: this.toDate ? createDateString(this.toDate) : undefined,
        };

        const queryString = Object.entries(queryParams)
            .filter(([key, value]) => !!value)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const baseUrl = `/deal/${product.productPath}/${product.id}`;
        return { baseUrl, queryParams };
    }

    applyFilters(filters: Filter[]) {
        this.loading = true;
        this.appliedFilterIds2 = filters
            .filter((f) => f.isSelected)
            .flatMap((f) =>
                f.filterValues
                    .filter((fv) => fv.isSelected)
                    .map((fv) => {
                        return {
                            filterTypeId: f.filterTypeId,
                            filterId: fv.filterId,
                        };
                    })
            );
        this.currentPage = 1;
        addFilterIdsToQueryStringAndSearch(this.router, this.appliedFilterIds2);
    }

    applySorting(sortLabel: SortLabel) {
        this.loading = true;
        this.sortLabel = sortLabel;
        addSortingDataToQueryString(this.router, sortLabel);
        this.currentPage = 1;
    }
}
