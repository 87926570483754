import { NgModule } from '@angular/core';
import { FilterDataComponent } from './filter-data.component';
import { CommonModule } from '@angular/common';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryDealFilterComponent } from './category-deal-filter/category-deal-filter.component';

@NgModule({
    declarations: [FilterDataComponent, CategoryDealFilterComponent],
    imports: [
        CommonModule,
        LazyLoadImageModule,
        NgxUsefulSwiperModule,
        TranslateModule,
    ],
    exports: [FilterDataComponent],
})
export class FilterDataModule {}
