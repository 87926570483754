import {Injectable} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {interval} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UpdateService {

    constructor(public updates: SwUpdate) {
        if (updates.isEnabled) {
            interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()
                .then(() => console.log('checking for updates')));
        }
    }

    public checkForUpdates(): void {
        this.updates.available.subscribe((event) => {
            console.log(`current`, event.current, `available`, event.available);
            // if (confirm('Update available for the app release confirm')) {
                this.promptUser()
            // }
        });
        this.updates.activated.subscribe((event) => {
            console.log(`current`, event.previous, `available`, event.current);
        })
    }

    private promptUser(): void {
        console.log('updating to new version');
        this.updates.activateUpdate().then(() => location.reload());
    }
}
