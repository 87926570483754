<md-profile-wrapper [activeSection]="ProfileSection.CHALLENGES">
    <md-profile-page-title
        [title]="'Loyalty Program' | translate"
        [description]="'Earn Badges and Reward Points.' | translate"
    />
    <div class="ma-mb-title-green">
        {{
            "Reward Points are also awarded based on Total Amount of each Order"
                | translate
        }}
    </div>
    <div *ngIf="isMyBadgePageSkeletonShow" class="ma-mb-badge-container">
        <md-my-badges-loading />
    </div>
    <div
        *ngIf="!isMyBadgePageSkeletonShow"
        scroll="false"
        class="ma-mb-badge-container"
    >
        <div class="ma-mb-badge-category" *ngFor="let badges of arrBadgesData">
            <h3>{{ badges.group }}</h3>
            <swiper
                class="swiper-container"
                style="width: 100%"
                [config]="badgesListConfig"
                #usefulSwiper
            >
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide ma-mb-badge"
                        *ngFor="let details of badges.data"
                        (click)="
                            BadgesPressed(
                                $event,
                                details.is_clickable,
                                details.link,
                                details.link_data,
                                details.is_availed
                            )
                        "
                    >
                        <div>
                            <img [src]="details.icon" />
                        </div>
                        <h4>
                            {{ details.title }}
                            <ng-container *ngIf="!details.is_availed">
                                (+{{ details.reward_points | number : "1.0-0"
                                }}{{ "pts" | translate }})
                            </ng-container>
                            <ng-container *ngIf="details.is_availed"
                                >(Unlocked)</ng-container
                            >
                        </h4>
                        <p>
                            {{ details.description }}
                        </p>
                    </div>
                </div>
            </swiper>
        </div>
    </div>
</md-profile-wrapper>
