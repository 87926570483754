<ng-template #errorModal let-modal>
    <div class="modal-header ge-title-container">
        <h4 class="modal-title">
            {{ "Something went wrong" | translate }}
        </h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="dismissError()"
        ></button>
    </div>
    <div class="modal-body ge-modal-body">
        <div class="general-error-body">
            <img
                *ngIf="service.showGeneralErrorOptions?.showImage"
                src="assets/img/error/prop2.png"
                class="ge-modal-image"
            />
            <p class="data-error-msg">
                {{ service.errorMessage }}
            </p>
            <p *ngIf="service.showGeneralErrorOptions?.showMailto">
                {{
                    "If the problem persists, please contact us at" | translate
                }}
                <a href="mailto:info@marideal.mu">info@marideal.mu</a>
            </p>
        </div>
        <div class="ge-ok-button-container">
            <button mdMainButton (click)="dismissError()">
                {{ "OK" | translate }}
            </button>
        </div>
    </div>
</ng-template>
