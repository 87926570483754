import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { screenSizes } from '../utilities/theme';

@Injectable({ providedIn: 'root' })
export class ScreenSizeService {
    private _screenWidth = window.innerWidth;
    screenWidth = new BehaviorSubject(this._screenWidth);
    isMobileWidth = new BehaviorSubject(this.isCurrentWidthMobile());

    onResize(event) {
        this._screenWidth = event.target.innerWidth;
        this.screenWidth.next(this._screenWidth);
        this.isMobileWidth.next(this.isCurrentWidthMobile());
    }

    get currentScreenWidth(): number {
        return this._screenWidth;
    }

    get isMobile(): boolean {
        return this.isCurrentWidthMobile();
    }

    private isCurrentWidthMobile(): boolean {
        return this._screenWidth <= screenSizes.mobile;
    }
}
