import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbOffcanvas, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { screenSizes } from 'src/app/utilities/theme';

@Component({
    selector: 'md-popup-offcanvas',
    templateUrl: './popup-offcanvas.component.html',
    styleUrls: ['./popup-offcanvas.component.scss'],
})
export class PopupOffcanvasComponent {
    @Input() mobileModalTitle: string;
    @Input() mobileContinueButtonText: string;
    @Input() mobileContinueButtonClick: () => void;
    @Input() mobileCloseButtonClick: () => void;

    hasRenderedAsMobile: boolean;

    @ViewChild('d') ngbPopover: NgbPopover;
    @ViewChild('mobileControl') mobileControl: TemplateRef<unknown>;

    constructor(private offcanvasService: NgbOffcanvas) {}

    open() {
        this.hasRenderedAsMobile = window.innerWidth < screenSizes.mobile;
        if (this.hasRenderedAsMobile) {
            this.offcanvasService.open(this.mobileControl, {
                position: 'bottom',
                panelClass: 'c-o-mobile-occupancy',
                scroll: true,
            });
        } else {
            this.ngbPopover.open();
        }
    }

    onModalCloseButtonClicked() {
        this.close();
        this.mobileCloseButtonClick();
    }

    onModalContinueClick() {
        this.close();
        this.mobileContinueButtonClick();
    }

    close() {
        if (this.hasRenderedAsMobile) {
            this.offcanvasService.dismiss();
        } else {
            this.ngbPopover.close();
        }
    }
}
