import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { Observable } from 'rxjs';
import { ForgottenPasswordResponse } from './model/forgotten-password-response.model';
import {
    ForgottenPasswordOtpResponse,
    ForgottenPasswordOtpResponseDto,
    transform,
} from './model/forgotten-password-otp-response.model';
import { map } from 'rxjs/operators';
import { VerifyForgottenPasswordOtpResponse } from './model/verify-forgotten-password-otp-response.model';
import { getApiUrlMofluid } from '../utilities/url.utils';
import { getCustomerId } from '../utilities/user.utils';

@Injectable({
    providedIn: 'root',
})
export class ForgetPasswordService {
    constructor(
        private httpClient: HttpClient,
        private common: CommonService
    ) {}

    forgotPasswordByEmail(
        email: string
    ): Observable<ForgottenPasswordResponse> {
        const customerId = getCustomerId();
        const params = new HttpParams()
            .set('service', 'forgotPassword')
            .set('email', email)
            .set('customerid', customerId)
            .set('customer_group_id', this.common.getCustomerGroupID());

        return this.httpClient.get<ForgottenPasswordResponse>(
            getApiUrlMofluid() + 'forgotPassword',
            {
                params: params,
                headers: this.common.getTokenHeaders(),
            }
        );
    }

    sendForgotPasswordOtp(
        encodedMobile: string
    ): Observable<ForgottenPasswordOtpResponse> {
        const customerId = getCustomerId();
        const params = new HttpParams()
            .set('service', 'sendForgotPasswordOtp')
            .set('mobile_number', encodedMobile)
            .set('send_otp_to', encodedMobile)
            .set('customerid', customerId)
            .set('customer_group_id', this.common.getCustomerGroupID());

        return this.httpClient
            .get<ForgottenPasswordOtpResponseDto>(
                getApiUrlMofluid() + 'sendForgotPasswordOtp',
                {
                    params: params,
                    headers: this.common.getTokenHeaders(),
                }
            )
            .pipe(map((response) => transform(response)));
    }

    verifyForgotPasswordOtp(
        otp: string | number,
        customerId: string
    ): Observable<VerifyForgottenPasswordOtpResponse> {
        const params = new HttpParams()
            .set('service', 'verifyForgotPasswordOtp')
            .set('otp_number', otp)
            .set('send_otp_to', 'forgot_password')
            .set('customerid', customerId)
            .set('customer_group_id', this.common.getCustomerGroupID());

        return this.httpClient.get<VerifyForgottenPasswordOtpResponse>(
            getApiUrlMofluid() + 'verifyForgotPasswordOtp',
            {
                params: params,
                headers: this.common.getTokenHeaders(),
            }
        );
    }
}
