import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ControlsModule } from '../controls/controls.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneralErrorComponent } from './general-error/general-error.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { WriteReviewComponent } from './reviews/write-review/write-review.component';
import { ReviewSummaryComponent } from './reviews/review-summary/review-summary.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { EventsModule } from '../events/events.module';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SearchDatesComponent } from './search-dates/search-dates.component';
import { SearchOccupancyComponent } from './search-occupancy/search-occupancy.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchLocationComponent } from './search-location/search-location.component';
import { CoreModule } from '../core/core.module';

@NgModule({
    declarations: [
        GeneralErrorComponent,
        ReviewsComponent,
        WriteReviewComponent,
        ReviewSummaryComponent,
        SearchDatesComponent,
        SearchOccupancyComponent,
        SearchLocationComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule,
        HttpClientModule,
        AppRoutingModule,
        CommonModule,
        CoreModule,
        ControlsModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        NgbModule,
        FormsModule,
        EventsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    exports: [
        GeneralErrorComponent,
        ReviewsComponent,
        WriteReviewComponent,
        ReviewSummaryComponent,
        SearchDatesComponent,
        SearchOccupancyComponent,
        SearchLocationComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
