import { Component } from '@angular/core';

@Component({
    selector: 'md-mips-loading',
    template: `<div class="cko-mips-loading">
        <div
            style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 15px;
        "
        >
            <div
                mdSkeleton
                style="
                width: 80px;
                height: 50px;
                margin: 8px;
                border-radius: 12px;
            "
            ></div>
            <div
                mdSkeleton
                style="
                width: 80px;
                height: 50px;
                margin: 8px;
                border-radius: 12px;
            "
            ></div>
            <div
                mdSkeleton
                style="
                width: 80px;
                height: 50px;
                margin: 8px;
                border-radius: 12px;
            "
            ></div>
        </div>
        <div
            mdSkeleton
            style="
                width: 100%;
                height: 18.5px;
                margin-bottom: 15px;
                border-radius: 8px;
            "
        ></div>
        <div
            mdSkeleton
            style="
                width: 100%;
                height: 50px;
                margin-bottom: 15px;
                border-radius: 8px;
            "
        ></div>
        <div style="display: flex; flex-direction: row">
            <div
                style="display: flex; flex-direction: column; margin-right: 25px"
            >
                <div
                    mdSkeleton
                    style="
                width: 176px;
                height: 50px;
                margin-bottom: 15px;
                border-radius: 8px;
            "
                ></div>
                <div
                    mdSkeleton
                    style="
                width: 176px;
                height: 50px;
                margin-bottom: 15px;
                border-radius: 8px;
            "
                ></div>
            </div>
            <div
                mdSkeleton
                style="
                width: 156px;
                height: 98px;
                border-radius: 12px;
            "
            ></div>
        </div>
    </div>`,
    styles: [
        `
            :host {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 20px;
            }
        `,
    ],
})
export class MipsLoading {}
