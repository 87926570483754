import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private commonService: CommonService, private httpClient: HttpClient) { }

  getAllOrders(customerId: any, status: string, pageSize: number, currentPage: number){
    return this.httpClient.get(this.commonService.api_url + "myordersnew?service=myordersnew&customerid=" + customerId + "&status=" + status +"&pagesize=" + pageSize + "&currentpage=" + currentPage + "&store=" + this.commonService.getStoreID() + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }

  getPendingOrders(customerId: any, status: string, pageSize: number, currentPage: number){
    return this.httpClient.get(this.commonService.api_url + "myordersnew?service=myordersnew&customerid=" + customerId + "&status=" + status +"&pagesize=" + pageSize + "&currentpage=" + currentPage + "&store=" + this.commonService.getStoreID() + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }

  getCompleteOrders(customerId: any, status: string, pageSize: number, currentPage: number){
    return this.httpClient.get(this.commonService.api_url + "myordersnew?service=myordersnew&customerid=" + customerId + "&status=" + status +"&pagesize=" + pageSize + "&currentpage=" + currentPage + "&store=" + this.commonService.getStoreID() + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }

  getCanceledOrders(customerId: any, status: string, pageSize: number, currentPage: number){
    return this.httpClient.get(this.commonService.api_url + "myordersnew?service=myordersnew&customerid=" + customerId + "&status=" + status +"&pagesize=" + pageSize + "&currentpage=" + currentPage + "&store=" + this.commonService.getStoreID() + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }
}
