import { Component, OnInit } from '@angular/core';
import { WinService } from './win.service';

@Component({
  selector: 'app-win',
  templateUrl: './win.component.html',
  styleUrls: ['./win.component.scss']
})
export class WinComponent implements OnInit {
  imageurl: any;
  bodycontent: any;
  winSkeleton:boolean=true;

  constructor(private winService:WinService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
   this.winService.cmsPage().subscribe((response:any)=>{
    this.winSkeleton = false;
    if (response.count) {
        this.imageurl = response.data[1].url;
        this.bodycontent = response.data[0].content;
    }
    else {
        console.log("error");
    }
  });
  }

}
