import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/category-deals-list/model/product.model';
import { RibbonStyleService } from 'src/app/services/ribbon-style.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { SwiperOptions } from 'swiper';

type PliProduct = Product & {
    reviewData: { label: string; borderColor: string; progVal: number };
};

@Component({
    selector: 'md-product-list-item',
    templateUrl: './product-list-item.component.html',
    styleUrls: ['./product-list-item.component.scss'],
})
export class ProductListItemComponent implements OnInit {
    @Input() set data(value: Product) {
        this.productWithReviewData = {
            ...value,
            reviewData: this.getReviewText(value.average),
        };
        
    }

    @Input() index: number;
    @Input() currencySymbol: 'Rs.' | 'EUR';
    @Input() locale: 'en-MU' | 'fr-RE';

    constructor(
        private ribbonStyleService: RibbonStyleService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    productWithReviewData: PliProduct;
    shouldDisplayImage: boolean;

    config: SwiperOptions = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        spaceBetween: 30,
    };

    ngOnInit(): void {

        this.shouldDisplayImage =
         this.ribbonStyleService.getRibbonContent(this.data).type === 'image';

        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
        this.currencyLanguageService
            .getCurrency()
            .subscribe((currencySymbol) => {
                this.currencySymbol = currencySymbol;
            });
    }

    getStars(rating: number) {
        let size = 0;
        if (rating) {
            size = (rating / 5) * 100;
        }
        return size + '%';
    }

    getReviewText(rating: number): {
        label: string;
        borderColor: string;
        progVal: number;
    } {
        let ratingLabel = 'Poor';
        let borderCol = '#f68e8e';
        if (rating >= 9.0) {
            ratingLabel = 'Superb';
            borderCol = '#90c652';
        } else if (rating >= 8.0) {
            ratingLabel = 'Great';
            borderCol = '#90c652';
        } else if (rating >= 6.0) {
            ratingLabel = 'Good';
            borderCol = '#6fc2eb';
        } else if (rating >= 4.0) {
            ratingLabel = 'Average';
            borderCol = '#feba02';
        }

        return {
            label: ratingLabel,
            borderColor: borderCol,
            progVal: rating * 10,
        };
    }

    getRibbonStyles(deal: Product) {
        return this.ribbonStyleService.getRibbonStyles(deal);
    }
}
