import { parse } from 'path';
import { DealRibbon } from 'src/app/category-deals-list/model/product.model';
import { ProductCardEntry } from 'src/app/controls/product-card/product-card.component';
import { getPathFromProductUrl } from 'src/app/utilities/url.utils';

export interface CustomerAlsoViewedResponseDto {
    data: {
        id: string;
        name: string;
        imageurl: string;
        product_url: string;
        price: string;
        actualprice: string;
        short_description: string;
        ribbon?: DealRibbon
    }[];
}

export const transformCustomerAlsoViewedResponse = (
    response: CustomerAlsoViewedResponseDto
): ProductCardEntry[] => {
    return response.data.map((product) => {
        return {
            id: parseInt(product.id),
            name: product.name,
            productUrl: product.product_url,
            productPath: getPathFromProductUrl(product.product_url),
            imageUrl: product.imageurl,
            shortDescription: product.short_description,
            specialPrice: Math.ceil(parseFloat(product.actualprice)),
            price: Math.ceil(parseFloat(product.price)),
            ribbon: product.ribbon
        };
    });
};
