<div
    class="sd-container"
    [ngClass]="{
        'sd-scrolled': isScrolled,
        'sd-when-where': isOnDayPackagePage
    }"
>
    <div class="date-picker-area">
        <div
        class="sd-date-picker"
        *ngIf="!isOnDayPackagePage; else singleCalendar"
    >
        <md-calendar
            #calendarSearchDates
            [infoForEachDay]="additionalInfoForCalendar"
            (dateRangeSelected)="onDateRangeSelected($event)"
            [showDesktopInPopup]="true"
            ><div popupTargetControl class="sd-date-picker-inside">
                <ion-icon name="calendar-clear-outline"></ion-icon>
                <div class="sd-date-picker-in">
                    <span class="sd-date-picker-chkin">{{
                        "Check-in" | translate
                    }}</span>
                    <span>{{ formattedFromDate }}</span>
                </div>
                <div class="sd-date-divider">-</div>
                <div class="sd-date-picker-out">
                    <span class="sd-date-picker-chkout">{{
                        "Check-out" | translate
                    }}</span>
                    <span>{{ formattedToDate }}</span>
                </div>
            </div>
        </md-calendar>
    </div>
    <div class="sd-occup" *ngIf="!isOnDayPackagePage; else locationControl">
        <md-search-occupancy
            #searchOccupancy
            (occupancyChanged)="onOccupancyChanged($event)"
        >
            <div
                targetControl
                class="sd-occup-inside"
                (click)="onOccupancyClicked()"
            >
                <ion-icon name="person-outline"></ion-icon>
                <span class="sd-occup-guests">{{ "Guests" | translate }}</span>
                <span class="sd-occup-value">{{
                    occupancyString | translate
                }}</span>
            </div>
        </md-search-occupancy>
    </div>
    </div>
    <button class="sd-button" (click)="search()">
        <span>{{ "Search" | translate }}</span>
        <ion-icon name="search-outline"></ion-icon>
    </button>
</div>
<ng-template #singleCalendar>
    <div class="sd-date-picker">
        <md-calendar
            #calendarSingleSearchDates
            [infoForEachDay]="additionalInfoForCalendar"
            (dateRangeSelected)="onSingleDateSelected($event)"
            [showDesktopInPopup]="true"
            [isSingleDate]="true"
            ><div popupTargetControl class="sd-date-picker-inside">
                <ion-icon name="calendar-clear-outline"></ion-icon>
                <div class="sd-date-picker-in">
                    <span class="sd-date-picker-chkin">{{
                        "When" | translate
                    }}</span>
                    <span>{{ formattedSingleDate }}</span>
                </div>
            </div>
        </md-calendar>
    </div>
</ng-template>
<ng-template #locationControl>
    <md-search-location
        #searchLocation
        (locationChanged)="onLocationChanged($event)"
        class="sd-occup"
    >
        <div
            targetControl
            class="sd-occup-inside"
            (click)="onLocationClicked()"
        >
            <ion-icon name="location-outline"></ion-icon>
            <span class="sd-occup-guests">{{ "Where" | translate }}</span>
            <span class="sd-occup-value">{{ locationString | translate }}</span>
        </div>
    </md-search-location>
</ng-template>
