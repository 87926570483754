import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreateInvoiceService {

  constructor(private httpClient: HttpClient, private common: CommonService) { }
  saveOrderInvoice(data:any){
 return this.httpClient.get(
    this.common.api_url+`generateinvoice?service=generateinvoice&customerid=${data.customerId}&orderid=${data.order_id}&po=${data.po}` + "&customer_group_id=" + this.common.getCustomerGroupID(), {headers: this.common.getTokenJWTheaders()})

}

}
