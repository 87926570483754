import { Component, OnInit } from '@angular/core';
import { StoreCreditService } from './store-credit.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { User } from 'src/app/model/user.model';
import { getUser } from 'src/app/utilities/user.utils';
import { ProfileSection } from 'src/app/user/profile-wrapper.component';
import { RewardsAndWalletService } from '../rewards-and-wallet.service';

@Component({
    selector: 'md-store-credit',
    templateUrl: './store-credit.component.html',
    styleUrls: ['../rewards-and-wallet.component.scss'],
})
export class StoreCreditComponent implements OnInit {
    isStoreCreditData: boolean = false;
    public loginResponse: User;
    public userLogin: boolean = false;
    currentpage: number = 1;
    customerId: any;
    paginationLimit!: number;
    storeCreditHistory = [];
    storeCreditDetails: any;
    can_show: boolean = false;

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    constructor(
        private scService: StoreCreditService,
        private eventService: EventService,
        private currencyLanguageService: CurrencyLanguageService,
        private rewardsAndWalletService: RewardsAndWalletService
    ) {}

    ngOnInit(): void {
        this.checkLoginResponse();
        this.currencyLanguageService
            .getCurrency()
            .subscribe((currencySymbol) => {
                this.currencySymbol = currencySymbol;
            });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    checkLoginResponse() {
        let userdata = getUser();
        if (userdata) {
            this.loginResponse = userdata;
            this.customerId = this.loginResponse?.id;
            this.userLogin = true;
            this.getStoreCreditData();
        } else {
            this.eventService.emitEvent(EventType.LOGIN);
        }
    }

    getStoreCreditData() {
        this.isStoreCreditData = true;
        this.scService
            .findStoreCredit(this.customerId, this.currentpage)
            .subscribe(
                (response: any) => {
                    this.isStoreCreditData = false;
                    if (response.status == 'success') {
                        this.can_show = response.can_show;
                        this.storeCreditHistory =
                            response.store_credit_history.data;
                        this.storeCreditDetails = response.storecredit_details;
                        this.rewardsAndWalletService.updateStoreCreditBalance(
                            this.storeCreditDetails.credit_amount
                        );
                        this.paginationLimit = Math.ceil(
                            response.store_credit_history.total / 10
                        );
                    }
                },
                (error) => {
                    this.isStoreCreditData = false;
                    console.log(error);
                }
            );
    }

    onScroll() {
        if (this.currentpage < this.paginationLimit) {
            this.currentpage += 1;
            this.scService
                .findStoreCredit(this.customerId, this.currentpage)
                .subscribe(
                    (response: any) => {
                        if (response.status == 'success') {
                            this.storeCreditHistory =
                                this.storeCreditHistory.concat(
                                    response.store_credit_history.data
                                );
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    removeAdminCommentPrefix(str: string) {
        return str.slice(25);
    }

    protected readonly ProfileSection = ProfileSection;
}
