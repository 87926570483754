import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'md-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss'],
})
export class CounterComponent implements OnInit {
    @Input() startValue = 0;
    @Input() minValue = 0;
    @Input() maxValue = 100;
    @Output() valueChanged = new EventEmitter<number>();

    counterValue = 0;

    increase() {
        if (this.counterValue < this.maxValue) {
            this.counterValue++;
            this.valueChanged.emit(this.counterValue);
        }
    }

    decrease() {
        if (this.counterValue > this.minValue) {
            this.counterValue--;
            this.valueChanged.emit(this.counterValue);
        }
    }

    ngOnInit(): void {
        this.counterValue = this.startValue;
    }
}
