import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import {
    RatingColor,
    RatingLabel,
    transformReviewData,
} from '../reviews.model';
import { ReviewsService } from '../reviews.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'md-review-summary',
    templateUrl: './review-summary.component.html',
    styleUrls: ['./review-summary.component.scss'],
})
export class ReviewSummaryComponent implements OnDestroy {
    @Input() set productId(value: number) {
        if (!value || value === this.internalProductId) return;

        this.internalProductId = value;
        this.reviewDataSubscription = this.service
            .getProductReview(Number(value), 0)
            .subscribe((reviewData) => {
                const data = transformReviewData(reviewData);

                if (data) {
                    this.averageRating = data.average;
                    this.reviewCount = data.total;
                    this.trimmedAverageRating = this.getTrimmedAverageRating();
                    this.strokeDasharray = `${this.averageRating} 100`;
                    const { color, label } = this.getStrokeColorAndRatingLabel(
                        this.averageRating
                    );
                    this.strokeColor = color;
                    this.ratingLabel = label;
                }
            });
    }

    @Input() large = false;
    @Input() showReviews = true;

    ratingLabel: RatingLabel;
    strokeDasharray: string;
    strokeColor: RatingColor;
    trimmedAverageRating: string;
    averageRating: number;
    reviewCount: number;

    @Output() summaryClick = new EventEmitter();

    private internalProductId: number;
    private reviewDataSubscription: Subscription;

    constructor(private service: ReviewsService) {}

    ngOnDestroy(): void {
        if (this.reviewDataSubscription) {
            this.reviewDataSubscription.unsubscribe();
        }
    }

    onClick(): void {
        this.summaryClick.emit();
    }

    private getTrimmedAverageRating(): string {
        if (!this.averageRating) return '-';
        return (this.averageRating / 10).toFixed(1);
    }

    private getStrokeColorAndRatingLabel(rating: number): {
        color: RatingColor;
        label: RatingLabel;
    } {
        if (rating >= 8.0) {
            return { color: '#90c652', label: 'Great' };
        } else if (rating >= 6.0) {
            return { color: '#6fc2eb', label: 'Good' };
        } else if (rating >= 4.0) {
            return { color: '#feba02', label: 'Average' };
        } else if (rating > 0) {
            return { color: '#f68e8e', label: 'Poor' };
        } else {
            return { color: '#f68e8e', label: 'No Reviews' };
        }
    }
}
