import { Component, Input } from '@angular/core';
import { DealInfo } from '../../model/product-detail.model';
import { parseMarkdown } from 'src/app/utilities/markdown.utils';

@Component({
  selector: 'md-deal-information',
  templateUrl: './deal-information.component.html',
  styleUrls: ['./deal-information.component.scss'],
})
export class DealInformationComponent {
  private _dealInformation: DealInfo;

  @Input()
  set dealInformation(value: DealInfo) {
    this._dealInformation = value;
    this.updateDealInformation();
  }

  purchaseIncludesMarkup: string | Promise<string>;
  packageDetailsMarkup: string | Promise<string>;
  aboutMarkup: string | Promise<string>;
  hasPackageDetails = false;
  hasAbout = false;

  private updateDealInformation(): void {
    const { about, purchaseIncludes, packageDetails } = this._dealInformation;
    this.aboutMarkup = parseMarkdown(about);
    this.purchaseIncludesMarkup = parseMarkdown(purchaseIncludes);
    this.packageDetailsMarkup = parseMarkdown(packageDetails);
    this.hasPackageDetails = !!packageDetails;
    this.hasAbout = !!about;
  }
}
