import { NgModule } from "@angular/core";
import { SocialLoginComponent } from "./social-login.component";
import { TranslateModule } from "@ngx-translate/core";
import { GoogleSigninButtonModule } from "@abacritt/angularx-social-login";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        TranslateModule,
        GoogleSigninButtonModule,
        CommonModule
    ],
    declarations: [SocialLoginComponent],
    exports: [SocialLoginComponent]
})
export class SocialLoginModule {

}