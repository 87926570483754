<div class="pdsc-container">
    <div mdMobileOnly>
        <ng-container *ngTemplateOutlet="mobileView"></ng-container>
    </div>
    <div mdDesktopOnly>
        <ng-container *ngTemplateOutlet="desktopView"></ng-container>
    </div>
</div>

<ng-template #mobileView>
    <div ngbAccordion>
        <ng-container
            *ngTemplateOutlet="
                mobileAccordionItem;
                context: { title: 'Information', innerTemplate: information }
            "
        >
        </ng-container>
        <ng-container
            *ngTemplateOutlet="
                mobileAccordionItem;
                context: { title: 'Location', innerTemplate: location }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                mobileAccordionItem;
                context: { title: 'Reviews', innerTemplate: reviews }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                mobileAccordionItem;
                context: { title: 'Facilities', innerTemplate: facilities }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                mobileAccordionItem;
                context: { title: 'Conditions', innerTemplate: conditions }
            "
        ></ng-container>

        <ng-template
            #mobileAccordionItem
            let-title="title"
            let-innerTemplate="innerTemplate"
        >
            <div ngbAccordionItem [collapsed]="false">
                <h2 ngbAccordionHeader>
                    <button
                        ngbAccordionButton
                        class="pd-static-content-header-button"
                    >
                        {{ title | translate }}
                    </button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-container
                            *ngTemplateOutlet="innerTemplate"
                        ></ng-container>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>
<ng-template #desktopView>
    <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activeNavItem"
        class="nav-tabs pdsc-nav"
    >
        <li [ngbNavItem]="0">
            <button ngbNavLink class="pd-static-content-header-button-desktop">
                {{ "Information" | translate }}
            </button>
            <ng-template ngbNavContent>
                <ng-container *ngTemplateOutlet="information"></ng-container>
            </ng-template>
        </li>
        <li [ngbNavItem]="3">
            <button ngbNavLink class="pd-static-content-header-button-desktop">
                {{ "Location" | translate }}
            </button>
            <ng-template ngbNavContent>
                <ng-container *ngTemplateOutlet="location"></ng-container>
            </ng-template>
        </li>
        <li [ngbNavItem]="4">
            <button ngbNavLink class="pd-static-content-header-button-desktop">
                {{ "Reviews" | translate }}
            </button>
            <ng-template ngbNavContent>
                <ng-container *ngTemplateOutlet="reviews"></ng-container>
            </ng-template>
        </li>
        <li *ngIf="staticContent.facilities && staticContent.facilities.length > 0" [ngbNavItem]="2">
            <button ngbNavLink class="pd-static-content-header-button-desktop">
                {{ "Facilities" | translate }}
            </button>
            <ng-template ngbNavContent>
                <ng-container *ngTemplateOutlet="facilities"></ng-container>
            </ng-template>
        </li>
        <li [ngbNavItem]="1">
            <button ngbNavLink class="pd-static-content-header-button-desktop">
                {{ "Conditions" | translate }}
            </button>
            <ng-template ngbNavContent>
                <ng-container *ngTemplateOutlet="conditions"></ng-container>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</ng-template>

<ng-template #information>
    <md-deal-information [dealInformation]="staticContent.information" />
</ng-template>
<ng-template #conditions>
    <md-deal-conditions [dealConditions]="staticContent.conditions" />
</ng-template>
<ng-template #reviews>
    <md-reviews
        [dealReviewData]="staticContent.reviews"
        [productId]="productId"
    />
</ng-template>
<ng-template #facilities>
    <md-deal-facilities
        [facilities]="staticContent.facilities"
        [roomCategories]="staticContent.roomCategories"
    />
</ng-template>
<ng-template #location>
    <md-deal-location [dealLocation]="staticContent.location" />
</ng-template>
