import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter } from 'src/app/filter-data/filter-data.model';

@Component({
    selector: 'md-category-deal-filter',
    templateUrl: './category-deal-filter.component.html',
    styleUrls: ['./category-deal-filter.component.scss'],
})
export class CategoryDealFilterComponent {
    @Input() filterData: Filter;
    expanded = true;
    formClass = 'filter-expanded';

    @Output() filterChanged = new EventEmitter<Filter>();

    toggleExpand(): void {
        this.expanded = !this.expanded;
        this.formClass = this.expanded ? 'filter-expanded' : 'filter-hidden';
    }

    selectionChange(index: number) {
        const thisFilter = this.filterData.filterValues[index];
        if (this.filterData.filterTypeId === 1) {
            this.filterData.filterValues.forEach((filterValue, i) => {
                filterValue.isSelected = i === index;
            });
        } else {
            thisFilter.isSelected = !thisFilter.isSelected;
        }

        if (thisFilter.isSelected) {
            this.filterData.isSelected = true;
        } else {
            this.filterData.isSelected = this.filterData.filterValues.some(
                (filterValue) => filterValue.isSelected
            );
        }

        this.filterData.filterValues[index] = thisFilter;
        this.filterChanged.emit(this.filterData);
    }
}
