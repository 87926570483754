import { Component, OnInit } from '@angular/core';
import { ReviewService } from '../reviews/review.service';
import { TranslateService } from '@ngx-translate/core';
import { EventService, EventType } from '../../../utilities/event.service';
import { getUser } from '../../../utilities/user.utils';
import { ProfileSection } from '../../profile-wrapper.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOffcanvasComponent } from 'src/app/controls/modal-offcanvas/modal-offcanvas.component';

@Component({
    selector: 'app-reviews',
    templateUrl: './reviews.component.html',
    styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent implements OnInit {
    badge_progress: any;
    customerId: string;
    reviewDetails: any;
    currentPage: number = 0;
    loadmoredatacalled: boolean = false;
    nomore: boolean = true;
    totalReview: number = 0;
    reviews: any[] = [];
    position: number;
    showfilter = true;
    isLoading: boolean = true;
    scrollablePane: Element;
    selectedProductForReviewId: number;

    constructor(
        private eventService: EventService,
        private reviewService: ReviewService,
        private translate: TranslateService,
        private modalService: NgbModal
    ) {}

    getReviewsData() {
        this.isLoading = false;
        if (this.totalReview > this.reviews.length || this.currentPage == 0) {
            this.currentPage += 1;
            if (this.currentPage > 1) {
                this.loadmoredatacalled = true;
            }
            const data = {
                id: this.customerId,
                pageIndex: this.currentPage,
                pageSize: 50,
            };
            this.reviewService.getReviewData(data).subscribe(
                (response) => {
                    this.isLoading = false;
                    if (response) {
                        this.reviewDetails = response;
                    }
                    if (this.currentPage == 1) {
                        this.reviews = this.reviewDetails.outlet_list;
                    } else {
                        this.reviews = this.reviews.concat(
                            this.reviewDetails.outlet_list
                        );
                    }
                    this.totalReview = parseInt(this.reviewDetails.total);
                    if (this.reviews.length >= this.totalReview) {
                        this.nomore = false;
                    }
                    this.loadmoredatacalled = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
        }
    }

    ngOnInit(): void {
        this.checkLoginResponse();
        this.isLoading = true;
        this.scrollablePane = document.getElementsByClassName('pane')[0];
    }

    checkLoginResponse() {
        const userdata = getUser();
        if (userdata) {
            this.customerId = userdata.id;
            this.getReviewsData();
        } else {
            this.eventService.emitEvent(EventType.LOGIN, 'review/customer');
            return false;
        }
    }

    getReviewPercentage() {
        const totalReview = parseInt(this.reviewDetails.badge_progress.Lvl4);
        const approvedReview = parseInt(
            this.reviewDetails.badge_progress.approved_review
        );
        if (approvedReview > totalReview) {
            return 100;
        } else {
            let percentage = (approvedReview / totalReview) * 100;
            return Math.round(percentage);
        }
    }

    getProgressText(lvlValue: number) {
        const { approved_review } = this.reviewDetails.badge_progress;
        if (approved_review >= lvlValue) {
            return this.translate.instant('Unlocked');
        } else {
            return approved_review + '/' + lvlValue;
        }
    }

    writeReview(review: any, content: ModalOffcanvasComponent) {
        this.selectedProductForReviewId = review.outlet_id;
        this.openModal(content);
    }

    getReviewImg(review: any) {
        const { average_rating } = review.review_data;
        const path = '../../../../assets/img/rwEmoji/';
        if (average_rating >= 80) {
            return path + 'Superb.png';
        } else if (average_rating >= 60) {
            return path + 'Great.png';
        } else if (average_rating >= 40) {
            return path + 'Good.png';
        } else {
            return path + 'Poor.png';
        }
    }

    private openModal(content: ModalOffcanvasComponent): void {
        content.open();
    }

    onReviewSubmitted(): void {
        this.modalService.dismissAll();
    }

    protected readonly ProfileSection = ProfileSection;
}
