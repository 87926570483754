import { Component, Input, OnInit } from '@angular/core';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

@Component({
    selector: 'md-booking-summary',
    templateUrl: './booking-summary.component.html',
    styleUrls: ['./booking-summary.component.scss'],
})
export class BookingSummaryComponent implements OnInit {
    @Input() cart_total_amount: number;
    @Input() coupon_code_discount: number;
    @Input() store_discount_amount: number;
    @Input() rw_discountAmount: number;
    @Input() discountCodeTitle: number;
    @Input() giftcard: number;
    @Input() gift_discount: number;
    @Input() rewardPts: number;
    @Input() servicFeeVal: number;
    @Input() totalPrice: number;

    @Input() standalone: boolean = false; // Has a border and shadow

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    showFeeInfoText: boolean = false;

    constructor(private currencyLanguageService: CurrencyLanguageService) {}

    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    showFeeInfo() {
        this.showFeeInfoText = !this.showFeeInfoText;
    }
}
