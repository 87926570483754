import { Component, Input, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'ngx-useful-swiper';
import {
    ProductDetailImage,
    ProductDetails,
} from '../model/product-detail.model';
import {
    DESKTOP_PDP_MAIN_IMG_TYPE,
    MOBILE_PDP_MAIN_IMG_TYPE,
} from 'src/app/model/deal.model';

const carouselConfig: SwiperOptions = {
    autoHeight: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
};

const thumbnailsConfig: SwiperOptions = {
    autoHeight: true,
    navigation: {
        nextEl: '.swiper-thumb-next',
        prevEl: '.swiper-thumb-prev',
    },
    spaceBetween: 4,
    slidesPerView: 7,
};

@Component({
    selector: 'md-product-image-carousel',
    templateUrl: './product-image-carousel.component.html',
    styleUrls: ['./product-image-carousel.component.scss'],
})
export class ProductImageCarouselComponent {
    carouselConfig: SwiperOptions = carouselConfig;
    thumbnailsConfig: SwiperOptions = thumbnailsConfig;

    @Input() set productDetails(value: ProductDetails) {
        if (value) {
            this.eventStartDate = value.eventStartDate;
            this.eventEndDate = value.eventEndDate;
            this.mobileImages = value.images.filter(
                (image) => image.imageTypeId === MOBILE_PDP_MAIN_IMG_TYPE
            );
            this.desktopImages = value.images.filter(
                (image) => image.imageTypeId === DESKTOP_PDP_MAIN_IMG_TYPE
            );
        }
    }

    eventStartDate?: Date;
    eventEndDate?: Date;
    mobileImages: ProductDetailImage[] = [];
    desktopImages: ProductDetailImage[] = [];

    @ViewChild('mainSwiper') mainSwiper: SwiperComponent;

    thumbClick(index: number): void {
        this.mainSwiper.swiper.slideTo(index);
    }
}
