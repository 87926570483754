<md-profile-wrapper [activeSection]="ProfileSection.REVIEWS">
    <md-profile-page-title
        [title]="'My Reviews' | translate"
        [description]="
            'Only a customer who has booked through MariDeal and stayed at the property in question can write a review. This lets us know that our reviews come from real guests, like you.'
                | translate
        "
    />
    <div>
        <div
            infiniteScroll
            [infiniteScrollContainer]="scrollablePane"
            [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="300"
            [scrollWindow]="false"
            [immediateCheck]="true"
            [alwaysCallback]="true"
            (scrolled)="getReviewsData()"
        >
            <div class="ma-rw-review header">
                <div class="ma-rw-review-outlet">
                    {{ "Property" | translate }}
                </div>
                <div class="ma-rw-review-score">
                    {{ "Rating" | translate }}
                </div>
                <div class="ma-rw-review-detail">
                    {{ "Review" | translate }}
                </div>
            </div>
            <div *ngIf="reviews && reviews.length > 0">
                <div *ngFor="let review of reviews" class="ma-rw-review">
                    <div class="ma-rw-review-outlet">
                        {{ review.outlet_name }}
                    </div>
                    <div class="ma-rw-review-score">
                        <md-review-score-bar
                            [averageScore]="
                                review.review_data.average_rating / 10
                            "
                            [label]="review.review_data.title"
                        />
                    </div>
                    <div *ngIf="review.review_data" class="ma-rw-review-detail">
                        {{ review.review_data.detail }}
                    </div>
                    <div
                        class="ma-rw-review-detail"
                        *ngIf="!review.review_data"
                    >
                        <button
                            class="ma-rw-button"
                            mdMainButton="light"
                            (click)="writeReview(review, modal)"
                        >
                            {{ "Write a Review" | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <md-reviews-loading *ngIf="isLoading" />
            <div>
                <div>
                    <div
                        class="view_more custom-user-review-div-23"
                        *ngIf="loadmoredatacalled"
                    >
                        {{ "Load more" | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</md-profile-wrapper>
<md-modal-offcanvas
    #modal
    [mobileModalTitle]="'Reviews by customer only' | translate"
    [thinPadding]="true"
>
    <div actualControl class="ma-rw-write-container">
        <md-write-review
            [productId]="selectedProductForReviewId"
            (reviewSubmitted)="onReviewSubmitted()"
        ></md-write-review>
    </div>
</md-modal-offcanvas>
