import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, switchMap } from 'rxjs';
import { CommonService } from '../shared/common.service';
import { encodeForUrl } from '../utilities/encoders';
import { HomeDealsDto, RecentlyViewedDto, UpcomingTripDto } from './home.model';
import { getCustomerId } from '../utilities/user.utils';
import { getApiUrlMofluid, getApiUrlV2 } from '../utilities/url.utils';
import { getRecentEntries } from '../utilities/cache.utils';
import {
    CurrencyLanguageService,
    LocaleData,
} from '../shared/currency-language.service';

@Injectable({
    providedIn: 'root',
})
export class HomeService {
    constructor(
        private httpClient: HttpClient,
        private common: CommonService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    /**
     * Service Method to get recently viewed data
     */
    getRecentlyViewedData(): Observable<RecentlyViewedDto> {
        const storedRecentIds = getRecentEntries();
        storedRecentIds.reverse();
        const encodedRecentIds: string = encodeForUrl(
            JSON.stringify(storedRecentIds)
        );

        const customerId = getCustomerId();

        // TODO: all of these localstorage methods should be in their own service
        const api_url =
            getApiUrlMofluid() +
            'getNewRecentProducts?service=getNewRecentProducts&product_arr=' +
            encodedRecentIds +
            '&customerid=' +
            customerId +
            '&store=' +
            this.common.getStoreID() +
            '&customer_group_id=' +
            this.common.getCustomerGroupID();
        return this.httpClient
            .get(api_url, { headers: this.common.getTokenHeaders() })
            .pipe(
                map((res: RecentlyViewedDto) => {
                    return res;
                })
            );
    }

    getUpcomingTrip() {
        const customerId = getCustomerId();
        const upcomingTripUrl =
            getApiUrlMofluid() +
            'myupcomingtrip?service=myupcomingtrip&customerid=' +
            customerId +
            '&category=all' +
            '&customer_group_id=' +
            this.common.getCustomerGroupID();

        return this.httpClient
            .get(upcomingTripUrl, { headers: this.common.getTokenHeaders() })
            .pipe(
                map((res: UpcomingTripDto) => {
                    return res;
                })
            );
    }

    getDeals(): Observable<HomeDealsDto> {
        return this.currencyLanguageService
            .getLocaleData()
            .pipe(
                switchMap((localeData: LocaleData) =>
                    this.httpClient.get<HomeDealsDto>(
                        `${getApiUrlV2()}results/home?lang=${
                            localeData.language
                        }&cc=${localeData.country}`
                    )
                )
            );
    }
}
