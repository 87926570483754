import { Component, Input } from '@angular/core';

@Component({
    selector: 'md-profile-page-title',
    template: `
        <div mdDesktopOnly class="ma-header">
            <h2>{{ title }}</h2>
            <p>{{ description }}</p>
        </div>
    `,
    styles: [
        `
            @import '/src/theme.scss';

            .ma-header {
                margin-bottom: $base-spacing * 5;

                h2 {
                    font-size: $font-size-xl;
                    font-weight: $font-weight-semibold;
                    line-height: 30px;
                    color: $blue-3;
                }

                p {
                    font-size: $font-size-xs;
                    line-height: 18px;
                    text-align: left;
                    color: $grey-3;
                }
            }
        `,
    ],
})
export class ProfilePageTitleComponent {
    @Input() title: string;
    @Input() description: string;
}
