<div
    *ngIf="screenSizeService.isMobileWidth | async"
    class="filter-data-component-parent-div"
>
    <div class="row category-filter-main">
        <div class="col-sm-12 filterDiv filters-container-mobile">
            <swiper
                class="swiper-container category-filter"
                [config]="categoryListConfig"
            >
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <button
                            type="button"
                            class="btn btn-light slideButton best-seller-btn"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#customOptionsMenu"
                            aria-controls="customOptionsMenu"
                        >
                            {{ sortLabel ?? "Best Seller" | translate }}
                            <img
                                src="assets/img/General Icons_Arrow Down - AI Blue3 24x24px.svg
                                "
                                alt="img1"
                            />
                        </button>
                    </div>
                    <div
                        class="swiper-slide"
                        *ngFor="let filter of filterOptions"
                    >
                        <button
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasBottom"
                            aria-controls="offcanvasBottom"
                            [ngClass]="
                                filter.isSelected
                                    ? 'slideButton blue-color-button'
                                    : 'slideButton white-color'
                            "
                            (click)="filterOverlayOpen(filter)"
                        >
                            {{ filter.filterName }}
                            <img
                                src="assets/img/General_Icons_Arrow_Down_-_AI_Blue3_24x24px.svg
                                "
                                alt="img1"
                            />
                        </button>
                    </div>
                </div>
            </swiper>
        </div>
    </div>

    <div
        class="offcanvas offcanvas-bottom h-auto"
        style="max-height: 50%"
        tabindex="-1"
        id="customOptionsMenu"
        aria-labelledby="offcanvasBottomLabel"
    >
        <div class="offcanvas-header">
            <button
                type="button"
                class="resetButton"
                [disabled]="!sortLabel"
                [ngClass]="sortLabel ? 'red-reset-color' : ''"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                (click)="resetSorting()"
            >
                {{ "Reset" | translate }}
            </button>
            <h5 class="offcanvas-title-heading" id="offcanvasBottomLabel">
                {{ sortLabel | translate }}
            </h5>
            <button
                type="button"
                class="DoneButton"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                (click)="applySort()"
            >
                {{ "Done" | translate }}
            </button>
        </div>
        <hr style="margin: 0" />
        <div class="offcanvas-body small" style="margin: 0; overflow: auto">
            <ng-container *ngIf="shouldShowClosestDate">
                <ng-container
                    *ngTemplateOutlet="
                        sortItem;
                        context: {
                            expectedLabel: 'Closest Date'
                        }
                    "
            /></ng-container>

            <ng-container
                *ngTemplateOutlet="
                    sortItem;
                    context: {
                        expectedLabel: 'Best Seller'
                    }
                "
            />
            <ng-container
                *ngTemplateOutlet="
                    sortItem;
                    context: {
                        expectedLabel: 'Lowest Price'
                    }
                "
            />
            <ng-container
                *ngTemplateOutlet="
                    sortItem;
                    context: {
                        expectedLabel: 'Highest Price'
                    }
                "
            />
            <ng-container
                *ngTemplateOutlet="
                    sortItem;
                    context: {
                        expectedLabel: 'Customer Rating'
                    }
                "
            />
            <ng-template #sortItem let-expectedLabel="expectedLabel">
                <div class="row filter-options">
                    <div class="col-sm-12">
                        <label
                            for="radio-{{ expectedLabel }}"
                            [ngClass]="false ? 'blue' : 'black'"
                            >{{ expectedLabel | translate }}</label
                        >
                        <input
                            type="radio"
                            name="customOption"
                            id="radio-{{ expectedLabel }}"
                            style="float: right"
                            [checked]="sortLabel === expectedLabel"
                            (change)="setSortLabel(expectedLabel)"
                        />
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div
        class="offcanvas offcanvas-bottom h-auto"
        style="max-height: 50%"
        tabindex="-1"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
    >
        <div class="offcanvas-header">
            <button
                type="button"
                data-bs-dismiss="offcanvas"
                class="resetButton"
                [disabled]="!filterForOpenOverlay?.isSelected"
                [ngClass]="
                    filterForOpenOverlay?.isSelected ? 'red-reset-color' : ''
                "
                aria-label="Close"
                (click)="resetFilters()"
            >
                {{ "Reset" | translate }}
            </button>
            <h5 class="offcanvas-title heading" id="offcanvasBottomLabel">
                {{ filterForOpenOverlay?.filterName }}
            </h5>
            <button
                type="button"
                class="DoneButton"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                (click)="filterChanged(filterForOpenOverlay)"
            >
                {{ "Done" | translate }}
            </button>
        </div>
        <hr style="margin: 0" />
        <div class="offcanvas-body small" style="margin: 0; overflow: auto">
            <div
                class="row filter-options"
                *ngFor="
                    let filterValue of filterForOpenOverlay?.filterValues;
                    let i = index
                "
            >
                <div class="col-sm-12">
                    <label
                        for="box"
                        [ngClass]="filterValue.isSelected ? 'blue' : 'black'"
                        >{{ filterValue.label }}</label
                    >
                    <input
                        [type]="
                            filterForOpenOverlay.filterTypeId === 1
                                ? 'radio'
                                : 'checkbox'
                        "
                        [checked]="filterValue.isSelected"
                        name=""
                        id="box"
                        style="float: right"
                        (change)="onFilterValueChange(i)"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!(screenSizeService.isMobileWidth | async)">
    <div class="md-category-filter-wrapper">
        <div class="md-category-filter-top-header">
            <h2>{{ "Filter By" | translate }}</h2>
            <h3 (click)="resetFilters()" class="reset">
                {{ "Reset" | translate }}
            </h3>
        </div>
        <md-category-deal-filter
            *ngFor="let filter of filterOptions; let i = index"
            [filterData]="filter"
            (filterChanged)="filterChanged($event)"
        >
        </md-category-deal-filter>
    </div>
</div>
