<div class="timer-component-parent-div" *ngIf="is_promotion_activated || is_promotion_started">
    <img [src]="promotionBanner" *ngIf="is_promotion_activated && !is_promotion_started && promotionBanner != ''"/>
    <img [src]="promotionBanner_1" *ngIf="is_promotion_activated && is_promotion_started && promotionBanner_1 != ''"/>
    <div class="time-counter">
        <ng-container *ngIf="timer$ | async as timer">
            <div class="content" *ngFor="let i of [0,1,2,3,4,5,6,7]">
                <div style="position:absolute">
                    <div class="flip">
                        <div class="up">
                            <div>{{timer[i]?.value}}</div>
                        </div>
                        <div class="down">
                            <div>
                                <div>{{timer[i]?.old}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="position:absolute">
                    <div class="flip-card">
                        <div class="flip-card-inner" [@flip]="timer[i].value"
                             (@flip.done)="timer[i].old=timer[i].value">
                            <div class="flip-card-front">
                                <div class="up">
                                    <div>{{timer[i].old}}</div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="down">
                                    <div>
                                        <div>{{timer[i].value}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span>:</span>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="time-counter-titles">
        <div class="days">
            <h5>{{'Days' | translate}}</h5>
        </div>
        <div class="hours">
            <h5>{{'Hours' | translate}}</h5>
        </div>
        <div class="minutes">
            <h5>{{'Minutes' | translate}}</h5>
        </div>
        <div class="seconds">
            <h5>{{'Seconds' | translate}}</h5>
        </div>
    </div>
</div>
