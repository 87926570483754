import { AbstractControl, ValidationErrors } from "@angular/forms";

export const isNullOrWhiteSpace = (value: string): boolean => {
    return !value || (value + '').trim() === '';
};

export const addClassToSearchDatesComponent = (): void => {
    const searchDates = document.getElementsByTagName('md-search-dates')[0];
    if (searchDates) {
        searchDates.classList.add('filters-aware');
    }
};

export const removeClassFromSearchDatesComponent = (): void => {
    const searchDates = document.getElementsByTagName('md-search-dates')[0];
    if (searchDates) {
        searchDates.classList.remove('filters-aware');
    }
};

export const isSafari = (): boolean => {
    const ua = navigator.userAgent.toLowerCase();
    return (
        ua.indexOf('safari') !== -1 &&
        ua.indexOf('chrome') === -1 &&
        ua.indexOf('android') === -1
    );
};

export const isFirefox = (): boolean => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('firefox') > -1;
};

export function noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}