<div class="c-counter-container">
    <button
        class="c-counter-button decrease"
        [disabled]="counterValue === minValue"
        (click)="decrease()"
    >
        -
    </button>
    <div class="c-counter-value">{{ counterValue }}</div>
    <button
        class="c-counter-button increase"
        [disabled]="counterValue === maxValue"
        (click)="increase()"
    >
        +
    </button>
</div>
