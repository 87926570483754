import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RouteParamsService {
    private sectionIdSource = new BehaviorSubject<number>(null);
    sectionId$ = this.sectionIdSource.asObservable();

    setSectionId(sectionId: number) {
        this.sectionIdSource.next(sectionId);
    }
}
