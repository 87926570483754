import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../shared/common.service';
import {
    MAURITIUS_LOGGED_IN_CURR,
    MAURITIUS_NOT_LOGGED_IN_CURR,
    OTHER_LOGGED_IN_CURR,
    OTHER_NOT_LOGGED_IN_CURR,
    REUNION_LOGGED_IN_CURR,
    REUNION_NOT_LOGGED_IN_CURR,
    currencyIdToCountryCodeMap,
    languageIdToLanguageCodeMap,
} from '../utilities/location.utils';
import { IS_REUNION, LANGUAGE, STORE_ID } from '../utilities/local-storage.keys';

interface LanguageDropdownOption {
    name: 'English' | 'French';
    code: 'en' | 'fr';
}

interface CurrencyDropdownOption {
    name: 'Mauritius' | 'Reunion' | 'Other';
    code: 'rs' | 'euro' | 'ot';
}

const mauritiusCurrencyOptions: CurrencyDropdownOption[] = [
    { name: 'Reunion', code: 'euro' },
    { name: 'Other', code: 'ot' },
];
const reunionCurrencyOptions: CurrencyDropdownOption[] = [
    { name: 'Mauritius', code: 'rs' },
    { name: 'Other', code: 'ot' },
];
const otherCurrencyOptions: CurrencyDropdownOption[] = [
    { name: 'Mauritius', code: 'rs' },
    { name: 'Reunion', code: 'euro' },
];

const englishLanguageOption: LanguageDropdownOption[] = [
    { name: 'French', code: 'fr' },
];
const frenchLanguageOption: LanguageDropdownOption[] = [
    { name: 'English', code: 'en' },
];

@Component({
    selector: 'md-language-modal-popup',
    templateUrl: './language-modal-popup.component.html',
    styleUrls: ['./language-modal-popup.component.scss'],
})
export class LanguageModalPopupComponent implements OnInit {
    @Input() isCountryVisible = false;
    @Input() isDark = false;

    storeCurName: 'Reunion' | 'Mauritius' | 'Other';
    storeCurCode: 'rs' | 'euro' | 'ot';
    storeLangName: 'English' | 'French';
    language = '';
    cacheLanguge = this.common.getCache(this, 'language')?.data;
    languages: LanguageDropdownOption[] = [
        { name: 'English', code: 'en' },
        { name: 'French', code: 'fr' },
    ];
    currency: CurrencyDropdownOption[] = [
        { name: 'Mauritius', code: 'rs' },
        { name: 'Reunion', code: 'euro' },
        { name: 'Other', code: 'ot' },
    ];
    tempLanguage: 'en' | 'fr';
    selectedCountryLanguage: 'EN' | 'FR';
    countryPickerHidden = false;

    constructor(
        public router: Router,
        public common: CommonService,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        const currUrl = new URL(window.location.href);
        this.setCountryAndCurrencyFromUrl(currUrl);
        this.countryPickerHidden = this.common.isLoggedIn;

        // when user log in
        this.common.isVerify.subscribe(() => {
            const currUrl = new URL(window.location.href);
            if (this.common.isLoggedIn) {
                this.countryPickerHidden = true;
                this.setCountryAndCurrencyFromUrl(currUrl);
            } else {
                this.countryPickerHidden = false;
            }
        });

        // For updating the value in dropdown
        this.common.updateLang.subscribe((val) => {
            this.selectedCountryLanguage = val as 'EN' | 'FR';
            const langugeCodeLowerCase = val.toLowerCase();
            if (langugeCodeLowerCase === 'en') {
                this.storeLangName = 'English';
                this.languages = englishLanguageOption;
            } else if (langugeCodeLowerCase === 'fr') {
                this.storeLangName = 'French';
                this.languages = frenchLanguageOption;
            }
        });
        this.common.updateCountry.subscribe((val) => {
            this.storeCurName = val;

            if (this.storeCurName === 'Mauritius') {
                this.currency = mauritiusCurrencyOptions;
            } else if (this.storeCurName === 'Reunion') {
                this.currency = reunionCurrencyOptions;
            } else if (this.storeCurName === 'Other') {
                this.currency = otherCurrencyOptions;
            }
        });
    }

    openModal(content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'sm',
            centered: true,
        });
    }

    switchCurrency(currency: 'rs' | 'euro' | 'ot') {
        this.storeCurCode = currency;
    }

    switchLanguage(languageCode: 'en' | 'fr') {
        this.tempLanguage = languageCode;
    }

    applyLangBtn() {
        this.setCurrency();

        const storeLang: 'en' | 'fr' = this.tempLanguage
            ? this.tempLanguage
            : (this.selectedCountryLanguage.toLowerCase() as 'en' | 'fr');

        this.setLanguage(storeLang);
        const curr = this.setCustomerGroupId();
        const urlh = new URL(window.location.href);

        const currUpperCase = currencyIdToCountryCodeMap.get(curr) || 'MU';
        const langUpperCase =
            languageIdToLanguageCodeMap.get(storeLang) || 'EN';

        urlh.searchParams.set('iso', currUpperCase + '-' + langUpperCase);
        history.replaceState(null, '', urlh);

        this.common.setCache(this, LANGUAGE, storeLang);
        this.language = storeLang;

        // TODO: this should not need a reload but should trigger a reaload of the data
        window.location.reload();
    }

    setCountryAndCurrencyFromUrl(currUrl: URL) {
        if (currUrl.search.includes('RE')) {
            this.storeCurName = 'Reunion';
            this.currency = reunionCurrencyOptions;
        }
        if (currUrl.search.includes('MU')) {
            this.storeCurName = 'Mauritius';
            this.currency = mauritiusCurrencyOptions;
        }
        if (currUrl.search.includes('OT')) {
            this.storeCurName = 'Other';
            this.currency = otherCurrencyOptions;
        }
        if (currUrl.search.includes('EN')) {
            this.selectedCountryLanguage = 'EN';
            this.storeLangName = 'English';
            this.languages = englishLanguageOption;
        }
        if (currUrl.search.includes('FR')) {
            this.storeLangName = 'French';
            this.languages = frenchLanguageOption;
            this.selectedCountryLanguage = 'FR';
        }
    }
    setCurrency(): void {
        if (this.storeCurCode === 'rs') {
            this.storeCurName = 'Mauritius';
            this.currency = mauritiusCurrencyOptions;
        } else if (this.storeCurCode === 'euro') {
            this.storeCurName = 'Reunion';
            this.currency = reunionCurrencyOptions;
        } else if (this.storeCurCode === 'ot') {
            this.storeCurName = 'Other';
            this.currency = otherCurrencyOptions;
        }
    }

    setLanguage(lang: 'en' | 'fr'): void {
        if (lang === 'en') {
            this.storeLangName = 'English';
            this.languages = englishLanguageOption;
            this.common.setCache(this, STORE_ID, 1);
        } else if (lang === 'fr') {
            this.storeLangName = 'French';
            this.languages = frenchLanguageOption;
            this.common.setCache(this, STORE_ID, 2);
        }
    }
    setCustomerGroupId(): number {
        const { isLoggedIn } = this.common;
        if (this.storeCurCode === 'rs') {
            this.common.setCustomerGroupID(
                isLoggedIn
                    ? MAURITIUS_LOGGED_IN_CURR
                    : MAURITIUS_NOT_LOGGED_IN_CURR
            );
            this.common.language = 'en'; // It should not like that but temporarily ...
            this.common.setCache(this, IS_REUNION, false);
        } else if (this.storeCurCode === 'euro') {
            this.common.setCustomerGroupID(
                isLoggedIn ? REUNION_LOGGED_IN_CURR : REUNION_NOT_LOGGED_IN_CURR
            );
            this.common.language = 'fr-reunion';
            this.common.setCache(this, IS_REUNION, true);
        } else if (this.storeCurCode === 'ot') {
            this.common.setCustomerGroupID(
                isLoggedIn ? OTHER_LOGGED_IN_CURR : OTHER_NOT_LOGGED_IN_CURR
            );
            this.common.language = 'en';
            this.common.setCache(this, IS_REUNION, false);
        }

        return this.common.getCustomerGroupID();
    }
}
