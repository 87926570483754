import { Component, Input } from '@angular/core';
import { CheckoutUtilsService } from '../checkout.utils.service';

@Component({
    selector: 'md-checkout-loading',
    templateUrl: './checkout-loading.component.html',
    styleUrls: ['./checkout-loading.component.scss'],
})
export class CheckoutLoadingComponent {
    @Input() showCartSkeleton: boolean = false;

    constructor(public checkoutUtilsService: CheckoutUtilsService) {}
}
