import { Component, Input } from '@angular/core';
import { RewardBadge } from '../model/product-detail.model';

@Component({
    selector: 'md-rewards',
    templateUrl: './rewards.component.html',
    styleUrls: ['./rewards.component.scss'],
})
export class RewardsComponent {
    @Input() rewardBadge: RewardBadge;
    @Input() rewardPoints: number;
}
