import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {CommonService} from 'src/app/shared/common.service';
import {Locale} from "../../../config/locale";
import { EventService, EventType } from "../../../utilities/event.service";
import { getUser } from "../../../utilities/user.utils";
import { User } from "../../../model/user.model";

@Component({
    selector: 'app-payment-failure',
    templateUrl: './payment-failure.component.html',
    styleUrls: ['./payment-failure.component.scss']
})
export class PaymentFailureComponent implements OnInit {
    localeBtn: any
    public loginStatus!: number;
    private subscription: Subscription;
    public loginResponse: User;
    public userLogin: boolean = false;
    public customerId: any;

    constructor(
        private router: Router,
        private common: CommonService,
        private eventService: EventService,
        private localeObj: Locale
    ) {

    }

    ngOnInit(): void {
        this.localeBtn = this.localeObj.getButtonMessages();
        this.checkLoginResponse();
        this.common.removeCache(this, 'cartItems');
        this.common.removeCache(this, 'cartItemsCount');
        this.common.removeCache(this, 'cartItemsdataCount');
    }


    checkLoginResponse() {
        let userdata = getUser()
        if (userdata) {
            this.loginResponse = userdata;
            this.customerId = this.loginResponse?.id;
            this.userLogin = true;
        } else {
            this.eventService.emitEvent(EventType.LOGIN)
            return false;
        }
    }

    continueShoping() {
        this.common.removeCache(this, 'orderdata');
        this.router.navigate(['/']);
    }
}
