<div class="main-create-invoice-div">
    <div class="skeleton" *ngIf="isGenrateInvoiceShowSkeleton" >
        <div class="animated-background" style="height: 94vh;border: 2px solid white;width: auto;box-sizing: border-box;"></div>
        </div>
  <!-- User order list -->
  <div id="genrateinvoice-page" class="generate-invoice" *ngIf="!isGenrateInvoiceShowSkeleton">
    <div class="outer generate-invoice1">
            <div  class="profile-Options" >
            <div class="inner generate-invoice-div" >
                <p  class="std_bold  generate-invoice-heading">{{"Enter a Purchase Order number (if applicable) and Submit this form to Generate VAT Invoice" | translate}}</p>
                <form   [formGroup]="createInvoiceFormGroup">
                <p  class="para_card font-weight">{{"P" | translate}}.{{"O" | translate}}.
                    <input class="chkout-input input-mar-bor chkot-input-border newStyleInput generate-invoice-input" type="tex" placeholder="" formControlName="PO">
                </p>
                <p  class="para_card font-weight">{{"Company Profile" | translate}}
                    <input class="chkout-input input-mar-bor chkot-input-border newStyleInput generate-invoice-input"  type="tex" disabled="true" placeholder="" formControlName="companyProfile">
                </p>
                <p></p>
                <button (click)="saveOrderInvoice()" class="button center_slim generate-invoice-button">
                    <p class="generate-invoice-text ripleEffect">{{"Submit" | translate}}</p>
                </button>
            </form>
            </div>
        </div>
    </div>

  </div>
  <!-- End: User order list -->
</div>
<div class="popup-container showPopupBox" *ngIf="showPopupBox == true">
    <div class="popup">
        <div class="popup-body">
            {{showPopupBoxMsg}}
        </div>
        <div class="popup-buttons">
            <button class="popup-close-btn" (click)="showPopupBox = false">{{"OK" | translate}}</button>
        </div>
    </div>
