import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgbdDatepickerPopup } from './datepicker-popup.component';
import { DateDirective } from './date.directive';
import { MomentDateFormatter } from './momentdate';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
    declarations: [NgbdDatepickerPopup, DateDirective],
    exports: [NgbdDatepickerPopup],
    bootstrap: [NgbdDatepickerPopup],
    providers: [
        { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
    ],
})
export class NgbdDatepickerPopupModule {
}
