import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { getCustomerId } from 'src/app/utilities/user.utils';

@Injectable({
    providedIn: 'root',
})
export class ChallengesService {
    constructor(
        private httpClient: HttpClient,
        private common: CommonService
    ) {}

    getCustumerPointsAndBalance() {
        return this.httpClient.get(
            getApiUrlMofluid() +
                'getCustumerPointsAndBalance?store=' +
                this.common.getStoreID() +
                '&currency=MUR&service=getCustumerPointsAndBalance&customerid=' +
                getCustomerId() +
                '&is_app_login=0&customer_group_id=' +
                this.common.getCustomerGroupID(),
            { headers: this.common.getTokenJWTheaders() }
        );
    }

    getRewardPointBadge() {
        return this.httpClient.get(
            getApiUrlMofluid() +
                'getRewardPointBadge?store=' +
                this.common.getStoreID() +
                '&currency=MUR&service=getRewardPointBadge&customerid=' +
                getCustomerId() +
                '&customer_group_id=' +
                this.common.getCustomerGroupID(),
            { headers: this.common.getTokenJWTheaders() }
        );
    }
}
