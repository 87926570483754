<iframe
    frameborder="0"
    scrolling="no"
    marginheight="0"
    marginwidth="0"
    [src]="mapSrc"
    width="100%"
    height="450"
    style="border: 0"
    allowfullscreen=""
    class="dl-map"
>
</iframe>
