import { Component, OnInit } from '@angular/core';
import { GiftsService } from './gifts.service';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { getUser } from 'src/app/utilities/user.utils';
import { ProfileSection } from 'src/app/user/profile-wrapper.component';
import { RewardsAndWalletService } from '../rewards-and-wallet.service';
import { encodeForUrl } from 'src/app/utilities/encoders';

@Component({
    selector: 'md-gift-voucher',
    templateUrl: './gift-voucher.component.html',
    styleUrls: ['../rewards-and-wallet.component.scss'],
})
export class GiftVoucherComponent implements OnInit {
    isShowDummyGiftCard: boolean = false;

    public userLogin: boolean = false;
    giftCode = new UntypedFormControl('', []);
    giftCardItem: any = [];

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    showGiftBalanceView: boolean = false;

    constructor(
        private giftsService: GiftsService,
        private router: Router,
        private translate: TranslateService,
        private currencyLanguageService: CurrencyLanguageService,
        private eventService: EventService,
        private rewardsAndWalletService: RewardsAndWalletService
    ) {}

    ngOnInit(): void {
        this.checkLoginResponse();
        this.currencyLanguageService
            .getCurrency()
            .subscribe((currencySymbol) => {
                this.currencySymbol = currencySymbol;
            });

        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    checkLoginResponse() {
        const userdata = getUser();
        if (userdata) {
            this.userLogin = true;
            this.getListItem();
        }
    }

    addGiftCardPressed() {
        if (!this.giftCode) {
            this.eventService.emitEvent(
                EventType.MODAL_INFO,
                this.translate.instant('Invalid Gift Card Code')
            );
            return;
        } else {
            this.add_gift_card_code(this.giftCode.value);
        }
    }

    add_gift_card_code(giftcode: any) {
        const encoded_gift_code = encodeForUrl(giftcode);
        this.giftsService
            .addGiftVoucher(encoded_gift_code)
            .subscribe((response: any) => {
                if (response) {
                    if (response.status == 0) {
                        this.eventService.emitEvent(
                            EventType.MODAL_INFO,
                            response.errorMessage
                        );
                    } else {
                        this.eventService.emitEvent(
                            EventType.MODAL_INFO,
                            response.message
                        );
                        this.getListItem();
                    }
                }
            });
    }

    viewGiftCardBalancePressed() {
        this.showGiftBalanceView = !this.showGiftBalanceView;
    }

    getGiftStatusColor(giftItem: any) {
        let color = '#90C752';
        if (
            giftItem.giftCardStatus == 'Used' ||
            giftItem.giftCardStatus == 'Pending'
        ) {
            color = '#FEBA02';
        } else if (
            giftItem.giftCardStatus == 'Disabled' ||
            giftItem.giftCardStatus == 'Expired'
        ) {
            color = '#EF3434';
        }
        return color;
    }

    getGiftCodeDate(date: string) {
        const orderDate = dayjs(date);
        return orderDate.format('DD/MM/YY');
    }

    getListItem() {
        this.isShowDummyGiftCard = true;
        this.giftsService.myGiftCardList().subscribe((response: any) => {
            this.isShowDummyGiftCard = false;
            if (response) {
                if (response !== 0) {
                    this.giftCardItem = response.giftCardDetails;
                    this.rewardsAndWalletService.updateGiftCardBalance(
                        parseInt(response.balance)
                    );
                } else {
                    this.eventService.emitEvent(
                        EventType.MODAL_INFO,
                        response.errorMessage
                    );
                }
            }
        });
    }

    notLoggedInUserRedirectToHomepage() {
        this.router.navigate(['/']);
    }

    protected readonly ProfileSection = ProfileSection;
}
