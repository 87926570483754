<div
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
    [scrollWindow]="false"
>
    <div *ngIf="userLogin && !isStoreCreditData" style="margin-top: 55px">
        <ul class="ma-gc-list">
            <li mdDesktopOnly class="ma-gc-list-item header">
                <div>{{ "Date" | translate }}</div>
                <div>{{ "Description" | translate }}</div>
                <div>{{ "Amount" | translate }}</div>
                <div>{{ "Balance" | translate }}</div>
            </li>
            <li
                class="ma-gc-list-item"
                *ngFor="let transactions of storeCreditHistory"
            >
                <div>
                    <p mdMobileOnly>{{ "Date" | translate }}:</p>
                    <p>{{ transactions?.date | date : "d/M/yy" }}</p>
                </div>
                <div>
                    <p mdMobileOnly>{{ "Description" | translate }}:</p>
                    <p>{{ transactions?.action }}</p>
                    <p>
                        {{
                            removeAdminCommentPrefix(
                                transactions?.admin_comment
                            )
                        }}
                    </p>
                </div>
                <div>
                    <p mdMobileOnly>{{ "Amount" | translate }}:</p>
                    <p>{{ transactions?.change }}</p>
                </div>
                <div>
                    <p mdMobileOnly>{{ "Balance" | translate }}:</p>
                    <p>{{ transactions?.new_balance }}</p>
                </div>
            </li>
        </ul>
    </div>
</div>
<div style="margin-top: 52px">
    <md-rewards-and-wallet-loading *ngIf="isStoreCreditData" />
</div>
