import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ProductDetails,
    ProductServiceOption,
} from 'src/app/product-detail/model/product-detail.model';
import {
    BookingConfiguredData,
    MinimalProductBookingData,
    getProductServiceOptionsFromProductDetails,
} from '../booking-config.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

@Component({
    selector: 'md-activity-booking',
    templateUrl: './activity-booking.component.html',
    styleUrls: ['./activity-booking.component.scss'],
})
export class ActivityBookingComponent implements OnInit {
    @Input() productDetails: ProductDetails;
    @Input() initialBookingData: MinimalProductBookingData;

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    selectedServiceOptionQuantities: Map<ProductServiceOption, number> =
        new Map();

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();

    constructor(private currencyLanguageService: CurrencyLanguageService) {}

    ngOnInit(): void {
        if (this.initialBookingData) {
            const minimalProductServiceOptions = Array.from(
                this.initialBookingData.selectedServiceOptionQuantities.keys()
            );
            const productServiceOptions =
                getProductServiceOptionsFromProductDetails(
                    this.productDetails,
                    minimalProductServiceOptions
                );
            minimalProductServiceOptions.forEach((option) => {
                const quantity =
                    this.initialBookingData.selectedServiceOptionQuantities.get(
                        option
                    );
                const productServiceOption = productServiceOptions.find(
                    (serviceOption) =>
                        serviceOption.id === option.id &&
                        serviceOption.occupancyId === option.occupancyId
                );
                this.selectedServiceOptionQuantities.set(
                    productServiceOption,
                    quantity
                );
            });
        }
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    onQuantityChanged(option: ProductServiceOption, quantity: number) {
        this.selectedServiceOptionQuantities.set(option, quantity);
        this.emitBookingConfigured();
    }

    private emitBookingConfigured() {
        const totalPrice = this.calculateTotalPrice();
        const totalFullPrice = this.calculateTotalFullPrice();
        this.bookingConfigured.emit({
            totalPrice,
            totalFullPrice,
            productBookingData: {
                id: this.productDetails.id,
                type: this.productDetails.type,
                selectedServiceOptionQuantities:
                    this.selectedServiceOptionQuantities,
            },
        });
    }

    private calculateTotalPrice() {
        let totalPrice = 0;
        this.selectedServiceOptionQuantities.forEach((quantity, option) => {
            totalPrice += option.price * quantity;
        });
        return totalPrice;
    }

    private calculateTotalFullPrice() {
        let totalFullPrice = 0;
        this.selectedServiceOptionQuantities.forEach((quantity, option) => {
            totalFullPrice += option.fullPrice * quantity;
        });
        return totalFullPrice;
    }
}
