import { Component, Input } from '@angular/core';

@Component({
    selector: 'md-meter',
    templateUrl: './meter.component.html',
    styleUrls: ['./meter.component.scss'],
})
export class MeterComponent {
    @Input() percentage = 0;
    @Input() label: string;
    @Input() sublabel: string;
    @Input() loading = false;

    roundPercentage(): number {
        return Math.round(this.percentage);
    }
}
