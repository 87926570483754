import { Component, HostListener } from '@angular/core';
import { ScreenSizeService } from "../../services/screen-size.service";

@Component({
    selector: 'md-screen-size-util',
    template: ''
})
export class ScreenSizeUtilComponent {

    constructor(private screenSizeService: ScreenSizeService) {
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenSizeService.onResize(event);
    }
}
