import { Component } from '@angular/core';

@Component({
    selector: 'md-product-list-item-loading',
    template: `<div mdMobileOnly>
            <div
                style="display: flex;flex-direction:column; margin-top: 16px;"
            >
                <div mdSkeleton class="aspect-ratio"></div>
                <div
                    style="display: flex; flex-direction:row; justify-content: space-between; margin: 8px 12px 0 0;"
                >
                    <div>
                        <div
                            mdSkeleton
                            style="width: 230px; height: 24px; border-radius: 8px"
                        ></div>
                        <div
                            mdSkeleton
                            style="width: 300px; height: 20px; border-radius: 8px; margin-top: 4px"
                        ></div>
                    </div>
                    <div
                        mdSkeleton
                        style="width: 50px; height: 20px; border-radius: 8px"
                    ></div>
                </div>
                <div
                style="display: flex; justify-content:space-between; align-items: flex-end; margin: 16px 0 0"
                >
                <div>
                    <div
                        mdSkeleton
                        style="width: 200px; height: 20px; border-radius: 8px; margin-top: 4px"
                    ></div>
                    <div
                        mdSkeleton
                        style="width: 160px; height: 20px; border-radius: 8px; margin-top: 4px"
                    ></div>
                </div>
                <div
                    mdSkeleton
                    style="width: 120px; height: 24px; border-radius: 8px"
                ></div>
        </div>
            </div>
        </div>
        <div mdDesktopOnly>
            <div
                style="display: flex;flex-direction:row; margin-bottom: 16px;
            border-radius: 8px;
    cursor: pointer;
    align-items: stretch;"
            >
                <div
                    class="aspect-ratio"
                    mdSkeleton
                    style="height: 40%; width: 40%; border-radius: 8px;"
                ></div>
                <div
                    style="display: flex; flex-direction: column; width: 60%; justify-content: space-between"
                >
                    <div
                        style="display: flex; flex-direction:row; justify-content: space-between; margin: 0 0 0 20px;"
                    >
                        <div>
                            <div
                                mdSkeleton
                                style="width: 230px; height: 24px; border-radius: 8px"
                            ></div>
                            <div
                                mdSkeleton
                                style="width: 300px; height: 20px; border-radius: 8px; margin-top: 4px"
                            ></div>
                        </div>
                        <div
                            mdSkeleton
                            style="width: 50px; height: 20px; border-radius: 8px"
                        ></div>
                    </div>
                    <div
                        style="display: flex; justify-content:space-between; align-items: flex-end; margin: 16px 20px"
                    >
                    <div>
                            
                            <div
                                mdSkeleton
                                style="width: 230px; height: 20px; border-radius: 8px; margin-top: 4px"
                            ></div>
                            <div
                                mdSkeleton
                                style="width: 230px; height: 20px; border-radius: 8px; margin-top: 4px"
                            ></div>
                        </div>
                        <div
                            mdSkeleton
                            style="width: 100px; height: 24px; border-radius: 8px"
                        ></div>
                    </div>
                </div>
            </div>
        </div>`,
    styles: [
        `
            .aspect-ratio,
            .aspect-ratio-desktop {
                position: relative;
                width: 100%;
                height: 100%;
                aspect-ratio: 1/0.69; 
                border-radius: 8px;
            }

            @media (min-width: 768px) {
                .aspect-ratio,
                .aspect-ratio-desktop {
                    aspect-ratio: 1 / 0.75;
                }
            }

            .aspect-ratio::before {
                content: '';
                display: block;
                padding-top: 50%; /* 9/16 aspect ratio */
            }

            .aspect-ratio-desktop::before {
                content: '';
                display: block;
                padding-top: 70%; /* 9/16 aspect ratio */
            }

            .aspect-ratio > *,
            .aspect-ratio-desktop > * {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            /* Add your styling here */
        `,
    ],
})
export class ProductListItemLoadingComponent {
    // Add your component logic here
}
