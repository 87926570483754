<div>
    <md-calendar
        #calendarDatedActivity
        [isSingleDate]="true"
        [infoForEachDay]="additionalCalendarDayInfo"
        (dateRangeSelected)="onDateRangeSelected($event)"
        [showDesktopInPopup]="true"
    >
        <div
            popupTargetControl
            class="bc-dab-date-picker"
            (click)="onOpenCalendar()"
        >
            <span class="bc-dab-date-picker-text">{{
                "Arrival Date" | translate
            }}</span>
            <span class="bc-dab-date-picker-value">{{ formattedDate }}</span>
        </div></md-calendar
    >
    <md-activity-booking
        [productDetails]="internalProductDetails"
        (bookingConfigured)="onBookingConfigured($event)"
    />
</div>
