<md-profile-wrapper [activeSection]="ProfileSection.EDIT_PROFILE">
    <div *ngIf="userLogin" id="account-page" has-header="true" scroll="false">
        <md-profile-page-title
            [title]="'Edit Profile' | translate"
            [description]="
                'Update your information and find out how it\'s used'
                    | translate
            "
        />
        <md-edit-profile-loading *ngIf="isEditCustomerShowSkeleton" />
        <div [formGroup]="userFormGroup">
            <div *ngIf="show_data">
                <div
                    *ngIf="sectionBeingEdited !== 'name'"
                    class="ma-ep-section"
                >
                    <div class="ma-ep-section-data">
                        <div class="ma-ep-section-label">
                            {{ "Name" | translate }}
                        </div>
                        <div class="ma-ep-section-value">
                            <h4>{{ name }}</h4>
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-button">
                        <button
                            mdTextButton
                            (click)="sectionBeingEdited = 'name'"
                        >
                            {{ "Edit" | translate }}
                        </button>
                    </div>
                </div>
                <div
                    class="ma-ep-section-edit"
                    *ngIf="sectionBeingEdited === 'name'"
                >
                    <div class="ma-ep-section-edit-left">
                        <div class="ma-ep-section-label">
                            {{ "Name" | translate }}
                        </div>
                        <div class="ma-ep-section-edit-input-block">
                            <label
                                for="firstname"
                                class="ma-ep-section-edit-label"
                                >{{ "First Name" | translate }}</label
                            >
                            <input
                                class="ma-ep-section-edit-input"
                                type="text"
                                placeholder="Jane"
                                formControlName="firstname"
                            />
                        </div>
                        <div class="ma-ep-section-edit-input-block">
                            <label
                                for="lastname"
                                class="ma-ep-section-edit-label"
                                >{{ "Last Name" | translate }}</label
                            >
                            <input
                                class="ma-ep-section-edit-input"
                                type="text"
                                placeholder="Doe"
                                formControlName="lastname"
                            />
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-controls">
                        <button
                            mdTextButton
                            class="ma-ep-section-edit-cancel"
                            (click)="sectionBeingEdited = ''"
                        >
                            {{ "Cancel" | translate }}
                        </button>
                        <button
                            class="ma-ep-section-edit-save"
                            mdMainButton="light"
                            (click)="updateProfile()"
                        >
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </div>
                <div
                    class="ma-ep-section"
                    *ngIf="sectionBeingEdited !== 'email'"
                >
                    <div class="ma-ep-section-data">
                        <div class="ma-ep-section-label">
                            {{ "Email address" | translate }}
                        </div>
                        <div class="ma-ep-section-value">
                            <div class="ma-ep-section-emailbadge">
                                <h4>{{ email }}</h4>
                                <span
                                    *ngIf="mailVerify"
                                    class="ma-ep-verified-badge"
                                    >{{ "Verified" | translate }}</span
                                >
                                <span
                                    *ngIf="!mailVerify"
                                    class="ma-ep-verified-badge not-verified"
                                    (click)="!saveDisabled && verifyEmail()"
                                    >{{ "Not verified" | translate }}</span
                                >
                            </div>

                            <p>
                                {{ "Email address subtitle" | translate }}
                            </p>
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-button">
                        <button
                            mdTextButton
                            (click)="sectionBeingEdited = 'email'"
                        >
                            {{ "Edit" | translate }}
                        </button>
                    </div>
                </div>
                <div
                    class="ma-ep-section-edit"
                    *ngIf="sectionBeingEdited === 'email'"
                >
                    <div class="ma-ep-section-edit-left">
                        <div class="ma-ep-section-label">
                            {{ "Email address" | translate }}
                        </div>
                        <div class="ma-ep-section-edit-input-block longer">
                            <label
                                for="email"
                                class="ma-ep-section-edit-label"
                                >{{ "Email address" | translate }}</label
                            >
                            <input
                                class="ma-ep-section-edit-input"
                                type="email"
                                placeholder="amelia12@gmail.com"
                                formControlName="email"
                            />
                            <p class="ma-ep-section-edit-sub">
                                {{ "Email verification subtitle" | translate }}
                            </p>
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-controls">
                        <button
                            mdTextButton
                            class="ma-ep-section-edit-cancel"
                            (click)="sectionBeingEdited = ''"
                        >
                            {{ "Cancel" | translate }}
                        </button>
                        <button
                            class="ma-ep-section-edit-save"
                            mdMainButton="light"
                            [disabled]="saveDisabled"
                            (click)="updateProfilePhoneAndEmail()"
                        >
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </div>
                <div
                    class="ma-ep-section"
                    *ngIf="sectionBeingEdited !== 'phone'"
                >
                    <div class="ma-ep-section-data">
                        <div class="ma-ep-section-label">
                            {{ "Phone number" | translate }}
                        </div>
                        <div class="ma-ep-section-value">
                            <div
                                *ngIf="telephone"
                                class="ma-ep-section-emailbadge"
                            >
                                <p>{{ telephone }}</p>
                                <span
                                    *ngIf="verify"
                                    class="ma-ep-verified-badge"
                                    >{{ "Verified" | translate }}</span
                                >
                                <span
                                    *ngIf="!verify"
                                    class="ma-ep-verified-badge not-verified"
                                    [disabled]="saveDisabled"
                                    (click)="verifyMobile()"
                                    >{{ "Not verified" | translate }}</span
                                >
                            </div>
                            <p *ngIf="!telephone">
                                {{ "Add your phone number" | translate }}
                            </p>
                            <p>
                                {{
                                    "Properties or attractions you book will use this number if they need to contact you."
                                        | translate
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-button">
                        <button
                            mdTextButton
                            (click)="sectionBeingEdited = 'phone'"
                        >
                            {{ "Edit" | translate }}
                        </button>
                    </div>
                </div>
                <div
                    class="ma-ep-section-edit"
                    *ngIf="sectionBeingEdited === 'phone'"
                >
                    <div class="ma-ep-section-edit-left">
                        <div class="ma-ep-section-label">
                            {{ "Phone number" | translate }}
                        </div>
                        <div class="ma-ep-section-edit-input-block">
                            <label
                                for="telephone"
                                class="ma-ep-section-edit-label"
                                >{{ "Phone number" | translate }}</label
                            >
                            <input
                                class="ma-ep-section-edit-input"
                                type="tel"
                                placeholder="+23057596720"
                                formControlName="telephone"
                            />
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-controls">
                        <button
                            mdTextButton
                            class="ma-ep-section-edit-cancel"
                            (click)="sectionBeingEdited = ''"
                        >
                            {{ "Cancel" | translate }}
                        </button>
                        <button
                            class="ma-ep-section-edit-save"
                            mdMainButton="light"
                            [disabled]="saveDisabled"
                            (click)="updateProfilePhoneAndEmail()"
                        >
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </div>
                <div class="ma-ep-section" *ngIf="sectionBeingEdited !== 'dob'">
                    <div class="ma-ep-section-data">
                        <div class="ma-ep-section-label">
                            {{ "Date of Birth" | translate }}
                        </div>
                        <div class="ma-ep-section-value">
                            <ng-container *ngIf="isDobEditable">
                                <p>
                                    {{ "Enter your date of birth" | translate }}
                                </p>
                            </ng-container>
                            <p *ngIf="!isDobEditable">{{ dob }}</p>
                            <p>
                                {{
                                    "Date of Birth cannot be changed once set!"
                                        | translate
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-button">
                        <button
                            mdTextButton
                            *ngIf="isDobEditable"
                            (click)="sectionBeingEdited = 'dob'"
                        >
                            {{ "Edit" | translate }}
                        </button>
                    </div>
                </div>
                <div
                    class="ma-ep-section-edit"
                    *ngIf="sectionBeingEdited === 'dob'"
                >
                    <div class="ma-ep-section-edit-left">
                        <div class="ma-ep-section-label">
                            {{ "Date of Birth" | translate }}
                        </div>
                        <div
                            class="ma-ep-section-edit-input-block horizontal-content"
                        >
                            <label
                                for="dobmonth"
                                class="ma-ep-section-edit-label"
                                >{{ "Date of Birth" | translate }}</label
                            >
                            <div class="ma-ep-section-edit-horizontal">
                                <select
                                    class="ma-ep-section-edit-select"
                                    formControlName="dobmonth"
                                    [readonly]="!isDobEditable"
                                >
                                    <option [value]="">
                                        {{ "Select" | translate }}
                                    </option>
                                    <option
                                        [value]="x.value"
                                        *ngFor="
                                            let x of editProfileUtilsService.getMonthArray()
                                        "
                                    >
                                        {{ x.label }}
                                    </option>
                                </select>
                                <ion-icon
                                    class="pdbc-occupancy-arrow"
                                    name="chevron-down-outline"
                                ></ion-icon>
                                <input
                                    class="ma-ep-section-edit-input"
                                    type="number"
                                    max="31"
                                    min="1"
                                    placeholder="DD"
                                    formControlName="dobdate"
                                    [readonly]="!isDobEditable"
                                />
                                <input
                                    class="ma-ep-section-edit-input"
                                    type="number"
                                    max="2021"
                                    min="1900"
                                    placeholder="YYYY"
                                    formControlName="dobyear"
                                    [readonly]="!isDobEditable"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-controls">
                        <button
                            mdTextButton
                            class="ma-ep-section-edit-cancel"
                            (click)="sectionBeingEdited = ''"
                        >
                            {{ "Cancel" | translate }}
                        </button>
                        <button
                            class="ma-ep-section-edit-save"
                            mdMainButton="light"
                            (click)="updateProfile()"
                        >
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </div>
                <div
                    class="ma-ep-section"
                    *ngIf="sectionBeingEdited !== 'gender'"
                >
                    <div class="ma-ep-section-data">
                        <div class="ma-ep-section-label">
                            {{ "Gender" | translate }}
                        </div>
                        <div class="ma-ep-section-value">
                            <p>
                                {{
                                    editProfileUtilsService.getGenderLabel(
                                        gender
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-button">
                        <button
                            mdTextButton
                            (click)="sectionBeingEdited = 'gender'"
                        >
                            {{ "Edit" | translate }}
                        </button>
                    </div>
                </div>
                <div
                    class="ma-ep-section-edit"
                    *ngIf="sectionBeingEdited === 'gender'"
                >
                    <div class="ma-ep-section-edit-left">
                        <div class="ma-ep-section-label">
                            {{ "Gender" | translate }}
                        </div>
                        <div class="ma-ep-section-edit-input-block">
                            <label
                                for="gender"
                                class="ma-ep-section-edit-label"
                                >{{ "Gender" | translate }}</label
                            >
                            <select
                                class="ma-ep-section-edit-select"
                                formControlName="gender"
                            >
                                <option value="">
                                    {{ "Select" | translate }}
                                </option>
                                <option
                                    [value]="x.value"
                                    *ngFor="
                                        let x of editProfileUtilsService.getGenderArray()
                                    "
                                >
                                    {{ x.label }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-controls">
                        <button
                            mdTextButton
                            class="ma-ep-section-edit-cancel"
                            (click)="sectionBeingEdited = ''"
                        >
                            {{ "Cancel" | translate }}
                        </button>
                        <button
                            class="ma-ep-section-edit-save"
                            mdMainButton="light"
                            (click)="updateProfile()"
                        >
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </div>
                <div
                    class="ma-ep-section"
                    *ngIf="sectionBeingEdited !== 'address'"
                >
                    <div class="ma-ep-section-data">
                        <div class="ma-ep-section-label">
                            {{ "Address" | translate }}
                        </div>
                        <div class="ma-ep-section-value">
                            <p>{{ address }}</p>
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-button">
                        <button
                            mdTextButton
                            (click)="sectionBeingEdited = 'address'"
                        >
                            {{ "Edit" | translate }}
                        </button>
                    </div>
                </div>
                <div
                    class="ma-ep-section-edit"
                    *ngIf="sectionBeingEdited === 'address'"
                >
                    <div class="ma-ep-section-edit-left">
                        <div class="ma-ep-section-label">
                            {{ "Address" | translate }}
                        </div>
                        <div class="ma-ep-section-edit-input-block">
                            <label
                                for="gender"
                                class="ma-ep-section-edit-label"
                                >{{ "Address" | translate }}</label
                            >
                            <textarea
                                class="ma-ep-section-edit-input"
                                placeholder=""
                                rows="3"
                                maxlength="360"
                                formControlName="street"
                            >
                            {{ this.userFormGroup.controls.street.value }}
                    </textarea
                            >
                            <label
                                for="country"
                                class="ma-ep-section-edit-label"
                                >{{ "Country" | translate }}</label
                            ><input
                                type="text"
                                class="ma-ep-section-edit-input"
                                placeholder="Country"
                                readonly="readonly"
                                value="{{ billing.city }}"
                            />

                            <label
                                for="location"
                                class="ma-ep-section-edit-label"
                                >{{ "Town/City" | translate }}
                            </label>
                            <select
                                class="ma-ep-section-edit-select"
                                formControlName="location"
                            >
                                <option value="">
                                    {{ "Select" | translate }}
                                </option>
                                <option
                                    [value]="x.value"
                                    *ngFor="let x of location"
                                >
                                    {{ x.label }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="ma-ep-section-edit-controls">
                        <button
                            mdTextButton
                            class="ma-ep-section-edit-cancel"
                            (click)="sectionBeingEdited = ''"
                        >
                            {{ "Cancel" | translate }}
                        </button>
                        <button
                            class="ma-ep-section-edit-save"
                            mdMainButton="light"
                            (click)="updateProfile()"
                        >
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </div>

                <p hidden>
                    {{ "Children" | translate }}
                    <label class="custom-user-profile-label-3">
                        <select
                            class="newStyleInput custom-user-profile-select-5"
                            formControlName="children"
                        >
                            <option value="">
                                {{ "Select" | translate }}
                            </option>
                            <option
                                [value]="x.value"
                                *ngFor="
                                    let x of editProfileUtilsService.getChildrenArray()
                                "
                            >
                                {{ x.label }}
                            </option>
                        </select>
                    </label>
                </p>

                <p hidden *ngIf="isWeddingElementEnabled">
                    {{ "Wedding Anniversary" | translate }}
                </p>
                <div hidden *ngIf="isWeddingElementEnabled">
                    <label>
                        <select formControlName="wedmonth">
                            <option value="">
                                {{ "Month" | translate }}
                            </option>
                            <option
                                [value]="x.value"
                                *ngFor="
                                    let x of editProfileUtilsService.getMonthArray()
                                "
                            >
                                {{ x.label }}
                            </option>
                        </select>
                    </label>
                    <label>
                        <input
                            type="text"
                            [placeholder]="'day' | translate"
                            formControlName="weddate"
                        />
                    </label>
                    <label>
                        <input
                            type="text"
                            [placeholder]="'year' | translate"
                            formControlName="wedyear"
                        />
                    </label>
                </div>

                <p hidden>
                    {{ "Prefered Holidays" | translate }}
                    <label>
                        <select
                            multiple
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="preferred_holiday_values"
                            ngDefaultControl
                        >
                            <option
                                [value]="x.value"
                                *ngFor="let x of holidayLocation"
                            >
                                {{ x.label }}
                            </option>
                        </select>
                    </label>
                </p>
                <p hidden (change)="onChangeRelationShip()">
                    {{ "Relationship Status" | translate }}
                    <label>
                        <select formControlName="married">
                            <option value="">
                                {{ "Select" | translate }}
                            </option>
                            <option
                                [value]="x.value"
                                *ngFor="
                                    let x of editProfileUtilsService.getRelationshipArray()
                                "
                            >
                                {{ x.label }}
                            </option>
                        </select>
                    </label>
                </p>
            </div>
        </div>
    </div>
</md-profile-wrapper>
<md-modal-offcanvas
    #otpModal
    [mobileModalTitle]="'Verify your account' | translate"
>
    <div actualControl>
        <p class="ma-ep-otp-text">
            {{ "OTP(one-time-password) has been sent to" | translate }}
            {{ userFormGroup.controls["email"].value }}
        </p>
        <form [formGroup]="emailVerifyOtpForm">
            <div>
                <input
                    type="tel"
                    maxlength="4"
                    autofocus
                    id="login-otp-input"
                    class="ma-ep-otp-input"
                    formControlName="emailVerifyOtp"
                    (keyup)="emailOtpInputChange($event)"
                />
            </div>
            <div class="ma-ep-otp-text">
                <span>{{ "Didn't receive OTP?" | translate }}</span> {{ " " }}
                <a (click)="resendEmailOtp()">{{ "Resend" | translate }}</a>
            </div>
        </form>
    </div></md-modal-offcanvas
>
<md-modal-offcanvas
    #otpPhoneModal
    [mobileModalTitle]="'Verify your account' | translate"
>
    <div actualControl>
        <p class="ma-ep-otp-text">
            {{ "OTP(one-time-password) has been sent to" | translate }}
            {{ userFormGroup.controls["telephone"].value }}
        </p>
        <form [formGroup]="mobileVerifyOtpForm">
            <div>
                <input
                    type="tel"
                    maxlength="4"
                    autofocus
                    id="login-otp-input"
                    class="ma-ep-otp-input"
                    formControlName="mobileVerifyOtp"
                    (keyup)="mobileOtpInputChange($event)"
                />
            </div>
            <div class="ma-ep-otp-text">
                <span>{{ "Didn't receive OTP?" | translate }}</span> {{ " " }}
                <a (click)="resendMobileOtp()">{{ "Resend" | translate }}</a>
            </div>
        </form>
    </div></md-modal-offcanvas
>
