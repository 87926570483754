import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token.service';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';

@Injectable({
    providedIn: 'root',
})
export class HelpService {
    header = new HttpHeaders({});

    constructor(
        private httpClient: HttpClient,
        private common: CommonService,
        private httpBackend: HttpBackend,
        private token: TokenService
    ) {}

    sumbitTicket(data: any): Observable<any> {
        return this.httpClient.post(
            getApiUrlMofluid() +
                `submitZDTicket?service=submitZDTicket&subject=${data.subject}&body=${data.body}&customerid=${data.customerId}&upload=${data.token}&email=${data.email}&type=${data.fileType}&filename=${data.filename}` +
                '&customer_group_id=' +
                this.common.getCustomerGroupID(),
            '',
            { headers: this.common.getTokenHeaders() }
        );
    }

    upload(data: any): Observable<any> {
        const http = new HttpClient(this.httpBackend);
        return http.post<any>(this.common.api_url + 'uploadTicketFile', data, {
            headers: this.common.getTokenHeaders(),
        });
    }

    headers() {
        return new HttpHeaders({
            'Content-Type':
                'application/json; application/x-www-form-urlencoded; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + this.token.getToken(),
        });
    }
}
