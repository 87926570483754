<div class="win-div">
    <div class="skeleton" *ngIf="winSkeleton" >
        <div class="animated-background" style="height: 94vh;border: 2px solid white;width: auto;box-sizing: border-box;"></div>
        </div>
<div class="page-bg-color" *ngIf="!winSkeleton" >
    <div class="coupon">
        <p class="pic">
          <img alt="Image Missing" [src]="imageurl" class="pic-img">
        </p>
        <div class="mainInstruction">
            <div class="wholebody body-heading instruction-para instruction-span" [innerHTML]="bodycontent">
            </div>
        </div>
    </div>
</div>
</div>
