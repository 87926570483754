import { Directive, OnChanges, OnInit,  ElementRef, HostListener, Input, } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from "moment";

@Directive({
    selector: '[appDate]'
})
export class DateDirective {

    @Input('form') form : UntypedFormGroup
    @Input('controlName') controlName : string;
    constructor(private e: ElementRef) { }

    @HostListener('ngModelChange', ['$event']) dateChange(value) {
        console.log('blur')
        const year = value.year;
        const date = value.day;
        const month = value.month;
        let newDate = new Date(year, month -1, date);
        let fdate = moment(newDate).format('YYYY-MM-DD');
        this.form.get(this.controlName).setValue(fdate)
    }

}
