import { Component } from '@angular/core';

@Component({
    selector: 'md-product-card-loading',
    template: `<div class="h-hdc-container">
        <div
            mdSkeleton
            style="position: relative;
                    width: 100%;
                    aspect-ratio: 1/0.687; border-radius: 8px;"
        ></div>
        <div class="h-hdc-text-container">
            <div
                mdSkeleton
                class="h-hdc-title"
                style="height:20px; width: 85%; border-radius: 4px; margin: 8px 2px 4px 0px"
            >
                <h4 class="h-hdc-title-text"></h4>
            </div>
            <div
                mdSkeleton
                class="h-hdc-description"
                style="height:30px; ; width: calc(100% - 4px); border-radius: 4px; margin: 4px 2px 2px 0; display: inline-block"
            ></div>
            <div>
                <span
                    mdSkeleton
                    style="height:18px; ; width: 20%; border-radius: 4px; display: inline-block"
                    class="h-hdc-price"
                ></span
                ><span
                    mdSkeleton
                    style="height:18px; ; width: 20%; border-radius: 4px; display: inline-block"
                    class="h-hdc-specialPrice"
                >
                </span>
            </div>
        </div>
    </div> `,
    styleUrls: ['./product-card.component.scss'],
})
export class ProductCardLoading {}
