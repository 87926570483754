<div class="gcf-container">
    <div mdCard class="gcf-card">
        <img
            [alt]="selectedGiftCardImage.name"
            [src]="selectedGiftCardImage.path"
        />
        <div class="gcf-card-details">
            <p>
                {{
                    deliveryMessage.value === ""
                        ? ("Hope you enjoy the Gift Card!" | translate)
                        : deliveryMessage.value
                }}
            </p>
            <div class="gcf-card-price-and-logo">
                <span class="gcf-card-price">{{
                    value.value
                        | currency
                            : currencySymbol
                            : "symbol"
                            : "1.0-2"
                            : locale
                }}</span>
                <img
                    class="gcf-card-logo"
                    alt="marideal logo"
                    src="/assets/img/logo_blue.png"
                />
            </div>
        </div>
    </div>
    <form
        [formGroup]="giftCardForm"
        (ngSubmit)="onBuyGiftCard()"
        (change)="onFormValuesChange()"
        class="gcf-form"
    >
        <label i18n for="value">{{ "Enter value" | translate }}</label>
        <input
            [attr.error]="value.errors?.['min'] || value.errors?.['max']"
            class="refactoringTemp"
            type="number"
            id="value"
            formControlName="value"
        />
        <p>
            ({{
                validation.giftCardAmount.min
                    | currency : currencySymbol : "symbol" : "1.0-2" : locale
            }}
            -
            {{
                validation.giftCardAmount.max
                    | currency : currencySymbol : "symbol" : "1.0-2" : locale
            }})
        </p>
        <label for="recipientName">{{ "Recipient Name" | translate }}*</label>
        <input
            class="refactoringTemp"
            type="text"
            id="recipientName"
            placeholder="John Doe"
            formControlName="recipientName"
        />
        <label for="deliveryToEmail">{{ "Delivery To" | translate }}*</label>
        <input
            [attr.error]="deliveryToEmail.errors?.['email']"
            type="email"
            class="refactoringTemp"
            id="deliveryToEmail"
            placeholder="johndoe@gmail.com"
            formControlName="deliveryToEmail"
        />
        <label for="deliveryFromName">{{ "From" | translate }}</label>
        <input
            type="text"
            class="refactoringTemp"
            id="deliveryFromName"
            placeholder="John Doe"
            formControlName="deliveryFromName"
        />
        <label for="deliveryMessage">{{ "Special Message" | translate }}</label>
        <textarea
            id="deliveryMessage"
            class="refactoringTemp"
            formControlName="deliveryMessage"
            [placeholder]="'Hope you enjoy the Gift Card!' | translate"
            rows="5"
            cols="33"
            [maxlength]="validation.maxMessageLength"
        ></textarea>
        <p i18n>
            {{ "Characters Remaining" | translate }}:
            {{
                validation.maxMessageLength -
                    giftCardForm.value.deliveryMessage.length
            }}
        </p>
        <label i18n for="quantity">{{ "Quantity" | translate }}</label>
        <input
            type="number"
            class="refactoringTemp"
            id="quantity"
            formControlName="quantity"
        />
        <label for="deliveryDate">{{ "Delivery Date" | translate }}*</label>
        <md-calendar
            #calendarDatedActivity
            [isSingleDate]="true"
            [infoForEachDay]="additionalCalendarDayInfo"
            (dateRangeSelected)="onDateRangeSelected($event)"
            [showDesktopInPopup]="true"
        >
            <div
                popupTargetControl
                class="bc-dab-date-picker"
            >
            <button type="button" class="btn calendar gcf-calendar-button"></button>
                <span class="bc-dab-date-picker-value p-2">{{ formattedDate }}</span>
            </div></md-calendar
        >
        
        <p>{{ "Up to a year from today" | translate }}</p>
        <div class="gcf-button-container">
            <button
                mdMainButton
                mdIconSize="small"
                [disabled]="giftCardForm.invalid"
                type="submit"
            >
                <ion-icon name="gift-outline"></ion-icon>
                {{ "Buy Gift Card" | translate }}
            </button>
        </div>
    </form>
</div>
