import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataService } from './data.service';
import { appConfig } from '../config/app-config';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from '../services/token.service';
import { loggedInToNotLoggedInSameCurrencyMap } from '../utilities/location.utils';
import { CUSTOMER_GROUP_ID } from '../utilities/local-storage.keys';
import { getCategoryRoutesArray } from '../static-content/menu-routes';
import { getCustomerId, getUser } from '../utilities/user.utils';
import { getApiUrlMofluid } from '../utilities/url.utils';
import { MdDeviceDetectorService } from '../services/device-detector.service';

declare let $: any;

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    public updateLang = new Subject<string>();
    public updateCountry = new Subject<'Mauritius' | 'Reunion' | 'Other'>();
    public isVerify = new Subject<any>();

    public customerGroupID: number = 0;
    public searchText: string = '';
    public language: string = '';
    public pageTitle: string = '';

    public isSearchEnable: boolean = false;

    /**
     * replace general error with error event emitter
     * @deprecated
     */
    public generalError: boolean = false;
    /**
     * replace general error with error event emitter
     * @deprecated
     */
    public generalErrorMsg: string = '';

    public generalErrorRequest: boolean = false;
    public generalErrorMsgRequest: string = '';
    public generalErrorCal: boolean = false;
    public generalErrorMsgCal: string = '';
    public side_drawer_user_initial: string = '';
    public side_drawer_user: string = '';
    public side_drawer_email: string = '';
    public isLoggedIn: boolean = false;
    public readonly api_url: string = '';
    private _keyStr: string =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    public cdnUrl: string = '';
    public newappLogo: string = '';
    public dataStore: any = [];
    public checkin_date: any = null;
    public checkout_date: any = null;
    public selectedpro_opt: any = null;
    public RecentcontentLoaded: boolean = false;
    public count = 2;
    public loadingText: string = '';

    // THIS MEANS THAT THE CALENDAR IS OPEN. BASICALLY COULD HAVE BEEN CALLED isCalendarOpen
    public isCalender: boolean = false;
    public data = new BehaviorSubject<any>({
        searchBar: '',
        adults: 0,
        childs: 0,
        infants: 0,
        isShowProductDetailSkeleton: false,
        guestCounter: 0,
        currentInfant: 0,
        adult: 0,
        child: 0,
        currentChild: 0,
        currentAdult: 0,
        openCalendar: '',
        searchBox: false,
        singleDateCalCatArray: ['4', '8', '7', '17', '6', '4'],
        advance_search_optionid: 0,
        isShowMultiplePriceSkeleton: false,
        fromCheckout: false,
        eventMonthArray: [],
        cdn: '',
        checkCatId: 0,
        header: '',
        page_title: '',
        is_promotion_started: '',
        counter_digit_color: '',
        counter_digit_text_color: '',
        counter_digit_background_color: '',
        before_background_image: '',
        after_background_image: '',
        counter_background_color: '',
        from_showOccupancy: false,
        ch_date: '',
        single_dt: '',
        selectedCategoryId: '',
        departure_date_travel: '',
        temp_checkin_date1: '',
        temp_checkout_date1: '',
        temp_single_date: '',
        pdp_single_date: '',
        pdp_checkin_date: '',
        pdp_checkout_date: '',
        single_date_adv: '',
        checkin_date_text: '',
        checkout_date_text: '',
        modal: '',
        modalOpenFlag_calender: false,
        finalCalculatedSubtotal: '',
        finalCalculatedCrossPrice: '',
        tempSubTotal: '',
        callfromdatetype: '',
        promotion_id: '',
        filterScrollCheck: false,
        modalOpenFlag: false,
        filterCheck: false,
        toProductDetails: false,
        toHome: false,
        categoryNameArray: getCategoryRoutesArray(),
        calenderCatArray: this.returnCategoryCatArray(),
        checkout: {},
        isCartSkeletonShow: false,
        isShowGetPaymentMethodSkeleton: false,
        timeoutletiable: false,
        get_device_OS: '',
        otherQueryKeys: {
            bookdateclone: 'bookdateclone',
            fbclid: 'fbclid',
        },
        advancedSearchKeys: {
            daycheckin: 'daycheckin',
            checkin: 'checkindate',
            checkout: 'checkoutdate',
            dealtype: 'dealtype',
            bookdate: 'bookdate',
            guest: 'guest',
            adult: 'adult',
            child: 'child',
            infants: 'infants',
            eventmonth: 'eventmonth',
        },
        advSearchValForCat: '',
        maribnbGuestOptionsSelectedDate: '',
    });

    // TODO: this one is used a lot and should at least be strongly typed
    currentData: any;
    imgCdnPath: string = '';
    cmsContent: any;
    catId: any;
    filter_data!: string;
    advancesearch!: string;
    params: any;
    sortType: any;
    sortOrder: any;
    page: any;
    pagesize: any;
    total!: number;
    userData: any;
    uId: string;
    quoteid: string; // this is an identifier given to a cart that is not logged in. Not used because we wrongly use localStorage. Leaving here for an explanation

    constructor(
        private httpClient: HttpClient,
        private dataService: DataService,
        private route: Router,
        private spinner: NgxSpinnerService,
        private socialAuthService: SocialAuthService,
        private deviceDetectorService: MdDeviceDetectorService,
        private translate: TranslateService,
        private tokenService: TokenService
    ) {
        this.currentData = this.data.asObservable();
        this.api_url = getApiUrlMofluid();
    }
    emitData(data: any) {
        this.data.next(data);
    }

    getStoreData() {
        const customerId = getCustomerId();
        this.httpClient
            .get(
                getApiUrlMofluid() +
                    'storedetails?store=' +
                    this.getStoreID() +
                    '&service=storedetails&theme=modern' +
                    '&customerid=' +
                    customerId +
                    '&customer_group_id=' +
                    this.getCustomerGroupID(),
                { headers: this.getTokenHeaders() }
            )
            .subscribe((store_details: any) => {
                this.newappLogo = store_details.store.logo;
                this.dataStore['store_details'] = store_details;
                this.dataService.setData(this.dataStore);
            });
    }

    /**
     * Method to get new initial details
     */

    getNewInitialDetails() {
        let customer_id: string = '';
        if (localStorage.getItem('marideal-pwa_user')) {
            customer_id = JSON.parse(localStorage.getItem('marideal-pwa_user'))
                .data.id;
        }
        return this.httpClient.get(
            this.api_url +
                'getNewInitial?store=' +
                this.getStoreID() +
                '&service=getNewInitial' +
                '&customerid=' +
                customer_id +
                '&customer_group_id=' +
                this.getCustomerGroupID(),
            { headers: this.getTokenHeaders() }
        );
    }

    /**
     * Method to get Token
     */

    getTokenHeaders() {
        let headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.tokenService.getToken(),
        });
        return headers;
    }

    getTokenJWTheaders() {
        let headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.tokenService.getToken(),
            'jwt-token': 'Bearer ' + this.tokenService.getJwtToken(),
        });
        return headers;
    }

    /**
     * Don't use these. Use the ones in urlUtils.ts
     */

    // TODO:
    /**
     * Don't use this during the refactor. Use encodeForUrl in encoders.ts
     * @param input
     */

    encode(input: any) {
        let output = '';
        let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        let i = 0;
        input = this._utf8_encode(input);
        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output =
                output +
                this._keyStr.charAt(enc1) +
                this._keyStr.charAt(enc2) +
                this._keyStr.charAt(enc3) +
                this._keyStr.charAt(enc4);
        }
        return output;
    }

    /**
     * Method to utf8 encode the string
     * @param string
     */

    _utf8_encode(string: any) {
        string = string.replace(/\r\n/g, '\n');
        let utftext = '';
        for (let n = 0; n < string.length; n++) {
            let c = string.charCodeAt(n);
            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if (c > 127 && c < 2048) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }
        return utftext;
    }

    /**
     * Method to set cache
     * @param thisObj
     * @param cacheName
     * @param data
     */

    setCache(thisObj: any, cacheName: string, data: any) {
        const config = appConfig;
        let isCacheable: number = config.cache_time;
        if (isCacheable > 0) {
            let appKey = config.storage_key;
            let storageName = appKey + '_' + cacheName;
            let date = new Date();
            let cacheableData = JSON.stringify({
                time: date.getTime(),
                data: data,
            });
            if (cacheName == 'checkin_date') {
                this.data.value.checkin_date = cacheableData;
            } else if (cacheName == 'checkout_date') {
                this.data.value.checkout_date = cacheableData;
            } else if (cacheName == 'selectedpro_opt') {
                this.selectedpro_opt = cacheableData;
            } else localStorage.setItem(storageName, cacheableData);
        }
    }

    /**
     * Method to get Cache by name
     * @param thisObj
     * @param cacheName
     */

    getCache(thisObj: any, cacheName: string): any {
        const config = appConfig;
        let isCacheable: number = config.cache_time;
        let result: any = null;
        if (isCacheable > 0) {
            let appKey = config.storage_key;
            let storageName = appKey + '_' + cacheName;
            if (cacheName == 'checkin_date') {
                result = !this.data.value.checkin_date
                    ? null
                    : JSON.parse(this.data.value.checkin_date);
            } else if (cacheName == 'checkout_date') {
                result = !this.data.value.checkout_date
                    ? null
                    : JSON.parse(this.data.value.checkout_date);
            } else if (cacheName == 'selectedpro_opt') {
                result = !this.selectedpro_opt
                    ? null
                    : JSON.parse(this.selectedpro_opt);
            } else
                result = JSON.parse(localStorage.getItem(storageName) || null);
        }
        return result;
    }

    /**
     * Method to remove cache
     * @param thisObj
     * @param cacheName
     */

    removeCache(thisObj: any, cacheName: string): void {
        const config = appConfig;
        let appKey = config.storage_key;
        let storageName = appKey + '_' + cacheName;
        localStorage.removeItem(storageName);
        return;
    }

    getDataStoreObj() {
        return this.dataStore;
    }

    /**
     * Method to organize route to return specific page title
     * @param url
     */

    organizeTitle(url: string = ''): string {
        let routeTitlesArray: any = {
            '/': '',
            '/help-new': this.translate.instant('Help')
                ? this.translate.instant('Help')
                : 'Help',
            '/customer/mainhelp': this.translate.instant('Help')
                ? this.translate.instant('Help')
                : 'Help',
            '/catalogsearch/result': this.translate.instant('Search')
                ? this.translate.instant('Search')
                : 'Search',
            '/catalogsearch/result/index': this.translate.instant('Search')
                ? this.translate.instant('Search')
                : 'Search',
            '/search': this.translate.instant('Search'),
            '/sales/order/history': this.translate.instant('My Orders')
                ? this.translate.instant('My Orders')
                : 'My Orders',
            '/companyprofile/index/index': this.translate.instant(
                'Company Profile'
            )
                ? this.translate.instant('Company Profile')
                : 'Company Profile',
            '/review/customer': this.translate.instant('My Reviews')
                ? this.translate.instant('My Reviews')
                : 'My Reviews',
            '/addgift-voucher': this.translate.instant('My Gift Card')
                ? this.translate.instant('My Gift Card')
                : 'My Gift Card',
            '/giftvoucher/index/history': this.translate.instant('My Gift Card')
                ? this.translate.instant('My Gift Card')
                : 'My Gift Card',
            '/storecredit': this.translate.instant('My Store Credit')
                ? this.translate.instant('My Store Credit')
                : 'My Store Credit',
            '/storecredit.html': this.translate.instant('My Store Credit')
                ? this.translate.instant('My Store Credit')
                : 'My Store Credit',
            '/sales/order/history/detail': this.translate.instant('My Orders')
                ? this.translate.instant('My Orders')
                : 'My Orders',
            '/login': this.translate.instant('Login')
                ? this.translate.instant('Login')
                : 'Login',
            '/register': this.translate.instant('Sign Up')
                ? this.translate.instant('Sign Up')
                : 'Sign Up',
            '/password': '',
            '/customer/account/edit/changepass/1/': this.translate.instant(
                'Change Password'
            )
                ? this.translate.instant('Change Password')
                : 'Change Password',
            '/customer/account/createPassword': '',
            '/customer/account/forgotpassword': '',
            '/address': '',
            '/checkout/address': '',
            '/invoice': this.translate.instant('Order Review')
                ? this.translate.instant('Order Review')
                : 'Order Review',
            '/order/invoice': this.translate.instant('Order Review')
                ? this.translate.instant('Order Review')
                : 'Order Review',
            '/invoicenew': this.translate.instant('Success!')
                ? this.translate.instant('Success!')
                : 'Success!',
            '/customer/account/edit': this.translate.instant('Settings')
                ? this.translate.instant('Settings')
                : 'Settings',
            '/customeraccount/settings': this.translate.instant('Settings')
                ? this.translate.instant('Settings')
                : 'Settings',
            '/gdpr/customer/settings/': this.translate.instant('Settings')
                ? this.translate.instant('Settings')
                : 'Settings',
            '/write-review/': this.translate.instant('Write Review')
                ? this.translate.instant('Write Review')
                : 'Write Review',
            '/rewardpoints/index/index/': this.translate.instant('My Rewards')
                ? this.translate.instant('My Rewards')
                : 'My Rewards',
            '/rewardpoints': this.translate.instant('My Rewards')
                ? this.translate.instant('My Rewards')
                : 'My Rewards',
            '/customer/recent': this.translate.instant('Recent')
                ? this.translate.instant('Recent')
                : 'Recent',
            '/customer/address/edit': this.translate.instant('My Profile')
                ? this.translate.instant('My Profile')
                : 'My Profile',
            '/badges': this.translate.instant('My Badges')
                ? this.translate.instant('My Badges')
                : 'My Badges',
            '/customer/wishlist': this.translate.instant('Saved')
                ? this.translate.instant('Saved')
                : 'Saved',
            '/customer/trips': this.translate.instant('My Trips')
                ? this.translate.instant('My Trips')
                : 'My Trips',
            '/customer/trips-detail': this.translate.instant('My Trip')
                ? this.translate.instant('My Trip')
                : 'My Trip',
            '/customer-notification': this.translate.instant('Notifications')
                ? this.translate.instant('Notifications')
                : 'Notifications',
            '/checkoutpaymentSuccess': this.translate.instant('Order Review')
                ? this.translate.instant('Order Review')
                : 'Order Review',
            '/checkout/checkoutpaymentSuccess': this.translate.instant(
                'Order Review'
            )
                ? this.translate.instant('Order Review')
                : 'Order Review',
            '/checkoutpaymentFailure': '',
            '/checkout/checkoutpaymentFailure': '',
            '/gift-voucher': this.translate.instant('eGift Card')
                ? this.translate.instant('eGift Card')
                : 'eGift Card',
            '/gift-voucher.html': this.translate.instant('eGift Card')
                ? this.translate.instant('eGift Card')
                : 'eGift Card',
            '/giftcard': this.translate.instant('eGift Card')
                ? this.translate.instant('eGift Card')
                : 'eGift Card',
            '/giftcard.html': this.translate.instant('eGift Card')
                ? this.translate.instant('eGift Card')
                : 'eGift Card',
            '/writereview': this.translate.instant('Write Review')
                ? this.translate.instant('Write Review')
                : 'Write Review',
            '/newsletter/manage': '',
        };
        let dynamicRouteTitlesArray: any = {
            '/companyprofile/index/invoiceform': this.translate.instant(
                'Generate VAT Invoice'
            )
                ? this.translate.instant('Generate VAT Invoice')
                : 'Generate VAT Invoice',
            '/writereview': this.translate.instant('Write Review')
                ? this.translate.instant('Write Review')
                : 'Write Review',
            '/customer/address/edit/id': this.translate.instant('My Profile')
                ? this.translate.instant('My Profile')
                : 'My Profile',
            '/checkout': this.translate.instant('Checkout')
                ? this.translate.instant('Checkout')
                : 'Checkout',
            '/order/checkout': this.translate.instant('Checkout')
                ? this.translate.instant('Checkout')
                : 'Checkout',
            '/customer/account': this.translate.instant('Reset Password')
                ? this.translate.instant('Reset Password')
                : 'Reset Password',

            '/deal/': '',
        };
        let arrayUrls = Object.keys(routeTitlesArray);
        for (let arrUrl of arrayUrls) {
            if (arrUrl.length > url.length || arrUrl.length == url.length) {
                if (arrUrl.includes(url)) {
                    return routeTitlesArray[arrUrl];
                }
            } else {
                let dynamicArrayUrls = Object.keys(dynamicRouteTitlesArray);
                for (let dynamicArrUrl of dynamicArrayUrls) {
                    if (url.includes(dynamicArrUrl)) {
                        return dynamicRouteTitlesArray[dynamicArrUrl];
                    }
                }
            }
        }
        return 'Home';
    }

    /**
     * Method to clear custom cache
     * @param thisObj
     */

    clearCustomCache(thisObj: any) {
        this.setCache(thisObj, 'filterData', '');
        this.setCache(thisObj, 'dealcity', '');
        this.setCache(thisObj, 'occupancy', '');
        this.setCache(thisObj, 'eventmonth', '');
        this.setCache(thisObj, 'guest', '');
        this.setCache(thisObj, 'activityData', '');
        this.setCache(thisObj, 'checkin_date', '');
        this.setCache(thisObj, 'checkout_date', '');
        this.setCache(thisObj, 'single_date', '');
        this.setCache(thisObj, 'selectedpro_opt', '');
        this.setCache(thisObj, 'temp_checkin_date', '');
        this.setCache(thisObj, 'temp_checkout_date', '');
        this.setCache(thisObj, 'temp_single_date', '');
        this.setCache(thisObj, 'stay_advance_search_deal', '');
        this.data.value.adults = 0;
        this.data.value.childs = 0;
        this.data.value.infants = 0;
        // this.emitData(this.data);
    }

    guestCount(infant: any) {
        this.data.value.guestCounter = '';
        let checkForAdult = false;
        if (infant != undefined) {
            this.data.value.currentInfant = infant;
            checkForAdult = true;
        }
        if (
            this.data.value.adult > 0 ||
            this.data.value.currentChild > 0 ||
            this.data.value.currentAdult > 1 ||
            checkForAdult == true
        ) {
            this.data.value.guestCounter =
                this.data.value.currentAdult + this.data.value.currentChild;
            this.data.value.guestCounter =
                this.data.value.guestCounter > 1
                    ? this.data.value.guestCounter + ' guests'
                    : this.data.value.guestCounter + ' guest';
        }
        if (this.data.value.infants > 0 || this.data.value.currentInfant > 0) {
            this.data.value.guestCounter +=
                ', ' + this.data.value.currentInfant;
            this.data.value.guestCounter =
                this.data.value.currentInfant > 1
                    ? this.data.value.guestCounter + ' infants'
                    : this.data.value.guestCounter + ' infant';
        }
        // this.emitData(this.data);
    }

    plusDropDownTypeClick(option: any, subOption: any, thisObj: any) {
        if (
            thisObj.private_stay_category != 'private_stays' &&
            thisObj.catId == 8 &&
            !thisObj.single_date
        ) {
            thisObj.catId = 8;
            // event.stopPropagation();
            // event.stopImmediatePropagation();
            thisObj.seclectOption_new();
        } else {
            let totalMax = parseInt(thisObj.drop.maximum);
            let single_date = this.getCache(thisObj, 'single_date')
                ? this.getCache(thisObj, 'single_date').data
                : '';
            single_date = single_date ? new Date(single_date) : '';
            if (single_date) {
                let bookDate = thisObj.getFormatedDate(single_date, 'ddmm');

                for (let value of thisObj.mulPriceArray) {
                    let eachDate = value.date;
                    if (bookDate == eachDate) {
                        if (value.default_allotment > 0) {
                            totalMax = value.default_allotment;
                        }
                    }
                }
            }
            subOption = thisObj.drop[subOption.id];
            let subOptionMax = parseInt(subOption.maxVal);
            let selectedOptionVal = parseInt(subOption.selected);
            let TotalSelectedOption =
                thisObj.getTotalSelectedOfDropDownOption(option);
            if (
                selectedOptionVal >= subOptionMax ||
                TotalSelectedOption >= totalMax
            ) {
                thisObj.drop[subOption.id].addEnable = false;
            } else {
                thisObj.drop[subOption.id].subEnable = true;
                let selectedVal = parseInt(thisObj.drop[subOption.id].selected);
                let min_require = parseInt(
                    thisObj.drop[subOption.id].min_require
                );
                let min_val = parseInt(thisObj.drop[subOption.id].minpax);
                if (min_val > 1 && min_require == 0) {
                    min_require = min_val;
                }
                if (min_require > 0 && selectedVal == 0) {
                    thisObj.drop[subOption.id].selected = min_require;
                } else {
                    thisObj.drop[subOption.id].selected = selectedVal + 1;
                }
                let totalSelectedOption =
                    thisObj.getTotalSelectedOfDropDownOption(option);
                thisObj.updation(subOption.id, 'drop_down', option, subOption);
                thisObj.disableEnablePlusOptions(
                    totalMax,
                    totalSelectedOption,
                    option
                );
            }
        }
    }

    /**
     *
     * @param option
     * @param subOption
     */

    minusDropDownTypeClick(option: any, subOption: any, thisObj: any) {
        subOption = thisObj.drop[subOption.id];
        let totalMax = parseInt(thisObj.drop.maximum);
        let single_date = this.getCache(this, 'single_date')
            ? this.getCache(this, 'single_date').data
            : '';
        single_date = single_date ? new Date(single_date) : '';
        if (single_date) {
            let bookDate = thisObj.getFormatedDate(single_date, 'ddmm');
            for (let value of thisObj.mulPriceArray) {
                let eachDate = value.date;
                if (bookDate == eachDate) {
                    if (value.default_allotment > 0) {
                        totalMax = value.default_allotment;
                    }
                }
            }
        }
        let selectedOptionVal = parseInt(subOption.selected);
        if (selectedOptionVal <= 0) {
            thisObj.drop[subOption.id].subEnable = false;
        } else {
            thisObj.drop[subOption.id].addEnable = true;
            let selectedVal = parseInt(thisObj.drop[subOption.id].selected);
            let min_require = parseInt(thisObj.drop[subOption.id].min_require);
            let min_val = parseInt(thisObj.drop[subOption.id].minpax);
            if (min_val > 1 && min_require == 0) {
                min_require = min_val;
            }
            if (min_require == selectedVal) {
                thisObj.drop[subOption.id].selected = 0;
            } else {
                thisObj.drop[subOption.id].selected = selectedVal - 1;
            }
            thisObj.updation(subOption.id, 'drop_down', option, subOption);
            selectedOptionVal = parseInt(thisObj.drop[subOption.id].selected);
            if (selectedOptionVal <= 0) {
                thisObj.drop[subOption.id].subEnable = false;
            }
        }
        let totalSelectedOption =
            thisObj.getTotalSelectedOfDropDownOption(option);
        thisObj.disableEnablePlusOptions(totalMax, totalSelectedOption, option);
    }

    // TODO: This can most likely be moved in payment-success.component.ts since that's the only place it is used
    getAllDataFromQueryString(isSearch = true) {
        let qStringLocal: any =
            window.location.pathname + window.location.search;
        qStringLocal = qStringLocal.split('?');
        let queryDataObject: any = {};
        if (qStringLocal.length > 1) {
            queryDataObject = this.createObjectFromQueryString(
                qStringLocal[1],
                1
            );
        }

        let sortType: string = '';
        let sortOrder: string = '';
        let searchString: string = '';
        if (queryDataObject.q) {
            searchString = queryDataObject.q;
        }
        if (queryDataObject.order) {
            sortType = queryDataObject.order;
        }
        if (queryDataObject.dir) {
            sortOrder = queryDataObject.dir;
        }
        if (!isSearch) {
            delete queryDataObject.q;
        }
        return queryDataObject;
    }

    createObjectFromQueryString(qString: any, stringType = 0) {
        let searchObject: any = {};
        if (stringType == 0) {
            searchObject = this.QueryStringToJSON(qString);
        } else if (stringType == 1) {
            searchObject = this.QueryStringToJSON('?' + qString);
        } else {
            searchObject = this.QueryStringToJSON(qString);
        }
        let filterDataQuery: any = {};
        for (let filterKey in searchObject) {
            if (searchObject.hasOwnProperty(filterKey)) {
                filterDataQuery[filterKey] = [];
                if (typeof searchObject[filterKey] == 'string') {
                    let valuesData = searchObject[filterKey].split(',');
                    for (
                        let valueIndex = 0;
                        valueIndex < valuesData.length;
                        valueIndex++
                    ) {
                        if (/^[0-9]+$/.test(valuesData[valueIndex])) {
                            if (filterKey == 'dealtype') {
                                filterDataQuery[filterKey] = parseInt(
                                    valuesData[valueIndex]
                                );
                            } else {
                                filterDataQuery[filterKey].push(
                                    parseInt(valuesData[valueIndex])
                                );
                            }
                        } else {
                            if (valuesData.length < 2) {
                                filterDataQuery[filterKey] =
                                    valuesData[valueIndex];
                            } else {
                                filterDataQuery[filterKey].push(
                                    valuesData[valueIndex]
                                );
                            }
                        }
                    }
                } else {
                    for (
                        let filterCodeIndex = 0;
                        filterCodeIndex < searchObject[filterKey].length;
                        filterCodeIndex++
                    ) {
                        if (filterKey == 'dealtype') {
                            filterDataQuery[filterKey] = parseInt(
                                searchObject[filterKey][filterCodeIndex]
                            );
                        } else {
                            filterDataQuery[filterKey].push(
                                parseInt(
                                    searchObject[filterKey][filterCodeIndex]
                                )
                            );
                        }
                    }
                }
            }
        }
        return filterDataQuery;
    }

    QueryStringToJSON(queryString: any) {
        let result: any = {};
        if (
            queryString == undefined ||
            queryString == 'undefined' ||
            queryString.length <= 2
        ) {
            return JSON.parse(JSON.stringify(result));
        }
        let pairs = queryString.slice(1).split('&');
        result.q = '';
        result.dir = 'asc';
        result.order = 'ordered_qty';
        if (queryString.includes('eventmonth')) {
            result.order = 'event_date';
        }
        for (let pair of pairs) {
            pair = pair.split('=');
            if (!result[pair[0]]) {
                result[pair[0]] = [];
            }
            if (/^[0-9]+$/.test(decodeURIComponent(pair[1] || ''))) {
                if (pair[0] == 'eventmonth') {
                    result[pair[0]].push(decodeURIComponent(pair[1] || null));
                } else {
                    result[pair[0]].push(
                        parseInt(decodeURIComponent(pair[1] || null))
                    );
                }
            } else {
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            }
            if (result[pair[0]] == '' || result[pair[0]] == undefined) {
                delete result[pair[0]];
            }
        }
        return JSON.parse(JSON.stringify(result));
    }

    isEmptyObject(obj: any) {
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) return !1;
        }
        return true;
    }

    // TODO: don't use this as you rewrite. Use
    pad(n: any) {
        return n < 10 ? `0${n}` : n;
    }

    // TODO: I have NO IDEA what this is used for or why it's needed
    returnCategoryCatArray() {
        return ['3', '4', '8', '9', '16', '17', '20', '21', '23'];
    }

    showDropdownOccupencies(id: any, from: any) {}

    defaultImage(): string {
        return 'assets/img/defaultIcon.png';
    }

    isValidName(name: any) {
        let alphaExp = /^[A-Za-z]+( [A-Za-z]+)*$/;
        if (name.match(alphaExp)) return !0;
        else return !1;
    }

    // TODO: This category_list_data seems to not be used anywhere but not removing yet
    storeDataInLocal(catId, advanceSearch, filterdata, category_name) {
        let data = {
            catId: catId,
            advancesearch: advanceSearch,
            filterdata: filterdata,
            category_name: category_name,
        };
        localStorage.setItem('category_list_data', JSON.stringify(data));
    }

    storeDataInCache(key, data) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    getDataFromCache(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    removeDataFromCache(key) {
        localStorage.removeItem(key);
    }

    clearmytimer() {
        if (
            this.data.value.timeoutletiable &&
            this.data.value.timeoutletiable.length > 0
        ) {
            for (let i = 0; i < this.data.value.timeoutletiable.length; i++) {
                clearTimeout(this.data.value.timeoutletiable[i]);
            }
            this.data.value.timeoutletiable = [];
        }
    }

    startProgress() {
        this.spinner.show();
    }

    /**
     * Method to hide loader
     */

    endProgress() {
        this.loadingText = '';
        this.spinner.hide();
    }

    /**
     * Method to get Device platform Android or iOS
     */

    getDevicePlatform() {
        return this.getCache(this, 'platform')
            ? this.getCache(this, 'platform').data
                ? this.getCache(this, 'platform').data
                : ''
            : '';
    }

    getStoreID() {
        return this.getCache(this, 'storeID')
            ? this.getCache(this, 'storeID').data
            : 1;
    }

    logout() {
        let userData = getUser();
        let uId = '';
        if (userData != null) {
            uId = '&customerid=' + userData.id;
        }
        let logoutUrl =
            this.api_url +
            'logout?service=logout' +
            uId +
            '&customer_group_id=' +
            this.getCustomerGroupID();
        this.startProgress();

        this.httpClient
            .get(logoutUrl, { headers: this.getTokenJWTheaders() })
            .subscribe(
                (response: any) => {
                    this.endProgress();
                },
                (error: any) => {
                    this.endProgress();
                }
            );

        window.localStorage.removeItem('jwt-token');

        this.isLoggedIn = false;
        this.side_drawer_user_initial = '';
        this.side_drawer_user = '';
        this.side_drawer_email = '';
        this.RecentcontentLoaded = true;
        this.logoutUsers();
    }

    logoutUsers() {
        this.removeCache(this, 'customer_group_id');
        // replace the customerGroupId with same country but not logged in
        const newCustomerGroupId = loggedInToNotLoggedInSameCurrencyMap.get(
            this.getCustomerGroupID()
        );
        this.setCache(this, CUSTOMER_GROUP_ID, newCustomerGroupId);

        this.removeCache(this, 'user');
        this.removeCache(this, 'billing_address');
        this.removeCache(this, 'shipping_address');
        this.removeCache(this, 'cartItemsCount');
        this.removeCache(this, 'cartItemsdataCount');
        this.removeCache(this, 'cartItemsdata');
        this.removeCache(this, 'otp');
        this.removeCache(this, 'ticketmofluid');
        localStorage.removeItem('quoteid');
        document.cookie =
            'loggedin_status' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        this.route.navigateByUrl('/').then(() => {
            window.location.reload();
        });

        let socialLoginType = this.getCache(this, 'socialLoginType') ? this.getCache(this, 'socialLoginType').data : '';

        if (socialLoginType) {
            this.socialAuthService.signOut();
        }

        const { isAndroid, isIos } = this.deviceDetectorService;
        if (isIos) {
            (
                window as any
            ).webkit.messageHandlers.logoutSocialLogin.postMessage(
                socialLoginType
            );
        } else if (isAndroid) {
            //AndroidInterface.googleButtonClick("Google click");
            (window as any).AndroidShareHandler.logoutSocialLogin(
                socialLoginType
            );
        }
    }

    // TODO: This is just a get method. Should have a common http service for this
    submitBookingRequest(url: string) {
        return this.httpClient.get(url, { headers: this.getTokenJWTheaders() });
    }

    // TODO: these 2 methods must be duplicated somewhere before we continue and used from there
    setCustomerGroupID(id: any) {
        const customerGroupID = parseInt(id);
        this.setCache(this, 'customer_group_id', customerGroupID);
        this.customerGroupID = customerGroupID;
        this.isVerify.next(true);
    }

    getCustomerGroupID(): number {
        if (this.getCache(this, 'customer_group_id')) {
            return this.getCache(this, 'customer_group_id').data;
        } else if (this.customerGroupID > 0) {
            return this.customerGroupID;
        } else {
            return 0;
        }
    }

    /**
     * Method to convert price to Euro display format
     * @param price
     */

    convertPriceToEuroFormat(price: any) {
        const customerGroupID = this.getCustomerGroupID();
        if (customerGroupID !== 0 && customerGroupID !== 1) {
            price = Number(price).toFixed(2);
            price = price.replace('.', ',');

            return price.toLocaleString('de-DE');
        }

        return price;
    }

    // TODO: There's a copy of this in the currencyLanguageService
    getCurrency(): string {
        const customerGroupID = this.getCustomerGroupID();
        return customerGroupID == 0 || customerGroupID == 1 ? 'Rs.' : '€';
    }
}
