import { Component, Input } from '@angular/core';

@Component({
    selector: 'md-review-score-bar',
    templateUrl: './review-score-bar.component.html',
    styleUrls: ['./review-score-bar.component.scss'],
})
export class ReviewScoreBarComponent {
    @Input() averageScore: number;
    @Input() label: string;

    progressValue: number;
    progressStyle: string;

    getProgressStyle = (): string => {
        return `--progress-color: ${this.getProgressColor()};`;
    };

    getProgressColor = (): string => {
        if (this.averageScore >= 8) {
            return '#47C447';
        } else if (this.averageScore >= 6) {
            return '#0E67DE';
        } else if (this.averageScore >= 4) {
            return '#FEBA02';
        } else {
            return '#F28181';
        }
    };
}
