import { Component, OnInit, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'md-countdown',
  template: `
    <div *ngIf="showCountdown" class="countdown">
      <img [attr.src]="showCountdown ? imagePath : null" alt="Countdown Image" class="countdown-image" id="countdown-image" />
      <div class="time-container">
        <div id="days" class="time-unit">{{ days < 10 ? '0' + days : days }}</div>
        <div class="points">:</div>
        <div id="hours" class="time-unit">{{ hours < 10 ? '0' + hours : hours }}</div>
        <div class="points">:</div>
        <div id="minutes" class="time-unit">{{ minutes < 10 ? '0' + minutes : minutes }}</div>
        <div class="points">:</div>
        <div id="seconds" class="time-unit">{{ seconds < 10 ? '0' + seconds : seconds }}</div>
      </div>
      <div class="label-container">
        <div id="label-days"    class="time-label">Days</div>
        <div id="label-hours"   class="time-label">Hours</div>
        <div id="label-minutes" class="time-label">Minutes</div>
        <div id="label-seconds" class="time-label">Seconds</div>
      </div>
  `,
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Output() countdownStarted = new EventEmitter<void>();
  @Output() countdownEnded = new EventEmitter<void>();
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  imagePath: string; 
  showCountdown = false;
  private countdownSubscription!: Subscription;
  showHomeBanner = false;

  // Image paths
  private readonly countdownImage = 'assets/img/bf-banners/BF-countdown.jpg';
  private readonly countdownImageMobile = 'assets/img/bf-banners/BF-countdown-Mob.jpg';
  private readonly finalCountdownImage = 'assets/img/bf-banners/BF-countdown-happening.jpg';
  private readonly finalCountdownImageMobile = 'assets/img/bf-banners/BF-countdown-Mob.jpg';

  // Countdown dates
  private readonly firstTargetDate = new Date('2024-10-30T16:30:00+04:00').getTime();
  private readonly finalTargetDate = new Date('2024-10-30T16:45:00+04:00').getTime();
  private readonly countdownStartDate = new Date('2024-10-30T16:15:00+04:00').getTime();
  private hasSwitchedToFinalImage = false;

  ngOnInit(): void {
    this.updateImagePath();
    this.startCountdown();
  }

  ngOnDestroy(): void {
    this.unsubscribeCountdown();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateImagePath();
  }

  private updateImagePath(): void {
    const isMobile = window.innerWidth <= 768;

    if (this.hasSwitchedToFinalImage) {
      this.imagePath = isMobile ? this.finalCountdownImageMobile : this.finalCountdownImage;
    } else {
      this.imagePath = isMobile ? this.countdownImageMobile : this.countdownImage;
    }
  }

  private startCountdown(): void {
    let targetDate = this.firstTargetDate;

    this.countdownSubscription = interval(1000).subscribe(() => {
      const now = new Date().getTime();

      if (now < this.countdownStartDate) {
        
        this.showCountdown = false;
        return;
      } else if (now >= this.finalTargetDate) {
        
        this.showCountdown = false;
        this.countdownEnded.emit(); 
        this.resetCountdown();
        this.showHomeBanner = true;
        // document.getElementById('home-banner-container-1')?.style.setProperty('display', 'block');
        return;
      } else {
        if (!this.showCountdown) {
          this.showCountdown = true;
          this.countdownStarted.emit(); 
          document.getElementById('home-banner-container-1')?.style.setProperty('display', 'none'); 
        }
        if (!this.hasSwitchedToFinalImage) {
          this.imagePath = window.innerWidth <= 768 ? this.countdownImageMobile : this.countdownImage;
        }
      }

      if (now > targetDate && !this.hasSwitchedToFinalImage) {
        if (targetDate === this.firstTargetDate) {
          targetDate = this.finalTargetDate;
          this.hasSwitchedToFinalImage = true;
          this.imagePath = window.innerWidth <= 768 ? this.finalCountdownImageMobile : this.finalCountdownImage;
        }
      }

      this.updateTime(targetDate);
    });
  }

  private updateTime(targetDate: number): void {
    const now = new Date().getTime();
    const timeLeft = targetDate - now;

    if (timeLeft > 0) {
      this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    } else {
      this.resetCountdown();
    }
  }

  private resetCountdown(): void {
    this.days = 0;
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.unsubscribeCountdown();
  }

  private unsubscribeCountdown(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }
}
