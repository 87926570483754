import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getApiUrlV2 } from '../utilities/url.utils';
import {
    CurrencyLanguageService,
    LocaleData,
} from '../shared/currency-language.service';
import { Observable, map, switchMap } from 'rxjs';
import {
    Filter,
    FilterDataDto,
    transformFilterData,
} from './filter-data.model';

@Injectable({
    providedIn: 'root',
})
export class FilterDataService {
    constructor(
        private httpClient: HttpClient,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    getFilterData(sectionId: number): Observable<{ filters: Filter[] }> {
        return this.currencyLanguageService.getLocaleData().pipe(
            switchMap((localeData: LocaleData) => {
                let params = new HttpParams()
                    .set('cc', localeData.country)
                    .set('lang', localeData.language);
                if (!isNaN(sectionId)) {
                    params = params.set('sectionId', sectionId);
                }

                return this.httpClient.get<FilterDataDto>(
                    `${getApiUrlV2()}results/filters/widget`,
                    {
                        params,
                    }
                );
            }),
            map((filterData: FilterDataDto) => {
                return {
                    filters: transformFilterData(filterData),
                };
            })
        );
    }
}
