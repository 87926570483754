<div class="md-category-sort">
    <div class="sort-title">
        <h3>{{ "Sort by" | translate }}</h3>
    </div>
    <div
        *ngIf="showClosestDate"
        [ngClass]="[
            'sort-option',
            sortLabel === 'Closest Date' ? 'active' : ''
        ]"
        (click)="applySort('Closest Date')"
    >
        <h3>{{ "Closest Date" | translate }}</h3>
    </div>
    <div
        [ngClass]="['sort-option', sortLabel === 'Best Seller' ? 'active' : '']"
        (click)="applySort('Best Seller')"
    >
        <h3>{{ "Best Seller" | translate }}</h3>
    </div>
    <div
        [ngClass]="[
            'sort-option',
            sortLabel === 'Lowest Price' ? 'active' : ''
        ]"
        (click)="applySort('Lowest Price')"
    >
        <h3>{{ "Lowest Price" | translate }}</h3>
    </div>
    <div
        [ngClass]="[
            'sort-option',
            sortLabel === 'Highest Price' ? 'active' : ''
        ]"
        (click)="applySort('Highest Price')"
    >
        <h3>{{ "Highest Price" | translate }}</h3>
    </div>
    <div
        [ngClass]="[
            'sort-option',
            sortLabel === 'Customer Rating' ? 'active' : ''
        ]"
        (click)="applySort('Customer Rating')"
    >
        <h3>{{ "Customer Rating" | translate }}</h3>
    </div>
</div>
