export interface Filter {
    filterTypeId: number;
    filterName: string;
    filterValues: FilterValue[];
    isSelected: boolean;
}

export interface FilterValue {
    label: string;
    filterId: number;
    orderIndex: number;
    isSelected: boolean;
}

export interface FilterDto {
    filterId: number;
    filterTypeId: number;
    filterHeaderName: string;
    filterName: string;
    sorting: number;
}

export interface FilterDataDto {
    total: number;
    filters: FilterDto[];
}

export interface AppliedFilterIds {
    filterTypeId: number;
    filterId: number;
}

export const transformFilterData = (raw: FilterDataDto): Filter[] => {
    return raw.filters.reduce((filters: Filter[], filterDto: FilterDto) => {
        const existingFilter = filters.find(
            (filter) => filter.filterTypeId === filterDto.filterTypeId
        );
        if (existingFilter) {
            existingFilter.filterValues.push({
                label: filterDto.filterName,
                filterId: filterDto.filterId,
                orderIndex: filterDto.sorting,
                isSelected: false,
            });
        } else {
            filters.push({
                filterTypeId: filterDto.filterTypeId,
                filterName: filterDto.filterHeaderName,
                isSelected: false,
                filterValues: [
                    {
                        label: filterDto.filterName,
                        filterId: filterDto.filterId,
                        orderIndex: filterDto.sorting,
                        isSelected: false,
                    },
                ],
            });
        }
        return filters;
    }, []);
};

export type SortLabel =
    | 'Best Seller'
    | 'Closest Date'
    | 'Lowest Price'
    | 'Highest Price'
    | 'Customer Rating';

export type SortType =
    | 'best_seller_qty'
    | 'price'
    | 'reviews_count'
    | 'event_date'
    | 'rating';
