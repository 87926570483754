import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'md-empty-cart',
    templateUrl: './empty-cart.component.html',
    styleUrls: ['./empty-cart.component.scss'],
})
export class EmptyCartComponent {
    @Input() items: unknown[] = [];

    constructor(private router: Router) {}

    goHome() {
        this.router.navigate(['/']);
    }
}
