import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { parseDate } from 'src/app/utilities/date.utils';

export interface ProductServicePricingDto {
    data: {
        actualprice: number;
        cross_price: number;
        date: string;
        minimumlength: number;
        option: string;
        OptionsData: {
            option: number;
            actualprice: string;
            cross_price: string;
        }[];
    }[];
}

export interface ProductServicePricingByDate {
    date: NgbDateStruct;
    dateAsString: string;
    optionId: number;
    actualPrice: number;
    crossPrice: number;
    minLenghtOfStay: number;
}

export const transformProductServicePricingDtoToPricingByDate = (
    productServicePricingDto: ProductServicePricingDto
): ProductServicePricingByDate[] => {
    const result: ProductServicePricingByDate[] = [];
    productServicePricingDto.data.forEach((data) => {
        result.push({
            date: parseDate(data.date),
            dateAsString: data.date,
            optionId: Number(data.option),
            actualPrice: Number(data.actualprice),
            crossPrice: Number(data.cross_price),
            minLenghtOfStay: data.minimumlength,
        });
        // TODO: There is additional data for 2 options on each date but not sure this is used anywhere yet
        // data.OptionsData.forEach((optionData) => {
        //     result.push({
        //         date: parseDate(data.date),
        //         optionId: optionData.option,
        //         actualPrice: Number(optionData.actualprice),
        //         crossPrice: Number(optionData.cross_price),
        //         minLenghtOfStay: data.minimumlength,
        //     });
        // });
    });
    return result;
};
