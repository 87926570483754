import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/common.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { CheckoutUtilsService } from '../../checkout.utils.service';
import { CheckoutService } from '../../checkout.service';
import { GeneralErrorService } from 'src/app/components/general-error/general-error.service';
import { Locale } from 'src/app/config/locale';
import { DeviceCommsService } from 'src/app/services/device-comms.service';

@Component({
    selector: 'md-cart-items',
    templateUrl: './cart-items.component.html',
    styleUrls: ['./cart-items.component.scss'],
})
export class CartItemsComponent implements OnInit {
    @Input() items: any[] = [];
    @Input() afterCartUpdated: (
        cartItems: any[],
        response: any,
        clearGuests: boolean
    ) => void;
    @Input() uncheckGiftAndApplyStoreCredit: () => void;

    currenttime = Date.now();
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    localeAlert: { [key: string]: string };

    constructor(
        private router: Router,
        private commonService: CommonService,
        private currencyLanguageService: CurrencyLanguageService,
        private checkoutUtilsService: CheckoutUtilsService,
        private checkoutService: CheckoutService,
        private generalErrorService: GeneralErrorService,
        private localeObj: Locale,
        private deviceCommsService: DeviceCommsService
    ) {}

    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
        this.localeAlert = this.localeObj.getAlertMessages();
    }

    itemHasCheckinCheckout(item: any) {
        return (
            item.nights != '-' &&
            item.nights != '0' &&
            item.nights != 0 &&
            item.nights &&
            item.check_in != '' &&
            item.check_out != ''
        );
    }

    itemHasSingleDate(item: any) {
        return (
            (item.nights == '0' || item.nights == 0) &&
            item.nights &&
            item.check_in != '-' &&
            item.check_in != ''
        );
    }

    redirectToProdDetail(id: number, name: string) {
        this.commonService.clearCustomCache(this);
        this.deviceCommsService.openLinkInNewTabIfBrowser(`deal/${name}/${id}`);
    }

    updateCart(item: any) {
        this.checkoutUtilsService.showGetPaymentMethodSkeleton = true;
        this.checkoutService.updateCart(item).subscribe({
            next: (response: any) =>
                this.handleResponseFromCartChanged(response),
            error: () => {
                this.generalErrorService.showGeneralError(
                    this.localeAlert['server_not_responding']
                );
            },
        });
    }

    increaseQuantity(item: any) {
        if (item.qty >= item.max_qty) {
            return;
        }
        item.qty = item.qty + 1;
        this.updateCart(item);
    }

    decreaseQuantity(item: any) {
        item.qty = item.qty - 1;
        this.updateCart(item);
    }

    removeCartAllotmentMsg: any;
    removeItem(item: any) {
        this.checkoutUtilsService.showGetPaymentMethodSkeleton = true;

        this.checkoutService.removeItemFromCart(item).subscribe({
            next: (response: any) =>
                this.handleResponseFromCartChanged(response),
            error: () => {
                this.generalErrorService.showGeneralError(
                    this.localeAlert['server_not_responding']
                );
            },
        });

        this.uncheckGiftAndApplyStoreCredit();
    }

    handleResponseFromCartChanged(response: any) {
        this.checkoutUtilsService.showGetPaymentMethodSkeleton = false;
        if (response.status == 'success') {
            this.items = [];
            setTimeout(() => {
                this.handleCartChanged(response);
                this.commonService.clearmytimer();
            });
        } else {
            this.generalErrorService.showGeneralError(response.msg);
        }
    }

    handleCartChanged(response: any) {
        this.items = response.cart_products;
        for (let i = 0; i < this.items.length; i++) {
            this.items[i].quantity_arr =
                this.checkoutUtilsService.create_quantity_array(
                    this.items[i].max_qty
                );
        }
        if (
            response.allotment_check_mdpErrorMsg &&
            response.allotment_check_mdpErrorMsg.length > 0
        ) {
            this.generalErrorService.showGeneralError(
                response.allotment_check_mdpErrorMsg,
                { showMailto: false }
            );
        }
        localStorage.setItem('quoteid', response.quoteid);
        this.commonService.setCache(
            this,
            'cartItemsdataCount',
            response.cart_items_qty
        );
        this.commonService.setCache(this, 'cartItemsdata', response);
        this.afterCartUpdated(this.items, response, true);
    }

    convert_to_date(date: any, type: any) {
        if (date == '') {
            return '-';
        }
        let dt: any;
        if (type == 0) {
            dt = date.split('-')[0] + ' ' + date.split('-')[1].split(',')[0];
        } else {
            dt =
                date.split('-')[0] +
                ' ' +
                date.split('-')[1].split(',')[0] +
                ', ' +
                date.split('-')[1].split(',')[1];
        }
        return dt;
    }

    convert_to_date2(date: any, type: any) {
        if (date == '') {
            return '-';
        }
        let month: any = date.split('/')[0];
        let name: any;
        switch (month) {
            case '01':
                name = 'Jan';
                break;
            case '02':
                name = 'Feb';
                break;
            case '03':
                name = 'Mar';
                break;
            case '04':
                name = 'Apr';
                break;
            case '05':
                name = 'May';
                break;
            case '06':
                name = 'Jun';
                break;
            case '07':
                name = 'Jul';
                break;
            case '08':
                name = 'Aug';
                break;
            case '09':
                name = 'Sept';
                break;
            case '10':
                name = 'Oct';
                break;
            case '11':
                name = 'Nov';
                break;
            case '12':
                name = 'Dec';
                break;
        }
        let dt: any;
        if (type == 0) {
            dt = date.split('/')[1] + ' ' + name;
        } else {
            dt = date.split('/')[1] + ' ' + name + ', ' + date.split('/')[2];
        }
        return dt;
    }
}
