<ng-container *ngIf="userLogin">
    <div has-header="true" scroll="false">
        <div class="ma-gc-inputs">
            <div class="ma-gc-inputs-top">
                <div class="ma-gc-inputs-top-left">
                    {{ "Enter Gift card Code" | translate }}*
                </div>
                <div class="ma-gc-inputs-top-right">
                    <input
                        type="text"
                        placeholder="GIFT-XXXXX-XXXXX"
                        [formControl]="giftCode"
                    />
                    <button
                        mdMainButton="light"
                        class="ripleEffect"
                        (click)="addGiftCardPressed()"
                    >
                        {{ "Add a Gift Card" | translate }}
                    </button>
                </div>
            </div>
            <div class="ma-gc-inputs-bottom">
                <button mdTextButton (click)="viewGiftCardBalancePressed()">
                    {{
                        !showGiftBalanceView
                            ? "View Gift Card balance history"
                            : ("View Gift Card list" | translate)
                    }}
                </button>
            </div>
        </div>
        <ng-container *ngIf="!isShowDummyGiftCard">
            <ng-container *ngIf="!showGiftBalanceView">
                <div>
                    <ul class="ma-gc-list">
                        <li mdDesktopOnly class="ma-gc-list-item header">
                            <div>{{ "Added Date" | translate }}</div>
                            <div>{{ "Gift Card Code" | translate }}</div>
                            <div>{{ "Amount" | translate }}</div>
                            <div>{{ "Status" | translate }}</div>
                            <div>{{ "Expiration Date" | translate }}</div>
                        </li>
                        <li
                            class="ma-gc-list-item"
                            *ngFor="let item of giftCardItem"
                        >
                            <div>
                                <p mdMobileOnly>
                                    {{ "Added Date" | translate }}:
                                </p>
                                <p>{{ getGiftCodeDate(item.addedDate) }}</p>
                            </div>
                            <div>
                                <p mdMobileOnly>
                                    {{ "Gift Card Code" | translate }}:
                                </p>
                                <p>{{ item.giftVoucherCode }}</p>
                            </div>
                            <div>
                                <p mdMobileOnly>{{ "Amount" | translate }}:</p>
                                <p>
                                    {{
                                        item.giftCardBal
                                            | currency
                                                : currencySymbol
                                                : "symbol"
                                                : "1.0-0"
                                                : locale
                                    }}
                                </p>
                            </div>
                            <div
                                mdDesktopOnly
                                [ngStyle]="{
                                    color: getGiftStatusColor(item)
                                }"
                            >
                                {{ item.giftCardStatus }}
                            </div>
                            <div>
                                <p mdMobileOnly>
                                    {{ "Expiration Date" | translate }}:
                                </p>
                                <p>{{ getGiftCodeDate(item.expiredAt) }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </ng-container></ng-container
        >

        <md-rewards-and-wallet-loading *ngIf="isShowDummyGiftCard" />
        <md-gift-card-balance *ngIf="showGiftBalanceView" />
    </div>
</ng-container>
<ng-container *ngIf="!userLogin">
    {{ notLoggedInUserRedirectToHomepage() }}
</ng-container>
