import { Component, HostListener, Input, OnInit } from '@angular/core';
import { screenSizes } from 'src/app/utilities/theme';
import { getUser } from '../utilities/user.utils';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from '../shared/common.service';

export enum ProfileSection {
    ORDERS,
    TRIPS,
    CHALLENGES,
    REVIEWS,
    GIFT_CARDS,
    STORE_CREDIT,
    EDIT_PROFILE,
    SETTINGS,
}

@Component({
    selector: 'md-profile-wrapper',
    templateUrl: './profile-wrapper.component.html',
    styleUrls: ['./profile-wrapper.component.scss'],
})
export class ProfileWrapperComponent implements OnInit {
    isMobile = new BehaviorSubject(false);
    userInitials = '';
    userFullName = '';
    availableBadges: number;
    balancePoints: number;

    @Input() activeSection: ProfileSection = null;

    ngOnInit(): void {
        this.checkScreenSize();
        const user = getUser();
        this.userInitials = user.firstName.charAt(0);
        this.userFullName = user.firstName + ' ' + user.lastName;
        this.availableBadges = user.availableBadges;
        this.balancePoints = user.rewardData.balancePoints;
    }

    constructor(private common: CommonService) {}

    @HostListener('window:resize')
    onResize(): void {
        this.checkScreenSize();
    }

    private checkScreenSize(): void {
        const width = window.innerWidth;
        this.isMobile.next(width <= screenSizes.mobile);
    }

    logout() {
        this.common.logout();
    }

    protected readonly ProfileSection = ProfileSection;
}
