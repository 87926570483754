<h2 class="pcl-title">{{ title }}</h2>
<swiper [config]="swiperConfig" class="pcl-presenter">
    <div class="swiper-wrapper pcl-swiper-container pcl-block">
        <div class="swiper-slide" *ngFor="let deal of productEntries">
            <md-product-card
                [condensed]="true"
                [openInNewTab]="true"
                [dealEntry]="deal"
                (dealSelected)="onDealSelected($event)"
            ></md-product-card>
        </div>
    </div>
</swiper>
