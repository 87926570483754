import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[mdSkeleton]',
})
export class SkeletonDirective {
    constructor(private el: ElementRef) {
        this.el.nativeElement.classList.add('c-sk-animated-background');
    }
}
