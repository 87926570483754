<div class="main-div">
    <div class="card new-ui">
        <div class="card-body">
            <!-- Start Email Login Form -->
            <div class="login-text" mdDesktopOnly>Welcome to MariDeal</div>
            <form [formGroup]="emailLoginForm" (ngSubmit)="onSubmitEmailLoginForm()"
                  [ngClass]="['email-login-form']"
                  *ngIf="showEmailForm">
                <div class="form-group" *ngIf="!isPasswordFieldVisible"> 
                    <label for="email"
                           [ngClass]="emailLoginForm.value.email.length > 0 ? 'has-input' : ''">{{'Email Address' |translate}}</label>
                    <input type="text"
                           id="email"
                           #email
                           formControlName="email"
                           (focusin)="emailErrorClass = ''"
                           (focusout)="validateEmail()"
                           [ngClass]="['form-control', emailErrorClass, formErrorClass]"
                           [placeholder]="'Email Address' | translate"/>
                </div>
                <div class="form-group" *ngIf="isPasswordFieldVisible">
                    <label for="password"
                           [ngClass]="emailLoginForm.value.password.length > 0 ? 'has-input' : ''">{{'Password' | translate}}</label>
                    <input type="{{showPassword ? 'text' : 'password'}}"
                           id="password"
                           #password
                           formControlName="password"
                           (focusin)="passwordErrorClass = ''"
                           (focusout)="validatePassword()"
                           [ngClass]="['form-control', passwordErrorClass, formErrorClass]"
                           [placeholder]="'Password' | translate"/>
                    <div (click)="toggleShowPassword()"
                         [ngClass]="['fa', passwordClass, 'showandhidepass']"></div>
                </div>
                <div class="content-body" *ngIf="!isPasswordFieldVisible">
                <div class="term-content form-group">
                    <span>
                    {{'By clicking an option below, I agree to the' | translate}}
                    <a href="https://marideal.freshdesk.com/support/solutions/articles/153000141319-terms-conditions"
                       target="_blank">{{'Terms of Use' | translate}}</a>
                    {{'and have read the' |translate}}
                    <a href="https://marideal.freshdesk.com/support/solutions/articles/153000141323-privacy-policy"
                       target="_blank">{{'Privacy Statement' | translate}}</a></span>
                </div>
                <div class="create-account form-group">
                    <a (click)="navigateToRegister()"
                          class="create-account">{{'Create an account' | translate}}</a>
                </div>
                </div>
                <div class="form-group">
                    <button class="btn ripleEffect" 
                        *ngIf="!isPasswordFieldVisible"
                        [disabled]="!emailLoginForm.get('email')?.valid || (isPasswordFieldVisible && emailLoginForm.get('password')?.invalid)"
                        (click)="onContinueClick()"
                        [ngClass]="{'valid-email': emailLoginForm.get('email')?.valid, 'invalid-email': !emailLoginForm.get('email')?.valid}">
                        {{'Continue' | translate}}
                    </button>
                    <button *ngIf="isPasswordFieldVisible" class="btn ripleEffect" type="submit" [ngClass]="emailLoginForm.value.email.length > 0 ? 'has-input' : ''">{{'Login' | translate}}</button>
                </div>
            </form>
            <!-- End Email Login Form -->

            <!-- Start Mobile OTP Login Form -->
            <form [formGroup]="mobileLoginForm" (ngSubmit)="onSubmitMobileLoginForm()" class="mobile-login-form"
                  *ngIf="showMobileForm">
                <div class="form-group" [ngClass]="showMobileForm && mobileLoginForm.value.mobile && mobileLoginForm.value.mobile.number.length > 0 ? 'has-input' : ''">
                    <label [ngClass]="showMobileForm && mobileLoginForm.value.mobile && mobileLoginForm.value.mobile.number.length > 0 ? 'has-input' : ''" for="mobile">{{'Mobile Number' |translate}}</label>
                    <ngx-intl-tel-input [ngClass]="['mobile-input', mobileErrorClass]"
                                        [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.Mauritius" [maxLength]="15"
                                        [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode"
                                        [numberFormat]="PhoneNumberFormat.National"
                                        [customPlaceholder]="'Mobile Number' | translate" name="mobile"
                                        (focusout)="validateMobile()"
                                        (focusin)="mobileErrorClass = ''"
                                        formControlName="mobile">
                    </ngx-intl-tel-input>
                </div>
                <div class="content-body">
                <div class="term-content form-group">
                    {{'By clicking an option below, I agree to the' | translate}}
                    <a href="https://marideal.freshdesk.com/support/solutions/articles/153000141319-terms-conditions"
                       target="_blank">{{'Terms of Use' | translate}}</a>
                    {{'and have read the' | translate}}
                    <a href="https://marideal.freshdesk.com/support/solutions/articles/153000141323-privacy-policy"
                       target="_blank">{{'Privacy Statement' | translate}}</a>.
                </div>
                <div class="create-account form-group">
                    <a (click)="navigateToRegister()"
                          class="create-account">{{'Create an account' | translate}}</a>
                </div>
                </div>
                <div class="form-group">
                    <button class="btn ripleEffect" type="submit">{{'Continue' | translate}}</button>
                </div>
            </form>
            <!-- End Mobile OTP Login Form -->

            <div class="content-body forget-pass-new-acc" *ngIf="isPasswordFieldVisible">
                <a (click)="navigateToForgottenPassword()"
                   class="forgot-password">{{'Forgot Password?' | translate}}</a>
            </div>
            <div *ngIf="!isPasswordFieldVisible">
            <p class="or-seprator">Or</p>

            <div class="continue-with-box">
                <!-- <button class="btn btn-outline-secondary social-button ripleEffect" *ngIf="showEmailForm"
                        (click)="showEmailForm = false; showMobileForm = true">
                    <div class="social-display">
                        <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.3205 26.25H2.67949C1.20513 26.25 0 25.0199 0 23.5151V2.73492C0 1.23006 1.20513 0 2.67949 0H12.3205C13.7949 0 15 1.23006 15 2.73492V23.502C15 25.0199 13.7949 26.25 12.3205 26.25ZM2.67949 1.30857C1.91026 1.30857 1.28205 1.94978 1.28205 2.73492V23.502C1.28205 24.2871 1.91026 24.9283 2.67949 24.9283H12.3205C13.0897 24.9283 13.7179 24.2871 13.7179 23.502V2.73492C13.7179 1.94978 13.0897 1.30857 12.3205 1.30857H2.67949Z" fill="#092E41"/>
                            <path d="M8.88461 3.96498H6.11538C5.75641 3.96498 5.47436 3.67709 5.47436 3.31069C5.47436 2.94429 5.75641 2.65641 6.11538 2.65641H8.88461C9.24359 2.65641 9.52564 2.94429 9.52564 3.31069C9.52564 3.67709 9.24359 3.96498 8.88461 3.96498Z" fill="#092E41"/>
                            <path d="M7.5 23.6721C7.41026 23.6721 7.33333 23.659 7.25641 23.6198C7.17949 23.5936 7.10256 23.5413 7.05128 23.4758C6.92308 23.358 6.85897 23.1879 6.85897 23.0178C6.85897 22.8477 6.92308 22.6776 7.05128 22.5598C7.10256 22.4944 7.17949 22.442 7.25641 22.4159C7.41026 22.3504 7.58974 22.3504 7.74359 22.4159C7.82051 22.442 7.89744 22.4944 7.94872 22.5598C8.07692 22.6776 8.14102 22.8477 8.14102 23.0178C8.14102 23.1879 8.07692 23.358 7.94872 23.4758C7.89744 23.5413 7.82051 23.5936 7.74359 23.6198C7.66667 23.659 7.58974 23.6721 7.5 23.6721Z" fill="#092E41"/>
                        </svg>
                        <div class="social-login">{{'Continue with Phone' | translate}}</div>
                    </div>
                </button> -->
                <button class="btn btn-outline-secondary social-button ripleEffect" *ngIf="showMobileForm"
                        (click)="showMobileForm = false; showEmailForm = true">
                    <div class="social-display">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.9995 4.7019L11.4052 9.98455C10.905 10.2747 10.0945 10.2547 9.6043 9.95453L1 4.7019" stroke="#092E41" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15.9275 1H5.07204C2.82311 1 1 2.82311 1 5.07204V15.9275C1 18.1764 2.82311 19.9995 5.07204 19.9995H15.9275C18.1764 19.9995 19.9995 18.1764 19.9995 15.9275V5.07204C19.9995 2.82311 18.1764 1 15.9275 1Z" stroke="#092E41" stroke-width="1.5" stroke-miterlimit="10"/>
                        </svg>
                        <div class="social-login">{{'Continue with Email' | translate}}</div>
                    </div>
                </button>
            </div>
            <div class="social-login-buttons">
            <md-app-social-login></md-app-social-login>
            </div>
        </div>
        </div>
    </div>
</div>

<md-modal-offcanvas #verifyOtpTemplate [mobileModalTitle]="'Confirm your code'  | translate" [closeButtonOnLeft]="true" [thinPadding]="true" [isBackbuttonShow]="false">
        <div class="card confirm-phone-code" actualControl>
        <p class="text-receive-otp m-content">{{'Enter the code we sent over SMS' | translate}} {{fullMobileNumber}}</p>
        <!-- Start OTP Form -->
        <form [formGroup]="otpForm" (ngSubmit)="verifyOtp()" class="email-login-form">

            <div class="mb-6 text-center">
                <ng-otp-input id="partitioned"
                              (onInputChange)="onOtpChange($event)"
                              allowNumbersOnly="true"
                              [config]="{length:4, placeholder:'-'}"></ng-otp-input>
            </div>

            <div class="form-group">
                <p class="text-receive-otp m-content">{{"Didn't receive OTP?" | translate}}</p>
                <a (click)="resendOtp()" class="text-receive-otp m-content">{{('Resend' | translate) ? ('Resend' | translate) : 'Resend'}}</a>
            </div>
            <div class="form-group">
                <button class="otp-submit btn ripleEffect" [ngClass]="(otpInput) ? (otpInput.length > 3 ? '' : 'disabled') : 'disabled'" type="submit">{{'Continue' | translate}}</button>
            </div>
        </form>
        <ngx-spinner id="ngx-spinner" type="ball-scale-multiple"></ngx-spinner>
        </div>
</md-modal-offcanvas>

<ng-template #otpResent let-modal>
    <div class="modal-body">
        {{'OTP resent successfully' | translate}}.
        <button
                type="button"
                class="btn btn-primary modalbtn"
                (click)="modal.close('Save click')"
                data-mdb-ripple-color="dark"
        >
            {{ "OK" | translate }}
        </button>
    </div>
</ng-template>