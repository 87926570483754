import { Component, Input } from '@angular/core';
import {
    DealFacility,
    DealRoomCategory,
} from '../../model/product-detail.model';

@Component({
    selector: 'md-deal-facilities',
    templateUrl: './deal-facilities.component.html',
    styleUrls: ['./deal-facilities.component.scss'],
})
export class DealFacilitiesComponent {
    @Input() roomCategories: DealRoomCategory[];
    @Input() facilities: DealFacility[];
}
