import {NgModule} from "@angular/core";
import {ForgetPasswordComponent} from "./forget-password.component";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxIntlTelInputModule} from "@capgo/ngx-intl-tel-input";
import {RouterModule, Routes} from "@angular/router";
import {ControlsModule} from "../controls/controls.module";
import { NgOtpInputModule } from "ng-otp-input";

const routes: Routes = [
    {
        path: '',
        component: ForgetPasswordComponent,
        data: {title: 'Forgot Password'}
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        CommonModule,
        ControlsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        NgOtpInputModule,
    ],
    declarations: [ForgetPasswordComponent],
    exports: [ForgetPasswordComponent]
})
export class ForgottenPasswordModule {

}