<ng-template #actualCalendar>
    <ngb-datepicker
        *ngIf="internalInfoForEachDay"
        #d
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="showSingleMonth ? 1 : totalMonths"
        [dayTemplate]="isSingleDate ? daySingleSelect : dayRangeSelect"
        style="border: none"
        [navigation]="'none'"
        [weekdays]="false"
        [footerTemplate]="
            !hasRenderedAsMobile && !showDesktopInPopup ? footer : undefined
        "
        [startDate]="startDate"
    >
        <ng-template ngbDatepickerContent>
            <div
                class="c-picker-header"
                [ngClass]="{
                    'on-desktop-inline':
                        !hasRenderedAsMobile && !showDesktopInPopup
                }"
            >
                <ng-container *ngIf="hasRenderedAsMobile">
                    <ng-container *ngIf="formattedFromDate">
                        <div class="c-picker-mobile-header-dates">
                            <span>{{ formattedFromDate }}</span>
                            <span> - {{ formattedToDate }}</span>
                            <span *ngIf="totalNights > 0">
                                ({{ totalNights }} nights)</span
                            >
                        </div>
                        <div class="c-picker-mobile-header-clear">
                            <button mdTextButton (click)="clearDateSelection()">
                                {{ "Clear Dates" | translate }}
                            </button>
                        </div>
                    </ng-container>
                    <div
                        *ngIf="isSingleDate"
                        class="c-picker-mobile-header-dates"
                    >
                        <span>{{ formattedSingleDate }}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!hasRenderedAsMobile">
                    <div>
                        <ng-container *ngIf="showDesktopInPopup">
                            <div class="c-picker-desktop-title">
                                {{ desktopTitle }}
                            </div>
                            <div class="c-picker-desktop-subtitle">
                                {{ desktopSubtitle }}
                            </div>
                        </ng-container>
                        <div class="c-picker-desktop-header">
                            <button
                                class="c-picker-desktop-header-button"
                                (click)="navigate(d, -1)"
                            >
                                <ion-icon
                                    name="chevron-back-outline"
                                ></ion-icon>
                            </button>
                            <span class="c-picker-desktop-header-year">
                                {{
                                    d.i18n.getMonthFullName(
                                        d.state.months[0].month
                                    )
                                }}
                                {{ d.state.months[0].year }}</span
                            >
                            <button
                                class="c-picker-desktop-header-button"
                                (click)="navigate(d, 1)"
                            >
                                <ion-icon
                                    name="chevron-forward-outline"
                                ></ion-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <div class="c-picker-mobile-header-weekday">
                    <span>{{ "M" | translate }}</span>
                    <span>{{ "T" | translate }}</span>
                    <span>{{ "W" | translate }}</span>
                    <span>{{ "T" | translate }}</span>
                    <span>{{ "F" | translate }}</span>
                    <span>{{ "S" | translate }}</span>
                    <span>{{ "S" | translate }}</span>
                </div>
            </div>
            <div
                *ngIf="hasRenderedAsMobile || showDesktopInPopup"
                class="c-picker-header-margin"
                [ngClass]="{
                    'with-date-selection': formattedFromDate,
                    'with-single-date-selection': formattedSingleDate,
                    'on-desktop-popup':
                        showDesktopInPopup && !hasRenderedAsMobile
                }"
            ></div>
            <div
                class="c-month-container"
                *ngFor="let monthStruct of d.state.months"
            >
                <h4 *ngIf="hasRenderedAsMobile">
                    {{ d.i18n.getMonthFullName(monthStruct.month) }}
                    {{ monthStruct.year }}
                </h4>
                <ngb-datepicker-month
                    class="c-picker-mobile-month"
                    [month]="monthStruct"
                ></ngb-datepicker-month>
            </div>
        </ng-template>
    </ngb-datepicker>
</ng-template>
<ng-template #dayRangeSelect let-date let-focused="focused">
    <span
        [ngbTooltip]="toolTip"
        [openDelay]="100"
        tooltipClass="c-range-tooltip"
        [class.disabled]="isDisabledDate(date)"
        class="c-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (click)="stopPropagationIfDisabled($event)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
    >
        {{ date.day }}
    </span>
    <ng-template #toolTip>
        <span class="c-picker-tooltip">{{ getTooltip(date) }}</span>
    </ng-template>
</ng-template>
<ng-template #daySingleSelect let-date>
    <span
        [ngbTooltip]="getTooltip(date)"
        [class.disabled]="isDisabledDate(date)"
        (click)="stopPropagationIfDisabled($event)"
        class="c-day"
        [class.single-date-selected]="singleDate && singleDate.equals(date)"
    >
        {{ date.day }}
    </span>
</ng-template>
<ng-template #footer>
    <div class="c-footer">
        <div class="c-footer-labels">
            <span>{{ "Check-in" | translate }}</span>
            <span>{{ "Check-out" | translate }}</span>
            <span>{{ "Night(s)" | translate }}</span>
        </div>
        <div class="c-footer-values">
            <span>{{ formattedFromDate }}</span>
            <span>{{ formattedToDate }}</span>
            <span>{{ totalNights }}</span>
        </div>
        <button mdTextButton (click)="clearDateSelection()">
            {{ "Clear Dates" | translate }}
        </button>
    </div>
</ng-template>
<ng-template #mobileCalendar let-offcanvas>
    <div
        class="c-header-container"
        [ngClass]="{ 'no-dates': !formattedFromDate }"
    >
        <div class="c-header-title-container">
            <h4 class="offcanvas-title c-header-title">
                {{ "When will you be there?" | translate }}
            </h4>
        </div>
        <button
            type="button"
            aria-label="Close"
            class="c-header-close-button"
            (click)="onModalCloseClick()"
        >
            <ion-icon name="close-circle-outline"></ion-icon>
        </button>
    </div>
    <div class="c-modal-content">
        <ng-container *ngTemplateOutlet="actualCalendar"></ng-container>
        <div class="c-modal-footer">
            <div><ng-content select="[summaryControl]"></ng-content></div>
            <div class="c-modal-footer-button">
                <button mdMainButton="dark" (click)="onModalContinueClick()">
                    {{ "Continue" | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
<div *ngIf="isInlineCalendarVisible">
    <h3 class="c-desktop-select">
        {{ "Select Check-In" | translate }}
    </h3>
    <ng-container *ngTemplateOutlet="actualCalendar"></ng-container>
</div>
<div *ngIf="showDesktopInPopup">
    <div
        [ngbPopover]="actualCalendar"
        [autoClose]="'outside'"
        triggers="manual"
        #p="ngbPopover"
        (click)="openCalendar()"
    >
        <ng-content select="[popupTargetControl]"></ng-content>
    </div>
</div>
