<footer mdMobileOnly>
    <div class="ft-container">
        <ul class="ft-row ft-border ft-row-ul">
            <li>
                <a
                    href="https://marideal.freshdesk.com/support/solutions/articles/153000141323-privacy-policy"
                    target="_blank"
                >
                    {{ "Privacy Policy" | translate }}</a
                >
            </li>
            <li>
                <a
                    href="https://marideal.freshdesk.com/support/solutions/articles/153000141327-cookie-policy"
                    target="_blank"
                    >{{ "Cookie Policy" | translate }}</a
                >
            </li>
            <li>
                <a
                    href="https://marideal.freshdesk.com/support/solutions/articles/153000141319-terms-conditions"
                    target="_blank"
                    >{{ "Terms and Conditions" | translate }}</a
                >
            </li>
        </ul>
        <div class="ft-row ft-bottom">
            <div>
                {{ "Copyright" | translate }} &#169; {{ currentYear }} &nbsp;{{
                    "MariDeal All rights reserved #Thinklocal" | translate
                }}
            </div>
        </div>
    </div>
</footer>
<footer mdDesktopOnly>
    <div class="page-footer">
        <div class="footer content">
            <div class="footer-top-links">
                <div class="footer-first-links">
                    <h5>{{ "Our Products" | translate }}</h5>
                    <ul>
                        <li>
                            <a
                                title="https://marideal.mu/plp/Stays/3?filters=38-3560"
                                href="https://marideal.mu/plp/Stays/3?filters=38-3560"
                                target="_blank"
                                rel="noopener"
                                >{{
                                    "All-Inclusive Hotel Deals" | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.mu/plp/Stays/3?filters=27-1900"
                                target="_blank"
                                rel="noopener"
                                >{{ "Beach Hotels in Mauritius" | translate }}
                            </a>
                        </li>
                        <li>
                            <a
                                title="Private Stays in Mauritius"
                                href="https://marideal.mu/plp/Stays/3?filters=7-480"
                                target="_blank"
                                rel="noopener"
                                >{{
                                    "Lodges / Villas in Mauritius" | translate
                                }}</a
                            >&nbsp;
                        </li>
                        <li>
                            <a
                                title="Day Packages&nbsp;In Mauritius"
                                href="https://marideal.mu/plp/Day%20Package/8"
                                target="_blank"
                                rel="noopener"
                                >{{
                                    "Day Packages In Mauritius" | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="Evening Packages in&nbsp;Mauritius"
                                href="https://marideal.mu/plp/Day%20Packages/8?filters=24-1300"
                                target="_blank"
                                rel="noopener"
                                >{{
                                    "Evening Packages in Mauritius" | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="Water Adventures in Mauritius"
                                href="https://marideal.mu/plp/Things%20to%20do/7"
                                target="_blank"
                                rel="noopener"
                                >{{
                                    "Sea & Water Activities in Mauritius"
                                        | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="Nature &amp; Adventure Activities&nbsp;"
                                href="https://marideal.mu/plp/Things%20to%20do/7"
                                target="_blank"
                                >{{
                                    "Nature & Adventure Activities" | translate
                                }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="footer-first-links">
                    <h5>Rodrigues</h5>
                    <ul>
                        <li>
                            <a
                                title="Best Hotels in Rodrigues&nbsp;"
                                href="https://marideal.mu/plp/Rodrigues/9?filters=36-2820"
                                target="_blank"
                                rel="noopener"
                                >{{ "Best Hotels in Rodrigues" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="Rent-a-Car in Rodrigues"
                                href="https://marideal.mu/plp/Rodrigues/9?filters=36-2860"
                                target="_blank"
                                rel="noopener"
                                >{{ "Rent-a-Car in Rodrigues" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="Best&nbsp;Excursions in Rodrigues"
                                href="https://marideal.mu/plp/Rodrigues/9?filters=36-2840"
                                target="_blank"
                                rel="noopener"
                                >{{
                                    "Best Excursions in Rodrigues" | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.mu/plp/Rodrigues/9?filters=36-2820"
                                target="_blank"
                                rel="noopener"
                                >{{
                                    "Hotels near Beach in Rodrigues" | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="Best Restaurants&nbsp;Rodrigues"
                                href="https://marideal.mu/plp/Rodrigues/9"
                                target="_blank"
                                rel="noopener"
                                >{{
                                    "Best Restaurants Rodrigues" | translate
                                }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="footer-first-links">
                    <h5>{{ "Help Desk" | translate }}</h5>
                    <ul>
                        <li>
                            <a href="https://marideal.freshdesk.com/support/solutions/153000085041"
                            target="_blank"
                            rel="noopener"
                                >FAQ</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.freshdesk.com/support/tickets/new"
                                target="_blank"
                                rel="noopener"
                                >{{ "Contact Us" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.freshdesk.com/support/home"
                                target="_blank"
                                rel="noopener"
                                >{{ "Useful Information" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.freshdesk.com/support/tickets/new?ticket_form=report_an_issue"
                                target="_blank"
                                rel="noopener"
                                >{{ "Troubleshooting" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.freshdesk.com/support/solutions/articles/153000141368-is-it-secured-to-pay-online-"
                                target="_blank"
                                rel="noopener"
                                >{{ "Billing & Payments" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.freshdesk.com/support/tickets/new?ticket_form=enquire_status"
                                target="_blank"
                                rel="noopener"
                                >{{ "Complaints Procedure" | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="footer-first-links">
                    <h5>MariDeal</h5>
                    <ul>
                        <li>
                            <a
                                href="https://marideal.freshdesk.com/support/solutions/articles/153000174517-about-us"
                                target="_blank"
                                rel="noopener"
                                >{{ "About Us" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.freshdesk.com/support/tickets/new"
                                target="_blank"
                                rel="noopener"
                                >{{ "Contact Us" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="Careers"
                                href="https://marideal.freshdesk.com/support/tickets/new?ticket_form=enquire_status"
                                target="_blank"
                                rel="noopener"
                                >{{ "Careers" | translate }}</a
                            >
                        </li>
                        <li>
                            <a href="https://magento.marideal.mu/store-locator"
                            target="_blank"
                            rel="noopener"
                            >{{
                                "Locate Us" | translate
                            }}</a>
                        </li>
                        <li>
                            <a
                                href="https://marideal.freshdesk.com/support/solutions/articles/153000141323-privacy-policy"
                                target="_blank"
                                rel="noopener"
                                >{{ "Privacy Policy" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.freshdesk.com/support/solutions/articles/153000141327-cookie-policy"
                                target="_blank"
                                rel="noopener"
                                >{{ "Cookie Policy" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.freshdesk.com/support/solutions/articles/153000141319-terms-conditions"
                                target="_blank"
                                rel="noopener"
                                >{{ "Terms & Conditions" | translate }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="footer-first-links">
                    <h5>{{ "Favorite Things" | translate }}</h5>
                    <ul>
                        <li>
                            <a
                                title="All-Inclusive Day Packages"
                                href="https://marideal.mu/plp/Day%20Package/8?filters=24-1340"
                                rel="noopener"
                                target="_blank"
                                >{{
                                    "All-Inclusive Day Packages" | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="Helicopter Tours in Mauritius"
                                href="https://marideal.mu/plp/Things%20to%20do/7?filters=41-4160"
                                rel="noopener"
                                target="_blank"
                                >{{
                                    "Helicopter Tours in Mauritius" | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.mu/plp/Things%20to%20do/7?filters=40-3920"
                                target="_blank"
                                rel="noopener"
                                >{{
                                    "Scuba Diving in Mauritius" | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="Ile Aux Cerfs Tours"
                                href="https://marideal.mu/plp/Things%20to%20do/7?filters=3-2640"
                                target="_blank"
                                rel="noopener"
                                >{{ "Ile Aux Cerfs Tours" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="https://marideal.mu/plp/Things%20to%20do/7"
                                target="_blank"
                                rel="noopener"
                                >{{
                                    "Island Tours In Mauritius" | translate
                                }}</a
                            >
                        </li>
                        <li>
                            <a
                                title="Quad Biking in Mauritius"
                                href="https://marideal.mu/plp/Things%20to%20do/7?filters=42-4380"
                                target="_blank"
                                rel="noopener"
                                >{{ "Quad Biking in Mauritius" | translate }}</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://marideal.mu/plp/Things%20to%20do/7?filters=3-2580"
                                target="_blank"
                                rel="noopener"
                                >{{ "Golf Specials in Mauritius" | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="bottom-footer">
                <div class="">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                            <h5>{{ "MariDeal On-The-Go" | translate }}</h5>
                            <p>
                                {{
                                    "Enjoy MariDeal from your Smartphone"
                                        | translate
                                }}
                            </p>
                            <div class="user-logo">
                                <ul>
                                    <li>
                                        <a href="https://play.google.com/store/search?q=marideal&c=apps" target="_blank">
                                            <img
                                                src="https://assets.marideal.mu/media/catalog/image_17.png"
                                                alt="Google Play"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://apps.apple.com/us/app/marideal/id1148891275" target="_blank">
                                            <img
                                                src="https://assets.marideal.mu/media/catalog/image_18.png"
                                                alt="Apple App Store"
                                            />
                                        </a>
                                    </li>
                                    <li >
                                        <a href="https://appgallery.huawei.com/app/C103001395" target="_blank">
                                            <img
                                                src="https://assets.marideal.mu/media/catalog/huawei-Badge-Black_1.png"
                                                alt="Huawei App Gallery"
                                            />
                                        </a>
                                        &nbsp;
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="bottom-second col-lg-4 col-md-4 col-12">
                            <h5>{{ "Contact us" | translate }}:</h5>
                            <p>
                                {{
                                    "Feel free to contact and reach us !!"
                                        | translate
                                }}
                            </p>
                            <div class="user-details">
                                <ul>
                                    <li class="set-mail">
                                        <a href="mailto:info@marideal.mu"
                                            >info&#64;marideal.mu</a
                                        >
                                    </li>
                                    <li class="set-call">
                                        <a href="tel:(230) 263 27 69"
                                            ><span
                                                class="ui-provider db b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak"
                                                dir="ltr"
                                                >(MU) 263-2769 or (RE)
                                                0262-850110</span
                                            ></a
                                        >
                                    </li>
                                    <li class="set-add">
                                        <p>
                                            3rd Floor, Roundabout, The Vale,
                                            Grandbay
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="bottom-third col-lg-2 col-md-2 col-12">
                            <h5>Follow us:</h5>
                            <ul class="social-set">
                                <li class="fb">
                                    <a
                                        href="https://www.facebook.com/MariDeal.mu?ref=h"
                                        target="_blank"
                                        rel="noopener"
                                        >facebook</a
                                    >&nbsp;
                                </li>
                                <li class="insta">
                                    <a
                                        href="https://www.instagram.com/marideal_mu/"
                                        target="_blank"
                                        rel="noopener"
                                        ><ion-icon
                                            name="logo-instagram"
                                        ></ion-icon></a
                                    >&nbsp;
                                </li>
                                <li class="linkdn">
                                    <a
                                        href="https://www.linkedin.com/company/marideal-mu"
                                        target="_blank"
                                        rel="noopener"
                                        ><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            style="margin-top: -18px"
                                            x="0px"
                                            y="0px"
                                            width="28"
                                            height="28"
                                            viewBox="0 0 26 26"
                                            fill="#adb5bd"
                                        >
                                            <path
                                                d="M 21.125 0 L 4.875 0 C 2.183594 0 0 2.183594 0 4.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 21.125 26 C 23.816406 26 26 23.816406 26 21.125 L 26 4.875 C 26 2.183594 23.816406 0 21.125 0 Z M 8.039063 22.070313 L 4 22.070313 L 3.976563 9.976563 L 8.015625 9.976563 Z M 5.917969 8.394531 L 5.894531 8.394531 C 4.574219 8.394531 3.722656 7.484375 3.722656 6.351563 C 3.722656 5.191406 4.601563 4.3125 5.945313 4.3125 C 7.289063 4.3125 8.113281 5.191406 8.140625 6.351563 C 8.140625 7.484375 7.285156 8.394531 5.917969 8.394531 Z M 22.042969 22.070313 L 17.96875 22.070313 L 17.96875 15.5 C 17.96875 13.910156 17.546875 12.828125 16.125 12.828125 C 15.039063 12.828125 14.453125 13.558594 14.171875 14.265625 C 14.066406 14.519531 14.039063 14.867188 14.039063 15.222656 L 14.039063 22.070313 L 9.945313 22.070313 L 9.921875 9.976563 L 14.015625 9.976563 L 14.039063 11.683594 C 14.5625 10.875 15.433594 9.730469 17.519531 9.730469 C 20.105469 9.730469 22.039063 11.417969 22.039063 15.046875 L 22.039063 22.070313 Z"
                                            ></path></svg></a
                                    >&nbsp;
                                </li>
                                <li class="utube">
                                    &nbsp;<a
                                        href="https://www.youtube.com/channel/UCW95wkzDif9xJDTGafDBmug"
                                        target="_blank"
                                        rel="noopener"
                                        ><ion-icon
                                            name="logo-youtube"
                                        ></ion-icon
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div mdDesktopOnly>
    <div class="footer-copyright">
        <div class="footer-copy-main content">
            <div class="copyright-left">
                <small class="copyright">
                    <span>
                        {{ "Copyright" | translate }} &#169;
                        {{ currentYear }} &nbsp;{{
                            "MariDeal All rights reserved #Thinklocal"
                                | translate
                        }}</span
                    >
                </small>
                <ul>
                    <li>
                        <a
                            class="first"
                            href="https://marideal.freshdesk.com/support/solutions/articles/153000141323-privacy-policy"
                            >{{ "Privacy Policy" | translate }}</a
                        >
                    </li>
                    <li>
                        <a
                            class="first"
                            href="https://marideal.freshdesk.com/support/solutions/articles/153000141327-cookie-policy"
                            >{{ "Cookie Policy" | translate }}</a
                        >
                    </li>
                    <li>
                        <a
                            href="https://marideal.freshdesk.com/support/solutions/articles/153000141319-terms-conditions"
                            >{{ "Terms and Conditions" | translate }}</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
