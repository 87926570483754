import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalOffcanvasServiceService {
    allOffcanvasRefs = [];
    constructor() { }

    registerNewOffcanvas(newOffcanvasRef) {
        this.allOffcanvasRefs.push(newOffcanvasRef);
    }

    dismissAllOffcanvases() {
        this.allOffcanvasRefs.forEach((offcanvasRef) => {
            offcanvasRef.dismiss();
        });
    }
}
