<div class="deals-card">
    <div mdMobileOnly>
        <ng-container *ngTemplateOutlet="mobileItem"></ng-container>
    </div>
    <div class="pli-desktop-container">
        <ng-container *ngTemplateOutlet="desktopCard"></ng-container>
    </div>
</div>

<ng-template #mobileItem>
    <swiper [config]="config">
        <ng-container
            *ngTemplateOutlet="
                imagesAndOverlays;
                context: { data: productWithReviewData }
            "
        />
        <div class="deals-data">
            <!-- <ul class="tag-list">
                <li>Hotel</li>
                <li>Beachfront</li>
                <li>Family-Friendly</li>
            </ul> -->
            <div class="title">
                <div class="title-box">
                    <div
                        class="title-rating"
                        style="display: flex; flex-wrap: wrap; width: 86%"
                    >
                        <strong
                            class="nameHotel"
                            [ngStyle]="{
                                'margin-right':
                                    productWithReviewData.starCount !== 0
                                        ? '0.5rem'
                                        : '0'
                            }"
                            >{{ productWithReviewData.name }}</strong
                        >
                        <ng-container
                            *ngTemplateOutlet="
                                ratingCircle;
                                context: { data: productWithReviewData }
                            "
                        ></ng-container>
                    </div>
                    <div
                        class="fill-ratings"
                        *ngIf="productWithReviewData.rating > 0"
                    >
                        <span class="h-hdc-rating ng-star-inserted">
                            <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6 10.0496L8.90991 11.8912C9.44281 12.2287 10.0949 11.7298 9.95468 11.0988L9.18337 7.63582L11.7567 5.3027C12.2265 4.87717 11.9741 4.07011 11.357 4.01875L7.97033 3.71794L6.64509 0.445713C6.40669 -0.148571 5.59331 -0.148571 5.35491 0.445713L4.02967 3.71061L0.642956 4.01142C0.025914 4.06278 -0.226512 4.86983 0.243281 5.29536L2.81663 7.62848L2.04532 11.0915C1.90509 11.7224 2.55719 12.2213 3.09009 11.8838L6 10.0496Z"
                                    fill="#FEBA02"
                                />
                            </svg>
                            {{ productWithReviewData.rating }}</span
                        >
                    </div>
                </div>
            </div>

            <ng-container
                *ngTemplateOutlet="
                    descriptionAndTimer;
                    context: { data: productWithReviewData }
                "
            />
            <ng-container
                *ngTemplateOutlet="
                    priceAndLocation;
                    context: { data: productWithReviewData }
                "
            ></ng-container>
        </div>
    </swiper>
</ng-template>

<ng-template #desktopCard>
    <swiper [config]="config" class="pli-desktop-swiper">
        <ng-container
            *ngTemplateOutlet="
                imagesAndOverlays;
                context: { data: productWithReviewData }
            "
        ></ng-container>
    </swiper>
    <div class="deals-data pli-desktop-description">
        <div class="deal-description">
            <!-- <ul class="tag-list">
                <li>Hotel</li>
                <li>Beachfront</li>
                <li>Family-Friendly</li>
            </ul> -->
            <div class="title">
                <div
                    class="title-rating"
                    style="display: flex; flex-wrap: wrap; width: 86%"
                >
                    <strong
                        class="nameHotel"
                        [ngStyle]="{
                            'margin-right':
                                productWithReviewData.starCount !== 0
                                    ? '0.5rem'
                                    : '0'
                        }"
                        >{{ productWithReviewData.name }}</strong
                    >
                    <ng-container
                        *ngTemplateOutlet="
                            descriptionAndTimer;
                            context: { data: productWithReviewData }
                        "
                    />
                    <div
                        class="star-ratings"
                        *ngIf="productWithReviewData.starCount !== 0"
                    ></div>
                </div>
                <div
                    class="fill-ratings"
                    *ngIf="productWithReviewData.rating > 0"
                >
                    <span class="h-hdc-rating ng-star-inserted">
                        <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6 10.0496L8.90991 11.8912C9.44281 12.2287 10.0949 11.7298 9.95468 11.0988L9.18337 7.63582L11.7567 5.3027C12.2265 4.87717 11.9741 4.07011 11.357 4.01875L7.97033 3.71794L6.64509 0.445713C6.40669 -0.148571 5.59331 -0.148571 5.35491 0.445713L4.02967 3.71061L0.642956 4.01142C0.025914 4.06278 -0.226512 4.86983 0.243281 5.29536L2.81663 7.62848L2.04532 11.0915C1.90509 11.7224 2.55719 12.2213 3.09009 11.8838L6 10.0496Z"
                                fill="#FEBA02"
                            />
                        </svg>
                        {{ productWithReviewData.rating }}</span
                    >
                </div>
            </div>
        </div>
        <ng-container
            *ngTemplateOutlet="
                priceAndLocation;
                context: { data: productWithReviewData }
            "
        ></ng-container>
    </div>
</ng-template>

<ng-template #priceAndLocation let-data="data">
    <div>
        <div class="high-demand-area" *ngIf="data.bookedMsg">
            <div class="high-demand">
                <span class="badge">{{ "In High Demand!" | translate }}</span>
                <span class="booked"> {{ data.bookedMsg }}</span>
            </div>
            <!-- <p>Booked 35 times in last 5 days</p> -->
        </div>
        <md-review-score-bar
            mdDesktopOnly
            [averageScore]="data.average"
            [label]="data.reviewData?.label"
        ></md-review-score-bar>
        <div class="row loc-div justify-content-between">
            <div class="col-6 text-start loc-div">
                <ion-icon name="location-outline"></ion-icon>
                <span> {{ data.cityLocation }}</span>
            </div>
            <div class="col-5 text-end loc-div">
                <span class="text-decoration-line-through realprice"
                    >{{
                        data.price
                            | currency
                                : currencySymbol
                                : "symbol"
                                : "1.0-2"
                                : locale
                    }}
                </span>
                <span class="disPrice">{{
                    data.actualPrice
                        | currency
                            : currencySymbol
                            : "symbol"
                            : "1.0-2"
                            : locale
                }}</span>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #ratingCircle let-data="data">
    <div class="single-chart-deal" *ngIf="data.average > 0">
        <svg viewBox="0 0 36 36" class="circular-chart orange">
            <path
                class="circle-bg"
                d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
                class="circle"
                stroke-dasharray="0, 100"
                [style.stroke-dasharray]="data.reviewData?.progVal + ', 100'"
                [style.stroke]="data.reviewData?.borderColor"
                d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <text x="18" y="18" class="percentage custom-product-detail-text-1">
                {{ data.average }}
            </text>
            <text
                x="18"
                y="25"
                class="percentage custom-product-detail-text-2-copy-1"
            >
                {{ data.reviewData?.label }}
            </text>
        </svg>
    </div>
</ng-template>
<ng-template #imagesAndOverlays let-data="data">
    <div class="swiper-wrapper">
        <img
            class="swiper-slide deals-slider"
            *ngFor="let img of data.bannerImages"
            src="assets/img/defaultIcon.png"
            [lazyLoad]="img"
            [ngStyle]="{ 'object-fit': 'cover' }"
        />
    </div>
    <div class="swiper-pagination"></div>
    <!-- ALL Inclusive banner -->
    <div
        [class.hightlightribon]="!ribbonStyleService.shouldHideRibbon()"
        [class.special-banner-container]="ribbonStyleService.getRibbonContent(productWithReviewData).type === 'image'"
        [ngStyle]="getRibbonStyles(data)"
        *ngIf="
            data.ribbon &&
            data.ribbon !== '' &&
            data.ribbon.ribbonName &&
            categoryId !== 19
            
        "
    >
        <ng-container *ngIf="ribbonStyleService.getRibbonContent(productWithReviewData).type === 'image'; else ribbonText">
            <img [src]="ribbonStyleService.getRibbonContent(productWithReviewData).value" class="special-banner-image" alt="Special Banner" />
        </ng-container>
        <ng-template #ribbonText>
            {{ data.ribbon.ribbonName.toUpperCase() }}
        </ng-template>
    </div>
    <md-date-badge
        [startDate]="data.eventStartDate"
        [endDate]="data.eventEndDate"
    />
    <md-points-discount-badge
        [pointsAsCurrency]="rewardPointsMoney"
        [priceToDeductFrom]="data.actualPrice"
    />
    <div
        class="discount"
        [ngClass]="{
            'cdl-push-discount': rewardPointsMoney
        }"
        *ngIf="(data.savedPrice / data.price) * 100 as percent"
    >
        <div *ngIf="percent > 20" class="innerDiscount innerDiscount2">
            -{{ percent | number : "2.0-0" }}
            <sup style="text-align: left">%</sup>
        </div>
    </div>
</ng-template>
<ng-template #descriptionAndTimer let-data="data">
    <div class="descrip" [innerHTML]="data.shortDescription | safeHtml">
    </div>

    <div
        *ngIf="data?.isDisplayTimer"
        class="timer-list custom-product-list-div-13"
    >
        <div class="day">
            <b class="custom-product-list-b-1">{{ data?.displayTimer }}</b>
        </div>
    </div>
</ng-template>
