import { Component, Input } from '@angular/core';
import { LocationCoordinates } from '../../model/product-detail.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'md-deal-location',
    templateUrl: './deal-location.component.html',
    styleUrls: ['./deal-location.component.scss'],
})
export class DealLocationComponent {
    @Input() set dealLocation(value: LocationCoordinates) {
        const unsanitizedUrl = `https://maps.google.com/maps?q=${value.latitude},${value.longitude}&output=embed`;
        this.mapSrc =
            this.sanitizer.bypassSecurityTrustResourceUrl(unsanitizedUrl);
    }

    mapSrc: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {}
}
