<div class="row main-div">
    <div class="fp-parent-div forgot-password card">
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmitForgotPassForm()">
            <div class="form-group">
            <p class="emailPara">
                {{'Please enter your email address below. You will receive a link to reset your password' | translate}}
            </p>
            </div>
            <div class="form-group">
                <label for="email"
                           [ngClass]="forgotPasswordForm.value.email.length > 0 ? 'has-input' : ''">{{'Email' |translate}}</label>
                <input type="email" name="" id="" formControlName="email"
                       [placeholder]="'Email Address' | translate">
            </div>
            <div class="form-group button">
                <button class="ripleEffect" type="submit">{{'Submit' | translate}}</button>
            </div>
        </form>

        <ng-template #verifyOtpModal let-modal>
            <div class="modal-header">
                <span class="popup-close-btn" (click)="modal.close('Save click')">×</span>
                <h4>{{'Please Enter the OTP to verify your account' | translate}}</h4>
            </div>
            <div class="modal-body">
                <p class="m-content">{{'OTP(one-time-password) has been sent to' | translate}} {{changeNumWithStars(forgotPasswordForm?.controls['mobile']?.value?.e164Number)}}</p>
                <!-- Start OTP Form -->
                <form class="mobile-verify-form">

                    <div class="mb-6 text-center">
                        <ng-otp-input id="partitioned"
                                      disabled="{{isOtpDisabled}}"
                                      (onInputChange)="mobileOtpInputChange($event)"
                                      allowNumbersOnly="true"
                                      [config]="{length:4, placeholder:'-'}"></ng-otp-input>
                    </div>

                    <div class="form-group">
                        <div class="m-content">{{"Didn't receive OTP?" | translate}}</div>
                        <a (click)="resendMobileOtp()">{{'Resend' | translate}}</a>
                    </div>

                    <div class="form-group">
                        <button (click)="verifyMobileOtp()" class="ripleEffect"
                                type="button">{{'Verify' | translate}}</button>
                    </div>
                </form>
                <!-- End OTP Form -->
            </div>
        </ng-template>
    </div>
</div>