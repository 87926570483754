<a
    class="h-hdc-container"
    [ngClass]="{ condensed: condensed }"
    [href]="href"
    (click)="cardClicked($event)"
    [target]="openInNewTab ? '_blank' : null"
>
    <div class="h-hdc-image">
        <ng-container *ngIf="dealEntry.bannerImages?.length > 0; else noImages">
            <img
                class="h-hdc-image"
                src="assets/img/deal_background.png"
                async
                [lazyLoad]="dealEntry.bannerImages[0]"
            />
        </ng-container>
        <ng-template #noImages>
            <img
                class="h-hdc-image"
                src="assets/img/deal_background.png"
                async
                [lazyLoad]="dealEntry.imageUrl"
            />
        </ng-template>
        <span
            class="h-hdc-discount"
            *ngIf="
                !condensed &&
                dealEntry.price !== dealEntry.specialPrice &&
                ((dealEntry.price - dealEntry.specialPrice) / dealEntry.price) *
                    100 >=
                    20
            "
            >-{{
                ((dealEntry.price - dealEntry.specialPrice) / dealEntry.price) *
                    100 | number : "2.0-0"
            }}<sup>%</sup></span
        >
    </div>
    <md-date-badge
        [startDate]="dealEntry.startDate"
        [endDate]="dealEntry.endDate"
    />
    <div class="h-hdc-text-container">
        <div class="h-hdc-title">
            <h4 class="h-hdc-title-text">{{ dealEntry.name }}</h4>
            <span class="h-hdc-rating" *ngIf="dealEntry.starCount > 0"></span>
        </div>
        <div
            class="h-hdc-description"
            [innerHTML]="dealEntry.shortDescription | safeHtml"
        ></div>
        <div>
            <span class="h-hdc-price">{{
                dealEntry.price
                    | currency : currencySymbol : "symbol" : "1.0-2" : locale
            }}</span
            ><span class="h-hdc-specialPrice">{{
                dealEntry.specialPrice
                    | currency : currencySymbol : "symbol" : "1.0-2" : locale
            }}</span>
        </div>
    </div>
</a>
