import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { getCustomerId } from 'src/app/utilities/user.utils';
import { HttpClient } from '@angular/common/http';
import {
    AddReviewData,
    AddReviewResponseDto,
    NewReviewDataResponseDto,
    ReviewDataDto,
} from './reviews.model';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
    providedIn: 'root',
})
export class ReviewsService {
    constructor(
        private httpClient: HttpClient,
        private commonService: CommonService
    ) {}

    private reviewCache: Map<string, Observable<ReviewDataDto>> = new Map();

    getProductReview(
        productId: number,
        currentPage: number
    ): Observable<ReviewDataDto> {
        const cacheKey = `${productId}-${currentPage}`;

        if (this.reviewCache.has(cacheKey)) {
            return this.reviewCache.get(cacheKey);
        }

        const apiUrl = getApiUrlMofluid();
        const customerId = getCustomerId();
        const customerGroupId = this.commonService.getCustomerGroupID();
        const storeId = this.commonService.getStoreID();
        const url = `${apiUrl}getProductReview?service=getProductReview&productid=${productId}&pagesize=10&currentpage=${currentPage}&customerid=${customerId}&store=${storeId}&customer_group_id=${customerGroupId}`;

        const reviewData$ = this.httpClient
            .get<ReviewDataDto>(url, {
                headers: this.commonService.getTokenHeaders(),
            })
            .pipe(shareReplay(1));

        this.reviewCache.set(cacheKey, reviewData$);

        return reviewData$;
    }

    getNewReviewData(productId: number): Observable<NewReviewDataResponseDto> {
        const apiUrl = getApiUrlMofluid();
        const storeId = this.commonService.getStoreID();
        const customerId = getCustomerId();
        const customerGroupId = this.commonService.getCustomerGroupID();
        const url = `${apiUrl}getNewReviewData?store=${storeId}&service=getNewReviewData&customerid=${customerId}&productid=${productId}&customer_group_id=${customerGroupId}`;

        return this.httpClient.get<NewReviewDataResponseDto>(url, {
            headers: this.commonService.getTokenJWTheaders(),
        });
    }

    addReviewData(data: AddReviewData): Observable<AddReviewResponseDto> {
        const apiUrl = getApiUrlMofluid();
        const storeId = this.commonService.getStoreID();
        const customerId = getCustomerId();
        const customerGroupId = this.commonService.getCustomerGroupID();
        const url = `${apiUrl}addProductReview?store=${storeId}&service=addProductReview&productid=${data.productId}&customerid=${customerId}&reviewdata=${data.details}&customer_group_id=${customerGroupId}`;

        return this.httpClient.get<AddReviewResponseDto>(url, {
            headers: this.commonService.getTokenJWTheaders(),
        });
    }
}
