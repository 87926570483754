import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-trips-detail',
  templateUrl: './trips-detail.component.html',
  styleUrls: ['./trips-detail.component.scss']
})
export class TripsDetailComponent implements OnInit {
  tripDetail:any;
  constructor(private common: CommonService, private actRoute: ActivatedRoute, private router: Router) {
    this.tripDetail = this.router.getCurrentNavigation().extras.state;
  }



    ngOnInit(): void {
        if (this.tripDetail) {
            localStorage.setItem('tripDetail', JSON.stringify(this.tripDetail));
        }
        this.loadTripDetail(JSON.parse(localStorage.getItem('tripDetail')));
    }

      /**
     * Method to load trip data
     * @param tripDetail
     */

       loadTripDetail(tripDetail:any) {
        this.tripDetail = tripDetail;
        console.log("trip Detail", this.tripDetail)
       };


    /**
     * Method to download PDF file
     */

    downloadTripDetailPdf (tripsProduct:any) {
        let pdfUrl = tripsProduct.tripDetail.pdf_url
        let platform = this.common.getCache(this, 'platform') ? (this.common.getCache(this, 'platform').data ? this.common.getCache(this, 'platform').data : '') : '';
        if (platform == '' || platform == null) {
            window.open(pdfUrl, "_blank")
        } else {
            if (platform == 'iOS') {
                (window as any).webkit.messageHandlers.customLinkClick.postMessage(pdfUrl);
            } else if (platform == 'android') {
                (window as any).AndroidShareHandler.customLinkClick(pdfUrl);
            }
        }
    };


}

