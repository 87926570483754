import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getApiUrlV2 } from '../utilities/url.utils';
import {
    CategoryProducts,
    ProductListDto,
    transformProductListDtoToCategoryProducts,
} from './model/category-products.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppliedFilterIds, SortLabel } from '../filter-data/filter-data.model';
import { getSortTypeAndDirection } from './category-sort-bar/category-sort-bar.utils';

export const PAGE_SIZE = 20;

@Injectable({
    providedIn: 'root',
})
export class CategoryListService {
    scrollPosition: number = 0;

    private dealCache: Map<string, CategoryProducts> = new Map();

    constructor(private httpClient: HttpClient) {}

    getCategoryDetails(
        filterIds: AppliedFilterIds[],
        sortLabel: SortLabel,
        language: 'en' | 'fr',
        sectionId: number,
        countryCode: 'MU' | 'RE' | 'OT'
    ): Observable<CategoryProducts> {
        const cacheKey = this.createDealCacheKey(
            sectionId,
            this.filterIdsToString(filterIds),
            sortLabel
        );
        const data = this.getDealCacheKey(cacheKey);

        if (data) {
            return new Observable<CategoryProducts>((observer) => {
                observer.next(data);
                observer.complete();
            });
        }

        this.scrollPosition = 0; // reset the scroll position if a new page is requested

        let paramsV2 = new HttpParams()
            .set('cc', countryCode)
            .set('lang', language)
            .set('sectionId', sectionId)
            .set('page', 1);

        if (filterIds.length > 0) {
            const filterIdsString = this.filterIdsToString(filterIds);
            paramsV2 = paramsV2.set('filters', filterIdsString);
        }

        if (sortLabel) {
            const [sortType, sortDirection] =
                getSortTypeAndDirection(sortLabel);
            paramsV2 = paramsV2
                .set('sortBy', sortType)
                .set('sortReverse', sortDirection === 'desc');
        }

        return this.httpClient
            .get<ProductListDto>(`${getApiUrlV2()}results/deals/plp`, {
                params: paramsV2,
            })
            .pipe(
                map((v2Response) => {
                    const transformedCategory =
                        transformProductListDtoToCategoryProducts(v2Response);
                    this.setDealCacheKey(cacheKey, transformedCategory);
                    return transformedCategory;
                })
            );
    }

    getMoreCategoryDetails(
        currPage: number,
        filterIds: AppliedFilterIds[],
        sortLabel: SortLabel,
        language: 'en' | 'fr',
        sectionId: number,
        countryCode: 'MU' | 'RE' | 'OT'
    ): Observable<CategoryProducts> {
        const cacheKey = this.createDealCacheKey(
            sectionId,
            this.filterIdsToString(filterIds),
            sortLabel
        );
        const data = this.getDealCacheKey(cacheKey);

        let paramsV2 = new HttpParams()
            .set('cc', countryCode)
            .set('lang', language)
            .set('sectionId', sectionId)
            .set('page', currPage);

        if (filterIds.length > 0) {
            const filterIdsString = this.filterIdsToString(filterIds);
            paramsV2 = paramsV2.set('filters', filterIdsString);
        }

        if (sortLabel) {
            const [sortType, sortDirection] =
                getSortTypeAndDirection(sortLabel);
            paramsV2 = paramsV2
                .set('sortBy', sortType)
                .set('sortReverse', sortDirection === 'desc');
        }

        return this.httpClient
            .get<ProductListDto>(`${getApiUrlV2()}results/deals/plp`, {
                params: paramsV2,
            })
            .pipe(
                map((v2Response) => {
                    const transformedCategory =
                        transformProductListDtoToCategoryProducts(v2Response);
                    if (data) {
                        data.data = data.data.concat(transformedCategory.data);
                        this.setDealCacheKey(cacheKey, data);
                        return data;
                    }
                    return transformedCategory;
                })
            );
    }

    private filterIdsToString(filterIds: AppliedFilterIds[]): string {
        return filterIds
            .map((fid) => `${fid.filterTypeId}-${fid.filterId}`)
            .join(',');
    }

    private createDealCacheKey(
        catId: number,
        encodedFilterData: string,
        sortLabel: SortLabel,
        totalRecords?: number
    ): string {
        return `category-${catId}-${encodedFilterData}-${sortLabel}-${totalRecords}`;
    }

    private getDealCacheKey(cacheKey: string): CategoryProducts {
        return this.dealCache.get(cacheKey);
    }

    private setDealCacheKey(cacheKey: string, data: CategoryProducts): void {
        this.dealCache.set(cacheKey, data);
    }
}
