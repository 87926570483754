import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'md-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent {
    @Input() cartItems: any[] = [];
    @Input() afterCartUpdated: (
        cartItems: any[],
        response: any,
        clearGuests: boolean
    ) => void;
    @Input() uncheckGiftAndApplyStoreCredit: () => void;

    @Input() cart_total_amount: number;
    @Input() coupon_code_discount: number;
    @Input() store_discount_amount: number;
    @Input() rw_discountAmount: number;
    @Input() discountCodeTitle: number;
    @Input() giftcard: number;
    @Input() gift_discount: number;
    @Input() rewardPts: number;
    @Input() servicFeeVal: number;
    @Input() totalPrice: number;
    @Input() desktopOnly: boolean = false;

    constructor(private translate: TranslateService) {}

    itemPluralMapping: { [k: string]: string } = {
        '=0': this.translate.instant(' No items in Cart'),
        '=1': this.translate.instant(' One item in Cart'),
        other: this.translate.instant(' # Items in Cart'),
    };
}
