<div *ngIf="showCartSkeleton">
    <div class="cko-page-left">
        <h2 class="c-rd-title" *ngIf="!showGiftOnly">
            1. {{ "Reservation Details" | translate }}
        </h2>
        <ng-container *ngFor="let gst of [].constructor(3)">
            <div class="c-rd-item-container">
                <div
                    mdSkeleton
                    style="
                        width: 300px;
                        height: 21px;
                        margin-bottom: 5px;
                        border-radius: 5px;
                    "
                ></div>
                <div class="input-container">
                    <div mdSkeleton class="input-1"></div>
                    <div mdSkeleton class="input-2"></div>
                </div>
                <div
                    mdSkeleton
                    style="
                        height: 16.5px;
                        width: 250px;
                        margin-bottom: 8px;
                        border-radius: 5px;
                    "
                ></div>
                <div
                    mdSkeleton
                    style="
                        height: 16.5px;
                        width: 130px;
                        margin-bottom: 20px;
                        border-radius: 5px;
                    "
                ></div>
            </div>
        </ng-container>
    </div>
    <div class="cko-right-loading">
        <div class="c-os-title-container">
            <h2 class="c-os-title">{{ "Order Summary" | translate }}</h2>
        </div>
        <div class="c-os-item-container">
            <div
                mdSkeleton
                style="
                    height: 21px;
                    width: 300px;
                    margin-bottom: 8px;
                    border-radius: 5px;
                "
            ></div>
            <div
                mdSkeleton
                style="
                    height: 18px;
                    width: 80px;
                    margin-bottom: 4px;
                    border-radius: 5px;
                "
            ></div>
            <div
                mdSkeleton
                style="
                    height: 18px;
                    width: 170px;
                    margin-bottom: 4px;
                    border-radius: 5px;
                "
            ></div>
            <div
                mdSkeleton
                style="
                    height: 18px;
                    width: 150px;
                    margin-bottom: 4px;
                    border-radius: 5px;
                "
            ></div>
            <div
                mdSkeleton
                style="
                    height: 18px;
                    width: 90px;
                    margin-bottom: 4px;
                    border-radius: 5px;
                "
            ></div>
            <div
                mdSkeleton
                style="
                    height: 18px;
                    width: 120px;
                    margin-bottom: 4px;
                    border-radius: 5px;
                "
            ></div>
        </div>
    </div>
</div>
