import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token.service';

@Injectable({
    providedIn: 'root',
})
export class TripService {
    constructor(private httpClient: HttpClient,private common: CommonService,) {}

    myPastTrip(data:any): Observable<any> {
        return this.httpClient.get(this.common.api_url+`mypasttrips?service=mypasttrips&customerid=${data.customerId}&pagesize=${data.pageSize}&currentpage=${data.currentPage}&category=${data.category}` + "&customer_group_id=" + this.common.getCustomerGroupID(), {headers: this.common.getTokenJWTheaders()});
    }

    myNewTrip(data:any): Observable<any> {
        return this.httpClient.get(this.common.api_url + `mynewtrips?service=mynewtrips&customerid=${data.customerId}&pagesize=${data.pageSize}&currentpage=${data.currentPage}&category=${data.category}` + "&customer_group_id=" + this.common.getCustomerGroupID(), {headers: this.common.getTokenJWTheaders()});
    }

    getNewInitial(): Observable<any>{
        let customer_id: string = '';
        if(localStorage.getItem('marideal-pwa_user')){
            customer_id = JSON.parse(localStorage.getItem('marideal-pwa_user')).data.id;
        }
        return this.httpClient.get(this.common.api_url +'getNewInitial?service=getNewInitial' + '&customerid=' + customer_id + "&store=" + this.common.getStoreID() + "&customer_group_id=" + this.common.getCustomerGroupID(), {headers: this.common.getTokenHeaders()});
    }

    getPastTrip(url){
        return this.httpClient.get(url, {headers: this.common.getTokenJWTheaders()});
    }

    getNewTrips(url){
        return this.httpClient.get(url, {headers: this.common.getTokenJWTheaders()});
    }

    getPastTripCount(url){
        return this.httpClient.get(url, {headers: this.common.getTokenJWTheaders()});
    }
}
