import { DealRibbon } from 'src/app/category-deals-list/model/product.model';
import { ProductCardEntry } from 'src/app/controls/product-card/product-card.component';
import { getPathFromProductUrl } from 'src/app/utilities/url.utils';

export interface GetMerchantDealsResponseDto {
    merchant_name: string;
    MerchantDetails: {
        product_id: string;
        name: string;
        product_image: string;
        product_url: string;
        price: string;
        spclprice: string;
        short_description: string;
        ribbon: DealRibbon;
    }[];
}

export interface GetMerchantDealsResponse {
    merchantName: string;
    merchantDeals: ProductCardEntry[];
}

export interface RelatedMerchantDeal {
    productId: number;
    name: string;
    productImage: string;
    productUrl: string;
    productPath: string;
    price: number;
    shortDescription: string;
    ribbon: DealRibbon;
}

export const transformGetMerchantDealsResponse = (
    response: GetMerchantDealsResponseDto
): GetMerchantDealsResponse => {
    return {
        merchantName: response.merchant_name,
        merchantDeals: response.MerchantDetails.map((deal) => {
            return {
                id: parseInt(deal.product_id),
                productUrl: deal.product_url,
                productPath: getPathFromProductUrl(deal.product_url),
                imageUrl: deal.product_image,
                name: deal.name,
                shortDescription: deal.short_description,
                specialPrice: Math.ceil(parseFloat(deal.spclprice)),
                price: Math.ceil(parseFloat(deal.price)),
                ribbon: deal.ribbon
            };
        }),
    };
};
