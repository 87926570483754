import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RewardsAndWalletService {
    private giftCardBalanceSubject: BehaviorSubject<number> =
        new BehaviorSubject<number>(0);
    private storeCreditBalanceSubject: BehaviorSubject<number> =
        new BehaviorSubject<number>(0);

    public giftCardBalance$: Observable<number> =
        this.giftCardBalanceSubject.asObservable();
    public storeCreditBalance$: Observable<number> =
        this.storeCreditBalanceSubject.asObservable();

    constructor() {}

    public updateGiftCardBalance(balance: number): void {
        this.giftCardBalanceSubject.next(balance);
    }

    public updateStoreCreditBalance(balance: number): void {
        this.storeCreditBalanceSubject.next(balance);
    }
}
