<div>
    <p
        class="ma-vi-title"
        *ngIf="
            companyProfileStatus != 'Pending' &&
            companyProfileStatus != 'Approved'
        "
    >
        {{
            "Please fill in your Company Details to be able to Generate VAT Invoices"
                | translate
        }}
    </p>
    <p
        *ngIf="
            companyProfileStatus == 'Pending' ||
            companyProfileStatus == 'Approved'
        "
    >
        {{
            "To request edit information, please contact us by calling Phone:"
                | translate
        }}
        263-2769.
    </p>
    <form action="" [formGroup]="invoiceFormGroup">
        <label class="ma-vi-label" for="personName"
            >{{ "Legal Personal Name" | translate }} *</label
        >
        <input
            class="ma-vi-input"
            type="text"
            placeholder=""
            formControlName="personName"
        />
        <label class="ma-vi-input" class="ma-vi-label" for="companyName"
            >{{ "Legal Company Name" | translate }} *</label
        >
        <input
            class="ma-vi-input"
            type="text"
            placeholder=""
            formControlName="companyName"
        />

        <label class="ma-vi-label" for="companyAddress"
            >{{ "Company Address" | translate }} *</label
        >
        <input
            class="ma-vi-input"
            type="text"
            placeholder=""
            formControlName="companyAddress"
        />

        <label class="ma-vi-label" for="companyAddress"
            >{{ "BRN" | translate }} *</label
        >
        <input
            class="ma-vi-input"
            type="text"
            placeholder=""
            formControlName="companyAddress"
        />

        <label class="ma-vi-label" for="vat">{{ "VAT" | translate }} *</label>
        <input
            class="ma-vi-input"
            type="text"
            placeholder=""
            formControlName="vat"
        />
        <div class="ma-vi-button-container">
            <button
                mdMainButton="light"
                *ngIf="!companyProfileStatus"
                (click)="saveCompanyProfile()"
            >
                {{ "Request Invoice" | translate }}
            </button>
        </div>
        <div *ngIf="companyProfileStatus" class="company-profile-div">
            <label class="company-profile-label" for="title"
                ><span>{{ "Status" | translate }}</span></label
            >
            <span class="company-profile-span">
                <span>{{ companyProfileStatus }}</span>
            </span>
        </div>
    </form>
</div>

<div class="popup-container showPopupBox" *ngIf="showPopupBox == true">
    <div class="popup">
        <div class="popup-body">
            {{ showPopupBoxMsg }}
        </div>
        <div class="popup-buttons">
            <button
                class="popup-close-btn ripleEffect"
                (click)="showPopupBox = false"
            >
                {{ "OK" | translate }}
            </button>
        </div>
    </div>
</div>
