import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { CommonService } from 'src/app/shared/common.service';

@Component({
    selector: 'md-header-search-bar',
    templateUrl: './header-search-bar.component.html',
    styleUrls: ['./header-search-bar.component.scss'],
})
export class HeaderSearchBarComponent implements OnInit {
    @Input() visibleSearchBarType:
        | 'datesGuests'
        | 'dates'
        | 'months'
        | 'plain'
        | undefined;

    @Input() catId: number;
    searchQuery = '';
    private debounceParentCall = _.debounce(this.submitSearch, 1000);

    constructor(
        public common: CommonService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.searchQuery = params['q'] || '';
        });
    }

    submitSearch(): void {
        if (this.searchQuery === '') {
            return;
        }

        this.router.navigate(['/search'], {
            queryParams: {
                q: this.searchQuery,
            },
        });
    }

    searchKeydown(): void {
        this.debounceParentCall();
    }

    clearSearch(): void {
        this.searchQuery = '';
    }
}
