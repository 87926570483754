<app-filter-data
    class="src-filters-container"
    (applyFilters)="applyFilters($event)"
    (sort)="applySorting($event)"
    [idsAlreadyAppliedFilters]="filterIdsFromQueryString2"
></app-filter-data>

<div
    infiniteScroll
    [infiniteScrollContainer]="scrollablePane"
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    [immediateCheck]="true"
    [infiniteScrollDisabled]="loading"
    [alwaysCallback]="true"
    (scrolled)="onScroll()"
    class="src-scroll-container"
>
    <md-category-sort-bar
        [showClosestDate]="false"
        [sortLabel]="sortLabel"
        (sort)="applySorting($event)"
        class="src-sort-bar"
    >
    </md-category-sort-bar>
    <!-- Loading Indicator -->
    <div *ngIf="loading">
        <md-product-list-item-loading *ngFor="let item of [].constructor(4)" />
    </div>
    <div class="err-box">
        <div *ngIf="errorMessage" class="error-message">
            {{ errorMessage }}
        </div>
        <!-- Search Results -->
        <div class="no-results" *ngIf="!loading">
            <div *ngIf="deals.length > 0; else noResults">
                <div *ngFor="let deal of deals; let i = index" (click)="redirectToProductDetails(deal)">
                    <md-product-list-item [data]="deal" [index]="index"></md-product-list-item>
                </div>
            </div>
            <ng-template #noResults>
                <p>{{ 'No results found.' | translate }}</p>
            </ng-template>
    </div>
    </div>
</div>
