import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ProductAddOnConfiguration } from './add-ons/add-ons.model';
import {
    ACTIVITY_CATEGORY,
    DAY_PACKAGE_CATEGORY,
    EVENT_CATEGORY,
    HOTEL_CATEGORY,
    MARIBNB_CATEGORY,
    RESTAURANT_CATEGORY,
    SPA_CATEGORY,
    TRAVEL_CATEGORY,
} from 'src/app/static-content/menu-routes';
import {
    ProductDetails,
    ProductServiceOption,
} from '../model/product-detail.model';

export type BookingConfigVariant =
    | 'hotel'
    | 'activity'
    | 'datedActivity'
    | 'spa'
    | 'maribnb'
    | 'travel';

export const getBookingVariantBasedOnCategory = (
    category: number,
    showCalendar: boolean
): BookingConfigVariant => {
    switch (category) {
        case HOTEL_CATEGORY:
            return 'hotel';
        case EVENT_CATEGORY:
            return 'activity';
        case ACTIVITY_CATEGORY:
        case RESTAURANT_CATEGORY: {
            return showCalendar ? 'datedActivity' : 'activity';
        }
        case DAY_PACKAGE_CATEGORY:
            return 'datedActivity';
        case SPA_CATEGORY:
            return 'spa';
        case MARIBNB_CATEGORY:
            return 'maribnb';
        case TRAVEL_CATEGORY:
            return 'hotel';
    }
};

// TODO: may need to have separate types for different booking types
export interface BookingConfiguredData {
    totalPrice?: number;
    totalFullPrice?: number;
    flightPrice?: number;
    productBookingData?: ProductBookingData;
}

export const emptyBookingConfiguredData: BookingConfiguredData = {
    totalPrice: 0,
    totalFullPrice: 0,
    productBookingData: undefined,
};

// this is a mapping of the chosen product but instead of
// containing all of the options it just contains whatever was selected
export interface ProductBookingData {
    id: number;
    type: string;
    checkinDate?: NgbDate;
    checkoutDate?: NgbDate;
    selectedDate?: NgbDate;
    totalNights?: number;
    selectedServiceOptions?: ProductServiceOption[];
    selectedServiceOptionQuantities?: Map<ProductServiceOption, number>;
    mariBnbOccupancy?: MariBnbOccupancyData;
    addOnConfigurations?: ProductAddOnConfiguration[];
}

export interface MariBnbOccupancyData {
    adults: number;
    children: number;
    infants: number;
}

export interface MinimalProductBookingData {
    id: number;
    checkinDate?: NgbDate;
    checkoutDate?: NgbDate;
    selectedDate?: NgbDate;
    selectedServiceOptions?: MinimalProductServiceOptionData[];
    selectedServiceOptionQuantities?: Map<
        MinimalProductServiceOptionData,
        number
    >;
}
export interface HotelSearchBookingData {
    fromDate: NgbDate;
    toDate: NgbDate;
    occupancyId: string;
}

export interface MinimalProductServiceOptionData {
    id: number;
    occupancyId: number;
    categoryId: number;
}

export const getProductServiceOptionsFromProductDetails = (
    productDetails: ProductDetails,
    minimalProductServiceOptions: MinimalProductServiceOptionData[]
): ProductServiceOption[] => {
    return minimalProductServiceOptions.map((option) => {
        const { occupancyId, categoryId } = option;
        const categories = productDetails.serviceOptionCategories.find(
            (option) => option.id === categoryId
        );
        const occupancy = categories.options.find(
            (option) => option.id === occupancyId
        );
        return occupancy;
    });
};

export const transformToMinimalProductBookingData = (
    productBookingData: ProductBookingData
): MinimalProductBookingData => {
    return {
        id: productBookingData.id,
        checkinDate: productBookingData.checkinDate,
        checkoutDate: productBookingData.checkoutDate,
        selectedDate: productBookingData.selectedDate,
        selectedServiceOptions: productBookingData.selectedServiceOptions
            ? productBookingData.selectedServiceOptions.map((option) => {
                  return {
                      id: option.id,
                      occupancyId: option.occupancyId,
                      categoryId: option.categoryId,
                  };
              })
            : undefined,
        selectedServiceOptionQuantities:
            productBookingData.selectedServiceOptionQuantities
                ? new Map(
                      Array.from(
                          productBookingData.selectedServiceOptionQuantities.entries()
                      ).map(([option, quantity]) => {
                          return [
                              {
                                  id: option.id,
                                  occupancyId: option.occupancyId,
                                  categoryId: option.categoryId,
                              },
                              quantity,
                          ];
                      })
                  )
                : undefined,
    };
};

export interface HodelBookingDataDto {
    qty: number;
    type: string;
    id: number;
    options: { [key: number]: string }; // TODO: This needs to be explained
    booking_from: string;
    booking_to: string;
    total_nights: number;
}

export interface MaribnbBookingDataDto extends HodelBookingDataDto {
    adults: number;
    children: number;
    infants: number;
}

export interface ProductBookingDto {
    qty: number;
    type: string;
    id: number;
    options?:
        | string
        | {
              [key: number]: number;
          };
    dropdown_options?:
        | string
        | {
              [key: number]: number;
          };
    booking_from?: string;
    booking_to?: string;
    booking_date?: string;
    total_nights: number;
    adults?: number;
    children?: number;
    infants?: number;
}

export interface AddBookingToCartResponseDto {
    status: 'success' | 'error';
    msg: string;
    quoteid: string;
    cart_items_qty: number;
    cart_items_count: number;
}

export interface RewardsResponseDto {
    amount: number;
    earning_points: number;
}

export interface RewardsData {
    amount: number;
    earningPoints: number;
}

export const transformRewardsData = (
    rewardsResponseDto: RewardsResponseDto
): RewardsData => {
    return {
        amount: rewardsResponseDto.amount,
        earningPoints: rewardsResponseDto.earning_points,
    };
};
