import { Component, OnInit } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { CommonService } from '../shared/common.service';
import { Router } from '@angular/router';
import { AppStateService } from '../shared/app-state.service';
import { NavigationItem } from '../model/menu';
import { EventService, EventType } from '../utilities/event.service';
import {
    getBadgeCount,
    getRewardPoints,
    getUser,
} from '../utilities/user.utils';
import { SectionsService } from '../shared/sections.service';

@Component({
    selector: 'md-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    sidebarItems: NavigationItem[] = [];

    sidebarOpen = false;
    showAccountLinks = false;
    isUserLoggedIn = false;
    balancePoints = 0;
    badgeCount = 0;

    constructor(
        public commonService: CommonService,
        private service: SidebarService,
        private router: Router,
        private eventService: EventService,
        private appStateService: AppStateService,
        private sectionsService: SectionsService
    ) {}

    ngOnInit(): void {
        this.service.toggleEvent.subscribe((isOpen) => {
            this.sidebarOpen = isOpen;
            if (isOpen && this.isUserLoggedIn) {
                this.balancePoints = getRewardPoints();
                this.badgeCount = getBadgeCount();
            }
        });
        this.sectionsService.getSections().subscribe((data) => {
            const { sections } = data;
            this.sidebarItems = sections.map((section) => {
                return {
                    id: section.sectionId,
                    name: section.sectionName,
                    icon: section.icon,
                    // TODO: this needs to be handled on the new plp
                    path: `/plp/${section.sectionName}/${section.sectionId}`,
                };
            });
        });
        this.isUserLoggedIn = this.appStateService.isUserLoggedIn();
        // TODO: this has to be done in a central location once the login is all figure out and data moved to the proper place
        if (this.isUserLoggedIn) {
            const userdata = getUser();
            this.commonService.side_drawer_user_initial =
                userdata.firstName?.split('')[0];
            this.commonService.side_drawer_user =
                userdata.firstName + ' ' + userdata.lastName;
            this.commonService.side_drawer_email = userdata.username
                ? userdata.username
                : userdata.email;
        }
    }

    closeSidebar() {
        this.service.toggle();
    }

    logout() {
        this.commonService.logout();
    }

    toggleAccountLinks(e: Event) {
        e.stopPropagation();
        this.showAccountLinks = !this.showAccountLinks;
    }

    numberWithCommas(x: number) {
        return new Intl.NumberFormat().format(x);
    }

    redirectToChallengesPage() {
        if (this.badgeCount != 0 || this.balancePoints != 0) {
            this.router.navigate(['/rewardpoints']);
        }
    }

    onSidebarItemClick(sidebarItem: NavigationItem, event: Event) {
        if (sidebarItem.hasChildren) {
            this.toggleChildrenVisibility(sidebarItem, event);
        } else {
            this.redirectToCategoryPage(sidebarItem, event);
        }
    }

    redirectToRodriguesPage(
        sidebarItem: NavigationItem,
        event: Event,
        subpageIndex: number
    ) {
        if (subpageIndex !== undefined) {
            this.redirectToCategoryPage(
                {
                    ...sidebarItem,
                    id: Number(sidebarItem.children[subpageIndex].id),
                },
                event,
                true
            );
        } else {
            this.redirectToCategoryPage(sidebarItem, event, true);
        }
    }

    toggleChildrenVisibility(drawerItem: NavigationItem, event: Event) {
        event.stopPropagation();
        drawerItem.areChildrenExpanded = !drawerItem.areChildrenExpanded;
    }

    redirectToCategoryPage(
        drawerItem: NavigationItem,
        event: Event,
        navigateInPlace = false
    ) {
        event.preventDefault();

        const { id, path } = drawerItem;
        this.appStateService.onRedirectToCategoryPage(id);
        const navigationParams = {
            state: {
                catId: id,
                isAll: 0, // this ended up always being 0
                advancesearch: '',
                filterdata: '',
                category_name: path,
            },
        };

        this.router.navigate([path], navigationParams);
    }

    swipeCoord: [number, number];
    swipeTime: number;

    swipe(e: TouchEvent, when: string): void {
        const coord: [number, number] = [
            e.changedTouches[0].clientX,
            e.changedTouches[0].clientY,
        ];
        const time = new Date().getTime();
        if (when === 'start') {
            this.swipeCoord = coord;
            this.swipeTime = time;
        } else if (when === 'end') {
            const direction = [
                coord[0] - this.swipeCoord[0],
                coord[1] - this.swipeCoord[1],
            ];
            const duration = time - this.swipeTime;

            if (
                duration < 1000 && //
                Math.abs(direction[0]) > 30 && // Long enough
                Math.abs(direction[0]) > Math.abs(direction[1] * 3)
            ) {
                // Horizontal enough
                // const swipe = direction[0] < 0 ? 'next' : 'previous';
                // Do whatever you want with swipe
                this.closeSidebar();
            }
        }
    }

    redirectToLogin() {
        this.eventService.emitEvent(EventType.LOGIN);
    }
}
