import {Component, OnInit} from '@angular/core';
import {appConfig} from 'src/app/config/app-config';
import {CommonService} from 'src/app/shared/common.service';
import {Router} from '@angular/router';
import {Locale} from "../../../config/locale";
import { EventService, EventType } from "../../../utilities/event.service";
import { getUser } from "../../../utilities/user.utils";
import { User } from "../../../model/user.model";

@Component({
    selector: 'app-payment-success',
    templateUrl: './payment-success.component.html',
    styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
    localeTxt: any
    orderdata: any;
    public appConfig = appConfig;
    localeBtn: any
    paymentStatus: any;
    paymentSuccessSkeleton: boolean = false;
    public loginStatus!: number;
    public loginResponse: User;
    public userLogin: boolean = false;
    public customerId: any;
    payment_method: any;
    payment_data: any;
    order_details: any;
    constructor(
        private router: Router,
        public common: CommonService,
        private eventService: EventService,
        private localeObj: Locale
    ) {
    }

    ngOnInit(): void {
        this.localeBtn = this.localeObj.getButtonMessages();
        this.localeTxt = this.localeObj.getTextMessages();
        this.checkLoginResponse();
        let userdata = getUser();
        let address = this.common.getCache(this, 'billing_address');
        let addressData = address ? JSON.parse(address.data) : "";
        let Order: any = new Object();
        let queryDataObject = this.common.getAllDataFromQueryString(false);
        if (queryDataObject) {
            this.order_details = queryDataObject ? JSON.parse(atob(queryDataObject.pdata)) : "";
            if (this.order_details != '') {
                Order.payment_method = this.order_details.payment_method;
                Order.amount = this.order_details.amount;
                Order.orderid = this.order_details.orderid;
            }
        }
        if (this.order_details.payment_method == 'mips' || this.order_details.payment_method == 'mipspop') {
            Order.orderStatus = 'Processing';
            Order.name = addressData ? (addressData.billfname + " " + addressData.billlname)  :"";
            Order.city = addressData ? addressData.billcity : "";
            Order.state = addressData ? addressData.billstate : "";
            Order.address = addressData ? (addressData.billstreet1 + " " + addressData.billstreet2) : "";
            Order.email = userdata.username;
            Order.contactno = addressData ? addressData.billphone : "";
            Order.pincode = addressData ? addressData.billpostcode : "";
            Order.transid = 111;
            Order.ship_type = 1;
            Order.recemail = userdata.username;
            this.orderdata = Order;
        }

        if (this.orderdata.address == "" || this.orderdata.address == " ") {
            this.orderdata.address = "-";
        }
        if (this.orderdata.contactno == "" || this.orderdata.contactno == " ") {
            this.orderdata.contactno = "-";
        }
        this.paymentStatus = "success";
        if (this.orderdata.payment_method == 'ipay') {
            if (this.orderdata.orderStatus == 2) {
                this.paymentStatus = "success";
            } else {
                this.paymentStatus = "failure";
            }
        }
        if (this.orderdata.payment_method == 'paypal_standard') {
            this.payment_data = JSON.parse(localStorage.getItem("payment_data"));
        }
        this.common.removeCache(this, 'cartItems');
        this.common.removeCache(this, 'cartItemsCount');
        this.common.removeCache(this, 'cartItemsdataCount');
    }

    checkLoginResponse() {
        let userdata = getUser();
        if (userdata) {
            this.loginResponse = userdata;
            this.customerId = this.loginResponse?.id;
            this.userLogin = true;
        } else {
            this.eventService.emitEvent(EventType.LOGIN)
        }
    }


    continueShoping() {
        this.common.removeCache(this, 'orderdata');
        this.router.navigate(['/']);
    }

}
