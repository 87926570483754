<div *ngIf="!isCoreValueModalOpen" class="main-div register-component-parent-div">
    <div class="register-div">
        <div class="card register-card">
            <div class="card-body">
                <form [formGroup]="registerForm" class="register-form">
                    <div class="form-group">
                        <div class="form-group first-name-group">
                            <label class="form-label-signup" for="firstName"
                                   [ngClass]="firstNameClass">{{'First Name' | translate}} </label>
                            <input type="text"
                                   (keyup)="onFirstNameChange()"
                                   id="firstName"
                                   #firstName
                                   [ngClass]="['form-control', 'from-input-signup', 'first-name', firstNameErrorClass]"
                                   (focusin)="firstNameErrorClass = ''"
                                   (focusout)="validateFirstName()"
                                   [placeholder]="'First Name' | translate"
                                   formControlName="firstName"/>
                        </div>

                        <div class="form-group last-name-group">
                            <label class="form-label-signup" for="lastName"
                                   [ngClass]="lastNameClass">{{'Last Name' | translate}} </label>
                            <input type="text"
                                   (keyup)="onLastNameChange()"
                                   id="lastName"
                                   #lastName
                                   [ngClass]="['form-control', 'from-input-signup', 'last-name', lastNameErrorClass]"
                                   (focusin)="lastNameErrorClass = ''"
                                   (focusout)="validateLastName()"
                                   [placeholder]="'Last Name' | translate"
                                   formControlName="lastName"/>

                        </div>

                        <div class="card form form-group">
                            <label class="form-label-signup custom-user-profile-label-2" for="country"
                                   [ngClass]="'has-input'">
                                {{"Country of Citizenship or Residency" | translate}}</label>
                            <select class="custom-user-profile-select-6"
                                    formControlName="country" (change)="onChangeCountry('1')">
                                <option value="">{{"Select" | translate}}</option>
                                <option *ngFor="let country of countries"
                                        [value]="country.code">{{country.name}}
                                </option>
                            </select>
                        </div>

                        <div class="card form form-group dob">
                            <label class="form-label-signup custom-user-profile-label-2" for="dob"
                                   [ngClass]="'has-input'">
                                {{"Birthdate" | translate}}</label>
                            <input
                                [ngClass]="['form-control', 'from-input-signup', 'dob', dobClass]"
                                placeholder="yyyy-mm-dd"
                                name="dob"
                                (keyup)="onDobChange()"
                                formControlName="dob"
                            />
                            <md-calendar
                                #calendarDatedActivity
                                [isSingleDate]="true"
                                [infoForEachDay]="additionalCalendarDayInfo"
                                (dateRangeSelected)="onDateRangeSelected($event)"
                                [showDesktopInPopup]="true"
                            >
                                <div
                                    popupTargetControl
                                    class="bc-dab-date-picker"
                                >
                                <button type="button" class="btn calendar gcf-calendar-button"></button>
                                    <!-- <span class="bc-dab-date-picker-value p-2">{{ formattedDate }}</span> -->
                                </div></md-calendar
                            >
                
                        </div>                     

                        <div class="card form form-group" *ngIf="shouldDesiplayOtherCountriesSelect">
                            <label class="form-label-signup custom-user-profile-label-2"
                                   [ngClass]="'has-input'">
                                {{"Select" | translate}}</label>
                            <select class="custom-user-profile-select-6"
                                    formControlName="country" (change)="onChangeCountry('2')">
                                <option value="">{{"Select" | translate}}</option>
                                <option [value]="country.value"
                                        *ngFor="let country of CountryISO|keyvalue">{{country.key}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group ">
                            <label class="form-label-signup" for="email"
                                   [ngClass]="emailClass">{{'Email Address' | translate}}</label>
                            <input type="email"
                                   id="email"
                                   #email
                                   [ngClass]="['form-control', 'from-input-signup', emailErrorClass]"
                                   (focusin)="emailErrorClass = ''"
                                   (focusout)="validateEmail()"
                                   (keyup)="onEmailChange()"
                                   [placeholder]="'Email Address' | translate" formControlName="email"/>

                        </div>

                        <div class="form-group">
                            <label class="form-label-signup" for="password"
                                   [ngClass]="passwordClass">{{'Password' | translate}}</label>
                            <input [type]="passwordInputType"
                                   id="password"
                                   #password
                                   [ngClass]="['form-control', 'from-input-signup', passwordErrorClass]"
                                   (keyup)="onPasswordChange()"
                                   (focusin)="passwordErrorClass = ''"
                                   (focusout)="validatePassword()"
                                   [placeholder]="'Password' | translate"
                                   (input)="validatePasswordStrength()"
                                   formControlName="password"/>
                            <div (click)="toggleShowPassword()"
                                 [ngClass]="['fa', passwordIconClass, 'showandhidepass']"></div>
                        </div>
                        <p *ngIf="passwordErrors.minLength" class="text-danger notification-text">Needs at least 8 characters</p>
                        <p *ngIf="passwordErrors.hasSymbolOrNumber" class="text-danger notification-text">Needs at least one symbol or number</p>
                        <p *ngIf="passwordErrors.noNameOrEmail" class="text-danger notification-text">Can’t contain your name or email address</p>
                        <p *ngIf="passwordStrength" class="text-success notification-text">Password strength: strong</p>

                        <div class="term-content">{{'By clicking an option below, I agree to the' | translate}}
                            <a class="term-condition"
                               href="https://marideal.freshdesk.com/support/solutions/articles/153000141319-terms-conditions"
                               target="_blank">{{'Terms of Use' | translate}}</a> {{'and have read the' | translate}}
                            <a class="term-condition"
                               href="https://marideal.freshdesk.com/support/solutions/articles/153000141323-privacy-policy"
                               target="_blank">{{'Privacy Statement' | translate}}</a>.
                        </div>


                        <button class="btn btn-primary sign-in submit-button ripleEffect"
                                (click)="openCoreValuesModal()"
                                [disabled]="!(registerForm.valid && passwordStrength)">
                            {{'Agree and continue' | translate}}
                        </button>

                        
                        <div class="term-content">
                            {{'Marketing Notification' | translate}}
                        </div>

                        <div class="md-filter-input marketing term-content">
                            <input
                                type="checkbox"
                                name=""
                                formControlName="receive_marketing_messages"
                            />
                            <span>{{ 'I don’t want to receive marketing messages from MariDeal' | translate}}</span>
                        </div>

                        <ng-template #verifyOtpAccount let-modal>
                            <div class="modal-header">
                                <span class="popup-close-btn" (click)="modal.close('Save click')">×</span>
                                <h4>{{'Please Enter the OTP to verify your account' | translate}}</h4>
                            </div>
                            <div class="modal-body">
                                <p class="mobile-text ">{{'OTP(one-time-password) has been sent to' | translate}}
                                    <span>{{otpMobileNumber}}</span>
                                </p>
                                <!-- Start OTP Form -->
                                <form class="email-login-form">

                                    <div class="mb-6 text-center">
                                        <ng-otp-input id="partitioned"
                                                      (onInputChange)="onOtpChange($event)"
                                                      allowNumbersOnly="true"
                                                      [config]="{length:4, placeholder:'-'}"></ng-otp-input>
                                    </div>

                                    <div class="form-group">
                                        <div>{{"Didn't receive OTP?" | translate}}</div>
                                        <a (click)="resendOtp()">{{'Resend' | translate}}</a>
                                    </div>
                                    <div class="form-group">
                                        <button class="ripleEffect" type="submit">{{'Verify' | translate}}</button>
                                    </div>
                                </form>
                                <!-- End OTP Form -->
                            </div>
                        </ng-template>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<md-modal-offcanvas #ourCoreValues [mobileModalTitle]="'Our core values'  | translate" [closeButtonOnLeft]="true" [thinPadding]="true" [isCloseButtonShow]="false" [isBackbuttonShow]="false">
    <div class="modal-body" actualControl>
        <p class="m-content blue">
            {{ 'Where guest satisfaction is our top priority and excellence is our standard.' | translate }}
        </p>
        <!-- Start OTP Form -->
        <p class="m-content">{{'Our Value' | translate}}</p>
        <!-- End OTP Form -->
        <div class="accept-core-value">
            <button class="btn btn-primary sign-in submit-button ripleEffect m-button m-bottom-20 m-bg-blue"
                (click)="submitRegistrationForm()"
                [disabled]="!registerForm.valid">
                {{'Agree and continue' | translate}}
            </button>
                <button class="btn btn-primary sign-in submit-button ripleEffect m-button m-bg-transparent m-button-grey"
                (click)="decline()"
                [disabled]="!registerForm.valid">
                {{'Decline' | translate}}
            </button>
        </div>
    </div>
</md-modal-offcanvas>