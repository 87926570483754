import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CurrencyLanguageService,
    LocaleData,
} from '../shared/currency-language.service';
import { Observable, map, switchMap } from 'rxjs';
import { SearchDatesDto, SearchResultDto } from './search.model';
import { Product } from '../category-deals-list/model/product.model';
import { DealDto } from '../model/deal.model';
import { transformDeal } from '../category-deals-list/model/category-products.model';
import { getApiUrlMofluid, getApiUrlV2 } from '../utilities/url.utils';
import { AppliedFilterIds, SortLabel } from '../filter-data/filter-data.model';
import { getSortTypeAndDirection } from '../category-deals-list/category-sort-bar/category-sort-bar.utils';
import { PAGE_SIZE } from '../category-deals-list/category-deals-list.service';
import { CommonService } from '../shared/common.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { getCustomerId } from '../utilities/user.utils';

const ACCOMMODATION_SECTION_ID = '3'; // this is hardcoded as a fallback in case the search is done from home

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    // TODO: the caching here and in the category-deals-list.service.ts is very similar. We should probably extract it into a separate service
    scrollPosition = 0;
    private dealCache: Map<string, Product[]> = new Map();
    private productIdCache: Map<string, number[]> = new Map();

    constructor(
        private httpService: HttpClient,
        private currencyLanguageService: CurrencyLanguageService,
        private commonService: CommonService
    ) {}

    searchForProductIds(
        fromDate: NgbDate,
        toDate: NgbDate,
        occupancyId: string,
        filterIds: AppliedFilterIds[],
        sectionId: number
    ): Observable<number[]> {
        const internalSectionId = sectionId
            ? sectionId.toString()
            : ACCOMMODATION_SECTION_ID;
        const cacheKey = this.createProductIdCacheKey(
            fromDate,
            toDate,
            occupancyId,
            internalSectionId,
            filterIds
        );
        const data = this.getProductIdCacheKey(cacheKey);

        if (data) {
            return new Observable<number[]>((observer) => {
                observer.next(data);
                observer.complete();
            });
        }

        const filterData = {
            checkindate: `${fromDate.day}-${fromDate.month}-${fromDate.year}`,
            checkoutdate: `${toDate.day}-${toDate.month}-${toDate.year}`,
            dealtype: occupancyId,
        };
        const encodedFilterData = btoa(JSON.stringify(filterData));
        const params = new HttpParams()
            .set('service', 'productids')
            .set('store', this.commonService.getStoreID())
            .set('categoryid', internalSectionId)
            .set('customer_group_id', this.commonService.getCustomerGroupID())
            .set('customerid', getCustomerId())
            .set('filter_data', encodedFilterData);

        return this.httpService
            .get<SearchDatesDto>(`${getApiUrlMofluid()}productids`, {
                params,
                headers: this.commonService.getTokenHeaders(),
            })
            .pipe(
                map((searchDatesDto: SearchDatesDto) => {
                    const ids = searchDatesDto.data;
                    this.setProductIdCacheKey(cacheKey, ids);
                    return ids;
                })
            );
    }

    searchProducts(
        searchQuery: string,
        productIds: number[],
        filterIds: AppliedFilterIds[],
        sortLabel: SortLabel,
        page: number,
        totalRecords: number,
        sectionId?: number
    ): Observable<{ totalHits: number; deals: Product[] }> {
        const internalSectionId = sectionId
            ? sectionId.toString()
            : ACCOMMODATION_SECTION_ID;
        const cacheKey = this.createDealCacheKey(
            searchQuery,
            productIds.join(','),
            filterIds
                .map((fid) => `${fid.filterTypeId}-${fid.filterId}`)
                .join(','),
            sortLabel,
            internalSectionId
        );
        let data = this.getDealCacheKey(cacheKey);

        if (
            (data && data.length >= PAGE_SIZE * page) ||
            (data && data.length >= totalRecords)
        ) {
            return new Observable<{ totalHits: number; deals: Product[] }>(
                (observer) => {
                    observer.next({
                        totalHits: totalRecords,
                        deals: data,
                    });
                    observer.complete();
                }
            );
        }

        this.scrollPosition = 0; // reset the scroll position if a new page is requested
        return this.currencyLanguageService.getLocaleData().pipe(
            switchMap((localeData: LocaleData) => {
                let params = new HttpParams()
                    .set('page', page)
                    .set('cc', localeData.country)
                    .set('lang', localeData.language);

                params = this.addProperParams(
                    params,
                    searchQuery,
                    productIds,
                    filterIds,
                    sortLabel,
                    internalSectionId
                );

                // TODO: this complexity will go away once we have a final API
                const url = this.getSearchUrl(searchQuery);

                return this.httpService.get<SearchResultDto>(url, {
                    params,
                });
            }),
            map((searchResult: SearchResultDto) => {
                const transformedSearchResults = {
                    totalHits: searchResult.totalHits,
                    deals: searchResult.deals.map((deal: DealDto) =>
                        transformDeal(deal)
                    ),
                };

                if (data) {
                    // the cache is not empty but a new page has been requested
                    const dataToConcat = transformedSearchResults.deals;
                    data = data.concat(dataToConcat);
                    this.setDealCacheKey(cacheKey, data);
                    return {
                        totalHits: transformedSearchResults.totalHits,
                        deals: data,
                    };
                } else {
                    // the cache is empty
                    this.setDealCacheKey(
                        cacheKey,
                        transformedSearchResults.deals
                    );
                    return transformedSearchResults;
                }
            })
        );
    }

    private getSearchUrl(searchQuery: string): string {
        return searchQuery
            ? `${getApiUrlV2()}results/search`
            : `${getApiUrlV2()}results/deals/plp`;
    }

    private addProperParams(
        params: HttpParams,
        searchQuery: string,
        productIds: number[],
        filterIds: AppliedFilterIds[],
        sortLabel: SortLabel,
        sectionId?: string
    ): HttpParams {
        if (searchQuery) {
            params = params.set('q', searchQuery);
        }
        if (productIds && productIds.length > 0) {
            const productIdsString = productIds.join(',');
            params = params
                .set('pids', productIdsString)
                .set('sectionId', sectionId);
        }
        if (filterIds.length > 0) {
            const filterIdsString = filterIds
                .map((fid) => `${fid.filterTypeId}-${fid.filterId}`)
                .join(',');
            params = params.set('filters', filterIdsString);
        }

        if (sortLabel) {
            const [sortType, sortDirection] =
                getSortTypeAndDirection(sortLabel);
            params = params
                .set('sortBy', sortType)
                .set('sortReverse', sortDirection === 'desc');
        }

        return params;
    }

    private createDealCacheKey(
        searchQuery: string,
        encodedProductIds: string,
        encodedFilterData: string,
        sortLabel: SortLabel,
        sectionId: string
    ): string {
        return `${searchQuery}-${encodedProductIds}-${encodedFilterData}-${sortLabel?.toString()}-${sectionId}`;
    }

    private getDealCacheKey(cacheKey: string): Product[] {
        return this.dealCache.get(cacheKey);
    }

    private setDealCacheKey(cacheKey: string, data: Product[]): void {
        this.dealCache.set(cacheKey, data);
    }

    private createProductIdCacheKey(
        fromDate: NgbDate,
        toDate: NgbDate,
        occupancyId: string,
        categoryId: string,
        filterIds: AppliedFilterIds[]
    ): string {
        const filterIdsString = filterIds
            .map((fid) => `${fid.filterTypeId}-${fid.filterId}`)
            .join(',');
        return `${fromDate.day}-${fromDate.month}-${fromDate.year}-${toDate.day}-${toDate.month}-${toDate.year}-${occupancyId}-${categoryId}-${filterIdsString}`;
    }

    private getProductIdCacheKey(cacheKey: string): number[] {
        return this.productIdCache.get(cacheKey);
    }

    private setProductIdCacheKey(cacheKey: string, data: number[]): void {
        this.productIdCache.set(cacheKey, data);
    }
}
