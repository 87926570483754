import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'md-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    public currentYear: number;

    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();
    }
}
