import { Component, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';

interface EventDate {
    dayName?: string;
    dayNumber?: string;
    month?: string;
}

@Component({
    selector: 'md-date-badge',
    templateUrl: './date-badge.component.html',
    styleUrls: ['./date-badge.component.scss'],
})
export class DateBadgeComponent implements OnInit {
    @Input() startDate?: Date;
    @Input() endDate?: Date;
    @Input() position: 'top-right' | 'top-left' = 'top-right';
    @Input() large = false;

    showDate = false;
    eventDate: EventDate = {};
    eventDateEnd: EventDate;

    ngOnInit(): void {
        this.showDate = this.startDate && dayjs(this.startDate).isValid();
        if (this.showDate && this.startDate) {
            const startDateDayjs = dayjs(this.startDate);
            this.eventDate = {
                dayName: startDateDayjs.format('ddd'),
                dayNumber: startDateDayjs.format('DD'),
                month: startDateDayjs.format('MMM'),
            };

            const endDateDayjs = dayjs(this.endDate);

            if (
                this.endDate &&
                endDateDayjs &&
                !endDateDayjs.isSame(startDateDayjs)
            ) {
                this.eventDateEnd = {
                    dayName: endDateDayjs.format('ddd'),
                    dayNumber: endDateDayjs.format('DD'),
                    month: endDateDayjs.format('MMM'),
                };
            }
        }
    }
}
