import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { getCustomerId } from 'src/app/utilities/user.utils';

@Injectable({
    providedIn: 'root',
})
export class InvoiceService {
    constructor(
        private httpClient: HttpClient,
        private common: CommonService
    ) {}

    getCompanyProfile() {
        return this.httpClient.get(
            getApiUrlMofluid() +
                `getcompanyprofile?service=getcompanyprofile&customerid=${getCustomerId()}` +
                '&customer_group_id=' +
                this.common.getCustomerGroupID(),
            { headers: this.common.getTokenHeaders() }
        );
    }

    submitCompanyProfile(data: any) {
        return this.httpClient.get(
            getApiUrlMofluid() +
                `savecompanydata?service=savecompanydata&customerid=${data.customerId}&legal_personal_name=${data.personName}&legal_company_name=${data.companyName}&company_address=${data.companyAddress}&brn=${data.brn}&vat=${data.vat}` +
                '&customer_group_id=' +
                this.common.getCustomerGroupID(),
            { headers: this.common.getTokenHeaders() }
        );
    }
}
