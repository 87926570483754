import { Injectable } from "@angular/core";
import { CART_ITEMS_DATA_COUNT } from 'src/app/utilities/local-storage.keys';
import { getCache } from '../utilities/cache.utils';

@Injectable({providedIn: 'root'})
export class LoginSuccessService {
    private readonly defaultLoginPath = '/rewardpoints'
    private _successRedirectPath = '/rewardpoints';

    set loginSuccessRedirectPath(path: string) {
        this._successRedirectPath = !path ? this.defaultLoginPath : path;
    }

    get loginSuccessRedirectPath(): string {
        this.checkCartAndSetRedirectPath();
        return this._successRedirectPath || this.defaultLoginPath;
    }

    private checkCartAndSetRedirectPath(): void {
        const cartItemsCount = this.getCartItemsCount();
        if (cartItemsCount > 0) {
            this._successRedirectPath = '/checkout';
        } else {
            this._successRedirectPath = this.defaultLoginPath;
        }
    }

    private getCartItemsCount(): number {
        const cartItems = getCache(CART_ITEMS_DATA_COUNT);
        const cartItemsCount = cartItems ? Number(cartItems.data) : 0;
        return cartItemsCount;
    }
}
