import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    isOpen = false;
    toggleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    toggle() {
        this.isOpen = !this.isOpen;
        this.toggleEvent.emit(this.isOpen);
        if (this.isOpen) {
            document
                .getElementsByClassName('pane')[0]
                ?.classList.add('sidebar-open');
        } else {
            document
                .getElementsByClassName('pane')[0]
                ?.classList.remove('sidebar-open');
        }
    }
}
