import { Injectable } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class WinService {

  constructor(private httpClient: HttpClient, private common: CommonService) { }

  cmsPage(){
    let customer_id: string = '';
    if(localStorage.getItem('marideal-pwa_user')){
      customer_id = JSON.parse(localStorage.getItem('marideal-pwa_user')).data.id;
    }
    return this.httpClient.get(this.common.api_url + "getWinPageData?store="+ this.common.getStoreID() +"&service=getWinPageData" + '&customerid=' + customer_id + "&customer_group_id=" + this.common.getCustomerGroupID(), {headers: this.common.getTokenHeaders()});
  }
}
