<h4 *ngIf="facilities && facilities.length > 0" class="df-title">
    {{ "What this place offers" | translate }}
</h4>
<ng-container
    *ngTemplateOutlet="facilitiesTemplate; context: { facilities: facilities }"
></ng-container>
<h4 *ngIf="roomCategories && roomCategories.length > 0" class="df-title">
    {{ "Room Categories" | translate }}
</h4>
<div class="df-room-category-container">
    <div
        *ngFor="let roomCategory of roomCategories"
        class="df-room-category-card"
    >
        <div class="df-room-category-item">
            <div class="df-room-category-image">
                <img
                    src="assets/img/deal_background.png"
                    [lazyLoad]="roomCategory.room.roomImage"
                />
            </div>
            <div class="df-room-category-facilities">
                <h4 class="df-room-category-name">
                    {{ roomCategory.room.roomName }}
                </h4>
                <ng-container
                    *ngTemplateOutlet="
                        facilitiesTemplate;
                        context: { facilities: roomCategory.facilities }
                    "
                ></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #facilitiesTemplate let-facilities="facilities">
    <div class="df-facility-container">
        <div *ngFor="let facility of facilities" class="df-facility-item">
            <div class="df-facility-icon">
                <img [src]="facility.iconUrl" />
            </div>
            <div class="df-facility-text">{{ facility.name }}</div>
        </div>
    </div>
</ng-template>
