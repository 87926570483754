import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root',
})
export class MdDeviceDetectorService {
    private readonly isAndroidInternal: boolean;
    private readonly isIosInternal: boolean;
    private readonly isBrowserInternal: boolean;

    constructor(private deviceSerivce: DeviceDetectorService) {
        const { os, browser } = deviceSerivce;
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        const platformId: 'browser' | 'android' | 'ios' = (deviceSerivce as any) // ios actually returns 'browser'
          .platformId;
        this.isAndroidInternal = os === 'Android' && (/wv/.test(userAgent) || /Android.*Version\/[0-9]+\.[0-9]+/.test(userAgent));
        this.isIosInternal = os === 'iOS' && browser === 'Unknown'; // not sure this will always be the case
        this.isBrowserInternal = platformId === 'browser' && !this.isIosInternal && !this.isAndroidInternal;

    }

    /**
     * Returns true if the code is running inside an Android app. Not in a browser on an Android device.
     */
    get isAndroid(): boolean {
        return this.isAndroidInternal;
    }

    /**
     * Returns true if the code is running inside an iOS app. Not in a browser on an iOS device.
     */
    get isIos(): boolean {
        return this.isIosInternal;
    }

    /**
     * Returns true if the device is a browser and not a mobile app.
     */
    get isBrowser(): boolean {
        return this.isBrowserInternal;
    }
}
