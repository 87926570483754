import { HttpParams } from '@angular/common/http';
import { NavigationExtras, QueryParamsHandling, Router } from '@angular/router';
import {
    AppliedFilterIds,
    SortLabel,
    SortType,
} from 'src/app/filter-data/filter-data.model';

export const getSortTypeAndDirection = (
    sortLabel: SortLabel
): [SortType, string] => {
    switch (sortLabel) {
        case 'Best Seller':
            return ['best_seller_qty', 'desc'];
        case 'Lowest Price':
            return ['price', 'asc'];
        case 'Highest Price':
            return ['price', 'desc'];
        case 'Customer Rating':
            return ['reviews_count', 'desc'];
        default:
            return ['price', 'asc'];
    }
};

export const addSortingDataToQueryString = (
    router: Router,
    sortLabel: SortLabel
) => {
    router.navigate([], {
        queryParams: { sort: sortLabel },
        queryParamsHandling: 'merge',
    });
};

export const addFilterIdsToQueryStringAndSearch = (
    router: Router,
    filterIds: AppliedFilterIds[]
) => {
    let params = new HttpParams();

    const queryParams = filterIds.length > 0
    ? {
        filters: filterIds.map((f) => `${f.filterTypeId}-${f.filterId}`).join(',')
    }
    : null;
    // Get current URL parameters and append them to HttpParams, excluding 'filters'
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    searchParams.forEach((value, key) => {
        if (key !== 'filters') {
            params = params.set(key, value);
        }
    });

    const queryParamsWithoutFilters: { [param: string]: string | string[] } = {};
    params.keys().map(key => {
        queryParamsWithoutFilters[key] = params.getAll(key).length > 1 ? params.getAll(key) : params.get(key) as string;
    });
    const navigationExtras: NavigationExtras = queryParams
        ? {
            queryParams: queryParams,
            queryParamsHandling: 'merge' as QueryParamsHandling
        }
        : {
            queryParams: queryParamsWithoutFilters
        };

    router.navigate([], navigationExtras);
};
