import { Component } from '@angular/core';

@Component({
    selector: 'md-load-more',
    template: `
        <div class="c-lm-container">
            <span mdSkeleton class="c-lm-dot"></span>
            <span mdSkeleton class="c-lm-dot"></span>
            <span mdSkeleton class="c-lm-dot"></span>
        </div>
    `,
    styleUrls: ['./load-more.component.scss'],
})
export class LoadMoreComponent {
    // Add your component logic here
}
