export interface ForgottenPasswordOtpResponse {
    status: string;
    sendOtpTo: string;
    message: string;
    customerId: string;
}

export interface ForgottenPasswordOtpResponseDto {
    status: string;
    send_otp_to: string;
    message: string;
    dummy_customer_id: string;
}

export const transform = (raw: ForgottenPasswordOtpResponseDto): ForgottenPasswordOtpResponse => {
    return {
        status: raw.status,
        sendOtpTo: raw.send_otp_to,
        message: raw.message,
        customerId: raw.dummy_customer_id
    } as ForgottenPasswordOtpResponse;
}