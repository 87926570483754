import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { Router } from '@angular/router';
import { DeviceCommsService } from 'src/app/services/device-comms.service';
import { untranslatedMenuNames } from 'src/app/static-content/menu-routes';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import * as Sentry from '@sentry/angular-ivy';
import { User } from 'src/app/model/user.model';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { getUser } from 'src/app/utilities/user.utils';
import {
    BookingsService,
    InvoicePdfUrl,
    Order,
    OrderProduct,
} from './bookings.service';
import { ProfileSection } from '../../profile-wrapper.component';
import { ModalOffcanvasComponent } from 'src/app/controls/modal-offcanvas/modal-offcanvas.component';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'md-bookings',
    templateUrl: './bookings.component.html',
    styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent implements OnInit {
    imgCdn = '';
    loginStatus!: number;
    loginResponse: User;
    userLogin: boolean = false;
    customerId: any;
    pageSize: number = 20;
    currentpage: number = 1;
    paginationLimit!: number;
    ordersArray: Order[] = [];
    ordersToShowArray: Order[] = [];
    totalOrders: number = 0;
    ordersToShow: 'active' | 'past' | 'cancelled' = 'active';
    modalContent:
        | 'bookingActions'
        | 'bookingDetails'
        | 'requestVat'
        | 'requestedVat'
        | '' = '';
    isOrdersData: boolean = false;
    companyProfileData: any;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    selectedProduct: OrderProduct;
    selectedOrder: Order;

    constructor(
        private eventService: EventService,
        private bookingsService: BookingsService,
        private deviceCommsService: DeviceCommsService,
        private currencyLanguageService: CurrencyLanguageService,
        private common: CommonService,
        private router: Router,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.checkLoginResponse();
        this.currencyLanguageService
            .getCurrency()
            .subscribe((currencySymbol) => {
                this.currencySymbol = currencySymbol;
            });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    checkLoginResponse() {
        let userdata = getUser();
        if (userdata) {
            this.loginResponse = userdata;
            this.customerId = this.loginResponse?.id;
            this.userLogin = true;
            this.getAllOrders();
        } else {
            this.eventService.emitEvent(EventType.LOGIN);
            return false;
        }
    }

    getModalTitle() {
        switch (this.modalContent) {
            case 'bookingActions':
                return this.translate.instant('Booking Details');
            case 'bookingDetails':
                return this.translate.instant('View Booking');
            case 'requestVat':
                return this.translate.instant('Request VAT Invoice');
            case 'requestedVat':
                return this.translate.instant('Request VAT Invoice');
        }
    }

    showDate(product: OrderProduct) {
        const { check_ins, check_outs } = product;
        return check_ins && check_outs;
    }

    createProductDatesString(product: OrderProduct) {
        const checkInDate = product.check_ins;
        const checkOutDate = product.check_outs;
        if (checkInDate && checkOutDate) {
            return `${dayjs(checkInDate).format('MMM DD')} - ${dayjs(
                checkOutDate
            ).format('MMM DD, YYYY')}`;
        }
        return '';
    }

    switchOrdersToShow(ordersToShow: 'active' | 'past' | 'cancelled') {
        this.ordersToShow = ordersToShow;
        this.currentpage = 1;
    }

    formatDate(date: Date): string {
        return dayjs(date).format('MMM DD, YYYY');
    }

    changeOrdersToShow(ordersToShow: 'active' | 'past' | 'cancelled') {
        this.ordersToShow = ordersToShow;
        this.ordersToShowArray = this.filterOrdersBasedOnOrdersToShow();
    }

    filterOrdersBasedOnOrdersToShow(): Order[] {
        if (this.ordersToShow === 'active') {
            return this.ordersArray
                .filter((order) => {
                    return order.product.some((product) => {
                        return this.isActiveProduct(product, order);
                    });
                })
                .map((order) => {
                    return {
                        ...order,
                        product: order.product.filter((product) => {
                            return this.isActiveProduct(product, order);
                        }),
                    };
                });
        } else if (this.ordersToShow === 'past') {
            return this.ordersArray
                .filter((order) => {
                    return order.product.some((product) => {
                        return this.isPastProduct(product, order);
                    });
                })
                .map((order) => {
                    return {
                        ...order,
                        product: order.product.filter((product) => {
                            return this.isPastProduct(product, order);
                        }),
                    };
                });
        } else if (this.ordersToShow === 'cancelled') {
            return this.ordersArray.filter((order) => {
                return this.isCancelledOrder(order);
            });
        }
    }

    private isActiveProduct(product: OrderProduct, order: Order) {
        const expiry = product.expiry || product.check_outs;
        const isCancelled = this.isCancelledOrder(order);
        return dayjs(expiry).isAfter(dayjs()) && !isCancelled;
    }

    private isPastProduct(product: OrderProduct, order: Order) {
        const expiry = product.expiry || product.check_outs;
        const isCancelled = this.isCancelledOrder(order);
        return dayjs(expiry).isBefore(dayjs()) && !isCancelled;
    }

    private isCancelledOrder(order: Order) {
        return order.status === 'Cancelled';
    }

    getAllOrders(scroll: boolean = false) {
        if (scroll) {
            this.currentpage += 1;
        }
        this.isOrdersData = true;
        this.bookingsService
            .getAllOrders(
                this.customerId,
                'all',
                this.pageSize,
                this.currentpage
            )
            .subscribe(
                (response) => {
                    this.common.endProgress();
                    this.isOrdersData = false;
                    this.ordersArray = response.data;
                    this.ordersToShowArray =
                        this.filterOrdersBasedOnOrdersToShow();
                    this.companyProfileData = response.company_profile_data;
                    this.common.setCache(
                        this,
                        'company_profile_data',
                        this.companyProfileData
                    );
                    this.paginationLimit = Math.ceil(
                        response.total / this.pageSize
                    );
                    this.totalOrders = response.total;
                },
                (error) => {
                    this.common.endProgress();
                    this.isOrdersData = false;
                }
            );
    }

    onScrollGetAllOrders() {
        if (this.currentpage < this.paginationLimit) {
            this.currentpage += 1;
            this.common.startProgress();
            this.bookingsService
                .getAllOrders(
                    this.customerId,
                    'all',
                    this.pageSize,
                    this.currentpage
                )
                .subscribe(
                    (response: any) => {
                        this.common.endProgress();
                        this.ordersArray.push(response.data);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    downloadOrderPdfClick(tripsProduct: { pdf_url: InvoicePdfUrl[] }) {
        const { pdf_url } = tripsProduct;
        const firstPdf = pdf_url[0];
        if (firstPdf) {
            this.deviceCommsService.openLinkInNewTabOrBrowser(firstPdf.url);
        } else {
            Sentry.captureMessage('NO-PDF-URL-FOUND');
        }
    }

    vatInvoiceClick(
        invoice_status: any,
        company_profile_data: any,
        orders: any
    ) {
        if (invoice_status == 'Approved') {
            let order_id = orders.id;
            this.router
                .navigate(['companyprofile/index/invoiceform/' + order_id], {
                    queryParams: {
                        // "order_id": order_id,
                        // "company_profile_data": company_profile_data
                    },
                })
                .then(() => {
                    window.location.reload();
                });
        } else {
            this.router.navigate(['companyprofile/index/index']).then(() => {
                window.location.reload();
            });
        }
    }

    getCategoryName(item: any) {
        if (item.category_id.length) {
            let catId = item.category_id[0];
            return untranslatedMenuNames.get(catId);
        } else if (item.sku == 'gift-voucher') {
            return 'Gift Cards';
        }
    }

    notLoggedInUserRedirectToHomepage() {
        this.router.navigate(['/']);
    }

    openModal(content: ModalOffcanvasComponent): void {
        content.open();
    }
    closeModal(content: ModalOffcanvasComponent): void {
        this.modalContent = '';
        content.close();
    }
    onVatInvoiceRequestSent() {
        this.modalContent = 'requestedVat';
    }

    itemPluralMapping: { [k: string]: string } = {
        '=1': this.translate.instant(' One night'),
        other: this.translate.instant(' # nights'),
    };

    protected readonly ProfileSection = ProfileSection;
}
