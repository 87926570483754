import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[mdTextButton]',
})
export class TextButtonDirective {
    constructor(private el: ElementRef) {
        this.el.nativeElement.classList.add('c-tb-text-button');
    }
}
