import { Component } from '@angular/core';

@Component({
    selector: 'md-my-badges-loading',
    template: `
        <div
            *ngFor="let row of [].constructor(3)"
            style="display: flex; flex-direction: row; margin-bottom: 40px; margin-top: 46px"
        >
            <div
                mdDesktopOnly
                *ngFor="let item of [].constructor(4)"
                mdSkeleton
                class="item"
            ></div>

            <div
                mdMobileOnly
                *ngFor="let item of [].constructor(3)"
                mdSkeleton
                class="item"
            ></div>
        </div>
    `,
    styles: [
        `
            @import 'src/theme.scss';

            .item {
                width: calc(25% - 69px);
                aspect-ratio: 164/184;
                margin-right: 92px;
                border-radius: 15px;

                &:last-child {
                    margin-right: 0;
                }

                @media (max-width: $mobile-width - 1) {
                    width: calc(33.33% - 18px);
                    margin-right: 27px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            /* Your SCSS styles go here */
        `,
    ],
})
export class MyBadgesLoadingComponent {
    // Component logic goes here
}
