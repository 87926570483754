import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { CommonService } from 'src/app/shared/common.service';
import { getCustomerId } from 'src/app/utilities/user.utils';
import { Observable, map } from 'rxjs';
import {
    OccupancyOption,
    OccupancyOptionsDto,
    transformOccupancyOptions,
} from './search-occupancy.model';

@Injectable({
    providedIn: 'root',
})
export class SearchOccupancyService {
    constructor(private http: HttpClient, private common: CommonService) {
        // Constructor logic here
    }

    getOccupancyOptions(): Observable<OccupancyOption[]> {
        const params = new HttpParams()
            .set('store', this.common.getStoreID())
            .set('service', 'occupencies')
            .set('customerid', getCustomerId())
            .set('customer_group_id', this.common.getCustomerGroupID());

        return this.http
            .get<OccupancyOptionsDto>(`${getApiUrlMofluid()}occupencies`, {
                params,
                headers: this.common.getTokenHeaders(),
            })
            .pipe(
                map((occupancyOptionsDto: OccupancyOptionsDto) =>
                    transformOccupancyOptions(occupancyOptionsDto)
                )
            );
    }
}
