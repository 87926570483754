<swiper
    *ngIf="!isLoading && isVisible"
    class="swiper-container dr-container"
    [config]="config"
    #usefulSwiper
>
    <div class="swiper-wrapper">
        <div
            class="swiper-slide"
            *ngFor="let drawerItem of drawerItems"
            [ngClass]="{
                'active-slide': drawerItem.id.toString() === sectionId
            }"
        >
            <a
                [routerLink]="[drawerItem.path]"
                class="dr-slide"
                (click)="redirectToCategoryPage(drawerItem, $event)"
            >
                <img
                    class="dr-icon"
                    [src]="drawerItem.icon"
                    alt="{{ drawerItem.name }}"
                />
                <div class="dr-text">
                    {{ drawerItem.name }}
                </div>
            </a>
        </div>
    </div>
</swiper>
<div *ngIf="isLoading && isVisible" class="dr-container dr-loading-container">
    <div class="swiper-wrapper">
        <div *ngFor="let skeleton1 of loadingDrawerItemPlaceholders">
            <div class="dr-slide">
                <div mdSkeleton class="dr-icon dr-loading-icon"></div>
                <div mdSkeleton class="dr-loading-text"></div>
            </div>
        </div>
    </div>
</div>
