import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export interface EGiftCardFormData {
    value: number;
    recipientName: string;
    deliveryToEmail: string;
    deliveryFromName: string;
    deliveryMessage: string;
    quantity: number;
    deliveryDate: NgbDateStruct;
}

export interface GiftCardImage {
    name: string;
    path: string;
    thumb: string;
}

export interface EGiftCardTemplate {
    id: number;
    name: string;
    position: number;
    images: GiftCardImage[];
}

export interface GiftCardValidation {
    maxMessageLength: number;
    giftCardAmount: { min: number; max: number };
}

export interface EGiftCardDetailsResponse extends GiftCardValidation {
    gitftcardTemplates: EGiftCardTemplate[];
    maxImageUploadSizeKb: number;
    id: string;
}

export interface EGiftCardTemplateDto {
    giftcard_template_id: number;
    images: {
        img_name: string;
        img_path: string;
    }[];
    position: number;
    template_name: string;
}

export interface EGiftCardDetailsResponseDto {
    gift_card_templates: EGiftCardTemplateDto[];
    custom_img_data_size: string;
    msg_max_length: number;
    gift_card_amount: {
        from: number;
        to: number;
    };
    id: string;
}

export interface EGiftAddToCartDto {
    id: string;
    price_amount: number;
    type: 'giftvoucher';
    amount: number;
    giftcard_template_id: number;
    giftcard_template_image: string;
    giftcard_use_custom_image: '0' | '1';
    send_friend: '0' | '1';
    customer_name: string;
    recipient_name: string;
    recipient_email: string;
    message: string;
    notify_success: '0' | '1';
    day_to_send: string; // mm-dd-yyyy
    timezone_to_send: 'Indian/Mauritius';
    qty: number;
}

// TODO: I think this is actually just a generic response object but will have to figure out as the refactoring progresses
export interface AddEGiftCardToCartResponseDto {
    status: 'success' | 'error';
    msg: string;
    quoteid: string;
    cart_items_qty: number;
}

export interface UploadGcImageDto {
    updated_filename: string;
    url: string;
}

export const transform = (
    dao: EGiftCardDetailsResponseDto
): EGiftCardDetailsResponse => {
    return {
        gitftcardTemplates: dao.gift_card_templates.map((template) =>
            transformCardTemplate(template)
        ),
        maxImageUploadSizeKb: parseInt(dao.custom_img_data_size),
        maxMessageLength: dao.msg_max_length,
        giftCardAmount: {
            min: dao.gift_card_amount.from,
            max: dao.gift_card_amount.to,
        },
        id: dao.id,
    };
};

const transformCardTemplate = (
    dao: EGiftCardTemplateDto
): EGiftCardTemplate => {
    return {
        id: dao.giftcard_template_id,
        name: dao.template_name,
        position: dao.position,
        images: dao.images.map((image) => ({
            name: image.img_name,
            path: image.img_path,
            thumb: image.img_path.replace(
                image.img_name,
                `thumbnail/${image.img_name}`
            ),
        })),
    };
};
