import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { getCustomerId } from 'src/app/utilities/user.utils';
import { BillingAddressDto } from './checkout.model';

@Injectable({
    providedIn: 'root',
})
export class CheckoutService {
    constructor(
        private httpClient: HttpClient,
        private commonService: CommonService
    ) {}

    serviceMethod(url: string) {
        return this.httpClient.get(url, {
            headers: this.commonService.getTokenJWTheaders(),
        });
    }

    getCountries(url: string) {
        return this.httpClient.get(url, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    /**
     * Method to fetch all countries
     */

    fetchCountries() {
        const api_url =
            getApiUrlMofluid() +
            'getAllCountries?store=' +
            this.commonService.getStoreID() +
            '&currency=MUR&service=getAllCountries&customer_group_id=' +
            this.commonService.getCustomerGroupID();
        return this.httpClient.get(api_url, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    getAllCartItems() {
        const quoteid = !localStorage.getItem('quoteid')
            ? null
            : localStorage.getItem('quoteid');

        const getCartItem =
            getApiUrlMofluid() +
            'getAllCartItems?store=' +
            this.commonService.getStoreID() +
            '&currency=MUR&service=getAllCartItems&customerid=' +
            getCustomerId() +
            '&quoteid=' +
            quoteid +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();

        return this.httpClient.get(getCartItem, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    updateProfile(billingAddress: BillingAddressDto, email: string) {
        const encodedBillingAddress = this.commonService.encode(
            JSON.stringify(billingAddress)
        );

        const url =
            getApiUrlMofluid() +
            'mofluidUpdateProfile?service=mofluidUpdateProfile&customerid=' +
            getCustomerId() +
            '&billaddress=' +
            encodedBillingAddress +
            '&shippaddress=' +
            '' +
            '&profile=' +
            email +
            '&shipbillchoice=billingaddress' +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();

        return this.httpClient.get(url, {
            headers: this.commonService.getTokenJWTheaders(),
        });
    }

    getCustomerAddress() {
        const profileUrl =
            getApiUrlMofluid() +
            'myprofile?service=myprofile&customerid=' +
            getCustomerId() +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();

        return this.httpClient.get(profileUrl, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    getGiftCardCredit() {
        const gift_card_credit_url =
            getApiUrlMofluid() +
            'getGiftCardTotalBalance?store=' +
            this.commonService.getStoreID() +
            '&service=getGiftCardTotalBalance&customerid=' +
            getCustomerId() +
            '&currency=MUR' +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();

        return this.httpClient.get(gift_card_credit_url, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    getPaymentMethods() {
        const paymentMthdUrl =
            getApiUrlMofluid() +
            'getpaymentmethod?service=getpaymentmethod&customerid=' +
            getCustomerId() +
            '&ismips=1' +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();

        return this.httpClient.get(paymentMthdUrl, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    updateCart(item: any) {
        const quoteid = !localStorage.getItem('quoteid')
            ? null
            : localStorage.getItem('quoteid');

        const updatecartqty =
            getApiUrlMofluid() +
            'updateCartQty?store=' +
            this.commonService.getStoreID() +
            '&currency=MUR&service=updateCartQty&customerid=' +
            getCustomerId() +
            '&quoteid=' +
            quoteid +
            '&cart_item_id=' +
            item.cart_item_id +
            '&qty=' +
            item.qty +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();

        return this.httpClient.get(updatecartqty, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    removeItemFromCart(item: any) {
        const quoteid = !localStorage.getItem('quoteid')
            ? null
            : localStorage.getItem('quoteid');
        const removecartitem =
            getApiUrlMofluid() +
            'removeCartItem?store=' +
            this.commonService.getStoreID() +
            '&currency=MUR&service=removeCartItem&customerid=' +
            getCustomerId() +
            '&quoteid=' +
            quoteid +
            '&cart_item_id=' +
            item.cart_item_id +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();

        return this.httpClient.get(removecartitem, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    getRewardsPrice(
        usedRewardPoints: number,
        cartTotalAmount: number,
        giftDiscount: number,
        couponCodeDiscount: number,
        giftValueUsed: number,
        storeDiscountAmount: number
    ) {
        const rewardPriceUrl =
            this.commonService.api_url +
            'getRewardPrice?store=' +
            this.commonService.getStoreID() +
            '&currency=MUR' +
            '&service=getRewardPrice&spending_points=' +
            usedRewardPoints +
            '&subtotal_amount=' +
            cartTotalAmount +
            '&giftcard_discount=' +
            giftDiscount +
            '&coupon_discount=' +
            couponCodeDiscount +
            '&gift_card_credit=' +
            giftValueUsed +
            '&store_credit=' +
            storeDiscountAmount +
            '&customerid=' +
            getCustomerId() +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();
        return this.httpClient.get(rewardPriceUrl, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    getCustumerPointsAndBalance() {
        return this.httpClient.get(
            getApiUrlMofluid() +
                'getCustumerPointsAndBalance?store=' +
                this.commonService.getStoreID() +
                '&currency=MUR&service=getCustumerPointsAndBalance&customerid=' +
                getCustomerId() +
                '&is_app_login=0&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            { headers: this.commonService.getTokenJWTheaders() }
        );
    }
}
