<div class="rs-container" [ngClass]="{ large: large }" (click)="onClick()">
    <div
        class="rs-review-progress"
        [ngClass]="{ large: large }"
        *ngIf="averageRating > 0 || large"
    >
        <svg viewBox="0 0 36 36" class="rs-review-progress-svg">
            <path
                class="rs-progress-bg"
                d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
                class="rs-progress"
                [attr.stroke]="strokeColor"
                [style.stroke-dasharray]="strokeDasharray"
                d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <text
                x="18"
                y="18"
                class="rs-progress-text rs-progress-rating"
                [ngClass]="{ large: large }"
            >
                {{ trimmedAverageRating }}
            </text>
            <text
                x="18"
                y="25"
                class="rs-progress-text rs-progress-label"
                [ngClass]="{ large: large }"
            >
                {{ ratingLabel | translate }}
            </text>
        </svg>
    </div>
    <div *ngIf="!large && showReviews">
        <div
            class="rs-review-count"
            *ngIf="reviewCount > 1 || reviewCount === 0"
        >
            {{ reviewCount }} {{ "reviews" | translate }}
        </div>
        <div class="rs-review-count" *ngIf="reviewCount === 1">
            {{ reviewCount }} {{ "review" | translate }}
        </div>
    </div>
</div>
