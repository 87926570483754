import { Component, Input, OnInit } from '@angular/core';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

@Component({
    selector: 'md-subtotal',
    templateUrl: './subtotal.component.html',
    styleUrls: ['./subtotal.component.scss'],
})
export class SubtotalComponent implements OnInit {
    @Input() condensed = false;
    @Input() set price(value: number) {
        if (value === undefined) return;
        this.internalPrice = value;
        this.calculateDiscount();
    }
    @Input() set fullPrice(value: number) {
        if (value === undefined) return;
        this.internalFullPrice = value;
        this.calculateDiscount();
    }

    internalPrice = 0;
    internalFullPrice = 0;

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    discountValue: number;
    discountPercentage: number;

    constructor(private currencyLanguageService: CurrencyLanguageService) {}
    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    private calculateDiscount() {
        if (!this.internalFullPrice || !this.internalPrice) return;
        this.discountValue = this.internalFullPrice - this.internalPrice;
        this.discountPercentage = Math.round(
            (this.discountValue / this.internalFullPrice) * 100
        );
    }
}
