<md-modal-offcanvas #login [mobileModalTitle]="'Log In' | translate" [closeButtonOnLeft]="true"  [thinPadding]="true" [isBorderRadius]="true" (modalBackButtonClicked)="showEmail()" [isBackbuttonShow]="showBackButton">
    <div actualControl>
        <md-app-login (showBackButton)="enableBackButton()"></md-app-login>
        <ngx-spinner id="ngx-spinner" type="ball-scale-multiple"></ngx-spinner>
    </div>
</md-modal-offcanvas>

<md-modal-offcanvas #register [mobileModalTitle]="'Sign Up' | translate" [closeButtonOnLeft]="true" [thinPadding]="true" [isBorderRadius]="true" (modalBackButtonClicked)="navigateToLogin()" [isBackbuttonShow]="false">>
    <div actualControl>
        <md-app-register></md-app-register>
        <ngx-spinner id="ngx-spinner" type="ball-scale-multiple"></ngx-spinner>
    </div>
</md-modal-offcanvas>

<md-modal-offcanvas #forgottenPassword [mobileModalTitle]="'Forgot Password?' | translate" [closeButtonOnLeft]="true"  [thinPadding]="true" [isBorderRadius]="true" (modalBackButtonClicked)="navigateToLogin()">
    <div actualControl>
        <md-forget-password></md-forget-password>
        <ngx-spinner id="ngx-spinner" type="ball-scale-multiple"></ngx-spinner>
    </div>
</md-modal-offcanvas>

<md-modal-offcanvas #welcomeMessage [mobileModalTitle]="'Welcome to MariDeal'  | translate: { userFullName: userFullName }" [closeButtonOnLeft]="true" [thinPadding]="true" [isBorderRadius]="true">
    <div actualControl>
        <!-- <div class="main-div register-component-parent-div"> -->
        <p class="m-sub-title m-text-center">{{'Create your profile' | translate}}</p>
        <p class="m-content m-text-center m-p-80">{{'There are places that needs to be discovered, felt and lived. This is how memories are created and cherished lifetime.' | translate}}</p>
        <button class="btn btn-primary sign-in submit-button ripleEffect m-button m-bottom-20 m-bg-blue"
        (click)="navigateToProfile()"
            >
            {{'Continue' | translate}}
        </button>
        <ngx-spinner id="ngx-spinner" type="ball-scale-multiple"></ngx-spinner>
    </div>
    <!-- </div> -->
</md-modal-offcanvas>

<md-modal-offcanvas #createProfile [mobileModalTitle]="'Create your profile'  | translate" [closeButtonOnLeft]="true" [thinPadding]="true" [isBorderRadius]="true" (modalBackButtonClicked)="navigateToWelcome()">
    <div actualControl>
        <p class="m-sub-title m-text-center">{{'Confirm your phone number' | translate}}</p>
        <form class="confirm-phone has-input" [formGroup]="registerForm">
            <div class="card form form-group first-div">
                <label class="form-label-signup custom-user-profile-label-2"
                       [ngClass]="'has-input'">
                    {{"Select" | translate}}</label>
                
                <select class="profile newStyleInput custom-user-profile-select-6"
                        formControlName="country" (change)="onChangeCountry('2')">
                    <option value="">{{"Select" | translate}}</option>
                    <option [value]="country.value"
                            *ngFor="let country of CountryISO|keyvalue">{{country.key}}
                    </option>
                </select>
            </div>

            <div class="form-group second-div">
                <label class="form-label-signup has-input" for="mobile"
                       [ngClass]="mobileClass">{{'Mobile Number' | translate}}</label>

                <div style="margin-bottom: 0.5rem;" class="signup_password_input mobile-number">

                    <ngx-intl-tel-input [ngClass]="['mobile-input', 'from-input-signup', mobileErrorClass]"
                                        id="mobile"
                                        #mobile
                                        [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="false"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="selectedCountryCode"
                                        [maxLength]="15"
                                        [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode"
                                        [numberFormat]="PhoneNumberFormat.National"
                                        [customPlaceholder]="'Mobile Number' | translate"
                                        formControlName="mobile"
                                        (keyup)="onMobileChange()"
                                        (focusin)="mobileErrorClass = ''"
                                        (focusout)="validateMobile()"
                                        (countryChange)="onPhoneCountryChange($event)">
                    </ngx-intl-tel-input>
                </div>

            </div>
            <span class="term-content">{{'We’ll text you to confirm your number. Standard message and data rates apply.' | translate}}</span>
        </form>
        <button class="btn btn-primary sign-in submit-button ripleEffect m-button m-bottom-20 m-bg-blue"
            (click)="veryfyMobileNumber()"
            >
            {{'Continue' | translate}}
        </button>
        <div class="form-group m-content text-center m-text-underline">
            <a (click)="doItLater()">{{'I’ll do this later' | translate}}</a>
        </div>
        <ngx-spinner id="ngx-spinner" type="ball-scale-multiple"></ngx-spinner>
    </div>
</md-modal-offcanvas>

<md-modal-offcanvas #verifyOtpAccount [mobileModalTitle]="'Create your profile'  | translate" [closeButtonOnLeft]="true" [thinPadding]="true" [isBorderRadius]="true" (modalBackButtonClicked)="navigateToConfirmNumber()">
    <div class="verify-mobile-otp" actualControl>
        <p class="m-sub-title ">{{'Confirm your phone number' | translate}}</p>
        <p class="mobile-text info-content">{{'Enter the 4-digit code MariDeal just sent to' | translate}}
            <span>{{otpMobileNumber}}</span>
        </p>
        <!-- Start OTP Form -->
        <form class="email-login-form" [formGroup]="registerForm">

            <div class="mb-6">
                <ng-otp-input id="partitioned"
                              (onInputChange)="onOtpChange($event)"
                              allowNumbersOnly="true"
                              aria-placeholder="-"

                              [config]="{length:4, placeholder:'-'}"></ng-otp-input>
            </div>

            <div class="form-group">
                <button class="btn ripleEffect confirm-code" [disabled]="(otpInput) ? (otpInput.length > 3 ? '' : 'disabled') : 'disabled'" (click)="verifyMobileOtp()">{{'Countinue' | translate}}</button>
            </div>
            <div class="form-group info-content">
                <div>{{"Didn't receive OTP?" | translate}} <span class="text-underline" (click)="resendOtp()">{{'Resend' | translate}}</span></div>
                
            </div>

            <div class="form-group info-content">
                <a (click)="doItLater()">{{'I’ll do this later' | translate}}</a>
            </div>
        </form>
        <!-- End OTP Form -->
    </div>
</md-modal-offcanvas>

<ng-template #messageModal let-modal>
    <div class="modal-body">
        {{ messageModalText }}
        <button
            type="button"
            class="btn btn-primary modalbtn"
            (click)="modal.close('Save click'); executeModalCallback()"
            data-mdb-ripple-color="dark"
        >
            {{ "OK" | translate }}
        </button>
    </div>
</ng-template>
