export interface LoginResponse {
    availed_badges: number;
    firstname: string;
    group_id: string;
    id: string;
    is_reward_applicable: boolean;
    mobile_login_username: string;
    jwt_token: string;
    lastname: string;
    login_status: 1 | 0 | string;
    message: string; // this may be 'Success' or 'Error'/'Failure'
    password: string; // yaaay, password in plain text in localstorage. Well done Ebizon!
    preferred_language: string;
    otp: string | number;
    reward_data: {
        balance_money: number;
        balance_points: number;
    };
    telephone: string;
    username: string;
}

export interface User {
    id: string;
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    groupId: string;
    jwtToken: string;
    isRewardApplicable: boolean;
    mobileLoginUsername: string;
    availableBadges: number;
    isSuccess: boolean;
    message: string;
    password: string;
    preferredLanguage: string;
    telephone: string;
    rewardData: RewardData;
    otp: string | number;
}

export interface RewardData {
    balanceMoney: number;
    balancePoints: number;
}


export const transform = (loginResponse: LoginResponse): User => {
    if (loginResponse) {
        return {
            id: loginResponse.id,
            email: loginResponse.username,
            username: loginResponse.username,
            firstName: loginResponse.firstname,
            lastName: loginResponse.lastname,
            groupId: loginResponse.group_id,
            jwtToken: loginResponse.jwt_token,
            isRewardApplicable: loginResponse.is_reward_applicable,
            mobileLoginUsername: loginResponse.mobile_login_username,
            availableBadges: loginResponse.availed_badges,
            isSuccess: loginResponse.login_status === 1 || loginResponse.login_status === '1',
            message: loginResponse.message,
            password: '',
            preferredLanguage: loginResponse.preferred_language,
            telephone: loginResponse.telephone,
            rewardData: {
                balanceMoney: loginResponse.reward_data?.balance_money,
                balancePoints: loginResponse.reward_data?.balance_points
            },
            otp: loginResponse.otp
        }
    }
    return {} as User;
}