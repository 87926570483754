<md-popup-offcanvas
    #searchOccupancyOffcanvas
    [mobileModalTitle]="'Guests' | translate"
    [mobileContinueButtonText]="'Continue' | translate"
    [mobileContinueButtonClick]="onModalContinueClick"
>
    <div actualControl select="[actualControl]" class="c-o-container">
        <div
            class="s-occu-option"
            [ngClass]="{
                's-occu-option-selected': occOption.id === selectedOccupancy?.id,
                'hide': hideOccupancy.includes(+occOption.id)
            }"
            *ngFor="let occOption of occupancyOptions"
            (click)="onOccupancyChange(occOption)"
        >
            {{ occOption.label }}
        </div>
    </div>
    <ng-content targetControl select="[targetControl]"></ng-content>
</md-popup-offcanvas>
