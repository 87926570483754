<div id="trip-detail">

    <div class="container-center-horizontal">
        <div class="hotel-deal screen">
            <div class="flex-col">
                <h1 class="title"><span>{{ tripDetail?.tripDetail?.outlet_name}}</span></h1>
                <div class="group-16">
                    <img class="fluentlocation-48-filled" src="assets/img/fluent-location-48-filled-1@2x.png"/>
                    <div class="surname montserrat-medium-navy-blue-14px">
                        <span class="montserrat-medium-navy-blue-14px">{{ tripDetail?.tripDetail?.location_name }}</span>
                    </div>
                </div>
                <div class="order-number"><span>Order Number:</span></div>
                <div class="overlap-group4">
                    <div ><span class="phone">{{tripDetail?.tripDetail?.increment_id}}</span></div>
                </div>
                <img class="vector-1" src="assets/img/vector-2-1@2x.png"/>
                <div *ngIf="tripDetail?.tripDetail?.checkin != '' && tripDetail?.tripDetail?.checkout != '' && (tripDetail?.tripDetail?.expiry == 'N/A' || tripDetail?.tripDetail?.expiry == '')" class="overlap-group3">
                    <img class="biarrow-down-right" src="assets/img/bi-arrow-down-right-1@2x.png"/>
                    <div class="group-11">
                        <div class="flex-row montserrat-medium-navy-blue-16px">
                            <img class="group" src="assets/img/group-2@2x.png"/>&nbsp;
                            <div class="check-in"><span class="montserrat-medium-navy-blue-16px">Check-in</span></div>
                            <img class="group-1" src="assets/img/group-1@2x.png"/>&nbsp;
                            <div class="check-out"><span class="montserrat-medium-navy-blue-16px">Check-out</span></div>
                        </div>
                        <div class="date-container">
                            <div class="date">
                                <span class="montserrat-bold-blue-whale-18px">{{ tripDetail?.tripDetail?.checkin | date: 'MMM dd'
                                    }}, </span><span
                                    class="span1">{{ tripDetail?.tripDetail?.checkin | date: 'yyyy' }}</span>
                            </div>
                            <div class="date-1">
                                <span class="montserrat-bold-blue-whale-18px">{{ tripDetail?.tripDetail?.checkout | date: 'MMM dd'
                                    }}, </span><span
                                    class="span1">{{ tripDetail?.tripDetail?.checkout | date: 'yyyy' }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  Expiry -->
                <div *ngIf="(tripDetail?.tripDetail?.expiry != 'N/A' && tripDetail?.tripDetail?.expiry != '') && tripDetail?.tripDetail?.checkin == '' && tripDetail?.tripDetail?.checkout == ''" class="overlap-group3">
                    <div class="group-11">
                        <div class="flex-row montserrat-medium-navy-blue-16px">
                            <img class="group" src="assets/img/valid_from.png"/>&nbsp;
                            <div class="check-in"><span class="montserrat-medium-navy-blue-16px">Valid from</span></div>
                            <img class="group-1" src="assets/img/expires_to.png"/>&nbsp;
                            <div class="check-out"><span class="montserrat-medium-navy-blue-16px">Expires on</span>
                            </div>
                        </div>
                        <div class="date-container">
                            <div class="date">
                                <span class="montserrat-bold-blue-whale-18px">{{ tripDetail?.tripDetail?.valid_from | date: 'MMM dd'
                                    }}, </span><span
                                    class="span1">{{ tripDetail?.tripDetail?.valid_from | date: 'yyyy' }}</span>
                            </div>
                            <div class="date-1">
                                <span class="montserrat-bold-blue-whale-18px">{{ tripDetail?.tripDetail?.expiry | date: 'MMM dd'
                                    }}, </span><span
                                    class="span1">{{ tripDetail?.tripDetail?.expiry | date: 'yyyy' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="vector-2" src="assets/img/vector-2-1@2x.png"/>
                <div class="flex-row-1 montserrat-medium-navy-blue-14px">
                    <div class="guest-name"><span class="montserrat-medium-navy-blue-14px">Guest Name</span></div>
                    <div class="category"><span class="montserrat-medium-navy-blue-14px">Category</span></div>
                </div>
                <div class="flex-row-2 montserrat-bold-blue-whale-18px">
                    <div class="name"><span class="montserrat-bold-blue-whale-18px">{{tripDetail?.tripDetail?.customer_name}}</span>
                    </div>
                    <div class="hotel"><span class="montserrat-bold-blue-whale-18px">{{tripDetail?.tripDetail?.category}}</span>
                    </div>
                </div>
                <div class="options montserrat-medium-navy-blue-14px">
                    <span class="montserrat-medium-navy-blue-14px">Options</span>
                </div>
                <div class="n1-half-board-al montserrat-bold-blue-whale-18px">
            <span class="montserrat-bold-blue-whale-18px"
            >{{tripDetail?.tripDetail?.options}}</span
            >
                </div>
                <div class="overlap-group1">
                    <div class="rectangle-52">
                        <img src="{{ tripDetail?.tripDetail?.qr_code_url }}" />
                    </div>
                    <div class="group-18">
                        <div class="x8-zpk1625131937"><span>{{tripDetail?.tripDetail?.coupon_code}}</span></div>
                        <div *ngIf="tripDetail?.tripDetail?.pdf_url != ''" class="overlap-group">
                            <img class="group-14" src="assets/img/group-14-1@2x.png"/>
                            <div class="download montserrat-medium-navy-blue-16px">
                                <div (click)="downloadTripDetailPdf(tripDetail)"
                                      class="montserrat-medium-navy-blue-16px">Download</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
