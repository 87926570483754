<ng-template #actualOccupancyControl>
    <div class="c-o-container">
        <div class="c-o-counter">
            <div class="c-o-text">
                <div class="c-o-text-name">{{ "Adults" | translate }}</div>
                <div class="c-o-text-description">
                    {{ "Ages 13 or above" | translate }}
                </div>
            </div>
            <md-counter
                [startValue]="adults"
                [minValue]="1"
                [maxValue]="maxAdults"
                (valueChanged)="onAdultsChanged($event)"
            ></md-counter>
        </div>
        <div class="c-o-counter">
            <div class="c-o-text">
                <div class="c-o-text-name">{{ "Children" | translate }}</div>
                <div class="c-o-text-description">
                    {{ "Ages 2-12 Years" | translate }}
                </div>
            </div>
            <md-counter
                [startValue]="children"
                [minValue]="0"
                [maxValue]="maxChildren"
                (valueChanged)="onChildrenChanged($event)"
            ></md-counter>
        </div>
        <div class="c-o-counter">
            <div class="c-o-text">
                <div class="c-o-text-name">{{ "Infants" | translate }}</div>
                <div class="c-o-text-description">
                    {{ "Under 2" | translate }}
                </div>
            </div>
            <md-counter
                [startValue]="infants"
                [minValue]="0"
                [maxValue]="maxInfants"
                (valueChanged)="onInfantsChanged($event)"
            ></md-counter>
        </div>
    </div>
</ng-template>
<ng-template #mobileOccupancy let-offcanvas>
    <div class="c-o-header-container">
        <button
            type="button"
            aria-label="Close"
            class="c-o-header-close-button"
            (click)="onModalCloseClick()"
        >
            Close
        </button>
        <div class="c-o-header-title-container">
            <h4 class="c-o-header-title">Guests</h4>
            <ng-content select="[datesControl]"></ng-content>
        </div>
    </div>
    <div class="c-modal-content">
        <ng-container *ngTemplateOutlet="actualOccupancyControl"></ng-container>
        <ng-content select="[summaryControl]"></ng-content>
    </div>
    <div class="c-modal-footer">
        <div class="c-modal-footer-button">
            <div></div>
            <button
                mdMainButton="dark"
                style="width: 100%"
                (click)="onModalContinueClick()"
            >
                Continue
            </button>
        </div>
    </div>
</ng-template>
<div
    [ngbPopover]="actualOccupancyControl"
    [placement]="'bottom'"
    [autoClose]="'outside'"
    triggers="manual"
    #d="ngbPopover"
>
    <ng-content select="[innerControl]"></ng-content>
</div>
