import { Injectable } from '@angular/core';
import {CommonService} from "../../shared/common.service";
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RecentService {

  constructor(
    private commonService: CommonService,
    private httpClient: HttpClient,
  ) { }

  getRecentlyViewedData(pro_arr: any){
    let customer_id: string = '';
    if(localStorage.getItem('marideal-pwa_user')){
      customer_id = JSON.parse(localStorage.getItem('marideal-pwa_user')).data.id;
    }
    return this.httpClient.get(this.commonService.api_url + 'getNewRecentProducts?service=getNewRecentProducts&currency=MUR&product_arr=' + pro_arr + '&customerid=' + customer_id +'&store='+this.commonService.getStoreID() + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenHeaders()});
  }
}
