<div *ngIf="true" class="md-category-filter radio">
    <div
        class="md-filter-header"
        [ngClass]="{ multiselect: filterData.filterTypeId === 1 }"
        (click)="toggleExpand()"
    >
        <h3>{{ filterData?.filterName }}</h3>
        <ion-icon
            name="{{ expanded ? 'chevron-up-outline' : 'chevron-down-outline' }}"
            style="margin: 0 0 0 auto;"
            class="md-filter-toggle-icon"
        ></ion-icon>
    </div>
    <div class="md-filter-holder">
        <form [ngClass]="expanded ? 'filter-expanded' : 'filter-hidden'">
            <div
                *ngFor="let value of filterData?.filterValues; let i = index"
                class="md-filter-input-grp"
            >
                <div class="md-filter-input">
                    <input
                        [type]="
                            filterData.filterTypeId === 1 ? 'radio' : 'checkbox'
                        "
                        name="{{ value.label }}"
                        [checked]="value.isSelected"
                        (change)="selectionChange(i)"
                    />
                    <label>{{ value.label }}</label>
                </div>
            </div>
        </form>
    </div>
</div>
