<div class="st-container" *ngIf="!condensed">
    <h5 *ngIf="!condensed" class="st-subtotal">{{ "Subtotal" | translate }}</h5>
    <div class="st-details">
        <div
            class="st-details-block st-details-block--start"
            *ngIf="internalPrice > 0"
        >
            <span class="st-discount-badge">-{{ discountPercentage }}%</span>
            <span *ngIf="!condensed" class="st-discount-text"
                >{{ "You Save" | translate }}
                {{
                    discountValue
                        | currency
                            : currencySymbol
                            : "symbol"
                            : "1.0-0"
                            : locale
                }}</span
            >
        </div>
        <div class="st-details-block st-details-block--end">
            <span class="st-details-price">{{
                internalPrice
                    | currency : currencySymbol : "symbol" : "1.0-2" : locale
            }}</span
            ><span class="st-details-old-price" *ngIf="internalFullPrice > 0">{{
                internalFullPrice
                    | currency : currencySymbol : "symbol" : "1.0-2" : locale
            }}</span>
        </div>
    </div>
    <div *ngIf="!condensed" class="st-vat">
        {{ "VAT included" | translate }}
    </div>
</div>
<div *ngIf="condensed" class="st-container">
    <span class="st-discount-badge" *ngIf="internalPrice > 0"
        >-{{ discountPercentage }}% {{ "OFF" | translate }}</span
    >
    <div class="">
        <span class="st-details-price condensed">{{
            internalPrice
                | currency : currencySymbol : "symbol" : "1.0-2" : locale
        }}</span
        ><span class="st-details-old-price" *ngIf="internalFullPrice > 0">{{
            internalFullPrice
                | currency : currencySymbol : "symbol" : "1.0-2" : locale
        }}</span>
    </div>
    <span *ngIf="internalPrice > 0" class="st-discount-text"
        >{{ "You Save" | translate }}
        {{
            discountValue
                | currency : currencySymbol : "symbol" : "1.0-0" : locale
        }}</span
    >
</div>
