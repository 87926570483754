export const appConfig = {
    currency: 'Rs.',
    currency_reunion: '€',
    api_url_m2dev: 'https://api1mdv.marideal.mu/rest/V1/mofluidapi/',
    api_url_prod: 'https://api1m.marideal.mu/rest/V1/mofluidapi/',
    base_url_prod: 'https://api1m.marideal.mu/',
    base_url_dev: 'https://api1mdv.marideal.mu/',
    m2dev_token_url: 'https://api1mdv.marideal.mu/rest/V1/getAuth',
    live_token_url: 'https://api1m.marideal.mu/rest/V1/getAuth',
    redirectUrl: 'http://marideal.re',
    is_m2dev_on: false, //to update code in staging before running ng build
    is_live_on: true, //to update code in production before running ng build
    storeid: 1,
    app_name: 'MariDeal',
    // google_ios_clientid:
    //     'com.googleusercontent.apps.427085441320-robt2nq519fui4l87not80qjgblndt3s',
    cache_time: 5,
    storage_key: 'marideal-pwa',
    app_curr_symbol: 'Rs.',
    app_currency: 'MUR',
    AndroidShare: 'AndroidShareHandler',

    bigDataCloudKey: 'bdc_1e143401716241d79e575f569367dd2e',
};
