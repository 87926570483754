import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import {
    AddBookingToCartResponseDto,
    ProductBookingData,
    ProductBookingDto,
    RewardsResponseDto,
} from './booking-config.model';
import { Observable } from 'rxjs';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { getCustomerId, getQuoteId } from 'src/app/utilities/user.utils';
import { encodeForUrl } from 'src/app/utilities/encoders';
import { createDateString } from 'src/app/controls/calendar/calendar.utils';
import { ProductServiceOption } from '../model/product-detail.model';

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    constructor(
        private httpClient: HttpClient,
        private commonService: CommonService
    ) {}

    getRewardPoints(subtotal: number): Observable<RewardsResponseDto> {
        const customerId = getCustomerId();
        const apiUrl = getApiUrlMofluid();
        const customerGroupId = this.commonService.getCustomerGroupID();
        const rewardUrl = `${apiUrl}getRewardPrice?service=getRewardPrice&subtotal_amount=${subtotal}&customerid=${customerId}&customer_group_id=${customerGroupId}`;
        return this.httpClient.get<RewardsResponseDto>(rewardUrl, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    addToCart(
        productData: ProductBookingData
    ): Observable<AddBookingToCartResponseDto> {
        const productDataDto = this.createProductDataDto(productData);
        const stringifiedProductData = JSON.stringify(productDataDto);
        const encodedProductData = encodeForUrl(stringifiedProductData);

        const addonDataDto = this.createAddonDataDto(productData);
        const stringifiedAddonData = JSON.stringify(addonDataDto);
        const encodedAddonData = encodeForUrl(stringifiedAddonData);

        const customerId = getCustomerId();
        const quoteId = getQuoteId();
        const storeId = this.commonService.getStoreID();
        const customerGroupId = this.commonService.getCustomerGroupID();

        const addtoCartUrl =
            getApiUrlMofluid() +
            'addCartItem?store=' +
            storeId +
            '&currency=MUR&service=addCartItem&customerid=' +
            customerId +
            '&quoteid=' +
            quoteId +
            '&products=' +
            encodedProductData +
            '&addon_data=' +
            encodedAddonData +
            '&customer_group_id=' +
            customerGroupId;
        return this.httpClient.get<AddBookingToCartResponseDto>(addtoCartUrl, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    private createProductDataDto(productData: ProductBookingData) {
        const productDto = {
            qty: 1, // this is encoded to always be 1 and can be changed from the cart
            type: productData.type,
            id: productData.id,
            options: productData.selectedServiceOptions
                ? this.extractServiceOptionAndCategoryIdFromProduct(
                      productData.selectedServiceOptions
                  )
                : '',
            dropdown_options: productData.selectedServiceOptionQuantities
                ? this.extractServiceOptionQuantitiesAndCategoryIdFromProduct(
                      productData.selectedServiceOptionQuantities
                  )
                : '',
            booking_from: productData.checkinDate
                ? createDateString(productData.checkinDate)
                : '',
            booking_to: productData.checkoutDate
                ? createDateString(productData.checkoutDate)
                : '',
            booking_date: productData.selectedDate
                ? createDateString(productData.selectedDate)
                : '',
            total_nights: productData.totalNights,
        };

        this.addMariBnbDataToProductDataDto(productData, productDto);

        return productDto;
    }

    private addMariBnbDataToProductDataDto(
        productData: ProductBookingData,
        productDto: ProductBookingDto
    ) {
        if (productData.mariBnbOccupancy) {
            productDto.adults = productData.mariBnbOccupancy.adults;
            productDto.children = productData.mariBnbOccupancy.children;
            productDto.infants = productData.mariBnbOccupancy.infants;
        }
    }

    private createAddonDataDto(productData: ProductBookingData) {
        return productData.addOnConfigurations
            ? productData.addOnConfigurations.map((addonConfig) => {
                  return {
                      id: addonConfig.addonItem.id,
                      price_type: addonConfig.addonItem.priceType,
                      sku: addonConfig.addonItem.sku,
                      title: addonConfig.addonItem.title,
                      qty: addonConfig.quantity,
                  };
              })
            : '';
    }

    private extractServiceOptionAndCategoryIdFromProduct(
        serviceOptions: ProductServiceOption[]
    ): { [key: number]: number } {
        const serviceOptionAndCategoryId = {};
        serviceOptions.forEach((serviceOption) => {
            serviceOptionAndCategoryId[serviceOption.categoryId.toString()] =
                serviceOption.id.toString();
        });
        return serviceOptionAndCategoryId;
    }

    private extractServiceOptionQuantitiesAndCategoryIdFromProduct(
        serviceOptionQuantities: Map<ProductServiceOption, number>
    ): { [key: number]: number } {
        const serviceOptionAndCategoryId = {};
        serviceOptionQuantities.forEach((quantity, serviceOption) => {
            if (!serviceOptionAndCategoryId[serviceOption.categoryId]) {
                serviceOptionAndCategoryId[serviceOption.categoryId] = {
                    [serviceOption.id]: quantity,
                };
            } else {
                serviceOptionAndCategoryId[serviceOption.categoryId][
                    serviceOption.id
                ] = quantity;
            }
        });
        return serviceOptionAndCategoryId;
    }
}
