<ng-template #actualControl>
    <ng-content select="[actualControl]"></ng-content>
</ng-template>
<ng-template #mobileControl let-offcanvas>
    <div class="c-po-header-container">
        <div class="c-po-header-title-container">
            <h4 class="offcanvas-title c-po-header-title">
                {{ mobileModalTitle }}
            </h4>
        </div>
        <button
            type="button"
            aria-label="Close"
            class="c-po-header-close-button"
            (click)="close()"
        >
            <ion-icon name="close-circle-outline"></ion-icon>
        </button>
    </div>
    <div class="c-po-modal-content">
        <ng-container *ngTemplateOutlet="actualControl"></ng-container>
    </div>
    <div class="c-po-modal-footer">
        <div class="c-po-modal-footer-button">
            <div></div>
            <button
                mdMainButton="dark"
                style="width: 100%"
                (click)="onModalContinueClick()"
            >
                {{ mobileContinueButtonText }}
            </button>
        </div>
    </div>
</ng-template>
<div
    [ngbPopover]="actualControl"
    [placement]="'bottom'"
    [autoClose]="'outside'"
    triggers="manual"
    #d="ngbPopover"
>
    <ng-content select="[targetControl]"></ng-content>
</div>
