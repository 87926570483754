import { Component, OnInit } from '@angular/core';
import { ProfileSection } from '../../profile-wrapper.component';
import { RewardsAndWalletService } from './rewards-and-wallet.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

@Component({
    selector: 'md-rewards-and-wallet',
    templateUrl: './rewards-and-wallet.component.html',
    styleUrls: ['./rewards-and-wallet.component.scss'],
})
export class RewardsAndWalletComponent implements OnInit {
    activeTab: 'gift-card' | 'store-credit' = 'gift-card';
    giftCardBalance = 0;
    storeCredit = 0;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    protected readonly ProfileSection = ProfileSection;

    constructor(
        private rewardsAndWalletService: RewardsAndWalletService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    ngOnInit(): void {
        if (window.location.href.includes('customer/storecredit')) {
            this.activeTab = 'store-credit';
        }
        this.rewardsAndWalletService.giftCardBalance$.subscribe(
            (balance: number) => {
                this.giftCardBalance = balance;
            }
        );
        this.rewardsAndWalletService.storeCreditBalance$.subscribe(
            (balance: number) => {
                this.storeCredit = balance;
            }
        );
        this.currencyLanguageService
            .getCurrency()
            .subscribe((currencySymbol) => {
                this.currencySymbol = currencySymbol;
            });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }
}
