<md-profile-wrapper [activeSection]="ProfileSection.SETTINGS">
    <div *ngIf="userLogin">
        <md-profile-page-title
            [title]="'Security' | translate"
            [description]="
                'Change your security settings, set up secure authentication, or delete your account.'
                    | translate
            "
        />
        <div *ngIf="sectionBeingEdited !== 'password'" class="ma-sq-section">
            <div class="ma-sq-section-data">
                <div class="ma-sq-section-label">
                    {{ "Password" | translate }}
                </div>
                <div class="ma-sq-section-value">
                    <p>
                        {{
                            "Reset your password regularly to keep your account secure."
                                | translate
                        }}
                    </p>
                </div>
            </div>
            <div class="ma-sq-section-edit-button">
                <button mdTextButton (click)="sectionBeingEdited = 'password'">
                    {{ "Edit" | translate }}
                </button>
            </div>
        </div>
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
            <div
                class="ma-sq-section-edit"
                *ngIf="sectionBeingEdited === 'password'"
            >
                <div class="ma-sq-section-edit-left">
                    <div class="ma-sq-section-label">
                        {{ "Password" | translate }}
                    </div>
                    <div class="ma-sq-section-edit-input-block">
                        <label
                            for="current-password"
                            class="ma-sq-section-edit-label"
                            >{{ "Current Password" | translate
                            }}<sup>*</sup></label
                        >
                        <input
                            type="password"
                            id="current-password"
                            class="ma-sq-section-edit-input"
                            formControlName="currentPassword"
                            placeholder="{{ 'Current Password' | translate }}"
                        />
                        <label
                            for="new-password"
                            class="ma-sq-section-edit-label"
                            >{{ "New Password" | translate }}<sup>*</sup></label
                        >
                        <input
                            type="password"
                            id="new-password"
                            class="ma-sq-section-edit-input"
                            formControlName="newPassword"
                            placeholder="{{ 'New Password' | translate }}"
                        />
                        <label
                            for="current-password"
                            class="ma-sq-section-edit-label"
                            >{{ "Confirm Password" | translate
                            }}<sup>*</sup></label
                        >
                        <input
                            type="password"
                            id="confirm-password"
                            class="ma-sq-section-edit-input"
                            formControlName="confirmPassword"
                            placeholder="{{ 'Confirm Password' | translate }}"
                        />
                    </div>
                </div>
                <div class="ma-sq-section-edit-controls">
                    <button
                        mdTextButton
                        class="ma-sq-section-edit-cancel"
                        (click)="sectionBeingEdited = ''"
                    >
                        {{ "Cancel" | translate }}
                    </button>
                    <button
                        class="ma-sq-section-edit-save"
                        mdMainButton="light"
                        type="submit"
                    >
                        {{ "Save" | translate }}
                    </button>
                </div>
            </div>
        </form>
        <div
            *ngIf="sectionBeingEdited !== 'personalData'"
            class="ma-sq-section"
        >
            <div class="ma-sq-section-data">
                <div class="ma-sq-section-label">
                    {{ "Personal Data" | translate }}
                </div>
                <div class="ma-sq-section-value">
                    <p>
                        {{
                            "Here you can download a copy of your personal data which we store for your account in CSV format."
                                | translate
                        }}
                    </p>
                </div>
            </div>
            <div class="ma-sq-section-edit-button">
                <button
                    mdTextButton
                    (click)="sectionBeingEdited = 'personalData'"
                >
                    {{ "Edit" | translate }}
                </button>
            </div>
        </div>
        <form
            [formGroup]="downloadPersonalDataForm"
            (ngSubmit)="downloadPersonalData()"
        >
            <div
                class="ma-sq-section-edit"
                *ngIf="sectionBeingEdited === 'personalData'"
            >
                <div class="ma-sq-section-edit-left">
                    <div class="ma-sq-section-label">
                        {{ "Personal Data" | translate }}
                    </div>
                    <div class="ma-sq-section-edit-input-block">
                        <label
                            for="current-password"
                            class="ma-sq-section-edit-label"
                            >{{ "Current Password" | translate
                            }}<sup>*</sup></label
                        >
                        <input
                            type="password"
                            id="current-password"
                            class="ma-sq-section-edit-input"
                            formControlName="currentPassword"
                            placeholder="{{ 'Current Password' | translate }}"
                        />
                    </div>
                </div>
                <div class="ma-sq-section-edit-controls">
                    <button
                        mdTextButton
                        class="ma-sq-section-edit-cancel"
                        (click)="sectionBeingEdited = ''"
                    >
                        {{ "Cancel" | translate }}
                    </button>
                    <button
                        class="ma-sq-section-edit-save"
                        mdMainButton="light"
                        type="submit"
                    >
                        {{ "Download" | translate }}
                    </button>
                </div>
            </div>
        </form>
        <div
            *ngIf="sectionBeingEdited !== 'deleteAccount'"
            class="ma-sq-section"
        >
            <div class="ma-sq-section-data">
                <div class="ma-sq-section-label">
                    {{ "Delete account" | translate }}
                </div>
                <div class="ma-sq-section-value">
                    <p>
                        {{
                            "Permanently delete your MariDeal account"
                                | translate
                        }}
                    </p>
                </div>
            </div>
            <div class="ma-sq-section-edit-button">
                <button
                    mdTextButton
                    (click)="sectionBeingEdited = 'deleteAccount'"
                >
                    {{ "Edit" | translate }}
                </button>
            </div>
        </div>
        <form [formGroup]="deleteAccountForm" (ngSubmit)="deleteAccount()">
            <div
                class="ma-sq-section-edit"
                *ngIf="sectionBeingEdited === 'deleteAccount'"
            >
                <div class="ma-sq-section-edit-left">
                    <div class="ma-sq-section-label">
                        {{ "Delete account" | translate }}
                    </div>
                    <div class="ma-sq-section-edit-input-block">
                        <p class="ma-sq-section-delete-data-text">
                            {{ "Request account deletion message" | translate }}
                        </p>
                        <label class="custom-checkbox ma-sq-section-checkbox">
                            <input
                                type="checkbox"
                                name="delete-account-checkbox"
                                id="delete-account-checkbox"
                                (change)="onCheckboxChange($event)"
                                formControlName="consentCheckbox"
                            />{{
                                "I understand and I want to delete my account"
                                    | translate
                            }}
                            <span class="checkmark"></span>
                        </label>
                        <label
                            for="current-password"
                            class="ma-sq-section-edit-label"
                            >{{ "Current Password" | translate }}</label
                        >
                        <input
                            type="password"
                            id="current-password"
                            class="ma-sq-section-edit-input"
                            formControlName="currentPassword"
                            placeholder="{{ 'Current Password' | translate }}"
                        />
                    </div>
                </div>
                <div class="ma-sq-section-edit-controls">
                    <button
                        mdTextButton
                        class="ma-sq-section-edit-cancel"
                        (click)="sectionBeingEdited = ''"
                    >
                        {{ "Cancel" | translate }}
                    </button>
                    <button
                        class="ma-sq-section-edit-save"
                        mdMainButton="light"
                        type="submit"
                    >
                        {{ "Submit request" | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</md-profile-wrapper>
