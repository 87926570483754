<div class="progress-container">
    <svg viewBox="0 0 100 50" class="semi-circular-chart">
        <path
            class="semi-circle-bg"
            d="M 10 50
                 A 40 40 0 0 1 90 50"
        />
        <path
            class="semi-circle"
            stroke-dasharray="126.92"
            [attr.stroke-dashoffset]="126.92 - (126.92 * percentage) / 100"
            d="M 10 50
                 A 40 40 0 0 1 90 50"
        />
    </svg>
    <ng-container>
        <div *ngIf="!loading" class="percentage">
            {{ roundPercentage() + "%" }}
        </div>
        <div *ngIf="loading" mdSkeleton class="percentage loading"></div>
        <div class="details">
            <div>{{ label }}</div>
            <div>{{ sublabel }}</div>
        </div>
    </ng-container>
</div>
