import { Component, OnInit } from '@angular/core';
import { OrdersService } from './orders.service';
import { CommonService } from 'src/app/shared/common.service';
import { Router } from '@angular/router';
import { EventService, EventType } from '../../utilities/event.service';
import { getUser } from '../../utilities/user.utils';
import { User } from '../../model/user.model';
import { ProfileSection } from '../profile-wrapper.component';
import { DeviceCommsService } from 'src/app/services/device-comms.service';
import { untranslatedMenuNames } from 'src/app/static-content/menu-routes';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import * as Sentry from '@sentry/angular-ivy';

interface InvoicePdfUrl {
    code: string;
    url: string;
}

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
    imgCdn = '';
    loginStatus!: number;
    loginResponse: User;
    userLogin: boolean = false;
    customerId: any;
    pageSize: number = 8;
    currentpage: number = 1;
    paginationLimit!: number;
    ordersArray: any = [];
    allOrdersVisible: boolean = true;
    pendingOrdersVisible: boolean = false;
    completeOrdersVisible: boolean = false;
    canceledOrdersVisible: boolean = false;
    isOrdersData: boolean = false;
    companyProfileData: any;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    constructor(
        private eventService: EventService,
        private ordersService: OrdersService,
        private deviceCommsService: DeviceCommsService,
        private currencyLanguageService: CurrencyLanguageService,
        private common: CommonService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.checkLoginResponse();
        this.currencyLanguageService
            .getCurrency()
            .subscribe((currencySymbol) => {
                this.currencySymbol = currencySymbol;
            });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    checkLoginResponse() {
        let userdata = getUser();
        if (userdata) {
            this.loginResponse = userdata;
            this.customerId = this.loginResponse?.id;
            this.userLogin = true;
            this.getAllOrders();
        } else {
            this.eventService.emitEvent(EventType.LOGIN);
            return false;
        }
    }

    getAllOrders(scroll: boolean = false) {
        if (scroll) {
            this.currentpage += 1;
        }
        this.isOrdersData = true;
        this.allOrdersVisible = true;
        this.pendingOrdersVisible = false;
        this.completeOrdersVisible = false;
        this.canceledOrdersVisible = false;
        this.ordersService
            .getAllOrders(
                this.customerId,
                'all',
                this.pageSize,
                this.currentpage
            )
            .subscribe(
                (response: any) => {
                    this.common.endProgress();
                    this.isOrdersData = false;
                    this.ordersArray = [];
                    this.ordersArray.push(response);
                    this.companyProfileData = response.company_profile_data;
                    this.common.setCache(
                        this,
                        'company_profile_data',
                        this.companyProfileData
                    );
                    this.paginationLimit = Math.ceil(
                        this.ordersArray[0].total / this.pageSize
                    );
                },
                (error) => {
                    this.common.endProgress();
                    this.isOrdersData = false;
                    console.log(error);
                }
            );
    }

    onScrollGetAllOrders() {
        if (this.currentpage < this.paginationLimit) {
            this.currentpage += 1;
            this.common.startProgress();
            this.ordersService
                .getAllOrders(
                    this.customerId,
                    'all',
                    this.pageSize,
                    this.currentpage
                )
                .subscribe(
                    (response: any) => {
                        this.common.endProgress();
                        this.ordersArray.push(response);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    getPendingOrders() {
        this.isOrdersData = true;
        this.currentpage = 1;
        this.allOrdersVisible = false;
        this.pendingOrdersVisible = true;
        this.completeOrdersVisible = false;
        this.canceledOrdersVisible = false;
        this.ordersService
            .getPendingOrders(
                this.customerId,
                'pending',
                this.pageSize,
                this.currentpage
            )
            .subscribe(
                (response: any) => {
                    this.isOrdersData = false;
                    this.ordersArray = [];
                    this.ordersArray.push(response);
                    this.paginationLimit = Math.ceil(
                        this.ordersArray[0].total / this.pageSize
                    );
                },
                (error) => {
                    this.isOrdersData = false;
                    console.log(error);
                }
            );
    }

    onScrollGetPendingOrders() {
        if (this.currentpage < this.paginationLimit) {
            this.currentpage += 1;
            this.common.startProgress();
            this.ordersService
                .getPendingOrders(
                    this.customerId,
                    'pending',
                    this.pageSize,
                    this.currentpage
                )
                .subscribe(
                    (response: any) => {
                        this.common.endProgress();
                        this.ordersArray.push(response);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    getCompleteOrders() {
        this.isOrdersData = true;
        this.currentpage = 1;
        this.allOrdersVisible = false;
        this.pendingOrdersVisible = false;
        this.completeOrdersVisible = true;
        this.canceledOrdersVisible = false;
        this.ordersService
            .getCompleteOrders(
                this.customerId,
                'complete',
                this.pageSize,
                this.currentpage
            )
            .subscribe(
                (response: any) => {
                    this.isOrdersData = false;
                    this.ordersArray = [];
                    this.ordersArray.push(response);
                    this.paginationLimit = Math.ceil(
                        this.ordersArray[0].total / this.pageSize
                    );
                },
                (error) => {
                    this.isOrdersData = false;
                    this.common.endProgress();
                    console.log(error);
                }
            );
    }

    onScrollGetCompleteOrders() {
        if (this.currentpage < this.paginationLimit) {
            this.currentpage += 1;
            this.common.startProgress();
            this.ordersService
                .getCompleteOrders(
                    this.customerId,
                    'complete',
                    this.pageSize,
                    this.currentpage
                )
                .subscribe(
                    (response: any) => {
                        this.common.endProgress();
                        this.ordersArray.push(response);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    getCanceledOrders() {
        this.isOrdersData = true;
        this.currentpage = 1;
        this.allOrdersVisible = false;
        this.pendingOrdersVisible = false;
        this.completeOrdersVisible = false;
        this.canceledOrdersVisible = true;
        this.ordersService
            .getCanceledOrders(
                this.customerId,
                'canceled',
                this.pageSize,
                this.currentpage
            )
            .subscribe(
                (response: any) => {
                    this.isOrdersData = false;
                    this.ordersArray = [];
                    this.ordersArray.push(response);
                    this.paginationLimit = Math.ceil(
                        this.ordersArray[0].total / this.pageSize
                    );
                },
                (error) => {
                    this.isOrdersData = false;
                    this.common.endProgress();
                    console.log(error);
                }
            );
    }

    onScrollGetCanceledOrders() {
        if (this.currentpage < this.paginationLimit) {
            this.currentpage += 1;
            this.common.startProgress();
            this.ordersService
                .getCanceledOrders(
                    this.customerId,
                    'canceled',
                    this.pageSize,
                    this.currentpage
                )
                .subscribe(
                    (response: any) => {
                        this.common.endProgress();
                        this.ordersArray.push(response);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    orderExpendClick(orders: any) {
        orders.isExpend = !orders.isExpend;
    }

    repeatThis(n: number): Array<number> {
        return Array(n);
    }

    getOrderDate(date: any) {
        let orderDate = new Date(date);
        let dd: any = orderDate.getDate();
        dd = dd < 10 ? '0' + dd : dd;
        let mm: any = orderDate.getMonth() + 1;
        mm = mm < 10 ? '0' + mm : mm;
        let yyyy = orderDate.getFullYear().toString().substr(-2);
        return dd + '/' + mm + '/' + yyyy;
    }

    downloadOrderPdfClick(tripsProduct: { pdf_url: InvoicePdfUrl[] }) {
        const { pdf_url } = tripsProduct;
        const firstPdf = pdf_url[0];
        if (firstPdf) {
            this.deviceCommsService.openLinkInNewTabOrBrowser(firstPdf.url);
        } else {
            Sentry.captureMessage('NO-PDF-URL-FOUND');
        }
    }

    makePaymentClick(orders: any) {
        let paymentUrl = orders.payment_link;
        let platform = this.common.getCache(this, 'platform')
            ? this.common.getCache(this, 'platform').data
                ? this.common.getCache(this, 'platform').data
                : ''
            : '';
        if (platform == '' || platform == null) {
            window.open(paymentUrl, '_blank');
        } else {
            if (platform == 'iOS') {
                (
                    window as any
                ).webkit.messageHandlers.customLinkClick.postMessage(
                    paymentUrl
                );
            } else if (platform == 'android') {
                (window as any).AndroidShareHandler.customLinkClick(paymentUrl);
            }
        }
    }

    vatInvoiceClick(
        invoice_status: any,
        company_profile_data: any,
        orders: any
    ) {
        if (invoice_status == 'Approved') {
            let order_id = orders.id;
            this.router
                .navigate(['companyprofile/index/invoiceform/' + order_id], {
                    queryParams: {
                        // "order_id": order_id,
                        // "company_profile_data": company_profile_data
                    },
                })
                .then(() => {
                    window.location.reload();
                });
        } else {
            this.router.navigate(['companyprofile/index/index']).then(() => {
                window.location.reload();
            });
        }
    }

    getCategoryName(item: any) {
        if (item.category_id.length) {
            let catId = item.category_id[0];
            return untranslatedMenuNames.get(catId);
        } else if (item.sku == 'gift-voucher') {
            return 'Gift Cards';
        }
    }

    notLoggedInUserRedirectToHomepage() {
        this.router.navigate(['/']);
    }

    protected readonly ProfileSection = ProfileSection;
}
