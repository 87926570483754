import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[mdShowOnMin]',
})
export class ShowOnMinDirective implements OnInit {
    @Input() mdShowOnMin: 'tablet' | 'desktop' | 'largeDesktop';

    constructor(private el: ElementRef) {
        this.el.nativeElement.classList.add('som-control');
    }

    ngOnInit() {
        switch (this.mdShowOnMin) {
            case 'tablet':
                this.el.nativeElement.classList.add('sm');
                break;
            case 'desktop':
                this.el.nativeElement.classList.add('md');
                break;
            case 'largeDesktop':
                this.el.nativeElement.classList.add('lg');
                break;
        }
    }
}
