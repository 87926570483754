export const HOTEL_CATEGORY = 3;
export const RESTAURANT_CATEGORY = 4;
export const SPA_CATEGORY = 6;
export const ACTIVITY_CATEGORY = 7;
export const DAY_PACKAGE_CATEGORY = 8;
export const RODRIGUES_CATEGORY = 9;
export const RODRIGUES_EXCURSIONS_CATEGORY = 14;
export const RODRIGUES_CAR_RENTAL_CATEGORY = 15;
export const RODRIGUES_HOTELS_CATEGORY = 16;
export const RODRIGUES_RESTAURANTS_CATEGORY = 17;
export const TRAVEL_CATEGORY = 18;
export const EVENT_CATEGORY = 19;
export const THINK_LOCAL_CATEGORY = 20;
export const MARIBNB_CATEGORY = 21;
export const SCHOOL_HOLIDAYS_CATEGORY = 29;
export const STMA_CATEGORY = 34;

export const categoryIdArray = [
    HOTEL_CATEGORY,
    RESTAURANT_CATEGORY,
    SPA_CATEGORY,
    ACTIVITY_CATEGORY,
    DAY_PACKAGE_CATEGORY,
    RODRIGUES_CATEGORY,
    EVENT_CATEGORY,
    THINK_LOCAL_CATEGORY,
    MARIBNB_CATEGORY,
    SCHOOL_HOLIDAYS_CATEGORY,
    STMA_CATEGORY,
    TRAVEL_CATEGORY,
];

export const menuRoutes = new Map<number, string>([
    [HOTEL_CATEGORY, 'hotel-deals.html'],
    [MARIBNB_CATEGORY, 'maribnb.html'],
    [DAY_PACKAGE_CATEGORY, 'day-package-deals.html'],
    [EVENT_CATEGORY, 'events.html'],
    [SPA_CATEGORY, 'spa-deals.html'],
    [ACTIVITY_CATEGORY, 'activity-deals.html'],
    [RESTAURANT_CATEGORY, 'restaurant-deals.html'],
    [RODRIGUES_CATEGORY, 'rodrigues-deals.html'],
    [TRAVEL_CATEGORY, 'travel.html'],
    [THINK_LOCAL_CATEGORY, 'thinklocal.html'],
    [SCHOOL_HOLIDAYS_CATEGORY, 'school-holidays.html'],
    [STMA_CATEGORY, 'stma.html'],
]);

export const getCategoryRoutesArray = (): string[] => {
    const menuRoutesArray = new Array<string>();

    menuRoutes.forEach((value: string, key: number) => {
        menuRoutesArray[key] = value;
    });

    return menuRoutesArray;
};

export const urlToCategoryId = new Map<string, number>([
    ['/hotel-deals.html', HOTEL_CATEGORY],
    ['/maribnb.html', MARIBNB_CATEGORY],
    ['/day-package-deals.html', DAY_PACKAGE_CATEGORY],
    ['/events.html', EVENT_CATEGORY],
    ['/spa-deals.html', SPA_CATEGORY],
    ['/activity-deals.html', ACTIVITY_CATEGORY],
    ['/restaurant-deals.html', RESTAURANT_CATEGORY],
    ['/rodrigues-deals.html', RODRIGUES_CATEGORY],
    ['/travel.html', TRAVEL_CATEGORY],
    ['/thinklocal.html', THINK_LOCAL_CATEGORY],
    ['/school-holidays.html', SCHOOL_HOLIDAYS_CATEGORY],
    ['/stma.html', STMA_CATEGORY],
]);

export const menuIcons = new Map<number, string>([
    [HOTEL_CATEGORY, 'assets/icons/menu/Icons_Hotels.svg'],
    [MARIBNB_CATEGORY, 'assets/icons/menu/Icons_Maribnb.png'],
    [DAY_PACKAGE_CATEGORY, 'assets/icons/menu/Icons_Day Package.svg'],
    [EVENT_CATEGORY, 'assets/icons/menu/Icons_Events.svg'],
    [SPA_CATEGORY, 'assets/icons/menu/Icons_beauty & spa.svg'],
    [ACTIVITY_CATEGORY, 'assets/icons/menu/Icons_Things to do.svg'],
    [RESTAURANT_CATEGORY, 'assets/icons/menu/Icons_Restaurants.svg'],
    [RODRIGUES_CATEGORY, 'assets/icons/menu/Icons_Rodrigues.svg'],
    [TRAVEL_CATEGORY, 'assets/icons/menu/Icons_Travel.svg'],
    [
        THINK_LOCAL_CATEGORY,
        'assets/icons/menu/MariSpecials_Grey_3_Icon_24x24px_1pts.svg',
    ],
    [SCHOOL_HOLIDAYS_CATEGORY, 'assets/img/SchoolHolidayIcon.svg'],
    [STMA_CATEGORY, 'assets/img/icons-d/BF-01 GREY.svg'],
]);

export const getMenuIconPathArray = (): string[] => {
    const menuIconPathArray = new Array<string>();

    menuIcons.forEach((value: string, key: number) => {
        menuIconPathArray[key] = value;
    });

    return menuIconPathArray;
};

export const untranslatedMenuNames = new Map<number, string>([
    [HOTEL_CATEGORY, 'Accommodation'],
    [MARIBNB_CATEGORY, 'Maribnb'],
    [DAY_PACKAGE_CATEGORY, 'Day Package'],
    [EVENT_CATEGORY, 'Events'],
    [SPA_CATEGORY, 'Beauty & Spa'],
    [ACTIVITY_CATEGORY, 'Things to do'],
    [RESTAURANT_CATEGORY, 'Restaurants'],
    [RODRIGUES_CATEGORY, 'Rodrigues'],
    [TRAVEL_CATEGORY, 'Travel'],
    [THINK_LOCAL_CATEGORY, '#ThinkLocal'],
    [SCHOOL_HOLIDAYS_CATEGORY, 'MariEaster'],
    [STMA_CATEGORY, 'STMA'],
]);

export const hideOccupancy = [
    4034, //  2 Teens (16-17) 
    5123, //  Up to 2 Pax 
    5124, //  Up to 4 Pax 
    5125, //  Up to 6 Pax 
    5126, //  Up to 8 Pax 
    5127, //  Up to 10 Pax 
    4204, //  Up to 20 Pax 
    5239, //  Up to 8 Adults + 4 Children (0-11) 
    5294, //  Up to 3 Pax 
    5295, //  Up to 7 Pax 
    5364, //  1 Teen (12-17) 
    5365, //  1 Teen (12-17) + 1 Child (3-11)
]
