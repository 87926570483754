import { EventEmitter, Injectable } from '@angular/core';

export interface ShowGeneralErrorOptions {
    showMailto?: boolean;
    showImage?: boolean;
}

const defaultOptions: ShowGeneralErrorOptions = {
    showMailto: true,
    showImage: true,
};

@Injectable({
    providedIn: 'root',
})
export class GeneralErrorService {
    private hasErrorInternal = false;
    private errorMessageInternal = '';
    private showGeneralErrorOptionsInternal: ShowGeneralErrorOptions =
        defaultOptions;

    get hasError() {
        return this.hasErrorInternal;
    }
    get errorMessage() {
        return this.errorMessageInternal;
    }
    get showGeneralErrorOptions() {
        return this.showGeneralErrorOptionsInternal;
    }

    public showGeneralErrorEvent: EventEmitter<void> = new EventEmitter();

    // This may need to have a synchronous version
    public showGeneralError(
        errorMessage: string,
        options?: ShowGeneralErrorOptions
    ): void {
        this.hasErrorInternal = true;
        this.errorMessageInternal = errorMessage;
        this.showGeneralErrorOptionsInternal = {
            ...defaultOptions,
            ...options,
        };
        this.showGeneralErrorEvent.emit();
    }

    public dismissError(): void {
        this.hasErrorInternal = false;
        this.errorMessageInternal = '';
        this.showGeneralErrorOptionsInternal = defaultOptions;
    }
}
