import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { getCustomerId } from 'src/app/utilities/user.utils';
@Injectable({
    providedIn: 'root',
})
export class BadgeService {
    constructor(
        private httpClient: HttpClient,
        private common: CommonService
    ) {}
    getBadgesInfo() {
        return this.httpClient.get(
            getApiUrlMofluid() +
                `getRewardPointBadge?store=` +
                this.common.getStoreID() +
                `&currency=MUR&service=getRewardPointBadge&customerid=${getCustomerId()}` +
                '&customer_group_id=' +
                this.common.getCustomerGroupID(),
            { headers: this.common.getTokenJWTheaders() }
        );
    }

    getRewardPoint(data: any) {
        return this.httpClient.get(
            getApiUrlMofluid() +
                `getRewardPointBySocialMedia?store=` +
                this.common.getStoreID() +
                `&currency=MUR&service=getRewardPointBySocialMedia&customerid=${getCustomerId()}&platform=${
                    data.platformType
                }` +
                '&customer_group_id=' +
                this.common.getCustomerGroupID(),
            { headers: this.common.getTokenJWTheaders() }
        );
    }
}
