import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    ProductDetails,
    ProductServiceCategory,
    ProductServiceOption,
} from 'src/app/product-detail/model/product-detail.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelBookingService } from './hotel-booking.service';
import {
    ProductServicePricingByDate,
    transformProductServicePricingDtoToPricingByDate,
} from './hotel-booking.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import {
    BookingConfiguredData,
    HotelSearchBookingData,
    emptyBookingConfiguredData,
} from '../booking-config.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular-ivy';
import * as dayjs from 'dayjs';
import { transformNgbDateToDayJs } from 'src/app/controls/calendar/calendar.utils';
import { TRAVEL_CATEGORY } from 'src/app/static-content/menu-routes';
import { screenSizes } from 'src/app/utilities/theme';

@Component({
    selector: 'md-hotel-booking',
    templateUrl: './hotel-booking.component.html',
    styleUrls: ['./hotel-booking.component.scss'],
})
export class HotelBookingComponent implements OnInit {
    @Input() set productDetailsInput(value: ProductDetails) {
        this.productDetails = value;
        this.isTravel = this.productDetails.category === TRAVEL_CATEGORY;
        if (this.initialBookingData) {
            // TODO: this is for query string parameters
        } else {
            this.selectedOccupancy = '44'; // the id for 2
            this.setServiceOptionOnDesktop();
        }
    }
    @Input() initialBookingData: HotelSearchBookingData;

    visibleServiceCategoriesAndOptions: ProductServiceCategory[];
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    pricingByDateForSelectedServiceOption: ProductServicePricingByDate[];
    additionalCalendarDayInfo: Map<string, CalendarDayInfo> = new Map();
    selectedServiceOptionInternal: ProductServiceOption;
    selectedOccupancyIdInternal: number;
    bookingSelected = false;
    productDetails: ProductDetails;
    isTravel = false;
    desktopCalendarLoading = false;

    fromDate: dayjs.Dayjs;
    toDate: dayjs.Dayjs;
    totalNights = 0;

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();
    @ViewChild('calendarHotel') calendarHotel: CalendarComponent;

    set selectedOccupancy(value: string | number) {
        this.resetBooking();
        const selectedOccupancyId = Number(value);
        this.selectedOccupancyIdInternal = selectedOccupancyId;
        this.selectedServiceOptionInternal = undefined;
        this.visibleServiceCategoriesAndOptions =
            this.productDetails.serviceOptionCategories
                .filter((serviceCategory) =>
                    serviceCategory.options.some(
                        (serviceOption) =>
                            serviceOption.occupancyId === selectedOccupancyId
                    )
                )
                .map((serviceCategory) => ({
                    ...serviceCategory,
                    options: serviceCategory.options.filter(
                        (serviceOption) =>
                            serviceOption.occupancyId === selectedOccupancyId
                    ),
                }));
    }

    setSelectedServiceOption(
        productServiceOption: ProductServiceOption,
        showSpinner = true
    ) {
        this.resetBooking();
        this.selectedServiceOptionInternal = productServiceOption;
        this.setMultiplePrices(productServiceOption, showSpinner);
    }

    constructor(
        private spinnerService: NgxSpinnerService,
        private hotelBookingService: HotelBookingService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}
    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    onOccupancyChanged(occupancyId: number): void {
        this.selectedOccupancy = occupancyId;
    }

    onServiceOptionChanged(serviceOption: ProductServiceOption): void {
        if (this.selectedServiceOptionInternal?.id === serviceOption.id) {
            this.calendarHotel.openCalendar();
        } else {
            this.setSelectedServiceOption(serviceOption, false);
        }
    }

    onDatesClick() {
        this.calendarHotel.openCalendar();
    }

    onDateRangeSelected(selectedDates: CalendarSelectedDates): void {
        const { fromDate, toDate, totalNights } = selectedDates;
        if (!fromDate || !toDate) {
            this.bookingConfigured.emit(emptyBookingConfiguredData);
            this.setCheckinCheckoutUI(undefined, undefined, undefined);
            this.bookingSelected = false;
            return;
        }
        this.setCheckinCheckoutUI(fromDate, toDate, totalNights);
        this.bookingSelected = true;
        const allDatesBooked = this.getAllBookedDates(
            fromDate,
            toDate,
            totalNights
        );

        const total = allDatesBooked.reduce(
            ({ totalBooked, totalFull }, pricingByDate) => {
                return {
                    totalBooked: totalBooked + pricingByDate.actualPrice,
                    totalFull: totalFull + pricingByDate.crossPrice,
                };
            },
            { totalBooked: 0, totalFull: 0 }
        );

        const { flightPrice } = this.selectedServiceOptionInternal;

        if (flightPrice) {
            total.totalBooked += flightPrice;
            total.totalFull += flightPrice;
        }

        this.bookingConfigured.emit({
            totalPrice: total.totalBooked,
            totalFullPrice: total.totalFull,
            flightPrice: flightPrice,
            productBookingData: {
                id: this.productDetails.id,
                type: this.productDetails.type,
                selectedServiceOptions: [this.selectedServiceOptionInternal],
                checkinDate: fromDate,
                checkoutDate: toDate,
                totalNights,
            },
        });
    }

    private getAllBookedDates(
        fromDate: NgbDate,
        toDate: NgbDate,
        totalNights: number
    ): ProductServicePricingByDate[] {
        const allBookedDates =
            this.pricingByDateForSelectedServiceOption.filter(
                (pricingByDate) =>
                    fromDate.equals(pricingByDate.date) ||
                    (fromDate.before(pricingByDate.date) &&
                        toDate.after(pricingByDate.date))
            );

        if (allBookedDates.length !== totalNights) {
            Sentry.captureMessage(
                `HB-DATES-MISMATCH-${this.productDetails.id}`
            );
        }

        return allBookedDates;
    }

    private resetBooking() {
        this.bookingConfigured.emit(emptyBookingConfiguredData);
        this.calendarHotel?.clearDateSelection();
        this.calendarHotel?.closeCalendar();
        this.bookingSelected = false;
    }

    private setCheckinCheckoutUI(
        fromDate: NgbDate,
        toDate: NgbDate,
        totalNights: number
    ): void {
        this.fromDate = fromDate
            ? transformNgbDateToDayJs(fromDate)
            : undefined;
        this.toDate = toDate ? transformNgbDateToDayJs(toDate) : undefined;
        this.totalNights = totalNights;
    }

    private setMultiplePrices(
        productServiceOption: ProductServiceOption,
        showSpinner = true
    ): void {
        this.startLoading(showSpinner);
        const { id, category } = this.productDetails;
        this.hotelBookingService
            .getMultiplePrices(id, category, productServiceOption.id)
            .subscribe((response) => {
                this.stopLoading();
                this.pricingByDateForSelectedServiceOption =
                    transformProductServicePricingDtoToPricingByDate(response);
                this.additionalCalendarDayInfo.clear();
                this.pricingByDateForSelectedServiceOption.forEach(
                    (pricingByDate) =>
                        this.additionalCalendarDayInfo.set(
                            pricingByDate.dateAsString,
                            {
                                date: pricingByDate.date,
                                tooltipContent: `${this.currencySymbol} ${pricingByDate.actualPrice}`,
                                minLengthOfStay: pricingByDate.minLenghtOfStay,
                            }
                        )
                );
                this.calendarHotel.openCalendar();
            });
    }

    private setServiceOptionOnDesktop(): void {
        const isMobile = window.innerWidth < screenSizes.mobile;
        if (!isMobile) {
            this.setSelectedServiceOption(
                this.visibleServiceCategoriesAndOptions[0]?.options[0],
                false
            );
        }
    }

    private startLoading(showSpinner: boolean): void {
        const isMobile = window.innerWidth < screenSizes.mobile;
        if (showSpinner || isMobile) {
            this.spinnerService.show();
        } else {
            this.desktopCalendarLoading = true;
        }
    }

    private stopLoading(): void {
        this.spinnerService.hide();
        this.desktopCalendarLoading = false;
    }
}
