<div *ngIf="averageScore > 0" class="review-area-box">
    <div class="progress-bar__wrapper">
        <progress
            id="progress-bar"
            value="{{ averageScore }}"
            [attr.style]="getProgressStyle()"
            max="10"
        ></progress>
    </div>
    <p>
        <strong>{{ averageScore }} </strong>
        <span>{{ label }}</span>
    </p>
</div>
