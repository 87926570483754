import { Component } from '@angular/core';

@Component({
    selector: 'md-product-detail-loading',
    styles: [
        `
            .pd-l-name {
                height: 24px;
                width: 340px;
                margin-bottom: 5px;
                border-radius: 4px;
            }
            .pd-l-image {
                position: relative;
                width: calc(100% - 8px);
                padding-top: 53%;
                margin-right: 8px;
                margin-bottom: 4px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            .pd-l-thumb {
                width: calc(14.2% - 4px);
                padding-top: 8%;
                position: relative;
                margin-right: 4px;
            }
            .pd-l-option {
                height: 54px;
                width: 100%;
                margin: 4px 0;
                border-radius: 4px;
            }
            .pd-l-image-container {
                max-width: 830px;
                width: calc(100vw - 350px);

                @media (min-width: 1220px) {
                    width: calc(100vw - 376px);
                }
            }
            .pd-l-booking-container {
                width: 350px;
                border-radius: 10px;
                height: 550px;
                padding: 20px;
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

                @media (min-width: 1220px) {
                    width: 376px;
                }
            }
        `,
    ],
    template: `
        <div>
            <div mdDesktopOnly style="max-width:1220px">
                <div>
                    <div mdSkeleton class="pd-l-name"></div>
                    <div
                        mdSkeleton
                        style="height: 18px; width: 440px; margin-bottom: 10px;  border-radius: 4px"
                    ></div>
                </div>
                <div style="display: flex; flex-direction: row;">
                    <div class="pd-l-image-container">
                        <div mdSkeleton class="pd-l-image"></div>
                        <div style="display: flex; flex-direction: row;">
                            <div
                                mdSkeleton
                                class="pd-l-thumb"
                                style=" border-bottom-left-radius: 10px"
                            ></div>
                            <div mdSkeleton class="pd-l-thumb"></div>
                            <div mdSkeleton class="pd-l-thumb"></div>
                            <div mdSkeleton class="pd-l-thumb"></div>
                            <div mdSkeleton class="pd-l-thumb"></div>
                            <div mdSkeleton class="pd-l-thumb"></div>
                            <div
                                mdSkeleton
                                class="pd-l-thumb"
                                style=" border-bottom-right-radius: 10px "
                            ></div>
                        </div>
                    </div>
                    <div class="pd-l-booking-container">
                        <div
                            mdSkeleton
                            style="height: 18px; width: 160px; margin-bottom: 12px; border-radius: 4px;"
                        ></div>
                        <div
                            mdSkeleton
                            style="height: 40px; width: 100%; margin: 4px 0; border-radius: 4px;"
                        ></div>
                        <div
                            mdSkeleton
                            style="height: 18px; width: 100%; margin: 8px 0 5px; border-radius: 4px;"
                        ></div>
                        <div mdSkeleton class="pd-l-option"></div>
                        <div mdSkeleton class="pd-l-option"></div>
                        <div mdSkeleton class="pd-l-option"></div>
                    </div>
                </div>
            </div>
            <div mdMobileOnly>
                <div
                    mdSkeleton
                    style="position: relative;
                    width: 100%;
                    aspect-ratio: 1.68; margin-bottom: 12px"
                ></div>
                <div style="margin: 0 8px">
                    <div
                        mdSkeleton
                        style="height: 24px; margin: 0 0 5px 0; width: 70%; border-radius: 4px"
                    ></div>
                    <div
                        style="height: 55px; width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 8px"
                    >
                        <div
                            mdSkeleton
                            style="height: 18px; width: 70px; border-radius: 4px"
                        ></div>

                        <div
                            style="display: flex; flex-direction: row; align-items: center;"
                        >
                            <div
                                mdSkeleton
                                style="height: 18px; width: 80px; margin-right: 8px; border-radius: 4px"
                            ></div>
                            <div
                                mdSkeleton
                                style="height: 55px; width: 55px; border-radius: 27px;"
                            ></div>
                        </div>
                    </div>
                    <div
                        mdSkeleton
                        style="height: 22px; width: 30%; margin: 12px 0; border-radius: 4px"
                    ></div>
                    <div
                        mdSkeleton
                        style="height: 40px; margin: 4px; border-radius: 8px"
                    ></div>
                    <div
                        mdSkeleton
                        style="height: 22px; width: 80%; margin: 12px 0; border-radius: 4px"
                    ></div>
                    <div
                        mdSkeleton
                        style="height: 60px; margin: 5px; border-radius: 8px"
                    ></div>
                    <div
                        mdSkeleton
                        style="height: 60px; margin: 5px; border-radius: 8px"
                    ></div>
                    <div
                        mdSkeleton
                        style="height: 60px; margin: 5px; border-radius: 8px"
                    ></div>
                    <div
                        mdSkeleton
                        style="height: 60px; margin: 5px; border-radius: 8px"
                    ></div>
                </div>
            </div>
        </div>
    `,
})
export class ProductDetailLoadingComponent {}
