<div
    [ngStyle]="{
        width: '48%',
        position: 'relative'
    }"
    *ngIf="visibleSearchBarType"
    class="header-section header-logo header-div he-search-container"
>
    <div style="position: static; height: 31px">
        <div
            class="item item-input search-center he-plain-search"
            id="advanceHeader"
        >
            <div class="item item-input he-plain-search-input">
                <input
                    (keydown)="searchKeydown($event)"
                    (keydown.enter)="submitSearch()"
                    style="height: 3.4rem"
                    id="searchboxinput"
                    type="search"
                    name="searchBar"
                    [(ngModel)]="searchQuery"
                    [placeholder]="'Search' | translate"
                    [value]="searchQuery"
                    autocomplete="off"
                />
                <i (click)="submitSearch()" class="fa fa-search search"></i>
                <i
                    *ngIf="searchQuery"
                    id="emptybar"
                    class="icon ion-close placeholder-icon"
                    (click)="clearSearch()"
                ></i>
            </div>
        </div>
    </div>
</div>
