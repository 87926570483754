<md-profile-wrapper [activeSection]="ProfileSection.GIFT_CARDS">
    <md-profile-page-title
        [title]="'Rewards & Wallet' | translate"
        [description]="'Redeem your Gift Cards and Store Credit.' | translate"
    />
    <div class="ma-raw-switch">
        <button
            class="ma-raw-switch-button"
            [ngClass]="{ active: activeTab === 'gift-card' }"
            (click)="activeTab = 'gift-card'"
        >
            <p class="ma-raw-switch-title">{{ "My Gift Card" | translate }}</p>
            <p class="ma-raw-switch-subtitle">
                {{ "Credit Balance" | translate }}:
                <strong>{{
                    giftCardBalance
                        | currency
                            : currencySymbol
                            : "symbol"
                            : "1.0-0"
                            : locale
                }}</strong>
            </p>
        </button>
        <button
            class="ma-raw-switch-button"
            [ngClass]="{ active: activeTab === 'store-credit' }"
            (click)="activeTab = 'store-credit'"
        >
            <p class="ma-raw-switch-title">
                {{ "My Store Credit" | translate }}
            </p>
            <p class="ma-raw-switch-subtitle">
                {{ "Credit Balance" | translate }}:
                <strong>{{
                    storeCredit
                        | currency
                            : currencySymbol
                            : "symbol"
                            : "1.0-0"
                            : locale
                }}</strong>
            </p>
        </button>
    </div>
    <md-gift-voucher *ngIf="activeTab === 'gift-card'" />
    <md-store-credit *ngIf="activeTab === 'store-credit'" />
</md-profile-wrapper>
