import { Component, OnInit } from '@angular/core';
import { appConfig } from '../../config/app-config';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/common.service';
import { TranslateService } from '@ngx-translate/core';
import { EventService, EventType } from '../../utilities/event.service';
import { getUser } from '../../utilities/user.utils';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

@Component({
    selector: 'app-order-confirmation',
    templateUrl: './order-confirmation.component.html',
    styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent implements OnInit {
    ipaytext: string = this.translate.instant(
        'Thank you for your order,Payment is pending to finalize this reservation,Please check your email for our bank details'
    );
    ipaytextSuccess: string = this.translate.instant(
        'Thank you for your order'
    );
    ipayBottomtext: string =
        this.translate.instant('Remember') +
        ':' +
        this.translate.instant(
            'Any interbank transfer will take 2 to 3 days to receive in our account'
        ) +
        ',' +
        this.translate.instant(
            'so if you want to redeem offer within the next 3 days or reduce the risk of offer selling out'
        ) +
        ',' +
        this.translate.instant(
            'finalize payment directly in 1 our accounts mentioned above'
        );
    mipstext: string =
        this.translate.instant('Payment Successful!') +
        '\n' +
        this.translate.instant(
            'Booking Confirmation & Invoice has been sent to your Email Address, Thank you for Choosing MariDeal'
        ) +
        '.';
    userLogin!: any;
    orderdata: any;
    appConfig = appConfig;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';

    constructor(
        private router: Router,
        private eventService: EventService,
        public commonService: CommonService,
        private translate: TranslateService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    ngOnInit(): void {
        window.scrollTo(0, 0);
        let userData = getUser();
        if (!userData) {
            this.eventService.emitEvent(EventType.LOGIN);
        } else {
            this.orderdata = JSON.parse(
                this.commonService.getCache(this, 'orderdata').data
            );
            this.commonService.removeCache(this, 'cartItems');
            this.commonService.clearmytimer();
            this.commonService.removeCache(this, 'cartItemsCount');
            this.commonService.removeCache(this, 'cartItemsdataCount');
        }
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    continueShoping() {
        this.commonService.removeCache(this, 'orderdata');
        this.router.navigate(['/']);
    }
}
