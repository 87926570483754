import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[mdMainButton]',
})
export class MainButtonDirective implements OnInit {
    @Input() mdMainButton: 'light' | 'dark';

    @Input() mdIconSize: 'small' | 'medium' | 'large';

    constructor(private el: ElementRef) {
        this.el.nativeElement.classList.add('c-mb-main-button');
    }

    ngOnInit(): void {
        switch (this.mdMainButton) {
            case 'light':
                this.el.nativeElement.classList.add('light');
                break;
            case 'dark':
                this.el.nativeElement.classList.add('dark');
                break;
        }

        switch (this.mdIconSize) {
            case 'small':
                this.el.nativeElement.classList.add('small-icon');
                break;
            case 'medium':
                this.el.nativeElement.classList.add('medium-icon');
                break;
            case 'large':
                this.el.nativeElement.classList.add('large-icon');
                break;
            default:
                this.el.nativeElement.classList.add('medium-icon');
                break;
        }
    }
}
