import { ChangeData } from "@capgo/ngx-intl-tel-input/lib/interfaces/change-data";

export class CreateUserDto {
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    others: never;
    mobile: ChangeData;
    dialCode: string;
    countryCode: string;
    otp: string;
    dob: string;
    customerId: string;
    password: string;
    confirmPassword: string;
}

export class RegisterResponseDto {
    id: string | number;
    groupId: string;
    preferredLanguage: string;
    status: string;
    dummyCustomerId: string;
    message: string;
    jwtToken: string
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    availableBadges: number;
    rewardData: RegisterRewardDataDto;
}

export class RegisterRawResponseDto {
    id: string | number;
    group_id: string;
    preferred_language: string
    status: string;
    dummy_customer_id: string;
    message: string;
    jwt_token: string;
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    availed_badges: number;
    reward_data: RegisterRewardRawDataDto;
}

export class RegisterRewardDataDto {
    balancePoints: number;
}

export class RegisterRewardRawDataDto {
    balance_points: number
}

export class ResendOtpResponseDto {
    status: string;
    message: string;
}

export const transform = (response: RegisterRawResponseDto): RegisterResponseDto => {
    return {
        id: response.id,
        groupId: response.group_id,
        preferredLanguage: response.preferred_language,
        status: response.status,
        dummyCustomerId: response.dummy_customer_id,
        message: response.message,
        jwtToken: response.jwt_token,
        firstName: response.firstname,
        lastName: response.lastname,
        username: response.username,
        email: response.email,
        availableBadges: response.availed_badges,
        rewardData: {
            balancePoints: response.reward_data?.balance_points
        } as RegisterRewardDataDto,
    } as RegisterResponseDto;
}