<div class="cbr-container">
    <a
        *ngFor="let banner of visibleBanners"
        [href]="banner.url"
        class="cbr-anchor"
    >
        <img
            style="border-radius: 8px"
            src="assets/img/deal_background.png"
            async
            [lazyLoad]="banner.imageUrl"
        />
        <span class="cbr-overlay"></span>
        <h1 class="cbr-text">
            {{ banner.text }}
        </h1>
    </a>
</div>
