import {Component, OnInit} from '@angular/core';
import {CommonService} from 'src/app/shared/common.service';
import {SwiperOptions} from 'swiper';
import {Router} from '@angular/router';
import {RecentService} from "./recent.service";
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-recent',
  templateUrl: './recent.component.html',
  styleUrls: ['./recent.component.scss']
})
export class RecentComponent implements OnInit {
  recentProductsViewed: any;
  userLogin: any | null | undefined;
  recentProductsViewedLength: any;
  isRecentData: boolean = false;
  imgCdn!: string;
  shared_data: any;
  advancesearch: string;
  private subscription: Subscription;

  constructor(
    public commonService: CommonService,
    private router: Router,
    private recentService: RecentService) {
      this.imgCdn = this.commonService.imgCdnPath;
      this.subscription = this.commonService.currentData.subscribe((data: any) => {
        this.shared_data = data;
      });
  }

  ngOnInit(): void {

    this.getRecentViewedDeals();
  }

  config: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30
  };

  getStars(rating: string) {
    let size = 0
    if (rating) {
      let val = parseFloat(rating);
      // Turn value into number/100000000
      size = val / 5 * 100;
    }
    // this.avgStar = false
    return size + '%';
  }

  redirectToUrl(fullUrl: any) {
    let a = new URL(fullUrl)
    let pathNameStr = a.pathname;
    let searchValuesStr = a.search;
    let urlWithoutDomain = pathNameStr + searchValuesStr;
    this.router.navigate([urlWithoutDomain]);
  }

  getUrlPathName(prodId: any, prodUrl: any, prodCatId: any, prodAdvSearchOptionId: any) {
    this.redirectToProdDetail(prodId, prodUrl, prodAdvSearchOptionId, prodCatId);
  }
  redirectToProdDetail(prodId: any, prod_url: any, advance_search_optionid: any, catId: any) {
    this.shared_data.checkCatId = ''
    this.shared_data.advance_search_optionid = advance_search_optionid
    if (localStorage.getItem('chk_toggle') == "1") {
        localStorage.setItem('chk_toggle', '')
    }
    if (localStorage.getItem('searchbar_toggle') == "1") {
        localStorage.setItem('searchbar_toggle', '')
    }
    if (this.advancesearch == "") {
        this.commonService.setCache(this, 'checkin_date', '');
        this.commonService.setCache(this, 'checkout_date', '');
    }
    let canonical_url_last = prod_url;
    canonical_url_last = canonical_url_last.split('/')[canonical_url_last.split('/').length - 1];
    this.commonService.setCache(this, 'selectedpro_opt', '');
      console.log("canonical_url_last", canonical_url_last)
      this.router.navigate(['deal/' + canonical_url_last], {
          state: {
              "productId": prodId,
              "canonical_url_last": canonical_url_last,
              "advancesearch": this.advancesearch,
              "catId": catId,
          }
      }).then(()=>{
        localStorage.setItem('productCategoryId',catId)
      });
  }

  getRecentViewedDeals() {
      this.isRecentData = true;
    let storedRecent: any;
    storedRecent = localStorage.getItem("recent");
    storedRecent = JSON.parse(storedRecent);
    let reverse_arr: Array<string> = [];
    if (storedRecent && typeof storedRecent !== undefined) {
      while (storedRecent.length) {
        reverse_arr.push(storedRecent.pop());
      }
    }
    let pro_arr: any = this.commonService.encode(JSON.stringify(reverse_arr));
    this.recentService.getRecentlyViewedData(pro_arr).subscribe((response: any) => {
        this.isRecentData = false;
      // for (let item in response.data) {
      //   response.data[item].price = parseFloat(this.commonService.removeDecimal(response.data[item].price));
      //   response.data[item].spclprice = parseFloat(this.commonService.removeDecimal(response.data[item].spclprice));
      // }
      this.recentProductsViewed = response.data;
      this.recentProductsViewedLength = response.data.length;
      setTimeout(() => {
        this.commonService.RecentcontentLoaded = true;
      }, 1000)
    }, error => {
        this.isRecentData = false;
      console.error("error from getRecentlyViewedData API ==> " + error);
    });
  }
}
