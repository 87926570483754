import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { CategoryListService } from '../category-deals-list/category-deals-list.service';
import { AppliedFilterIds, Filter, SortLabel } from './filter-data.model';
import { ScreenSizeService } from '../services/screen-size.service';
import { FilterDataService } from './filter-data.service';
import { debounce } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { EVENT_CATEGORY } from '../static-content/menu-routes';

@Component({
    selector: 'app-filter-data',
    templateUrl: './filter-data.component.html',
    styleUrls: ['./filter-data.component.scss'],
})
export class FilterDataComponent {
    @Input() idsAlreadyAppliedFilters: AppliedFilterIds[];
    @Input() showClosestDate = false;
    @Input() sortLabel: SortLabel;

    @Output() sort = new EventEmitter<SortLabel>();
    @Output() applyFilters = new EventEmitter<Filter[]>();

    filterOptions: Filter[] = [];
    filterForOpenOverlay: Filter;
    shouldShowClosestDate = false;

    categoryListConfig: SwiperOptions = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 3.5,
            },

            420: {
                slidesPerView: 4.2,
            },
        },
    };

    constructor(
        public categoryService: CategoryListService,
        public screenSizeService: ScreenSizeService,
        private filterDataService: FilterDataService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            const sectionId = Number.parseInt(params['sectionId']);
            this.shouldShowClosestDate = sectionId === EVENT_CATEGORY;
            this.filterDataService
                .getFilterData(sectionId)
                .subscribe((data) => {
                    this.filterOptions = data.filters;
                    this.setFiltersBasedOnIds(this.filterOptions);
                });
        });
    }

    filterOverlayOpen(filter: Filter) {
        this.filterForOpenOverlay = filter;
    }

    onFilterValueChange(index: number) {
        const thisFilter = this.filterForOpenOverlay.filterValues[index];
        if (this.filterForOpenOverlay.filterTypeId === 1) {
            this.filterForOpenOverlay.filterValues.forEach((filterValue, i) => {
                filterValue.isSelected = i === index;
            });
        } else {
            thisFilter.isSelected = !thisFilter.isSelected;
        }

        if (thisFilter.isSelected) {
            this.filterForOpenOverlay.isSelected = true;
        } else {
            this.filterForOpenOverlay.isSelected =
                this.filterForOpenOverlay.filterValues.some(
                    (filterValue) => filterValue.isSelected
                );
        }

        this.filterForOpenOverlay.filterValues[index] = thisFilter;
    }

    filterChanged(filter: Filter) {
        this.filterOptions = this.filterOptions.map((f) => {
            if (f.filterTypeId === filter.filterTypeId) {
                return filter;
            }
            return f;
        });
        this.debounceFilterChangeCall();
    }

    applySort(): void {
        this.sort.emit(this.sortLabel);
    }

    setSortLabel(sortLabel: SortLabel) {
        this.sortLabel = sortLabel;
    }

    resetSorting() {
        this.sortLabel = null;
        this.applySort();
    }

    resetFilters() {
        this.filterOptions = this.filterOptions.map((f) => {
            f.filterValues = f.filterValues.map((fv) => {
                fv.isSelected = false;
                return fv;
            });
            f.isSelected = false;
            return f;
        });
        this.applyFilters.emit(this.filterOptions);
    }

    private debounceFilterChangeCall = debounce(() => {
        this.applyFilters.emit(this.filterOptions);
    }, 2000);

    // TODO: this can be optimised to use the filterTypeId too
    private setFiltersBasedOnIds(filterOptions: Filter[]) {
        if (this.idsAlreadyAppliedFilters) {
            filterOptions = filterOptions.map((f) => {
                f.filterValues = f.filterValues.map((fv) => {
                    if (
                        this.idsAlreadyAppliedFilters
                            .map((idAAF) => idAAF.filterId)
                            .includes(fv.filterId)
                    ) {
                        fv.isSelected = true;
                    }
                    return fv;
                });
                f.isSelected = f.filterValues.some((fv) => fv.isSelected);
                return f;
            });
        }
    }
}
