import { Component, Input, OnInit, OnChanges } from '@angular/core';
import {
    ACTIVITY_CATEGORY,
    DAY_PACKAGE_CATEGORY,
    HOTEL_CATEGORY,
    MARIBNB_CATEGORY,
    RESTAURANT_CATEGORY,
    RODRIGUES_CATEGORY,
    SPA_CATEGORY,
} from 'src/app/static-content/menu-routes';

interface BannerReelItem {
    imageUrl: string;
    text: string;
    url: string;
    categoryId: number;
}

@Component({
    selector: 'md-banner-reel',
    templateUrl: './category-banner-reel.component.html',
    styleUrls: ['./category-banner-reel.component.scss'],
})
export class CategoryBannerReelComponent implements OnInit, OnChanges {
    @Input() categoryId: number;

    banners: BannerReelItem[] = [
        // {
        //     imageUrl: '/assets/img/category-reel/all-inclusive.webp',
        //     text: 'All-Inclusive',
        //     url: '/plp/Accommodation/3?popular_filters=3919',
        //     categoryId: HOTEL_CATEGORY,
        // },
        // {
        //     imageUrl: '/assets/img/category-reel/maribnb.png',
        //     text: 'Maribnb',
        //     url: 'plp/MariBnb/21',
        //     categoryId: HOTEL_CATEGORY,
        // },
        {
            imageUrl: '/assets/img/category-reel/evening-package.jpg',
            text: 'Evening Package',
            url: '/plp/Day%20Packages/8?filters=24-1300',
            categoryId: DAY_PACKAGE_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/room-meals.jpg',
            text: 'Room & Meals',
            url: '/plp/Day%20Packages/8?filters=24-1320',
            categoryId: DAY_PACKAGE_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/all-inclusive-package.jpg',
            text: 'All-Inclusive Package',
            url: '/plp/Day%20Packages/8?filters=24-1340',
            categoryId: DAY_PACKAGE_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/relaxing-massage.jpg',
            text: 'Relaxing Massage',
            url: '/plp/Beauty%20&%20Spa/6?filters=25-5300',
            categoryId: SPA_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/spa-break.jpg',
            text: 'Spa Break',
            url: '/plp/Beauty%20&%20Spa/6?filters=45-5540',
            categoryId: SPA_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/spa-flash-sales.jpg',
            text: 'Flash Sales',
            url: '/plp/Beauty%20&%20Spa/6?filters=45-5560',
            categoryId: SPA_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/nature-park.jpg',
            text: 'Nature Parks',
            url: '/plp/Things%20to%20do/7?filters=42-4400',
            categoryId: ACTIVITY_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/quadaug.jpg',
            text: 'Quad & Buggy',
            url: '/plp/Things%20to%20do/7?filters=42-4380',
            categoryId: ACTIVITY_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/catamaran.jpg',
            text: 'Catamaran',
            url: '/plp/Things%20to%20do/7?filters=40-3840',
            categoryId: ACTIVITY_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/resto-lunch.jpg',
            text: 'Lunch',
            url: '/plp/Restaurants/4?filters=43-4780',
            categoryId: RESTAURANT_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/resto-buffet.jpg',
            text: 'Buffet',
            url: '/plp/Restaurants/4?filters=43-4680',
            categoryId: RESTAURANT_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/resto-hotel-resto.jpg',
            text: 'Hotel-Restaurant',
            url: '/plp/Restaurants/4?filters=31-2560',
            categoryId: RESTAURANT_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/rodrigues-hotels.jpeg',
            text: 'Hotels',
            url: '/plp/Rodrigues/9?filters=36-2820',
            categoryId: RODRIGUES_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/rodrigues-excursions.jpeg',
            text: 'Excursions',
            url: '/plp/Rodrigues/9?filters=36-2840',
            categoryId: RODRIGUES_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/rodrigues-car-rental.jpeg',
            text: 'Car Rental',
            url: '/plp/Rodrigues/9?filters=36-2860',
            categoryId: RODRIGUES_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/maribnb-hotels.jpeg',
            text: 'Hotels',
            url: '/plp/Accommodation/3',
            categoryId: MARIBNB_CATEGORY,
        },
        {
            imageUrl: '/assets/img/category-reel/maribnb-day-package.jpeg',
            text: 'Day package',
            url: '/plp/Day%20Packages/8',
            categoryId: MARIBNB_CATEGORY,
        },
    ];

    visibleBanners: BannerReelItem[] = [];

    ngOnInit() {
        this.filterBanners();
    }

    ngOnChanges() {
        this.filterBanners();
    }

    filterBanners() {
        this.visibleBanners = this.banners.filter(
            (banner) => banner.categoryId === this.categoryId
        );
    }
}
