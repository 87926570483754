import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, mergeMap, shareReplay } from 'rxjs';
import {
    CORPORATE_LOGGED_IN_CURR,
    EMPLOYEE_LOGGED_IN_CURR,
    MAURITIUS_LOGGED_IN_CURR,
    MAURITIUS_NOT_LOGGED_IN_CURR,
    OTHER_LOGGED_IN_CURR,
    OTHER_NOT_LOGGED_IN_CURR,
    REUNION_LOGGED_IN_CURR,
    REUNION_NOT_LOGGED_IN_CURR,
    countryCodeToFullCountryNameMap,
    currencyIdToCountryCodeMap,
    languageIdToLanguageCodeMap,
} from '../utilities/location.utils';
import {
    CUSTOMER_GROUP_ID,
    IS_REUNION,
    LANGUAGE,
    STORE_ID,
} from '../utilities/local-storage.keys';
import { getUser } from '../utilities/user.utils';
import { getTokenUrlMofluid } from '../utilities/url.utils';

export interface LocaleData {
    language: 'en' | 'fr';
    currency: 'Rs.' | 'EUR';
    country: 'MU' | 'RE' | 'OT';
    localeForCurrency: 'en-MU' | 'fr-RE';
}
import { EventService, EventType } from '../utilities/event.service';

@Injectable({
    providedIn: 'root',
})
export class CurrencyLanguageService {
    private localeData: LocaleData;

    constructor(
        private commonService: CommonService,
        private httpClient: HttpClient,
        private tokenService: TokenService,
        private translate: TranslateService,
        private eventService: EventService
    ) {
        this.commonService = commonService;
        this.httpClient = httpClient;
        this.tokenService = tokenService;
        this.translate = translate;
    }

    private firstCall$: Observable<void>;

    setCurrencyAndLanguageOnAppInit(): Observable<void> {
        if (this.firstCall$) {
            return this.firstCall$;
        }

        this.firstCall$ = this.getToken()
            .pipe(
                map((response) => {
                    this.tokenService.saveToken(response[0].token);
                    if (!this.commonService.isLoggedIn) {
                        return this.getCountryLanguageFromServer().pipe(
                            map((result: any) => {
                                const url = new URL(window.location.href);
                                const args = new URLSearchParams(url.search);

                                if (!args.has('iso')) {
                                    this.setCountryLanguageBasedOnServerResponse(
                                        result[0]
                                    );
                                } else {
                                    this.setCountryLanguageBasedOnIso(args);
                                }
                            })
                        );
                    } else {
                        return this.setCountryLanguageForLoggedInUser();
                    }
                })
            )
            .pipe(
                mergeMap((x) => x),
                shareReplay(1)
            );

        return this.firstCall$;
    }

    addIsoToUrlIfNeeded() {
        const url = new URL(window.location.href);
        const args = new URLSearchParams(url.search);
        if (!args.has('iso')) {
            const lang: string = this.commonService.getCache(
                this,
                'language'
            )?.data;
            const curr: string | number =
                this.commonService.getCustomerGroupID();

            if (lang != null && curr != null && lang != 'undefined') {
                const urlh = new URL(window.location.href);
                const countryCode = currencyIdToCountryCodeMap.get(curr);
                const langCode = languageIdToLanguageCodeMap.get(lang);
                urlh.searchParams.set('iso', `${countryCode}-${langCode}`);
                history.replaceState(null, '', urlh.toString());
            }
        }
    }

    private setCountryLanguageBasedOnServerResponse(response: any) {
        // if nothing in the URL we set to what we get from the service
        const nothingFromTheService = !response;

        const defaultCountryCode = nothingFromTheService
            ? 'MU'
            : response.countryCode;
        const defaultLanguage = nothingFromTheService
            ? 'EN'
            : response.customerGrouplan;
        const customerGroup = nothingFromTheService
            ? 0
            : response.customerGroupId;
        this.commonService.setCustomerGroupID(customerGroup);
        const newSearchParams = new URLSearchParams();
        newSearchParams.set('iso', `${defaultCountryCode}-${defaultLanguage}`);
        this.translate.use(defaultLanguage.toLocaleLowerCase());
        const updateUrl =
            window.location.origin +
            window.location.pathname +
            '?' +
            newSearchParams.toString();
        history.replaceState(null, '', updateUrl);
        // we also need to set the localStorage values if we get nothing from the service
        if (nothingFromTheService) {
            this.setCustomerGroupIdBasedOnIso(defaultCountryCode, undefined);
            this.setLanguageBasedOnIso(defaultLanguage);
            // need to notify the language picker that the country and language was set
            this.commonService.updateCountry.next(
                countryCodeToFullCountryNameMap.get(defaultCountryCode)
            );
            this.commonService.updateLang.next(defaultLanguage);
        }
        this.eventService.emitEvent(EventType.AUTH_LOADED);
    }

    private setCountryLanguageBasedOnIso(args: URLSearchParams) {
        // if we have stuff in the URL we just set as that\
        const isoShards = args.get('iso')?.split('-');
        const curr = isoShards?.[0];
        const lang = isoShards?.[1];
        this.translate.use(lang.toLocaleLowerCase());
        this.setCustomerGroupIdBasedOnIso(curr, undefined);
        this.setLanguageBasedOnIso(lang);
        // need to notify the language picker that the country and language was set
        this.commonService.updateCountry.next(
            countryCodeToFullCountryNameMap.get(curr as 'MU' | 'RE' | 'OT')
        );
        this.commonService.updateLang.next(lang);
        this.eventService.emitEvent(EventType.AUTH_LOADED);
    }

    private setCountryLanguageForLoggedInUser() {
        const userdata = getUser();
        return this.customerGroupCall(userdata.id).pipe(
            map((res: any) => {
                const customerGroupId = Number(res?.group_id);
                this.commonService.setCustomerGroupID(customerGroupId);
                // we need to also set the iso here if not present because it may come from some other service
                // here we should already have the language and storeId in the cache. If not we set the defaults
                let lang = this.commonService.getCache(this, LANGUAGE)?.data;
                let storeId = this.commonService.getCache(this, STORE_ID)?.data;
                if (!lang) {
                    // language should be in local storage for logged in users. If it is not, let it to French for RE and English otherwise
                    lang =
                        customerGroupId == REUNION_LOGGED_IN_CURR ? 'fr' : 'en';
                    this.commonService.setCache(this, LANGUAGE, lang);
                }
                this.translate.use(lang.toLocaleLowerCase());
                if (storeId == undefined) {
                    storeId = customerGroupId == REUNION_LOGGED_IN_CURR ? 2 : 1;
                    this.commonService.setCache(this, STORE_ID, storeId);
                }
                this.eventService.emitEvent(EventType.AUTH_LOADED);
            })
        );
    }
    private getCountryFromCustomerGroup(customerGroupId: number) {
        switch (customerGroupId) {
            case MAURITIUS_LOGGED_IN_CURR:
            case MAURITIUS_NOT_LOGGED_IN_CURR:
                return 'MU';
            case REUNION_LOGGED_IN_CURR:
            case REUNION_NOT_LOGGED_IN_CURR:
                return 'RE';
            default:
                return 'OT';
        }
    }

    private getCountryLanguageFromServer() {
        return this.bdcCall().pipe(
            map((res: any) => {
                return this.ipLocationCall(res.ip);
            })
        );
    }

    private bdcCall(): Observable<any> {
        return this.httpClient.get(
            'https://api-bdc.net/data/country-by-ip?key=bdc_1e143401716241d79e575f569367dd2e'
        );
    }
    private ipLocationCall(ip: string): Observable<any> {
        return this.httpClient.get(
            this.commonService.api_url +
                'getIpLocation?service=getIpLocation&ip=' +
                ip,
            {
                headers: this.commonService.getTokenHeaders(),
            }
        );
    }

    private customerGroupCall(customerId: string) {
        return this.httpClient.get(
            this.commonService.api_url +
                'getCustomerGroup?service=getCustomerGroup&customer_id=' +
                customerId,
            { headers: this.commonService.getTokenHeaders() }
        );
    }

    private getCurrencyFromCustomerGroup(customerGroupId: number) {
        const currency =
            customerGroupId === MAURITIUS_LOGGED_IN_CURR ||
            customerGroupId === MAURITIUS_NOT_LOGGED_IN_CURR ||
            customerGroupId === EMPLOYEE_LOGGED_IN_CURR ||
            customerGroupId === CORPORATE_LOGGED_IN_CURR
                ? 'Rs.'
                : 'EUR';
        return currency;
    }

    private getLocaleFromCustomerGroup(customerGroupId: number) {
        const locale =
            customerGroupId === MAURITIUS_LOGGED_IN_CURR ||
            customerGroupId === MAURITIUS_NOT_LOGGED_IN_CURR
                ? 'en-MU'
                : 'fr-RE';
        return locale;
    }

    getLocaleData(): Observable<LocaleData> {
        return this.eventService
            .onEventOrAlreadyHappened(EventType.AUTH_LOADED)
            .pipe(
                map(() => {
                    const language = this.commonService.getCache(
                        this,
                        'language'
                    )?.data;
                    const customerGroupId =
                        this.commonService.getCustomerGroupID();
                    const currency =
                        this.getCurrencyFromCustomerGroup(customerGroupId);
                    const country =
                        this.getCountryFromCustomerGroup(customerGroupId);
                    const localeForCurrency =
                        this.getLocaleFromCustomerGroup(customerGroupId);

                    return { language, currency, country, localeForCurrency };
                })
            );
    }

    getCurrency(): Observable<'Rs.' | 'EUR'> {
        return this.eventService
            .onEventOrAlreadyHappened(EventType.AUTH_LOADED)
            .pipe(
                map(() => {
                    const customerGroupId =
                        this.commonService.getCustomerGroupID();
                    return this.getCurrencyFromCustomerGroup(customerGroupId);
                })
            );
    }

    // TODO: This is not quite right. The locale should regard both the language and the country but there is no en-RE for example
    getLocaleForCurrency(): Observable<'en-MU' | 'fr-RE'> {
        return this.eventService
            .onEventOrAlreadyHappened(EventType.AUTH_LOADED)
            .pipe(
                map(() => {
                    const lang = this.commonService.getCache(
                        this,
                        'language'
                    )?.data;
                    return lang === 'en' ? 'en-MU' : 'fr-RE';
                })
            );
    }

    private setLanguageBasedOnIso(langn: string) {
        if (langn == 'EN') {
            this.commonService.setCache(this, 'language', 'en');
            this.commonService.setCache(this, 'storeID', 1);
        } else if (langn == 'FR') {
            this.commonService.setCache(this, 'language', 'fr');
            this.commonService.setCache(this, 'storeID', 2);
        }
    }
    private setCustomerGroupIdBasedOnIso(
        currn: string,
        latest: number | undefined
    ) {
        const allLoggedIn = [
            MAURITIUS_LOGGED_IN_CURR,
            REUNION_LOGGED_IN_CURR,
            OTHER_LOGGED_IN_CURR,
        ];

        if (currn == 'MU') {
            this.setCustomerGroupForMauritius(allLoggedIn, latest);
            this.commonService.setCache(this, IS_REUNION, false);
        } else if (currn == 'RE') {
            this.setCustomerGroupForReunion(allLoggedIn, latest);
            this.commonService.setCache(this, IS_REUNION, true);
        } else if (currn == 'OT') {
            this.setCustomerGroupForOther(allLoggedIn, latest);
            this.commonService.setCache(this, IS_REUNION, false);
        }
    }
    private setCustomerGroupForOther(
        allLoggedIn: number[],
        latest: number | undefined
    ) {
        if (allLoggedIn.includes(latest)) {
            this.commonService.setCache(
                this,
                CUSTOMER_GROUP_ID,
                OTHER_LOGGED_IN_CURR
            );
        } else {
            this.commonService.setCache(
                this,
                CUSTOMER_GROUP_ID,
                OTHER_NOT_LOGGED_IN_CURR
            );
        }
    }
    private setCustomerGroupForReunion(allLoggedIn: number[], latest: number) {
        if (allLoggedIn.includes(latest)) {
            this.commonService.setCache(
                this,
                CUSTOMER_GROUP_ID,
                REUNION_LOGGED_IN_CURR
            );
        } else {
            this.commonService.setCache(
                this,
                CUSTOMER_GROUP_ID,
                REUNION_NOT_LOGGED_IN_CURR
            );
        }
    }
    private setCustomerGroupForMauritius(
        allLoggedIn: number[],
        latest: number
    ) {
        if (allLoggedIn.includes(latest)) {
            // in this case don't do anything because user is logged in and we don't want to change country
            this.commonService.setCache(
                this,
                CUSTOMER_GROUP_ID,
                MAURITIUS_LOGGED_IN_CURR
            );
        } else {
            this.commonService.setCache(
                this,
                CUSTOMER_GROUP_ID,
                MAURITIUS_NOT_LOGGED_IN_CURR
            );
        }
    }
    private getToken() {
        let headers = new HttpHeaders({
            Authorization: 'Basic ' + btoa('DPwt23!:pgYD!5Z3R0Rf'),
            'Content-Type': 'application/json',
        });
        return this.httpClient.get(getTokenUrlMofluid(), { headers: headers });
    }
}
