import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { BillingAddressDto, CheckoutAddress } from './checkout.model';

@Injectable({
    providedIn: 'root',
})
export class CheckoutUtilsService {
    isGuestNameFilled: boolean;
    areChildrenAgesValid: boolean;
    showGetPaymentMethodSkeleton: boolean = false;

    constructor(
        private translate: TranslateService,
        private eventService: EventService
    ) {
        // Constructor logic here
    }

    validate_guest(guests: any[], isShowAlert: any): boolean {
        let flag = !0;
        let sameproduct_guestname = [];
        let guest_name_with_id = new Object();
        let temp_id = '';

        guests.forEach((value: any) => {
            if (flag) {
                sameproduct_guestname = [];
                temp_id = '';
                value.forEach((value: any) => {
                    if (flag) {
                        temp_id = value.id;
                        if (value.name == '') {
                            value.isempty = !0;
                            if (isShowAlert) {
                                this.eventService.emitEvent(
                                    EventType.MODAL_ERROR,
                                    this.translate.instant(
                                        'Guest name cannot be empty'
                                    )
                                );
                            }
                            flag = !1;
                        } else if (
                            value.category_type == 'Hotels' &&
                            value.name.trim().split(' ').length < 2
                        ) {
                            value.isempty = !0;
                            if (isShowAlert) {
                                this.eventService.emitEvent(
                                    EventType.MODAL_ERROR,
                                    this.translate.instant(
                                        'Guest name in Hotel should contain both first name and last name'
                                    )
                                );
                            }
                            flag = !1;
                        } else {
                            value.isempty = !1;
                        }
                    }
                    let temp_array = [];
                    if (guest_name_with_id[temp_id] != undefined) {
                        temp_array = guest_name_with_id[temp_id];
                    }
                    temp_array.push(value.name);
                    guest_name_with_id[temp_id] = temp_array;
                    sameproduct_guestname.push(value.name);
                });
                let check_array = this.unique(guest_name_with_id[temp_id]);
                if (check_array && flag) {
                    if (isShowAlert) {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            this.translate.instant(
                                'Guest name for same product with same custom option should not be same.'
                            )
                        );
                    }
                    flag = !1;
                }
            }
        });
        this.isGuestNameFilled = flag;
        return flag;
    }

    has_children(guests: any[]): boolean {
        let flag = false;
        guests.forEach((value: any) => {
            value.forEach((value2: any) => {
                if (value2.child_range && value2.child_range.length > 0) {
                    flag = true;
                }
            });
        });
        return flag;
    }

    validate_child_age(guests: any[]) {
        let flag = 0;
        let empty_age_box_id = '';
        guests.forEach((value: any) => {
            value.forEach((value2: any) => {
                let age_array = '';
                value2.child_range?.forEach((value3: any, key3: any) => {
                    value3.Child?.forEach((value4: any, key4: any) => {
                        let new_end_range: any = 0;
                        if (value4.end_range == 0) {
                            new_end_range = 10000;
                        } else {
                            new_end_range = value4.end_range;
                        }
                        if (value4.id != undefined) {
                            if (
                                value4.entered_date == '' ||
                                value4.entered_date == null
                            ) {
                                if (
                                    empty_age_box_id == '' ||
                                    empty_age_box_id == undefined
                                ) {
                                    empty_age_box_id = value4.id;
                                    flag = 2;
                                }
                                value4.status = 'empty';
                            } else if (
                                !isNaN(value4.entered_date) &&
                                !isNaN(new_end_range) &&
                                !isNaN(value4.starting_range)
                            ) {
                                if (
                                    value4.entered_date > new_end_range ||
                                    value4.entered_date < value4.starting_range
                                ) {
                                    if (
                                        empty_age_box_id == '' ||
                                        empty_age_box_id == undefined
                                    ) {
                                        empty_age_box_id = value4.id;
                                        flag = -1;
                                    }
                                    value4.status = 'invalid';
                                } else {
                                    value4.status = 'full';
                                    age_array += value4.entered_date + ',';
                                }
                            }
                        }
                    });
                });
                value2.kidAge = age_array.substring(0, age_array.length - 1);
            });
        });
        this.areChildrenAgesValid = flag == 0;
        return flag;
    }

    buildBillingAddress(
        billingAddress: CheckoutAddress,
        selectedCountryId: string
    ): BillingAddressDto {
        const billaddress: BillingAddressDto = {
            billfname: billingAddress.firstname,
            billlname: billingAddress.lastname,
            billstreet1: billingAddress.address,
            billstreet2: '',
            billcity: billingAddress.city,
            billpostcode: billingAddress.zipcode,
            billstate: '',
            billcountry: selectedCountryId,
            billphone: billingAddress.phone,
        };

        return billaddress;
    }

    unique(a: any) {
        let counts = [];
        for (let i = 0; i <= a.length; i++) {
            if (counts[a[i]] === undefined) {
                counts[a[i]] = 1;
            } else {
                return !0;
            }
        }
        return !1;
    }

    create_quantity_array(max_qty: any) {
        let arr = [];
        for (let i = 1; i <= max_qty; i++) {
            arr.push(i);
        }
        return arr;
    }
}
