<div style="padding-top: 1rem">
    <div>
        <div>
            <button
                class="btn btn-outline-secondary social-button"
                (click)="loginWithFacebook()"
            >
                <!-- <div class="social-display divLikeButton"> -->
                    <img
                        src="assets/img/fb-icon.jpeg"
                        class="social-img"
                        alt=""
                    />
                    <div class="social-login">
                        {{ "Continue with Facebook" | translate }}
                    </div>
                <!-- </div> -->
            </button>
        </div>

        <div style="padding-top: 1rem" *ngIf="checkIsiOSWrapperApp()">
            <button
                class="btn btn-outline-secondary social-button"
                (click)="loginWithApple()"
            >
                <!-- <div class="social-display"> -->
                    <img
                        [src]="cdn + 'assets/img/appleNew.png'"
                        class="social-img"
                        alt=""
                    />
                    <div class="social-login">
                        {{ "Continue with Apple" | translate }}
                    </div>
                <!-- </div> -->
            </button>
        </div>

        <div style="padding-top: 1rem; position: relative">
            <button
                    class="btn btn-outline-secondary social-button"
                    (click)="loginWithGoogle()"
            >
                <!-- <div class="social-display divLikeButton"> -->
                    <img
                            src="assets/img/g-logoNew.png"
                            class="social-img"
                            alt=""
                    />
                    <div class="social-login">
                        {{ "Continue with Google" | translate }}
                    </div>
                <!-- </div> -->
            </button>
            <asl-google-signin-button
                    *ngIf="!isMobileApp"
                    style="opacity: 0; position: absolute; width: 100%; top: 15px"
                    type="standard"
                    size="large"
                    shape="pill"
                    text="continue_with">
            </asl-google-signin-button>
        </div>
    </div>
</div>
