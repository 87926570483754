import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[mdMobileOnly]',
})
export class MobileOnlyDirective {
    constructor(private el: ElementRef) {
        this.el.nativeElement.classList.add('c-mo-mobile-only');
    }
}
