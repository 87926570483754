<md-profile-wrapper [activeSection]="ProfileSection.ORDERS">
    <md-profile-page-title
        [title]="'Bookings' | translate"
        [description]="'Review your bookings or make an amendment' | translate"
    />
    <div *ngIf="userLogin">
        <md-bookings-loading *ngIf="isOrdersData" />
        <div
            *ngIf="!isOrdersData"
            infiniteScroll
            [infiniteScrollDistance]="3"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScrollGetAllOrders()"
            [scrollWindow]="false"
        >
            <div class="ma-bk-filters">
                <h2
                    [ngClass]="{ active: ordersToShow === 'active' }"
                    (click)="changeOrdersToShow('active')"
                >
                    {{ "Active" | translate }}
                </h2>
                <h2
                    [ngClass]="{ active: ordersToShow === 'past' }"
                    (click)="changeOrdersToShow('past')"
                >
                    {{ "Past" | translate }}
                </h2>
                <h2
                    [ngClass]="{ active: ordersToShow === 'cancelled' }"
                    (click)="changeOrdersToShow('cancelled')"
                >
                    {{ "Cancelled" | translate }}
                </h2>
            </div>
            <div class="ma-bk-order" *ngFor="let order of ordersToShowArray">
                <ng-container *ngFor="let product of order.product">
                    <ng-container
                        *ngTemplateOutlet="
                            productItem;
                            context: { product: product, order: order }
                        "
                    ></ng-container
                ></ng-container>
            </div>
            <div class="no-orders" *ngIf="totalOrders == 0">
                <h2>{{ "You have no Orders" | translate }}</h2>
            </div>
        </div>
    </div>
</md-profile-wrapper>
<md-modal-offcanvas
    #modal
    [mobileModalTitle]="getModalTitle()"
    [thinPadding]="modalContent === 'bookingActions'"
>
    <div
        actualControl
        *ngIf="
            modalContent === 'bookingDetails' &&
            selectedProduct &&
            selectedOrder
        "
        class="ma-bk-details"
    >
        <h3>{{ selectedProduct.name }}</h3>
        <div class="ma-bk-details-container">
            <div class="ma-bk-details-item" *ngIf="selectedProduct.location">
                <ion-icon name="location-outline"></ion-icon>
                <div class="ma-bk-details-right">
                    <p>{{ selectedProduct.location }}</p>
                    <p></p>
                </div>
            </div>
            <div class="ma-bk-details-item" *ngIf="selectedOrder.order_date">
                <ion-icon name="calendar-clear-outline"></ion-icon>
                <div class="ma-bk-details-right">
                    <p>{{ "Booking date" | translate }}</p>
                    <p>
                        {{ formatDate(selectedOrder.order_date) }}
                    </p>
                </div>
            </div>
            <div class="ma-bk-details-item" *ngIf="selectedProduct.expiry">
                <ion-icon name="calendar-clear-outline"></ion-icon>
                <div class="ma-bk-details-right">
                    <p>{{ "Expiry date" | translate }}</p>
                    <p>
                        {{ formatDate(selectedProduct.expiry) }}
                    </p>
                </div>
            </div>
            <div class="ma-bk-details-item" *ngIf="selectedOrder.order_id">
                <ion-icon name="reader-outline"></ion-icon>
                <div class="ma-bk-details-right">
                    <p>{{ "Order Number" | translate }}</p>
                    <p>{{ selectedOrder.order_id }}</p>
                </div>
            </div>
            <div class="ma-bk-details-item">
                <ion-icon name="qr-code-outline"></ion-icon>
                <div class="ma-bk-details-right">
                    <p>{{ "Coupon code" | translate }}</p>
                    <p>{{ selectedProduct.coupon_code }}</p>
                </div>
            </div>
            <div class="ma-bk-details-item" *ngIf="selectedProduct.check_ins">
                <ion-icon name="calendar-clear-outline"></ion-icon>
                <div class="ma-bk-details-right">
                    <p>{{ "Check-in date" | translate }}</p>
                    <p>{{ formatDate(selectedProduct.check_ins) }}</p>
                </div>
            </div>
            <div class="ma-bk-details-item" *ngIf="selectedProduct.check_ins">
                <ion-icon name="calendar-clear-outline"></ion-icon>
                <div class="ma-bk-details-right">
                    <p>{{ "Check-out date" | translate }}</p>
                    <p>{{ formatDate(selectedProduct.check_ins) }}</p>
                </div>
            </div>
            <div
                class="ma-bk-details-item"
                *ngIf="selectedProduct.type_of_room"
            >
                <ion-icon name="bed-outline"></ion-icon>
                <div class="ma-bk-details-right">
                    <p>
                        {{
                            selectedProduct.no_of_nights
                                | i18nPlural : itemPluralMapping
                        }}, {{ "One Room" | translate }}
                    </p>
                    <p>{{ selectedProduct.type_of_room }}</p>
                </div>
            </div>
            <div
                class="ma-bk-details-item"
                *ngIf="selectedProduct.customdescription"
            >
                <ion-icon name="person-outline"></ion-icon>
                <div class="ma-bk-details-right">
                    <p>{{ "Occupancy" | translate }}</p>
                    <p>{{ selectedProduct.customdescription }}</p>
                </div>
            </div>
        </div>
        <button
            mdMainButton="light"
            (click)="downloadOrderPdfClick(selectedProduct)"
        >
            {{ "Download Confirmation" | translate }}
        </button>
        <button mdMainButton="light" (click)="modalContent = 'requestVat'">
            {{ "VAT Invoice" | translate }}
        </button>
    </div>
    <div
        actualControl
        *ngIf="modalContent === 'bookingActions'"
        style="margin-top: 16px"
    >
        <ng-container
            *ngTemplateOutlet="
                productItem;
                context: { product: selectedProduct, small: true }
            "
        ></ng-container>
        <ul class="ma-bk-actions">
            <li (click)="modalContent = 'bookingDetails'">
                {{ "View Booking" | translate }}
            </li>
            <li (click)="modalContent = 'requestVat'">
                {{ "Request VAT Invoice" | translate }}
            </li>
            <li (click)="downloadOrderPdfClick(selectedProduct)">
                {{ "Download Confirmation" | translate }}
            </li>
        </ul>
    </div>
    <div actualControl *ngIf="modalContent === 'requestVat'">
        <md-vat-invoice (requestSent)="onVatInvoiceRequestSent($event)" />
    </div>
    <div
        actualControl
        *ngIf="modalContent === 'requestedVat'"
        class="ma-bk-requested-vat"
    >
        <ion-icon name="checkmark-circle-outline"></ion-icon>
        <p>
            {{
                "Your Invoice request has been submitted. Once approved, you will be able to download it."
                    | translate
            }}
        </p>
        <button mdMainButton="light" (click)="closeModal(modal)">
            {{ "Ok" | translate }}
        </button>
    </div>
</md-modal-offcanvas>
<ng-template
    #productItem
    let-product="product"
    let-order="order"
    let-small="small"
>
    <div
        class="ma-bk-order-product"
        [ngClass]="{ small: small, selected: selectedProduct === product }"
        *ngIf="product"
        (click)="
            selectedProduct = product;
            selectedOrder = order;
            modalContent = 'bookingActions';
            openModal(modal)
        "
    >
        <img class="ma-bk-order-product-img" [src]="product.image" />
        <div class="ma-bk-order-product-right">
            <div class="ma-bk-order-top">
                <div>{{ product.name }}</div>
                <ion-icon
                    class="ma-bk-order-menu"
                    name="ellipsis-vertical"
                ></ion-icon>
            </div>
            <div class="ma-bk-order-mid">
                <p>
                    {{ product.type_of_room }} <br />
                    {{ product.customdescription }}
                </p>
            </div>
            <div class="ma-bk-order-bottom">
                <div>
                    <ion-icon
                        *ngIf="showDate(product)"
                        name="calendar-clear-outline"
                    ></ion-icon>
                    {{ createProductDatesString(product) }}
                </div>
            </div>
        </div>
    </div>
</ng-template>
