import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { getUser } from "../utilities/user.utils";

@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    constructor(private commonService: CommonService) {}

    onRedirectToCategoryPage(categoryId: number): void {
        // TODO: this is wrong because it should be a number but we need to keep as string until the refactoring is closer to done because there are many usages of this
        this.commonService.data.value.checkCatId = categoryId.toString();
        localStorage.setItem('searchResult', '');
        this.commonService.clearCustomCache(this);
    }

    isUserLoggedIn(): boolean {
        const userdata = getUser();
        const isLoggedIn = !!userdata;
        // TODO: this needs to be removed when we no longer depend on this flag in the common service
        this.commonService.isLoggedIn = isLoggedIn;
        return isLoggedIn;
    }
}
