import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { CommonService } from '../shared/common.service';
import { EventService, EventType } from "../utilities/event.service";

export const canAccessLoggedInResource: CanActivateFn = () => {
    const common: CommonService = inject(CommonService);
    const eventService: EventService = inject(EventService);
    if (common.isLoggedIn) {
        return true;
    }
    eventService.emitEvent(EventType.LOGIN)
    return false;
};
