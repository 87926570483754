import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
import { Router } from '@angular/router';
import { SocialLogin } from "../../social-login/model/social-login.model";
import { Observable } from "rxjs";
import { LoginResponse, transform, User } from "../../model/user.model";
import { map } from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class SocialService {

    constructor(private httpClient: HttpClient,
                private common: CommonService,
                public router: Router) {
    }

    socialLoginApi(data: SocialLogin): Observable<User> {
        const params = new HttpParams()
          .set('service', 'createSocialCustomer')
          .set('email', data.email)
          .set('firstname', data.firstName)
          .set('lastname', data.lastName)
          .set('store', this.common.getStoreID())
          .set('login_type', data.provider)
          .set('login_identifier', data.id)
          .set('is_real_login', 1)
          .set('is_app_login', data?.isAppLogin)
        return this.httpClient.get<LoginResponse>(
          this.common.api_url + 'createSocialCustomer', {
              params: params,
              headers: this.common.getTokenHeaders()
          }).pipe(map(user => transform(user)));
    }
}
