<div
    class="h-hdc-date"
    *ngIf="showDate"
    [ngClass]="{
        'top-left': position === 'top-left',
        'top-right': position === 'top-right',
        large: large
    }"
>
    <div class="h-hdc-date-header"></div>
    <div class="h-hdc-date-container">
        <div
            class="h-hdc-date-day-name"
            [class.h-hdc-date-day-name--start]="eventDateEnd"
        >
            {{ eventDate.dayName }}
        </div>
        <div class="h-hdc-date-day">{{ eventDate.dayNumber }}</div>
        <div class="h-hdc-date-month-name">{{ eventDate.month }}</div>
    </div>
    <ng-container *ngIf="eventDateEnd">
        <div>-</div>
        <div class="h-hdc-date-container">
            <div class="h-hdc-date-day-name h-hdc-date-day-name--end">
                {{ eventDateEnd.dayName }}
            </div>
            <div class="h-hdc-date-day">{{ eventDateEnd.dayNumber }}</div>
            <div class="h-hdc-date-month-name">
                {{ eventDateEnd.month }}
            </div>
        </div>
    </ng-container>
</div>
