import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
import { getCustomerId } from 'src/app/utilities/user.utils';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { Observable } from 'rxjs';
import { ProductPricingByDateDto } from './dated-activity-booking.model';

@Injectable({
    providedIn: 'root',
})
export class DatedActivityBookingService {
    constructor(
        private commonService: CommonService,
        private httpClient: HttpClient
    ) {
        // Constructor logic here
    }

    getNewCalendarDates(
        productId: number
    ): Observable<ProductPricingByDateDto> {
        const apiUrl = getApiUrlMofluid();
        const customerId = getCustomerId();
        const customerGroupId = this.commonService.getCustomerGroupID();
        const storeId = this.commonService.getStoreID();
        const url = `${apiUrl}getNewCalendarDates?service=getNewCalendarDates&productid=${productId}&customerid=${customerId}&store=${storeId}&customer_group_id=${customerGroupId}`;

        return this.httpClient.get<ProductPricingByDateDto>(url, {
            headers: this.commonService.getTokenHeaders(),
        });
    }
}
