import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DealRibbon } from 'src/app/category-deals-list/model/product.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

export interface ProductCardEntry {
    id: number;
    productUrl: string;
    productPath: string;
    imageUrl: string;
    bannerImages?: Array<string>;
    name: string;
    shortDescription: string;
    price?: number;
    specialPrice: number;
    starCount?: number;
    average?: number;
    startDate?: Date;
    endDate?: Date;
    ribbon: DealRibbon;
}

@Component({
    selector: 'md-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
    @Input() dealEntry: ProductCardEntry;
    @Input() condensed = false;
    @Input() openInNewTab = false;
    @Output() dealSelected = new EventEmitter<ProductCardEntry>();

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    href: string;

    constructor(private currencyLanguageService: CurrencyLanguageService) {}

    ngOnInit(): void {
        const { productPath, id } = this.dealEntry;
        this.href = `/deal/${productPath}/${id}`;
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    cardClicked(e: Event): void {
        if (this.openInNewTab) {
            return;
        }
        e.preventDefault();
        this.dealSelected.emit(this.dealEntry);
    }
}
