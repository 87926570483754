import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';

@Injectable({
    providedIn: 'root',
})
export class ReviewService {
    constructor(
        private httpClient: HttpClient,
        private common: CommonService
    ) {}

    getReviewData(data: any) {
        return this.httpClient.get(
            getApiUrlMofluid() +
                `getreviewlist?service=getreviewlist&customerid=${data.id}&pagesize=${data.pageSize}&currentpage=${data.pageIndex}` +
                '&customer_group_id=' +
                this.common.getCustomerGroupID(),
            { headers: this.common.getTokenJWTheaders() }
        );
    }
}
