import { NgModule } from '@angular/core';
import { CategoryListComponent } from './category-deals-list.component';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FilterDataModule } from '../filter-data/filter-data.module';
import { TranslateModule } from '@ngx-translate/core';
import { CategorySortBarComponent } from './category-sort-bar/category-sort-bar.component';
import { ControlsModule } from '../controls/controls.module';
import { CategoryBannerReelComponent } from './category-banner-reel/category-banner-reel.component';

@NgModule({
    declarations: [
        CategoryListComponent,
        CategorySortBarComponent,
        CategoryBannerReelComponent,
    ],
    imports: [
        CommonModule,
        ControlsModule,
        LazyLoadImageModule,
        NgxUsefulSwiperModule,
        InfiniteScrollModule,
        FilterDataModule,
        TranslateModule,
    ],
    exports: [CategorySortBarComponent],
})
export class CategoryDealsListModule {}
