import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { ProfileWrapperComponent } from './profile-wrapper.component';
import { RecentComponent } from './recent/recent.component';
import { NotificationComponent } from './notification/notification.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { ChallengesComponent } from './my-account/challenges/challenges.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EditProfileComponent } from './my-account/edit-profile/edit-profile.component';
import { OrdersComponent } from './orders/orders.component';
import { ReviewsComponent } from './my-account/reviews/reviews.component';
import { HelpComponent } from './help/help.component';
import { MyBadgesComponent } from './my-account/challenges/my-badges/my-badges.component';
import { TripsComponent } from './trips/trips.component';
import { SettingsComponent } from './my-account/settings/settings.component';
import { NgxIntlTelInputModule } from '@capgo/ngx-intl-tel-input';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { VatInvoiceComponent } from './vat-invoice/vat-invoice.component';
import { OrderReviewComponent } from './order-review/order-review.component';
import { PaymentSuccessComponent } from './checkout-payment/payment-success/payment-success.component';
import { PaymentFailureComponent } from './checkout-payment/payment-failure/payment-failure.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { TranslateModule } from '@ngx-translate/core';
import { TripsDetailComponent } from './trips/trips-detail/trips-detail.component';
import { ControlsModule } from '../controls/controls.module';
import { ComponentsModule } from '../components/components.module';
import { CoreModule } from '../core/core.module';
import { ProfilePageTitleComponent } from './my-account/profile-page-title/profile-page-title.component';
import { StoreCreditComponent } from './my-account/rewards-and-wallet/store-credit/store-credit.component';
import { RewardsAndWalletComponent } from './my-account/rewards-and-wallet/rewards-and-wallet.component';
import { GiftVoucherComponent } from './my-account/rewards-and-wallet/gift-voucher/gift-voucher.component';
import { GiftCardBalanceComponent } from './my-account/rewards-and-wallet/gift-voucher/gift-card-balance/gift-card-balance.component';
import { BookingsComponent } from './my-account/bookings/bookings.component';
import { EditProfileLoadingComponent } from './my-account/edit-profile/edit-profile.loading';
import { BookingsLoadingComponent } from './my-account/bookings/bookings.loading';
import { ReviewsLoadingComponent } from './my-account/reviews/reviews.loading';
import { RewardsAndWalletLoadingComponent } from './my-account/rewards-and-wallet/rewards-and-wallet.loading';
import { MyBadgesLoadingComponent } from './my-account/challenges/my-badges/my-badges.loading';

@NgModule({
    declarations: [
        ProfileWrapperComponent,
        RecentComponent,
        NotificationComponent,
        GiftVoucherComponent,
        ChallengesComponent,
        StoreCreditComponent,
        EditProfileComponent,
        OrdersComponent,
        ReviewsComponent,
        HelpComponent,
        MyBadgesComponent,
        SettingsComponent,
        TripsComponent,
        OrderConfirmationComponent,
        GiftCardBalanceComponent,
        VatInvoiceComponent,
        OrderReviewComponent,
        PaymentSuccessComponent,
        PaymentFailureComponent,
        CreateInvoiceComponent,
        TripsDetailComponent,
        ProfilePageTitleComponent,
        RewardsAndWalletComponent,
        BookingsComponent,
        EditProfileLoadingComponent,
        BookingsLoadingComponent,
        ReviewsLoadingComponent,
        RewardsAndWalletLoadingComponent,
        MyBadgesLoadingComponent,
    ],
    imports: [
        CommonModule,
        ControlsModule,
        CoreModule,
        ComponentsModule,
        UserRoutingModule,
        NgxUsefulSwiperModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        NgxIntlTelInputModule,
        TranslateModule,
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserModule {}
