import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { OccupancyOption } from './search-occupancy.model';
import { SearchOccupancyService } from './search-occupancy.service';
import { PopupOffcanvasComponent } from 'src/app/controls/popup-offcanvas/popup-offcanvas.component';
import { hideOccupancy } from 'src/app/static-content/menu-routes';

@Component({
    selector: 'md-search-occupancy',
    templateUrl: './search-occupancy.component.html',
    styleUrls: ['./search-occupancy.component.scss'],
})
export class SearchOccupancyComponent implements OnInit {
    occupancyOptions: OccupancyOption[] = [];
    selectedOccupancy: OccupancyOption;
    hideOccupancy:number[] = [];

    @Output() occupancyChanged = new EventEmitter<OccupancyOption>();

    @ViewChild('searchOccupancyOffcanvas') offcanvas: PopupOffcanvasComponent;

    constructor(private searchOccupancyService: SearchOccupancyService) {
        this.openOccupancy = this.openOccupancy.bind(this);
        this.onOccupancyChange = this.onOccupancyChange.bind(this);
        this.onModalContinueClick = this.onModalContinueClick.bind(this);
    }

    onOccupancyChange(occupancy: OccupancyOption) {
        this.selectedOccupancy = occupancy;
        this.occupancyChanged.emit(this.selectedOccupancy);
        this.offcanvas.close();
    }

    openOccupancy() {
        this.offcanvas.open();
    }

    ngOnInit(): void {
        this.hideOccupancy = hideOccupancy;
        this.searchOccupancyService
            .getOccupancyOptions()
            .subscribe((options) => {
                this.occupancyOptions = options;
            });
    }

    onModalContinueClick() {
        this.occupancyChanged.emit(this.selectedOccupancy);
    }
}
