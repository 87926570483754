<div>
    <div id="profile" class="custom-invoice-new-div-1">
        <div
            id="invoice_page"
            has-header="true"
            class="page-bg-color"
            scroll="false"
        >
            <div class="custom-invoice-new-div-2">
                <div class="custom-invoice-new-div-3">
                    <div class="custom-invoice-new-div-3">
                        {{ "Your" | translate }}
                        {{ "Order is" | translate }}
                    </div>
                    <div
                        [ngClass]="
                            orderdata?.amount == '0.00'
                                ? 'custom-complete-order'
                                : 'custom-invoice-new-div-4'
                        "
                    >
                        {{
                            orderdata?.amount == "0.00"
                                ? ("Complete" | translate)
                                : ("Pending" | translate)
                        }}
                    </div>
                </div>
                <div class="custom-invoice-new-div-5">
                    <div class="custom-invoice-new-div-6">
                        <div class="custom-invoice-new-div-7">
                            {{ "Order Number:" | translate }}
                        </div>
                        <div class="custom-invoice-new-div-8">
                            {{ orderdata.orderid }}
                        </div>
                    </div>
                    <div class="custom-invoice-new-div-9"></div>
                    <div class="custom-invoice-new-div-10">
                        <div class="custom-invoice-new-div-11">
                            {{ "Amount Due:" | translate }}
                        </div>
                        <div class="custom-invoice-new-div-12">
                            {{
                                orderdata?.amount
                                    | currency
                                        : currencySymbol
                                        : "symbol"
                                        : "1.0-2"
                                        : locale
                            }}
                        </div>
                    </div>
                </div>
                <div
                    class="custom-invoice-new-div-13"
                    *ngIf="orderdata.amount != '0.00'"
                >
                    {{ ipaytext | translate }}
                </div>

                <div
                    class="custom-invoice-new-div-13 text-center"
                    *ngIf="orderdata.amount == '0.00'"
                >
                    {{ ipaytextSuccess | translate }}
                </div>
                <div>
                    <img
                        src="assets/img/orderSuccess.png"
                        class="custom-invoice-new-div-14"
                    />
                </div>
                <div>
                    <div class="custom-invoice-new-div-15">
                        {{ "Internet Banking" | translate }}
                    </div>
                    <div class="custom-invoice-new-div-16">
                        <div class="custom-invoice-new-div-17">
                            <div class="custom-invoice-new-div-18">
                                {{ "Bank:" | translate }}
                            </div>
                            <div class="custom-invoice-new-div-19">
                                {{
                                    "Mauritius Commercial Bank Ltd Bank"
                                        | translate
                                }}
                            </div>
                        </div>
                        <div class="custom-invoice-new-div-17">
                            <div class="custom-invoice-new-div-18">
                                {{ "Account:" | translate }}
                            </div>
                            <div class="custom-invoice-new-div-19">
                                000442345917
                            </div>
                        </div>
                        <div class="custom-invoice-new-div-17">
                            <div class="custom-invoice-new-div-18">
                                {{ "Beneficiary" | translate }}
                                {{ "Name:" | translate }}
                            </div>
                            <div class="custom-invoice-new-div-19">
                                {{ "Mari Deal Ltd" | translate }}
                            </div>
                        </div>
                        <div class="custom-invoice-new-div-17">
                            <div class="custom-invoice-new-div-18">
                                {{ "Payment" | translate }}
                                {{ "Reference:" | translate }}
                            </div>
                            <div class="custom-invoice-new-div-20">
                                {{ orderdata.orderid }}
                            </div>
                        </div>
                        <div class="custom-invoice-new-div-17">
                            <div class="custom-invoice-new-div-18">
                                {{ "Amount Due:" | translate }}
                            </div>
                            <div class="custom-invoice-new-div-21">
                                {{
                                    orderdata?.amount
                                        | currency
                                            : currencySymbol
                                            : "symbol"
                                            : "1.0-2"
                                            : locale
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="custom-invoice-new-div-15">
                        {{ "OR" | translate }}
                    </div>
                    <div class="custom-invoice-new-div-16">
                        <div class="custom-invoice-new-div-17">
                            <div class="custom-invoice-new-div-18">
                                {{ "Bank:" | translate }}
                            </div>
                            <div class="custom-invoice-new-div-19">
                                {{ "State Bank of Mauritius Ltd" | translate }}
                            </div>
                        </div>
                        <div class="custom-invoice-new-div-17">
                            <div class="custom-invoice-new-div-18">
                                {{ "Account:" | translate }}
                            </div>
                            <div class="custom-invoice-new-div-19">
                                62030100185095
                            </div>
                        </div>
                        <div class="custom-invoice-new-div-17">
                            <div class="custom-invoice-new-div-18">
                                {{ "Beneficiary" | translate }}
                                {{ "Name:" | translate }}
                            </div>
                            <div class="custom-invoice-new-div-19">
                                {{ "Mari Deal Ltd" | translate }}
                            </div>
                        </div>
                        <div class="custom-invoice-new-div-17">
                            <div class="custom-invoice-new-div-18">
                                {{ "Payment" | translate }}
                                {{ "Reference:" | translate }}
                            </div>
                            <div class="custom-invoice-new-div-20">
                                {{ orderdata.orderid }}
                            </div>
                        </div>
                        <div class="custom-invoice-new-div-17">
                            <div class="custom-invoice-new-div-18">
                                {{ "Amount Due:" | translate }}
                            </div>
                            <div class="custom-invoice-new-div-21">
                                {{
                                    orderdata?.amount
                                        | currency
                                            : currencySymbol
                                            : "symbol"
                                            : "1.0-2"
                                            : locale
                                }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="custom-invoice-new-div-22">
                    {{ ipayBottomtext }}
                </div>
                <div class="custom-invoice-new-div-23">
                    <div
                        class="custom-invoice-new-div-24 divLikeButton ripleEffect"
                        (click)="continueShoping()"
                    >
                        {{ "Continue Shopping" | translate }}
                    </div>
                </div>
                <div class="custom-invoice-new-div-25">
                    <div class="custom-invoice-new-div-26">
                        <div class="custom-invoice-new-div-27">
                            {{ "Need help?" | translate }}
                        </div>
                        <div class="custom-invoice-new-div-28">
                            {{ "Please contact us" | translate }}
                        </div>
                    </div>
                    <div class="custom-invoice-new-div-16">
                        <div class="custom-invoice-new-div-29">
                            <div class="custom-invoice-new-div-30">
                                <img
                                    src="assets/img/Email-Icon.png"
                                    class="custom-invoice-new-img-1"
                                />
                            </div>
                            <div>
                                <div class="custom-invoice-new-div-31">
                                    {{ "Email us" | translate }}
                                </div>
                                <div
                                    class="custom-invoice-new-div-32 custom-mail"
                                >
                                    <a
                                        href="mailto:info@marideal.mu"
                                        target="_top"
                                        >info@marideal.mu</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="custom-invoice-new-div-33">
                            <div class="custom-invoice-new-div-34">
                                <img
                                    src="assets/img/Phone-Icon.png"
                                    class="custom-invoice-new-img-1"
                                />
                            </div>
                            <div>
                                <div class="custom-invoice-new-div-35">
                                    {{ "Call us" | translate }}
                                </div>
                                <div
                                    class="custom-invoice-new-div-36 custom-ph"
                                >
                                    <a href="tel:+2302632769"
                                        >(+230) 263 27 69</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
