import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortLabel } from 'src/app/filter-data/filter-data.model';

@Component({
    selector: 'md-category-sort-bar',
    templateUrl: './category-sort-bar.component.html',
    styleUrls: ['./category-sort-bar.component.scss'],
})
export class CategorySortBarComponent {
    @Input() showClosestDate = false;
    @Input() sortLabel: SortLabel;

    @Output() sort = new EventEmitter<SortLabel>();

    applySort(sortLabel: SortLabel): void {
        if (this.sortLabel === sortLabel) {
            this.sortLabel = null;  // Deselect if already selected
        } else {
            this.sortLabel = sortLabel;
        }
        this.sort.emit(this.sortLabel);
    }
}
