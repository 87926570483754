import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ProductCardEntry } from '../product-card/product-card.component';
import { SwiperOptions } from 'swiper';
import { screenSizes } from 'src/app/utilities/theme';

@Component({
    selector: 'md-product-card-list',
    templateUrl: './product-card-list.component.html',
    styleUrls: ['./product-card-list.component.scss'],
})
export class ProductCardListComponent implements OnInit {
    @Input() productEntries: ProductCardEntry[];
    @Input() title: string;

    swiperConfig: SwiperOptions;

    ngOnInit(): void {
        this.swiperConfig = {
            autoHeight: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoplay:
                this.productEntries.length > 4
                    ? {
                          // Conditionally setting autoplay
                          delay: 5000, // Setting delay
                      }
                    : false, // Disable autoplay if deals length is not greater than 4
            spaceBetween: 12,
            breakpoints: {
                [screenSizes.largeDesktop]: {
                    slidesPerView: 5,
                },
                [screenSizes.desktop]: {
                    slidesPerView: 4,
                },
                0: {
                    slidesPerView: 2.3,
                },
            },
        };
    }

    @Output() dealSelected = new EventEmitter<ProductCardEntry>();

    onDealSelected(dealEntry: ProductCardEntry): void {
        this.dealSelected.emit(dealEntry);
    }
}
