import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { getCustomerId } from 'src/app/utilities/user.utils';
import { GetMerchantDealsResponseDto } from './more-detals.model';

@Injectable({
    providedIn: 'root',
})
export class MoreDealsService {
    constructor(
        private httpClient: HttpClient,
        private commonService: CommonService
    ) {}

    getDeals(productId: number): Observable<GetMerchantDealsResponseDto> {
        const customerId = getCustomerId();
        const apiUrl = getApiUrlMofluid();
        const customerGroupId = this.commonService.getCustomerGroupID();
        const url = `${apiUrl}getMerchantDeals?service=getMerchantDeals&productid=${productId}&customerid=${customerId}&customer_group_id=${customerGroupId}`;

        return this.httpClient.get<GetMerchantDealsResponseDto>(url, {
            headers: this.commonService.getTokenHeaders(),
        });
    }
}
