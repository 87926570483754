declare const gtag;

export const gaPageTransition = (pageName: string) => {
    const standalone = window.navigator['standalone'],
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);

    let gaPlatformKey: string;
    // TODO: are these tags used or are the ones from index.js used?
    if (ios) {
        if (!standalone && safari) {
            gaPlatformKey = 'G-6JVWRV7EP9'; //pwa
        } else if (!standalone && !safari) {
            gaPlatformKey = 'G-RYK31WW09K'; //iOS wrapper app
        }
    } else {
        if (userAgent.includes('huawei')) {
            gaPlatformKey = 'G-7G6HLLKJE6'; //huawei
        } else if (userAgent.includes('wv')) {
            gaPlatformKey = 'G-B7QSVZY9P7'; //Android Wrapper
        } else {
            gaPlatformKey = 'G-6JVWRV7EP9'; //pwa
        }
    }
    gtag('config', gaPlatformKey, {
        page_path: pageName,
    });
};
