import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EgiftCardService } from './egift-card.service';
import {
    AddEGiftCardToCartResponseDto,
    EGiftAddToCartDto,
    EGiftCardDetailsResponse,
    EGiftCardFormData,
    EGiftCardTemplate,
    UploadGcImageDto,
    transform,
} from './egift-card.model';
import { SwiperOptions } from 'swiper';
import { CommonService } from '../shared/common.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as dayjs from 'dayjs';
import { screenSizes } from 'src/app/utilities/theme';
import {
    CART_ITEMS_DATA_COUNT,
    QUOTE_ID,
} from '../utilities/local-storage.keys';
import { GeneralErrorService } from '../components/general-error/general-error.service';
import { TranslateService } from '@ngx-translate/core';
import { ServerError } from '../model/server';
import { EventService } from '../utilities/event.service';

const cardTemplatesVisibleByDefault = 5;
const swiperConfig: SwiperOptions = {
    autoHeight: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    spaceBetween: 16,
    breakpoints: {
        [screenSizes.desktop]: {
            slidesPerView: 4,
        },
        [screenSizes.mobile]: {
            slidesPerView: 3,
        },
        0: {
            slidesPerView: 2,
        },
    },
};

@Component({
    selector: 'md-egift-card',
    templateUrl: './egift-card.component.html',
    styleUrls: ['./egift-card.component.scss'],
})
export class EgiftCardComponent implements OnInit {
    visibleCardTemplates: EGiftCardTemplate[] = [];
    showMoreTemplates = false;
    swiperConfig = swiperConfig;
    selectedTemplateIndex: number;
    selectedGiftCardImage: { name: string; path: string };
    giftCardDetails: EGiftCardDetailsResponse;
    loadingContent = true;
    isCustomGiftCardImage = false;
    formData: EGiftCardFormData;

    @ViewChild('modalDetailsForm') modalDetailsForm;

    constructor(
        private service: EgiftCardService,
        private eventService: EventService,
        private modalService: NgbModal,
        private commonService: CommonService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private generalErrorService: GeneralErrorService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.service.getEGiftDetails().subscribe({
            next: (res) => {
                this.giftCardDetails = transform(res);
                this.visibleCardTemplates =
                    this.giftCardDetails.gitftcardTemplates.slice(
                        0,
                        cardTemplatesVisibleByDefault
                    );
                this.loadingContent = false;
            },
            error: () => {
                this.generalErrorService.showGeneralError(
                    this.translate.instant(
                        'Could not load gift card details. Please refresh the page and try again.'
                    )
                );
            },
        });
    }

    toggleShowMore() {
        this.showMoreTemplates = !this.showMoreTemplates;
        const { gitftcardTemplates } = this.giftCardDetails;
        if (this.showMoreTemplates) {
            this.visibleCardTemplates = gitftcardTemplates;
        } else {
            this.visibleCardTemplates = gitftcardTemplates.slice(
                0,
                cardTemplatesVisibleByDefault
            );
        }
    }

    uploadGiftCardImage(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];

        if (!file) {
            this.isCustomGiftCardImage = false;
            this.selectedGiftCardImage = undefined;
        }

        const { maxImageUploadSizeKb } = this.giftCardDetails;

        if (file.size > maxImageUploadSizeKb * 1000) {
            this.generalErrorService.showGeneralError(
                `${this.translate.instant(
                    'File upload not allowed, allowed maximum size is:'
                )} ${maxImageUploadSizeKb}kb`,
                { showMailto: false }
            );
            return false;
        }

        this.spinner.show();

        const fd = new FormData();
        fd.append('templateimage', file);
        fd.append('sec_key', '===bW9mbHVpZC1tYXJpZGVhbA==');
        this.service.uploadGiftCardImage(fd).subscribe({
            next: (res: UploadGcImageDto | ServerError) => {
                this.spinner.hide();
                const serverError = res as ServerError;
                if (serverError?.status === 'error') {
                    this.generalErrorService.showGeneralError(
                        serverError.message,
                        { showMailto: false }
                    );
                } else {
                    const result = res as UploadGcImageDto;
                    this.selectedGiftCardImage = {
                        name: result.updated_filename,
                        path: result.url,
                    };
                    this.isCustomGiftCardImage = true;
                    this.openModal(this.modalDetailsForm);
                }
            },
            error: () => {
                this.spinner.hide();
                this.generalErrorService.showGeneralError(
                    this.translate.instant(
                        'Could not upload custom image. Please refresh the page and try again.'
                    )
                );
            },
        });
    }

    expandTemplate(content: TemplateRef<unknown>, index: number): void {
        this.selectedTemplateIndex = index;
        this.modalService.open(content, { fullscreen: true });
    }

    selectImage(
        content: TemplateRef<unknown>,
        template: EGiftCardTemplate,
        templateIndex: number,
        index: number
    ): void {
        this.selectedGiftCardImage = template.images[index];
        this.selectedTemplateIndex = templateIndex;
        // if in desktop mode this should be a small instead of fullscreen
        this.openModal(content);
    }

    formDataChanged(formData: EGiftCardFormData): void {
        this.formData = formData;
    }

    // TODO: this needs to be moved around
    transformDate(date: NgbDateStruct): Date {
        return new Date(date.year, date.month - 1, date.day);
    }

    buyGiftCard(): void {
        this.modalService.dismissAll();
        this.spinner.show();

        const dayToSent = this.transformDate(this.formData.deliveryDate);

        const productData: EGiftAddToCartDto = {
            id: this.giftCardDetails.id, // id
            price_amount: this.formData.value, // price from the form
            type: 'giftvoucher',
            amount: this.formData.value, // price from form. Do we need both?
            giftcard_template_id:
                this.giftCardDetails.gitftcardTemplates[
                    this.selectedTemplateIndex
                ]?.id, // EGiftCardTemplate id
            giftcard_template_image: this.selectedGiftCardImage.name, // EGiftCardTemplate image name
            giftcard_use_custom_image: this.isCustomGiftCardImage ? '1' : '0', // this is in fact a number sent to the server (0 or 1)
            send_friend: '1',
            customer_name: this.formData.deliveryFromName, // sender name from form
            recipient_name: '', // recipient name from form
            recipient_email: this.formData.deliveryToEmail, // recipient email from form
            message: this.formData.deliveryMessage, // message from form
            notify_success: '1',
            day_to_send: dayjs(dayToSent).format('MM/DD/YYYY'), // formData.deliveryDate is yyyy-mm-dd
            timezone_to_send: 'Indian/Mauritius',
            qty: this.formData.quantity, // qty from form
        };

        // if success this page doesn't care anymore - it redirects to checkout
        // if failure it displays whatever
        this.service.addToCart(productData).subscribe({
            next: (response: AddEGiftCardToCartResponseDto) => {
                this.spinner.hide();
                if (response.status === 'success') {
                    localStorage.setItem(QUOTE_ID, response.quoteid);
                    this.commonService.setCache(
                        this,
                        CART_ITEMS_DATA_COUNT,
                        response.cart_items_qty
                    );
                    // this.msg = response.msg;
                    this.checkoutInit();
                } else {
                    this.generalErrorService.showGeneralError(
                        'Could not add item to cart. Please refresh the page and try again.'
                    );
                }
            },
            error: () => {
                this.generalErrorService.showGeneralError(
                    'Could not add item to cart. Please refresh the page and try again.'
                );
            },
        });
    }

    checkoutInit() {
        this.router.navigate(['checkout']);
    }

    openModal(content: TemplateRef<unknown>): void {
        const screenWidth = window.innerWidth;
        if (screenWidth > screenSizes.mobile) {
            this.modalService.open(content, { size: 'md', scrollable: true });
        } else {
            this.modalService.open(content, {
                fullscreen: true,
                scrollable: true,
            });
        }
    }
}
