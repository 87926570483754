import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { CreateInvoiceService } from './create-invoice.service';
import { getUser } from "../../utilities/user.utils";
import { User } from "../../model/user.model";

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent implements OnInit {
    isGenrateInvoiceShowSkeleton:boolean = true;
    loginStatus!: number;
    private subscription: Subscription;
    public loginResponse: User;
    public userLogin: boolean = false;
    createInvoiceFormGroup = new UntypedFormGroup({
        PO: new UntypedFormControl('', []),
        companyProfile: new UntypedFormControl('', [])
    });
    customerId: any;
    showPopupBox: boolean = false;
    showPopupBoxMsg: string= "";

  constructor(private createInvoiceService:CreateInvoiceService, private common: CommonService, private activatedRoute: ActivatedRoute,) {
    this.checkLoginResponse();
   }

  ngOnInit(): void {
    let company_profile_data = this.common.getCache(this, 'company_profile_data') ? (this.common.getCache(this, 'company_profile_data').data ? this.common.getCache(this, 'company_profile_data').data : '') : '';
    this.createInvoiceFormGroup.controls['companyProfile'].setValue(company_profile_data);
    console.log(company_profile_data);
    this.isGenrateInvoiceShowSkeleton=false;
  }

  checkLoginResponse(){
    let userdata = getUser();
    if(userdata.hasOwnProperty('data')){ //checking if user is logged in or not
      this.loginResponse = userdata;
      this.customerId = this.loginResponse?.id;
      this.userLogin = true;

    }
}

saveOrderInvoice(){
    const payload={
     customerId :this.customerId,
     po: this.createInvoiceFormGroup.controls['PO'].value,
     order_id: this.activatedRoute.snapshot.params['order_id'],
    }
    this.createInvoiceService.saveOrderInvoice(payload).subscribe((response:any)=>{
            this.common.generalErrorMsg = response.message;
            this.common.generalError = true;
            window.history.back();
    })
}
}
