<div mdDesktopOnly class="gv-banner">
    <div class="gv-banner-title">
        <h1 i18n>{{ "eGift Card" | translate }}</h1>
        <p i18n class="gv-banner-subtitle">
            {{ "Gift Giving Made Easy!" | translate }}
            <br />
            {{ "One card. So many options." | translate }}
        </p>
    </div>
</div>
<div class="gv-container">
    <div class="gv-main-content">
        <h2 i18n mdDesktopOnly>A. {{ "Choose a design" | translate }}</h2>
    </div>
    <div class="gcs-container" *ngIf="loadingContent">
        <!-- this should look like the finished thing. So with rows of cards-->
        <div *ngFor="let i of [1, 2, 3, 4, 5]">
            <div mdSkeleton class="gcs-group-header"></div>
            <div style="display: flex; direction: column; width: 100%">
                <div mdSkeleton mdCard class="gcs-card"></div>
                <div mdSkeleton mdCard class="gcs-card"></div>
                <div
                    mdSkeleton
                    mdCard
                    mdShowOnMin="tablet"
                    class="gcs-card"
                ></div>
                <div
                    mdSkeleton
                    mdCard
                    mdShowOnMin="desktop"
                    class="gcs-card"
                ></div>
            </div>
        </div>
    </div>
    <div *ngIf="!loadingContent">
        <ng-container
            *ngFor="let template of visibleCardTemplates; let i = index"
        >
            <div class="gv-template-name">
                <h3>{{ template.name }}</h3>
                <button
                    (click)="expandTemplate(modalTemplateList, i)"
                    mdMobileOnly
                    mdTextButton
                    i18n
                >
                    {{ "See all" | translate }}
                </button>
            </div>

            <swiper [config]="swiperConfig">
                <div class="swiper-wrapper gv-swiper-container">
                    <div
                        class="swiper-slide"
                        *ngFor="let image of template.images; let j = index"
                        (click)="selectImage(modalDetailsForm, template, i, j)"
                    >
                        <img mdCard [alt]="image.name" [src]="image.thumb" />
                    </div>
                </div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </swiper>
        </ng-container>
    </div>
    <div class="gv-show-more-container">
        <button
            mdTextButton
            i18n
            class="gv-show-more"
            [attr.expanded]="showMoreTemplates"
            (click)="toggleShowMore()"
        >
            {{ (showMoreTemplates ? "Show less" : "Show more") | translate }}
        </button>
    </div>
    <div class="gv-main-content">
        <h2 i18n mdDesktopOnly>B. {{ "Personalise your own" | translate }}</h2>
    </div>
    <div class="gv-image-uploader-container">
        <div class="gv-image-uploader">
            <div class="gv-image-uploader-text-container">
                <div class="gv-image-uploader-icon"></div>
                <div class="gv-image-uploader-text">
                    <h3>{{ "Drag and Drop an image" | translate }}</h3>
                    <p>{{ "or browse to choose a file" | translate }}</p>
                </div>
            </div>
        </div>
        <input
            type="file"
            accept="image/*"
            (change)="uploadGiftCardImage($event)"
        />
    </div>
    <img
        mdCard
        class="gv-uploaded-image"
        *ngIf="isCustomGiftCardImage"
        [src]="selectedGiftCardImage.path"
        [alt]="selectedGiftCardImage.name"
        (click)="openModal(modalDetailsForm)"
    />
    <div class="gv-features-container">
        <h3>{{ "Features" | translate }}</h3>
        <ul>
            <li>
                {{ "MariDeal Gift Card has 1 year of validity." | translate }}
            </li>
            <li>
                {{
                    "Upload your photo or multiple gift card designs and denominations to choose from."
                        | translate
                }}
            </li>
            <li>
                {{
                    "Redeemable towards over 500 offers live and for sale at any one time."
                        | translate
                }}
            </li>
            <li>
                {{
                    "Available for immediate delivery. Gift cards sent by email can be scheduled up to year in advance."
                        | translate
                }}
            </li>
            <li>
                {{ "No returns and no refunds on Gift Cards." | translate }}
            </li>
        </ul>
    </div>
</div>
<ng-template #modalTemplateList let-modal>
    <div class="modal-header gc-template-list-modal-container">
        <h4 class="modal-title">
            {{ visibleCardTemplates[selectedTemplateIndex].name }}
        </h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <div class="gct-container">
            <img
                *ngFor="
                    let img of visibleCardTemplates[selectedTemplateIndex]
                        .images;
                    let i = index
                "
                mdCard
                [alt]="img.name"
                [src]="img.path"
                (click)="
                    selectImage(
                        modalDetailsForm,
                        visibleCardTemplates[selectedTemplateIndex],
                        selectedTemplateIndex,
                        i
                    )
                "
            />
        </div>
    </div>
</ng-template>
<ng-template #modalDetailsForm let-modal>
    <div mdMobileOnly class="modal-header gc-form-modal-title-container">
        <h4 class="modal-title">
            {{ "Create Gift Card" | translate }}
        </h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body gc-form-modal-body">
        <md-gc-form
            [selectedGiftCardImage]="selectedGiftCardImage"
            [validation]="giftCardDetails"
            [initialValues]="formData"
            (buyGiftCard)="buyGiftCard()"
            (formDataChanged)="formDataChanged($event)"
        />
    </div>
</ng-template>
