import {Component, VERSION, Input, OnInit} from "@angular/core";
import {BehaviorSubject, Subject, timer} from "rxjs";
import {switchMap, map, takeUntil, tap, pairwise} from "rxjs/operators";
import {trigger, transition, animate, keyframes, style} from "@angular/animations";
import {CommonService} from "../shared/common.service";


@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
    animations: [
        trigger("flip", [
            transition('*=>*', [
                animate(".6s", keyframes([
                    style({transform: "rotateX(0deg)", offset: 0}),
                    style({transform: "rotateX(-90deg)", offset: .5}),
                    style({transform: "rotateX(-180deg)", offset: 1}),
                ]))
            ])
        ])]
})

export class TimerComponent implements OnInit {

    change: boolean = false;
    showShadow = [];
    name = "Angular " + VERSION.major;

    expired$: any = new Subject();
    initialMinutes$: BehaviorSubject<any> = new BehaviorSubject(0);

    @Input('minutes') set in(val: any) {
        this.initialMinutes$.next(val);
    }
    @Input() promotionBanner;
    @Input() promotionBanner_1;
    @Input() is_promotion_started;
    @Input() is_promotion_activated;

    constructor(private commonService: CommonService) {
    }

    ngOnInit(): void {
    }

    value = 0;
    timer$ = this.initialMinutes$.pipe(
        map(minutes => minutes * 60000 + new Date().getTime()),
        switchMap(minutes =>
            timer(0, 1000).pipe(
                map(t => (minutes - new Date().getTime()) / 1000),
                tap(seconds => {
                    if (seconds < 0) {
                        this.expired$.next();
                    }
                }),
                takeUntil(this.expired$),
                map(seconds => ({
                    day: Math.max(Math.floor(seconds / (3600 * 24)), 0),
                    hr: Math.max(Math.floor(seconds % (3600 * 24) / 3600), 0),
                    min: Math.max(Math.floor(seconds % 3600 / 60), 0),
                    s: Math.floor(seconds % 60)
                })),
                map(({day, hr, min, s}) => [
                    day > 9 ? day.toString() : "0" + day.toString(),
                    hr > 9 ? hr.toString() : "0" + hr.toString(),
                    min > 9 ? min.toString() : "0" + min.toString(),
                    s > 9 ? s.toString() : "0" + s.toString()
                ]),
                map(val => val.map(i => i.split("")).reduce((a, b) => [...a, ...b], [])),
                pairwise(),
                map(([old, value]) => {
                    return value.map((x, index) => ({value: x, old: old[index]}))
                })
            )
        )
    );
}
