import { Component, Input } from '@angular/core';
import { DealCondition } from '../../model/product-detail.model';
import { parseMarkdown } from 'src/app/utilities/markdown.utils';

@Component({
    selector: 'md-deal-conditions',
    templateUrl: './deal-conditions.component.html',
    styleUrls: ['./deal-conditions.component.scss'],
})
export class DealConditionsComponent {
    @Input() set dealConditions(value: DealCondition[]) {
        this.dealConditionsMarkup = value.map((condition) => {
            return {
                title: parseMarkdown(condition.conditionTitle),
                content: parseMarkdown(condition.condition),
            };
        });
    }

    dealConditionsMarkup: {
        title: string | Promise<string>;
        content: string | Promise<string>;
    }[] = [];
}
