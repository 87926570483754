import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RecentlyViewedService } from './recently-viewed.service';
import {
    BestSellerEntry,
    RecentlyViewedDto,
    trasnformBestSellerEntryDto,
} from 'src/app/home/home.model';
import { ProductCardEntry } from 'src/app/controls/product-card/product-card.component';
import { CommonService } from 'src/app/shared/common.service';
import { Router } from '@angular/router';
import { DeviceCommsService } from 'src/app/services/device-comms.service';

@Component({
    selector: 'md-recently-viewed',
    templateUrl: './recently-viewed.component.html',
})
export class RecentlyViewedComponent implements OnInit {
    recentlyViewedEntries: BestSellerEntry[];

    constructor(
        private service: RecentlyViewedService,
        private commonService: CommonService,
        private deviceCommsService: DeviceCommsService
    ) {}

    @Output() dealSelected = new EventEmitter<ProductCardEntry>();

    ngOnInit() {
        this.service.getRecentlyViewedData().subscribe({
            next: (response: RecentlyViewedDto) => {
                this.recentlyViewedEntries = response.data.map((entry) =>
                    trasnformBestSellerEntryDto(entry)
                );
            },
            error: () => {
                // TODO: this is not blocking but there should be some error logged
                this.recentlyViewedEntries = [];
            },
        });
    }

    onDealSelected(dealEntry: ProductCardEntry): void {
        this.commonService.clearCustomCache(this);

        const { productPath, id } = dealEntry;
        this.deviceCommsService.openLinkInNewTabIfBrowser(
            `deal/${productPath}/${id}`
        );
        this.dealSelected.emit(dealEntry);
    }
}
