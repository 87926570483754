import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getApiUrlMofluid } from './utilities/url.utils';
import {
    getCustomerId,
    getUser,
    setBadgeCount,
    setRewardPoints,
} from './utilities/user.utils';
import { CommonService } from './shared/common.service';
import { Observable } from 'rxjs';
import { EventService, EventType } from './utilities/event.service';

export interface CustomerPointsAndBalanceDto {
    balance_money: number;
    balance_points: number;
}

export interface RewardPointBadgeDto {
    availed_badges: number;
}

@Injectable({
    providedIn: 'root',
})
export class AppService {
    constructor(
        private httpClient: HttpClient,
        private common: CommonService,
        private eventService: EventService
    ) {}

    refreshPointsAndBalance() {
        this.eventService
            .onEventOrAlreadyHappened(EventType.AUTH_LOADED)
            .subscribe(() => {
                const user = getUser();
                if (!user)
                    // not logged in so this data is not relevant
                    return;
                this.getCustumerPointsAndBalance().subscribe(
                    (response: CustomerPointsAndBalanceDto) => {
                        const points = response.balance_points;
                        setRewardPoints(points);
                    }
                );

                this.getRewardPointBadge().subscribe(
                    (response: RewardPointBadgeDto) => {
                        const badgeCount = response.availed_badges;
                        setBadgeCount(badgeCount);
                    }
                );
            });
    }

    getCustumerPointsAndBalance(): Observable<CustomerPointsAndBalanceDto> {
        return this.httpClient.get<CustomerPointsAndBalanceDto>(
            getApiUrlMofluid() +
                'getCustumerPointsAndBalance?store=' +
                this.common.getStoreID() +
                '&currency=MUR&service=getCustumerPointsAndBalance&customerid=' +
                getCustomerId() +
                '&is_app_login=0&customer_group_id=' +
                this.common.getCustomerGroupID(),
            { headers: this.common.getTokenJWTheaders() }
        );
    }

    getRewardPointBadge() {
        return this.httpClient.get(
            getApiUrlMofluid() +
                'getRewardPointBadge?store=' +
                this.common.getStoreID() +
                '&currency=MUR&service=getRewardPointBadge&customerid=' +
                getCustomerId() +
                '&is_app_login=0&customer_group_id=' +
                this.common.getCustomerGroupID(),
            { headers: this.common.getTokenJWTheaders() }
        );
    }
}
