import { Component, TemplateRef, ViewChild } from '@angular/core';
import { GeneralErrorService } from './general-error.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'md-general-error',
    templateUrl: './general-error.component.html',
    styleUrls: ['./general-error.component.scss'],
})
export class GeneralErrorComponent {
    service: GeneralErrorService;

    private modalRef: NgbModalRef;

    @ViewChild('errorModal') errorModal: TemplateRef<unknown>;

    constructor(service: GeneralErrorService, private modalService: NgbModal) {
        this.service = service;
        this.service.showGeneralErrorEvent.subscribe(() => {
            this.modalRef = this.modalService.open(this.errorModal, {
                centered: true,
            });
        });
    }

    dismissError(): void {
        this.service.dismissError();
        this.modalRef.close();
    }
}
