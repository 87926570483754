<md-profile-wrapper [activeSection]="ProfileSection.TRIPS">
    <div class="trips-parent-div">
        <div class="trip-page-skeleton" *ngIf="isTripData">
            <div *ngFor="let repeat of repeatThis(6)" class="order-skeleton">
                <div class="animated-background order-skeleton-div"></div>
                <div class="box row order-skeleton-div-1">
                    <div
                        class="image-box animated-background order-skeleton-div-2"
                    ></div>
                    <div class="order-skeleton-div-3">
                        <div
                            class="animated-background order-skeleton-div-4"
                            *ngFor="let repeat of repeatThis(4)"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="trips-list-page"
            *ngIf="userLogin && !isTripData"
            class="has-subheader custom-trips-screen-div-1"
        >
            <div class="swipe_div custom-trips-screen-div-2">
                <div
                    class="homeSwiper custom-trips-screen-div-3"
                    [ngStyle]="{
                        'background-color':
                            menuItem.id != selectedCategoryOption
                                ? 'transparent'
                                : 'rgba(111,194, 236, 0.1)'
                    }"
                    *ngFor="let menuItem of categoryData"
                    (click)="categoryOptionSelected(menuItem)"
                >
                    <div
                        class="home-catName-circle custom-trips-screen-div-4"
                        [ngStyle]="{
                            color:
                                menuItem.id != selectedCategoryOption
                                    ? '#0E67DE'
                                    : '#092E41'
                        }"
                    >
                        {{ menuItem.name }}
                    </div>
                </div>
            </div>
            <div class="custom-trips-screen-div-5">
                <div *ngIf="this.divIndex == 0">
                    <div
                        class="custom-trips-screen-div-6"
                        *ngFor="let item of locations"
                    >
                        <div class="custom-trips-screen-div-7">
                            {{ item.name }}
                        </div>
                        <div class="rating-bars custom-trips-screen-div-8">
                            <div
                                [class]="getTripsLineChartColor(item)"
                                id="superbProgress"
                                [ngStyle]="{
                                    width: getLocationPrecent(item) + '%'
                                }"
                            ></div>
                        </div>
                        <div class="custom-trips-screen-div-10">
                            {{ item.count }}
                        </div>
                    </div>
                </div>
                <div
                    class="custom-trips-screen-div-11"
                    *ngIf="this.divIndex == 1"
                >
                    <img
                        class="custom-trips-screen-img-1"
                        src="assets/img/map-account.jpeg"
                        alt="Demo"
                    />
                    <div class="custom-trips-screen-div-12">
                        <span class="custom-trips-screen-span-1 greenm">
                            {{ getLocationCount(2) }}
                        </span>
                        <span class="custom-trips-screen-span-2 skybm">
                            {{ getLocationCount(1) }}
                        </span>
                        <span class="orangem custom-trips-screen-span-3">
                            {{ getLocationCount(4) }}
                        </span>
                        <span class="pinkm custom-trips-screen-span-4">
                            {{ getLocationCount(3) }}
                        </span>
                        <span class="lightgm custom-trips-screen-span-5">
                            {{ getLocationCount(5) }}
                        </span>
                        <span class="bluem custom-trips-screen-span-6">
                            {{ getLocationCount(6) }}
                        </span>
                    </div>
                </div>
            </div>

            <div
                class="custom-trips-screen-div-13"
                style="
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    padding: 0px 15px;
                "
            >
                <div
                    class="custom-trips-screen-div-14"
                    (click)="divIndex = 0"
                    [ngStyle]="{
                        'background-color':
                            divIndex == 0 ? '#0E67DE' : '#c4c4c4'
                    }"
                >
                    <img
                        class="custom-trips-screen-img-2"
                        style="width: 25px"
                        src="assets/img/lineTrip.png"
                    />
                </div>
                <div
                    class="custom-trips-screen-div-15"
                    (click)="divIndex = 1"
                    [ngStyle]="{
                        'background-color':
                            divIndex == 1 ? '#0E67DE' : '#c4c4c4'
                    }"
                >
                    <img
                        class="custom-trips-screen-img-2"
                        style="width: 25px"
                        src="assets/img/mapTrip.png"
                    />
                </div>
            </div>

            <div>
                <div
                    *ngIf="upcomingTrips.length"
                    class="custom-trips-screen-div-16"
                >
                    {{ "Upcoming Trips" | translate }}
                </div>
                <ul
                    class="product1-list custom-trips-screen-ul-3"
                    id="mofluid-list-products"
                    *ngFor="let categoryProduct of upcomingTrips"
                >
                    <li
                        class="product-item product-list-border1 custom-trips-screen-li-3"
                        (click)="showMyTrip(categoryProduct)"
                    >
                        <a class="custom-trips-screen-a-2">
                            <div
                                class="product-image1 custom-trips-screen-div-17"
                            >
                                <img
                                    class="custom-trips-screen-img-3"
                                    src="{{ categoryProduct.image }}"
                                    alt="Image not found"
                                    onerror="this.onerror=null;this.src='assets/img/default_mofluid.jpg';"
                                />
                            </div>

                            <div
                                class="product-name-price-container custom-trips-screen-div-18"
                            >
                                <div
                                    class="listing-product-name custom-trips-screen-div-19"
                                >
                                    {{ categoryProduct.outlet_name }}
                                </div>
                                <div
                                    class="prd-lstng-review custom-trips-screen-div-20"
                                >
                                    <div
                                        class="product-listing-location custom-trips-screen-div-21"
                                    >
                                        <img
                                            src="assets/img/General_Icons_Location_-_AI_Blue3_24x24px.svg"
                                            class="custom-trips-screen-img-4"
                                            s
                                        />{{ categoryProduct.location_name }}
                                    </div>
                                </div>
                                <div class="custom-trips-screen-div-22">
                                    {{ categoryProduct.category }}
                                </div>
                                <div class="custom-trips-screen-div-23">
                                    <div
                                        class="custom-trips-screen-div-24"
                                        *ngIf="
                                            categoryProduct.checkin &&
                                            categoryProduct.checkout
                                        "
                                    >
                                        <div class="custom-trips-screen-div-25">
                                            <img
                                                src="assets/img/ICONS/General Icons_Check In Calendar - AI Blue3 24x24px.svg"
                                                class="custom-trips-screen-img-13"
                                            />
                                            <div
                                                class="custom-trips-screen-div-26"
                                            >
                                                {{
                                                    getTripsDate(
                                                        categoryProduct.checkin
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="custom-trips-screen-div-27"
                                            *ngIf="
                                                categoryProduct.checkin !=
                                                categoryProduct.checkout
                                            "
                                        >
                                            <img
                                                src="assets/img/ICONS/General Icons_Check Out Calendar - AI Blue3 24x24px.svg"
                                                class="custom-trips-screen-img-13"
                                            />
                                            <div
                                                class="custom-trips-screen-div-26"
                                            >
                                                {{
                                                    getTripsDate(
                                                        categoryProduct.checkout
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="custom-trips-screen-div-24"
                                        *ngIf="
                                            !categoryProduct.checkin &&
                                            !categoryProduct.checkout
                                        "
                                    >
                                        <div class="custom-trips-screen-div-25">
                                            <img
                                                src="assets/img/order/tripExpiry.png"
                                                class="custom-trips-screen-img-13"
                                            />
                                            <div
                                                class="custom-trips-screen-div-26"
                                            >
                                                {{ "Expires" | translate }}
                                                {{
                                                    getTripsDate(
                                                        categoryProduct.expiry
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <div class="clearfix row-seperator"></div>
                </ul>
            </div>

            <div>
                <div
                    *ngIf="vouchersTrips.length"
                    class="custom-trips-screen-div-16"
                >
                    {{ "Vouchers" | translate }}
                </div>
                <ul
                    class="product1-list custom-trips-screen-ul-3"
                    id="mofluid-list-products"
                    *ngFor="let categoryProduct of vouchersTrips"
                >
                    <li
                        class="product-item product-list-border1 card custom-trips-screen-li-3"
                        (click)="showMyTrip(categoryProduct)"
                    >
                        <a class="custom-trips-screen-a-2">
                            <div
                                class="product-image1 custom-trips-screen-div-17"
                            >
                                <img
                                    class="custom-trips-screen-img-3"
                                    src="{{ categoryProduct.image }}"
                                    alt=""
                                />
                            </div>

                            <div
                                class="product-name-price-container custom-trips-screen-div-18"
                            >
                                <div
                                    class="listing-product-name custom-trips-screen-div-19"
                                >
                                    {{ categoryProduct.outlet_name }}
                                </div>
                                <div
                                    class="prd-lstng-review custom-trips-screen-div-20"
                                >
                                    <div
                                        class="product-listing-location custom-trips-screen-div-21"
                                    >
                                        <img
                                            src="assets/img/General_Icons_Location_-_AI_Blue3_24x24px.svg"
                                            class="custom-trips-screen-img-4"
                                            s
                                        />{{ categoryProduct.location_name }}
                                    </div>
                                </div>
                                <div class="custom-trips-screen-div-22">
                                    {{ categoryProduct.category }}
                                </div>
                                <div class="custom-trips-screen-div-23">
                                    <div
                                        class="custom-trips-screen-div-24"
                                        *ngIf="
                                            categoryProduct.checkin &&
                                            categoryProduct.checkout
                                        "
                                    >
                                        <div class="custom-trips-screen-div-25">
                                            <img
                                                src="assets/img/ICONS/General Icons_Check In Calendar - AI Blue3 24x24px.svg"
                                                class="custom-trips-screen-img-13"
                                            />
                                            <div
                                                class="custom-trips-screen-div-26"
                                            >
                                                {{
                                                    getTripsDate(
                                                        categoryProduct.checkout
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="custom-trips-screen-div-27"
                                            *ngIf="
                                                categoryProduct.checkin !=
                                                categoryProduct.checkout
                                            "
                                        >
                                            <img
                                                src="assets/img/ICONS/General Icons_Check Out Calendar - AI Blue3 24x24px.svg"
                                                class="custom-trips-screen-img-13"
                                            />
                                            <div
                                                class="custom-trips-screen-div-26"
                                            >
                                                {{
                                                    getTripsDate(
                                                        categoryProduct.checkout
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="custom-trips-screen-div-24"
                                        *ngIf="
                                            !categoryProduct.checkin &&
                                            !categoryProduct.checkout
                                        "
                                    >
                                        <div class="custom-trips-screen-div-25">
                                            <img
                                                src="assets/img/order/tripExpiry.png"
                                                class="custom-trips-screen-img-13"
                                            />
                                            <div
                                                class="custom-trips-screen-div-26"
                                            >
                                                {{ "Expires" | translate }}
                                                {{
                                                    getTripsDate(
                                                        categoryProduct.expiry
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <div class="clearfix row-seperator"></div>
                </ul>
            </div>

            <div>
                <div
                    *ngIf="pastTrips.length"
                    class="custom-trips-screen-div-16"
                >
                    {{ "Past Trips" | translate }}
                </div>
                <ul
                    class="product1-list custom-trips-screen-ul-3"
                    id="mofluid-list-products"
                >
                    <li
                        class="product-item product-list-border1 card custom-trips-screen-li-3"
                        href=""
                        *ngFor="let categoryProduct of pastTrips"
                    >
                        <a
                            class="custom-trips-screen-a-2"
                            href="javascript:void(0)"
                        >
                            <div
                                class="product-image1 custom-trips-screen-div-17"
                            >
                                <img
                                    class="custom-trips-screen-img-3"
                                    src="{{ categoryProduct.image }}"
                                    alt=""
                                />
                            </div>

                            <div
                                class="product-name-price-container custom-trips-screen-div-18"
                            >
                                <div
                                    class="listing-product-name custom-trips-screen-div-19"
                                >
                                    {{ categoryProduct.outlet_name }}
                                </div>
                                <div
                                    class="prd-lstng-review custom-trips-screen-div-20"
                                    *ngIf="categoryProduct.location_name"
                                >
                                    <div
                                        class="product-listing-location custom-trips-screen-div-21"
                                    >
                                        <img
                                            src="assets/img/General_Icons_Location_-_AI_Blue3_24x24px.svg"
                                            class="custom-trips-screen-img-4"
                                        />{{ categoryProduct.location_name }}
                                    </div>
                                </div>
                                <div class="custom-trips-screen-div-22">
                                    {{ categoryProduct.category }}
                                </div>
                                <div class="custom-trips-screen-div-23">
                                    <div
                                        class="custom-trips-screen-div-24"
                                        *ngIf="
                                            categoryProduct.checkin &&
                                            categoryProduct.checkout &&
                                            categoryProduct.checkin !=
                                                '0000-00-00 00:00:00' &&
                                            categoryProduct.checkout !=
                                                '0000-00-00 00:00:00'
                                        "
                                    >
                                        <div class="custom-trips-screen-div-25">
                                            <img
                                                src="assets/img/ICONS/General Icons_Check In Calendar - AI Blue3 24x24px.svg"
                                                class="custom-trips-screen-img-13"
                                            />
                                            <div
                                                class="custom-trips-screen-div-26"
                                            >
                                                {{
                                                    getTripsDate(
                                                        categoryProduct.checkin
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="custom-trips-screen-div-27"
                                            *ngIf="
                                                categoryProduct.checkin !=
                                                categoryProduct.checkout
                                            "
                                        >
                                            <img
                                                src="assets/img/ICONS/General Icons_Check Out Calendar - AI Blue3 24x24px.svg"
                                                class="custom-trips-screen-img-13"
                                            />
                                            <div
                                                class="custom-trips-screen-div-26"
                                            >
                                                {{
                                                    getTripsDate(
                                                        categoryProduct.checkout
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="custom-trips-screen-div-24"
                                        *ngIf="
                                            !categoryProduct.checkin &&
                                            !categoryProduct.checkout &&
                                            categoryProduct.expiry
                                        "
                                    >
                                        <div class="custom-trips-screen-div-25">
                                            <img
                                                src="assets/img/order/tripExpiry.png"
                                                class="custom-trips-screen-img-13"
                                            />
                                            <div
                                                class="custom-trips-screen-div-26"
                                            >
                                                {{ "Expires" | translate }}
                                                {{
                                                    getTripsDate(
                                                        categoryProduct.expiry
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <img (click)="reviewTripsClick(categoryProduct)"  *ngIf="!categoryProduct.pdf_url || categoryProduct.pdf_url==''" src="../../../assets/img/tripsReview.png" style="width: 45px;height: 45px;margin-right: 10px;position: absolute;margin-left: 80%;" /> -->
                                    <a
                                        class="btn review-img ripleEffect"
                                        type="button"
                                        (click)="
                                            openModal(
                                                modalNewReviewForm,
                                                categoryProduct
                                            )
                                        "
                                    >
                                        <img
                                            src="../../../assets/img/tripsReview.png"
                                            class="review-img1"
                                        />
                                    </a>
                                </div>
                            </div>
                        </a>
                    </li>
                    <div class="clearfix row-seperator"></div>
                </ul>
                <div *ngIf="IsLoading" class="loading">
                    {{ "Loading" | translate }}...
                </div>
            </div>

            <div>
                <div
                    class="view_more custom-user-review-div-23"
                    *ngIf="loadmoredatacalled"
                >
                    Loading more
                </div>
            </div>
        </div>

        <div *ngIf="!userLogin" class="custom-trip-sign-up">
            <div>
                <img
                    src="assets/img/ic_my_trips.png"
                    class="custom-trip-sign-up-img"
                />
            </div>
            <div class="custom-trip-sign-up-booking">
                {{ "Sign in to view your bookings" | translate }}
            </div>
            <div class="custom-trip-sign-up-booking">
                {{ "View your bookings" | translate }}
            </div>
            <div class="custom-trip-sign-up-text">
                {{
                    "Did you know? Now you can use MariDeal app to access all Booking Confirmations"
                        | translate
                }}
            </div>
            <div class="custom-trip-sign-up-text1">
                {{ "Its fast, easy and convenient" | translate }}.
            </div>
            <div class="custom-trip-sign-up-text2">
                {{ "No current Trips available" | translate }}
            </div>
            <div
                class="custom-trip-sign-up-login"
                (click)="redirectToLoginPage()"
            >
                {{ "Sign In" | translate }}
            </div>
        </div>
    </div>
</md-profile-wrapper>

<ng-template #modalNewReviewForm let-modal>
    <div mdMobileOnly class="modal-header rw-modal-title">
        <h4 class="modal-title">
            {{ "Reviews by customers only" | translate }}
        </h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body gc-form-modal-body">
        <md-write-review
            [productId]="selectedProductForReviewId"
            (reviewSubmitted)="onReviewSubmitted()"
        ></md-write-review>
    </div>
</ng-template>
