import { Component } from '@angular/core';

@Component({
    selector: 'md-reviews-loading',
    template: `
        <div class="ma-rw-review">
            <div class="ma-rw-review-outlet">
                <div
                    mdSkeleton
                    style="width: 130px; height: 21px; border-radius: 4px"
                ></div>
            </div>
            <div class="ma-rw-review-score">
                <div
                    mdSkeleton
                    style="width: 110px; height: 21px; border-radius: 4px"
                ></div>
            </div>
            <div class="ma-rw-review-detail">
                <div
                    mdSkeleton
                    style="width: 150px; height: 21px; border-radius: 4px"
                ></div>
            </div>
        </div>
        <div class="ma-rw-review">
            <div class="ma-rw-review-outlet">
                <div
                    mdSkeleton
                    style="width: 145px; height: 21px; border-radius: 4px"
                ></div>
            </div>
            <div class="ma-rw-review-score">
                <div
                    mdSkeleton
                    style="width: 110px; height: 21px; border-radius: 4px"
                ></div>
            </div>
            <div class="ma-rw-review-detail">
                <div
                    mdSkeleton
                    style="width: 140px; height: 21px; border-radius: 4px"
                ></div>
            </div>
        </div>
        <div class="ma-rw-review">
            <div class="ma-rw-review-outlet">
                <div
                    mdSkeleton
                    style="width: 130px; height: 21px; border-radius: 4px"
                ></div>
            </div>
            <div class="ma-rw-review-score">
                <div
                    mdSkeleton
                    style="width: 110px; height: 21px; border-radius: 4px"
                ></div>
            </div>
            <div class="ma-rw-review-detail">
                <div
                    mdSkeleton
                    style="width: 150px; height: 21px; border-radius: 4px"
                ></div>
            </div>
        </div>
        <div class="ma-rw-review">
            <div class="ma-rw-review-outlet">
                <div
                    mdSkeleton
                    style="width: 135px; height: 21px; border-radius: 4px"
                ></div>
            </div>
            <div class="ma-rw-review-score">
                <div
                    mdSkeleton
                    style="width: 110px; height: 21px; border-radius: 4px"
                ></div>
            </div>
            <div class="ma-rw-review-detail">
                <div
                    mdSkeleton
                    style="width: 110px; height: 21px; border-radius: 4px"
                ></div>
            </div>
        </div>
    `,
    styles: [
        `
            @import '/src/theme.scss';

            .ma-rw-review {
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 19px 0;
                border-bottom: 1px solid #dee2e6;

                .ma-rw-review-outlet {
                    width: 30%;
                }

                .ma-rw-review-score {
                    width: 25%;
                }

                .ma-rw-review-detail {
                    width: 45%;
                    font-weight: $font-weight-normal;
                }

                @media (max-width: $mobile-width - 1) {
                    flex-direction: column;
                    padding: 12px 16px;
                    border: 1px solid $grey-0;
                    border-radius: $base-spacing * 2.5;
                    margin-bottom: 20px;
                    margin-top: 28px;

                    .ma-rw-review-outlet {
                        width: 100%;
                        margin-bottom: 8px;
                        font-size: 13px;
                        font-weight: 600;
                    }

                    .ma-rw-review-score {
                        width: 100%;
                        margin-bottom: 8px;
                    }

                    .ma-rw-review-detail {
                        width: 100%;
                        margin-bottom: 8px;
                        font-size: 13px;
                        font-weight: 400;
                    }
                }
            }
        `,
    ],
})
export class ReviewsLoadingComponent {
    // Component logic goes here
}
