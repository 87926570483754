<div class="main-div-payment" *ngIf="userLogin">
<!--    <div class="skeleton">-->
<!--        <div class="animated-background"-->
<!--             style="height: 94vh;border: 2px solid white;width: auto;box-sizing: border-box;"></div>-->
<!--    </div>-->
    <div class="checkoutpayment_failurepage" has-header="true" scroll="false">
        <div class="page-side-space">
            <div class="checkoutpayment_failurecontent">
                <div class="payment-failed-messageblock">
                    <div class="payment-failed-emoticon">
                        <img src="assets/img/payment-failed-emoticon.png"/>
                    </div>
                    <div class="payment-failed-msg">
                        <p>{{"We're sorry!" | translate}}</p>
                        <p>{{"There was a problem with payment, unfortunately we could not process your booking request, Please try payment again, use a different card and or select a different payment method" | translate}}
                            .</p>
                    </div>
                </div>
                <div class="height30"></div>
                <div class="securecheckout_textblock">
                    <div class="securecheckout_heading">
                        <h3>{{"Secure Checkout" | translate}}</h3>
                        <p class="securecheckout_text">{{"Your personal details are protected at all times" | translate}}
                            .</p>
                    </div>
                    <div class="sercurecheckout_msg">
                        <p>{{"MariDeal Management, staff or employees at no time will ever see your credit card number, your account name, expiration dates or CVV codes"}}
                            . {{"All details regarding your payment will be processed directly by the Bank, State Bank of Mauritius"}}
                            . {{"All data and personal details are protected by bank at the highest encryption possible" | translate}}
                            .</p>
                    </div>
                </div>
                <button class="button button-block button-positiveBtn-rounded continue_shopping ripleEffect"
                        (click)="continueShoping()">
                    {{localeBtn.continue_shopping}}
                </button>
            </div>
        </div>
    </div>
</div>
