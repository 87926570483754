import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomersAlsoViewedService } from './customers-also-viewed.service';
import { ProductCardEntry } from 'src/app/controls/product-card/product-card.component';
import { transformCustomerAlsoViewedResponse } from './customers-also-viewed.model';
import { CommonService } from 'src/app/shared/common.service';
import { DeviceCommsService } from 'src/app/services/device-comms.service';

@Component({
    selector: 'md-customers-also-viewed',
    templateUrl: './customers-also-viewed.component.html',
    styleUrls: [],
})
export class CustomersAlsoViewedComponent {
    @Input() set productId(value: number) {
        if (!value || value === this.internalProductId) return;
        this.internalProductId = value;
        this.customersAlsoViewedService
            .getCustomersAlsoViewed(value)
            .subscribe((data) => {
                this.customersAlsoViewed =
                    transformCustomerAlsoViewedResponse(data);
            });
    }

    internalProductId: number;
    customersAlsoViewed: ProductCardEntry[];

    @Output() dealSelected = new EventEmitter<ProductCardEntry>();

    constructor(
        private customersAlsoViewedService: CustomersAlsoViewedService,
        private commonService: CommonService,
        private deviceCommsService: DeviceCommsService
    ) {}

    onDealSelected(dealEntry: ProductCardEntry): void {
        this.commonService.clearCustomCache(this);

        const { productPath, id } = dealEntry;
        this.deviceCommsService.openLinkInNewTabIfBrowser(
            `deal/${productPath}/${id}`
        );

        this.dealSelected.emit(dealEntry);
    }
}
