import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { TokenService } from '../services/token.service';
import { DataService } from '../shared/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Locale } from '../config/locale';
import { finalize, Observable } from 'rxjs';
import { LoginResponse, transform, User } from '../model/user.model';
import { getCache, setCache } from '../utilities/cache.utils';
import { getApiUrlMofluid } from '../utilities/url.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { MdDeviceDetectorService } from '../services/device-detector.service';
import { DeviceCommsService } from '../services/device-comms.service';
import { REUNION_NOT_LOGGED_IN_CURR } from '../utilities/location.utils';
import { EventService, EventType } from '../utilities/event.service';
import { map } from 'rxjs/operators';
import { LoginSuccessService } from './login-success.service';
import {
    getUser,
    setBadgeCount,
    setRewardPoints,
} from '../utilities/user.utils';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(
        private httpClient: HttpClient,
        private eventService: EventService,
        private common: CommonService,
        private tokenService: TokenService,
        private router: Router,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private loginSuccessService: LoginSuccessService,
        private dataService: DataService,
        private deviceDetectorService: MdDeviceDetectorService,
        private deviceCommsService: DeviceCommsService,
        private localeObj: Locale,
        private translate: TranslateService
    ) {}

    sendOtp(fullMobileNumber: number) {
        const encryptedNumber = btoa(String(fullMobileNumber));
        const params = new HttpParams()
            .set('service', 'sendCustomerLoginOtp')
            .set('mobile_number', encryptedNumber)
            .set('send_otp_to', 'mobile_login')
            .set('createsession', '1')
            .set('is_real_login', '1');
        return this.httpClient.get(
            getApiUrlMofluid() + 'sendCustomerLoginOtp',
            {
                params: params,
                headers: this.common.getTokenHeaders(),
            }
        );
    }

    verifyOtpLogin(
        customerId: string,
        fullMobileNumber: number,
        outputStr: string
    ) {
        this.spinner.show();
        const isAppLogin = this.deviceDetectorService.isBrowser ? 0 : 1;

        const url = getApiUrlMofluid() + 'verifymobilelogin';
        const params = new HttpParams()
            .set('store', this.common.getStoreID())
            .set('service', 'verifymobilelogin')
            .set('mobile_number', btoa(String(fullMobileNumber)))
            .set('send_otp_to', 'mobile_login')
            .set('otp_number', outputStr)
            .set('customerid', customerId)
            .set('is_real_login', isAppLogin);
        const loginErrMsg =
            this.localeObj.getAlertMessages().invalid_login_otp_message;

        this.sendVerifyLoginRequest(url, params)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe({
                next: (data: User) =>
                    this.processVerifyLoginResponse(data, loginErrMsg),
                error: (error) =>
                    console.debug(
                        "Error while calling extra api's after successful login" +
                            error
                    ),
            });
    }

    private processVerifyLoginResponse(
        data: User,
        loginErrMsg: string,
        isFromRegistrationForm = false
    ): void {
        if (data.isSuccess) {
            this.deviceCommsService.successfullyLogin(data.id);
            this.common.isLoggedIn = true;
            if (parseInt(data.groupId) === REUNION_NOT_LOGGED_IN_CURR) {
                setCache('is_reunion', true);
                this.common.setCustomerGroupID(data.groupId);

                if (data.preferredLanguage) {
                    this.translate.setDefaultLang(data.preferredLanguage);
                    setCache('language', data.preferredLanguage);
                } else {
                    this.translate.setDefaultLang(
                        getCache('language')
                            ? (getCache('language').data as string)
                            : 'en'
                    );
                    setCache(
                        'language',
                        getCache('language') ? getCache('language').data : 'en'
                    );
                }

                setCache('storeID', 2);
                this.common.language = 'fr-reunion';
            } else {
                setCache('is_reunion', false);
                this.common.language = getCache('language')
                    ? (getCache('language').data as string)
                    : '';

                if (!this.common.language) {
                    this.common.setCustomerGroupID(parseInt(data.groupId));
                    this.translate.setDefaultLang('en');
                    setCache('storeID', 1);
                    this.common.language = 'en';
                    setCache('language', 'en');
                } else {
                    this.common.setCustomerGroupID(parseInt(data.groupId));
                    if (this.common.language == 'en') {
                        this.translate.setDefaultLang('en');
                        setCache('storeID', 1);
                        setCache('language', 'en');
                        this.common.language = 'en';
                    } else {
                        this.translate.setDefaultLang('fr');
                        setCache('storeID', 2);
                        setCache('language', 'fr');
                        this.common.language = 'fr';
                    }
                }
            }

            setCache('user', data);
            this.common.side_drawer_user_initial = data.firstName.split('')[0];
            this.common.side_drawer_user = `${data.firstName} ${data.lastName}`;
            this.common.side_drawer_email = data.username
                ? data.username
                : data.email;
            setBadgeCount(data.availableBadges);
            setRewardPoints(data.rewardData.balancePoints);
            this.tokenService.saveJwtToken(data.jwtToken);
            const dataStore = this.common.getDataStoreObj();
            dataStore['loginResponse'] = data;
            this.dataService.setData(dataStore);
            if (isFromRegistrationForm) {
                this.registerMergeCart();
            } else {
                this.mergeCart();
            }
            this.common.getStoreData();
            this.eventService.emitEvent(EventType.LOGIN_SUCCESS);
        } else {
            this.eventService.emitEvent(EventType.LOGIN_FAILED);
            this.eventService.emitEvent(EventType.MODAL_ERROR, loginErrMsg);
            this.common.isLoggedIn = false;
            this.spinner.hide();
        }
    }

    verifyNormalLogin(
        email: string,
        password: string,
        isFromRegistrationForm = false
    ): void {
        this.spinner.show();
        const url = getApiUrlMofluid() + 'verifylogin';
        const isAppLogin = this.deviceDetectorService.isBrowser ? 0 : 1;
        const params = new HttpParams()
            .set('service', 'verifylogin')
            .set('username', email)
            .set('password', password)
            .set('createsession', '1')
            .set('is_real_login', '1')
            .set('is_app_login', isAppLogin);
        const loginErrMsg =
            this.localeObj.getAlertMessages()
                .invalid_username__password_message;

        this.sendVerifyLoginRequest(url, params)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe({
                next: (data: User) =>
                    this.processVerifyLoginResponse(
                        data,
                        loginErrMsg,
                        isFromRegistrationForm
                    ),
                error: (error: never) =>
                    console.debug(
                        "Error while calling extra api's after successful" +
                            ' login' +
                            error
                    ),
            });
    }

    private sendVerifyLoginRequest(
        url: string,
        params: HttpParams
    ): Observable<User> {
        return this.httpClient
            .get<LoginResponse>(url, {
                headers: this.common.getTokenHeaders(),
                params: params,
            })
            .pipe(map((response) => transform(response)));
    }

    /**
     *
     */

    mergeCart() {
        const userData = getUser();
        const uId = this.common.getCustomerGroupID() ? userData.id : '';
        const quoteid = localStorage.getItem('quoteid')
            ? localStorage.getItem('quoteid')
            : '';
        const mergeCart = getApiUrlMofluid() + 'mergeCartItems';
        const params = new HttpParams()
            .set('store', this.common.getStoreID())
            .set('currency', 'MUR')
            .set('service', 'mergeCartItems')
            .set('customerid', uId)
            .set('quoteid', quoteid)
            .set('customer_group_id', this.common.getCustomerGroupID());

        this.httpClient
            .get(mergeCart, {
                params: params,
                headers: this.common.getTokenHeaders(),
            })
            .subscribe({
                next: (response) => {
                    this.spinner.hide();
                    if (response['status'] == 'success') {
                        localStorage.setItem('quoteid', response['quoteid']);
                        setCache(
                            'cartItemsdataCount',
                            response['cart_items_qty']
                        );
                        setCache('cartItemsdata', response);

                        const params =
                            this.router.getCurrentNavigation()?.extras.state;

                        if (
                            params &&
                            params['category_id'] &&
                            params['product_id'] &&
                            params['canonical_url_last']
                        ) {
                            this.router.navigate(
                                ['deals/' + params['canonical_url_last'] + '/'],
                                {
                                    state: {
                                        productId: params['product_id'],
                                        advancesearch: null,
                                        canonical_url_last:
                                            params['canonical_url_last'],
                                        is_booking_enabled:
                                            params['is_booking_enabled'],
                                    },
                                }
                            );
                        } else {
                            this.router
                                .navigateByUrl(
                                    this.loginSuccessService
                                        .loginSuccessRedirectPath
                                )
                                .then(() => {
                                    if (
                                        this.loginSuccessService.loginSuccessRedirectPath.includes(
                                            'rewardpoints'
                                        )
                                    ) {
                                        // window.location.reload();
                                    }
                                });
                        }
                    } else {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            response['msg']
                        );
                    }
                },
                error: () => {
                    this.spinner.hide();
                    this.eventService.emitEvent(
                        EventType.MODAL_ERROR,
                        this.localeObj.getAlertMessages().server_not_responding
                    );
                },
            });
    }

    registerMergeCart() {
        const userData = getUser();
        const uId = userData
            ? userData.id
            : (getCache('dummy_cust_id').data as string | number);

        const quoteid = localStorage.getItem('quoteid')
            ? localStorage.getItem('quoteid')
            : '';

        const mergeCart = getApiUrlMofluid() + 'mergeCartItems';
        const params = new HttpParams()
            .set('store', this.common.getStoreID())
            .set('currency', 'MUR')
            .set('service', 'mergeCartItems')
            .set('customerid', uId)
            .set('quoteid', quoteid)
            .set('customer_group_id', this.common.getCustomerGroupID());

        this.httpClient
            .get(mergeCart, {
                params: params,
                headers: this.common.getTokenHeaders(),
            })
            .subscribe({
                next: (response) => {
                    if (response['status'] == 'success') {
                        this.spinner.hide();
                        localStorage.setItem('quoteid', response['quoteid']);
                        setCache(
                            'cartItemsdataCount',
                            response['cart_items_qty']
                        );
                        setCache('cartItemsdata', response);
                        this.router
                            .navigateByUrl(
                                this.loginSuccessService
                                    .loginSuccessRedirectPath
                            )
                            .then(() => {
                                if (
                                    this.loginSuccessService.loginSuccessRedirectPath.includes(
                                        'rewardpoints'
                                    )
                                ) {
                                    setCache('welcome_pop_up', true);                             
                                    // window.location.reload();
                                }
                            });
                    } else {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            this.localeObj.getAlertMessages()
                                .login_success_message
                        );
                    }
                },
                error: () => {
                    this.spinner.hide();
                    this.eventService.emitEvent(
                        EventType.MODAL_ERROR,
                        this.localeObj.getAlertMessages().login_success_message
                    );
                },
            });
    }
}
