import { NgModule } from '@angular/core';
import { SkeletonDirective } from './skeleton/skeleton.directive';
import { CardDirective } from './card/card.directive';
import { DesktopOnlyDirective } from './resizers/desktop-only.directive';
import { MobileOnlyDirective } from './resizers/mobile-only.directive';
import { TextButtonDirective } from './buttons/text-button/text-button.directive';
import { MainButtonDirective } from './buttons/main-button/main-button.directive';
import { ShowOnMinDirective } from './resizers/show-on-min.directive';
import { DatePickerInput } from './datepicker/datepicker.component';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdDatepickerPopupModule } from '../datepicker-popup/datepicker-popup.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarComponent } from './calendar/calendar.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { CounterComponent } from './counter/counter.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { ProductCardLoading } from './product-card/product-card.loading';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { ProductCardListComponent } from './product-card-list/product-card-list.component';
import { LoadMoreComponent } from './load-more/load-more.component';
import { OccupancyComponent } from './occupancy/occupancy.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DateBadgeComponent } from './date-badge/date-badge.component';
import { PointsDiscountBadgeComponent } from './points-discount-badge/points-discount-badge.component';
import { ProductListItemComponent } from './product-list-item/product-list-item.component';
import { ProductListItemLoadingComponent } from './product-list-item/product-list-item.loading';
import { PopupOffcanvasComponent } from './popup-offcanvas/popup-offcanvas.component';
import { MdLogoComponent } from './md-logo/md-logo.component';
import { ModalOffcanvasComponent } from './modal-offcanvas/modal-offcanvas.component';
import { CoreModule } from '../core/core.module';
import { ReviewScoreBarComponent } from './review-score-bar/review-score-bar.component';
import { MeterComponent } from './meter/meter.component';

@NgModule({
    imports: [
        CoreModule,
        NgbdDatepickerPopupModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
        NgbModule,
        LazyLoadImageModule,
        NgxUsefulSwiperModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    declarations: [
        SkeletonDirective,
        CardDirective,
        DesktopOnlyDirective,
        MobileOnlyDirective,
        ShowOnMinDirective,
        TextButtonDirective,
        MainButtonDirective,
        DatePickerInput,
        CalendarComponent,
        CounterComponent,
        ProductCardComponent,
        ProductCardLoading,
        ProductCardListComponent,
        LoadMoreComponent,
        OccupancyComponent,
        DateBadgeComponent,
        PointsDiscountBadgeComponent,
        ProductListItemComponent,
        ProductListItemLoadingComponent,
        PopupOffcanvasComponent,
        MdLogoComponent,
        ModalOffcanvasComponent,
        ReviewScoreBarComponent,
        MeterComponent,
    ],
    exports: [
        SkeletonDirective,
        CardDirective,
        DesktopOnlyDirective,
        MobileOnlyDirective,
        ShowOnMinDirective,
        TextButtonDirective,
        MainButtonDirective,
        DatePickerInput,
        CalendarComponent,
        CounterComponent,
        ProductCardComponent,
        ProductCardLoading,
        ProductCardListComponent,
        LoadMoreComponent,
        OccupancyComponent,
        DateBadgeComponent,
        PointsDiscountBadgeComponent,
        ProductListItemComponent,
        ProductListItemLoadingComponent,
        PopupOffcanvasComponent,
        MdLogoComponent,
        ModalOffcanvasComponent,
        ReviewScoreBarComponent,
        MeterComponent,
    ],
    providers: [DecimalPipe],
})
export class ControlsModule {}

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
