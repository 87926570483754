import * as Sentry from '@sentry/angular-ivy';
import { MARIDEAL_PWA_USER } from './local-storage.keys';

/**
 * There appear to be relics in localstorage that are no longer used. This function will remove them.
 */
export const removeGunk = (): void => {
    try {
        if (localStorage.getItem('chk_toggle') == '1') {
            localStorage.removeItem('chk_toggle');
        }
        if (localStorage.getItem('searchbar_toggle') == '1') {
            localStorage.removeItem('searchbar_toggle');
        }
        if (localStorage.getItem(`${MARIDEAL_PWA_USER}_server_time`)) {
            localStorage.removeItem(`${MARIDEAL_PWA_USER}_server_time`);
        }
        if (localStorage.getItem('isFav') == 'true') {
            localStorage.removeItem('isFav');
        }
        if (localStorage.getItem('marideal-pwa_menuItems')) {
            localStorage.removeItem('marideal-pwa_menuItems');
        }
    } catch (error) {
        Sentry.captureException(error);
    }
};
