import {Injectable} from '@angular/core';

const TOKEN_KEY = 'auth-token';
const JWT_TOKEN_KEY = 'jwt-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() {
  }

  /**
   * Method to signout
   */

  signOut(): void {
    window.localStorage.clear();
  }

  /**
   * Method to save token
   * @param token
   */

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  /**
   * Method to save JWT token
   * @param token
   */

  public saveJwtToken(token: string) {
    window.localStorage.removeItem(JWT_TOKEN_KEY);
    window.localStorage.setItem(JWT_TOKEN_KEY, token);
  }

  /**
   * Method to get token
   */

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  /**
   * Method to get JWT token
   */

  public getJwtToken(): string | null {
    return window.localStorage.getItem(JWT_TOKEN_KEY);
  }

  /**
   * Method to save user in session
   * @param user
   */

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  /**
   * Method to get user from session variable
   */

  public getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }
}
