import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private dataSource = new BehaviorSubject<any>({});

    constructor() {}

    /**
     * Method to get shared data
     */

    public getData(): Observable<any> {
        return this.dataSource.asObservable();
    }

    /**
     * Method to set shared data
     * @param data
     */

    public setData(data: any): void {
        return this.dataSource.next(data);
    }
}
