import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { getApiUrlMofluid } from 'src/app/utilities/url.utils';
import { getCustomerId } from 'src/app/utilities/user.utils';
import { ProductServicePricingDto } from './hotel-booking.model';

@Injectable({
    providedIn: 'root',
})
export class HotelBookingService {
    constructor(
        private httpClient: HttpClient,
        private commonService: CommonService
    ) {}

    getMultiplePrices(
        productid: number,
        catId: number,
        // this is the actual option id (on custom_option_value_array)
        optId: number
    ): Observable<ProductServicePricingDto> {
        const customerId = getCustomerId();
        return this.httpClient.get<ProductServicePricingDto>(
            getApiUrlMofluid() +
                'multipleprices?service=multipleprices&store=' +
                this.commonService.getStoreID() +
                '&productid=' +
                productid +
                '&categoryid=' +
                catId +
                '&optionid=' +
                optId +
                '&customerid=' +
                customerId +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID(),
            { headers: this.commonService.getTokenHeaders() }
        );
    }
}
