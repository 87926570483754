<div
    class="lmp-globe"
    [ngClass]="{ 'lmp-globe--dark': isDark }"
    (click)="openModal(content)"
>
    <div>
        <ion-icon class="lmp-globe-icon" name="globe-outline"></ion-icon>
    </div>
    <div *ngIf="isCountryVisible">
        <p class="para">{{ storeCurName }} ({{ selectedCountryLanguage }})</p>
    </div>
</div>

<ng-template #content let-modal id="country-picker-modal">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            {{ "Choose settings" | translate }}
        </h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <form class="modalform">
            <div class="drp1" [hidden]="countryPickerHidden">
                <div>
                    <label id="Citizenship">
                        {{ "Country of Citizenship or Residency" | translate }}
                    </label>
                </div>
                <label for="Country" id="count"></label>
                <select
                    id="Country"
                    (change)="switchCurrency($event.target.value)"
                >
                    <option *ngIf="storeCurName">{{ storeCurName }}</option>
                    <option *ngFor="let curr of currency" [value]="curr.code">
                        {{ curr.name }}
                    </option>
                </select>
            </div>

            <div class="drp2">
                <div>
                    <label id="lang"> {{ "Language" | translate }} </label>
                </div>
                <label for="Country" id="count"></label>
                <select
                    id="Country"
                    (change)="switchLanguage($event.target.value)"
                >
                    <option *ngIf="storeLangName">
                        {{ storeLangName }}
                    </option>
                    <option *ngFor="let lang of languages" [value]="lang.code">
                        {{ lang.name }}
                    </option>
                </select>
            </div>

            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-primary modalbtn"
                    (click)="applyLangBtn($event)"
                    (click)="modal.close('Save click')"
                    data-mdb-ripple-color="dark"
                >
                    {{ "Apply" | translate }}
                </button>
            </div>
        </form>
    </div>
</ng-template>
