import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../shared/common.service';
import { getCustomerId } from '../utilities/user.utils';
import { getApiUrlMofluid, getApiUrlV2 } from '../utilities/url.utils';
import { Observable, forkJoin, switchMap, take } from 'rxjs';
import {
    CurrencyLanguageService,
    LocaleData,
} from '../shared/currency-language.service';
import { ProductDetailsDto } from './model/product-detail.dto.model';
import { ProductDetailsDtoV2 } from './model/product-detail.model';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(
        private httpClient: HttpClient,
        private commonService: CommonService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}

    addToRecent(pId: number) {
        const apiUrl = getApiUrlMofluid();
        const customerId = getCustomerId();
        const customerGroupId = this.commonService.getCustomerGroupID();
        const url = `${apiUrl}addNewRecentProduct?service=addNewRecentProduct&customerid=${customerId}&productid=${pId}&customer_group_id=${customerGroupId}`;
        return this.httpClient.get(url, {
            headers: this.commonService.getTokenHeaders(),
        });
    }

    getProductDetailsV2(
        productId: number,
        productUrl: string,
        fromDate: string,
        toDate: string
    ): Observable<[ProductDetailsDto, ProductDetailsDtoV2]> {
        const call1$ = this.httpClient
            .get<ProductDetailsDto>(
                this.buildProductDetailsUrl(
                    productId,
                    productUrl,
                    fromDate,
                    toDate
                ),
                {
                    headers: this.commonService.getTokenHeaders(),
                }
            )
            .pipe(
                take(1) // Ensure completion
            );
        const call2$ = this.currencyLanguageService.getLocaleData().pipe(
            switchMap((localeData: LocaleData) =>
                this.httpClient.get<ProductDetailsDtoV2>(
                    `${getApiUrlV2()}results/deals/pdp?lang=${
                        localeData.language
                    }&cc=${localeData.country}&pid=${productId}`
                )
            ),
            take(1) // Ensure completion
        );

        return forkJoin([call1$, call2$]);
    }

    private buildProductDetailsUrl(
        productId: number,
        productUrl: string,
        fromDate: string,
        toDate: string
    ): string {
        const customerId = getCustomerId();
        const storeId = this.commonService.getStoreID();
        const customerGroupId = this.commonService.getCustomerGroupID();
        const productIdUrlPart = productId ? `&productid=${productId}` : '';
        const productUrlUrlPart = productUrl
            ? `&product_url=${productUrl}`
            : '';
        return `${getApiUrlMofluid()}productdetaildescription?service=productdetaildescription&store=${storeId}${productUrlUrlPart}${productIdUrlPart}&customerid=${customerId}&customer_group_id=${customerGroupId}&checkin=${fromDate}&checkout=${toDate}&checkout=${toDate}`;
    }
}
