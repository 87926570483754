<div *ngIf="productHasAddOns">
    <div *ngFor="let addonGroup of productAddOns" class="bc-ao-group">
        <div class="bc-ao-group-title">{{ addonGroup.name }}</div>
        <div *ngFor="let addon of addonGroup.items" class="bc-ao-item">
            <div>
                <div class="bc-ao-item-title">
                    {{ addon.title }}
                </div>
                <div class="bc-ao-item-price">
                    {{
                        addon.price
                            | currency
                                : currencySymbol
                                : "symbol"
                                : "1.0-2"
                                : locale
                    }}
                </div>
            </div>
            <md-counter
                [maxValue]="addon.maxQty"
                (valueChanged)="onQuantityChanged(addon, $event)"
            />
        </div>
    </div>
</div>
